const navber_files = require.context('@/views/navber', true, /\.vue$/),
    navber = {};
let children = [];

navber_files.keys().forEach(key => {
    navber[key.replace(/(\.\/|\.vue)/g, '')] = navber_files(key).default
})

Object.keys(navber).forEach(item => {
    children.push({
        name: item === "home" ? "/" : item,
        path: item === "home" ? "/" : "/" + item,
        component: () => import('@/views/navber/' + item)
    })
})

export default children;