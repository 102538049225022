<template>
  <div>
    <!-- 顶部导航栏 -->
    <Nav name="mine" />
    <section>
      <div>
        <b-container>
          <div-height height="40px"></div-height>
          <div-text color="#1D2124" size="18px" center>{{this['push_title'+$t('suffix')]}}</div-text>
          <div-height height="10px"></div-height>

          <section>
            <div-padding padding="20px 0">
              <b-row no-gutters>
                <b-col cols="auto" class="mr-auto">
                  <div-left center>
                    <div-text color="#595A5F" size="12px" center>{{create_time}}</div-text>
                  </div-left>
                </b-col>
                <b-col cols="auto">
                  <b-breadcrumb>
                    <b-breadcrumb-item @click="go('mine')">{{$t('我的')}}</b-breadcrumb-item>
                    <b-breadcrumb-item @click="go('message')">{{$t('信息通知')}}</b-breadcrumb-item>
                    <b-breadcrumb-item active>{{$t('消息详情')}}</b-breadcrumb-item>
                  </b-breadcrumb>
                </b-col>
              </b-row>
            </div-padding>
          </section>

          <div class="windows">
            <div-html :html="this['push_cont'+$t('suffix')]"></div-html>
          </div>
        </b-container>
      </div>
    </section>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: "",
      push_title: "",
      push_title_en: "",
      push_title_zh: "",
      create_time: "",
      push_cont: "",
      push_msg_en: "",
      push_msg_zh: "",
      item_id:''
    }
  },
  created() {
    //获取路由上的参数
    this.urlparam()
   
    //获取信息
    this.get_info()
    this.get_under_num()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/Pushnotis/pagedata",
        data: {
          id: this.id,
        },
        success: (res) => {
          // this.original = res.data
          let obj = res.data.original
          Object.keys(obj).map((item) => (this[item] = obj[item]))
        },
        tip: () => {},
      })
    },
    get_under_num() {
      this.$post({
        url: "/index/Pushnotisitems/read",
        data: {
          id: this.item_id,
        },
        success: (res) => {
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
//保证内容最小高度为一屏。防止底部塌陷
.windows {
  min-height: calc(100vh - 360px);
}
</style>