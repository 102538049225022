import Vue from 'vue'
import App from './App'
import router from './router/index'
import http from './request/http'

// import { Cascader, Input, Empty, Button } from 'element-ui';
// Vue.component(Cascader.name, Cascader);
// Vue.component(Input.name, Input);
// Vue.component(Empty.name, Empty);
// Vue.component(Button.name, Button);
import ElementUI from 'element-ui'

import 'element-ui/lib/theme-chalk/index.css'   //引用 element-ui 样式

Vue.use(ElementUI)  //最后要use ElementUI ，不然会报错

import NP from "number-precision"
Vue.prototype.$np = NP;

// import VConsole from 'vconsole';
// const vConsole = new VConsole();
// // 接下来即可照常使用 `console` 等方法
// console.log('Hello world');




// import vue_fun from './mixin/fun.js';
// Vue.use(vue_fun);


// import 'vant/lib/index.css';

import './assets/css/reset.css';

// import * as echarts from 'echarts';
// Vue.prototype.$echarts = echarts;


import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import './assets/scss/custom.scss'

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);


// import { WOW } from 'wowjs'
import animated from './assets/css/animate.css'

//这里需要将样式用起来，
Vue.use(animated)
//创建全局实例
// Vue.prototype.$wow = new WOW({
//   boxClass: 'wow', // 需要执行动画的元素的 class
//   animateClass: 'animated', //animation.css 动画的 class
//   offset: 0, // 距离可视区域多少开始执行动画
//   mobile: true, // 是否在移动设备上执行动画
//   live: false, // 异步加载的内容是否有效
//   callback: function (box) {
//     console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
//   }

// })


// Vue.prototype.$scrollReveal = scrollReveal();

import 'swiper/dist/css/swiper.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)


import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


const options = {
  // You can set your default options here
};


Vue.use(Toast, options);




Vue.use(http)
Vue.config.productionTip = false;
//复制粘贴
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)
//常规验证码 运算验证码 滑动验证码 拼图验证码 选字验证码
// import Verify from 'vue2-verify'
// Vue.component('Verify', Verify),

import Mixin from './mixin/div-mixin.js';
Vue.mixin(Mixin);

// import CommonAnim from './mixin/CommonAnim.js';
// Vue.mixin(CommonAnim);

// 导入资源文件
// import { i18n, vantLocales } from '@/i18n'
// vantLocales(i18n.locale)

import { i18n } from '@/i18n'

// import VPerfectSignature from "v-perfect-signature"
// Vue.use(VPerfectSignature)

// import store from "./store/index.js" //引入你新建的store文件

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
