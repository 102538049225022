<template>
  <transition name="van-fade">
    <!-- <div v-if="visible" @click.stop="handleClick" :style="{
        right: styleRight,
        bottom: styleBottom,
      }" class="xl-backtop">
      <slot>
      
      </slot>
    </div> -->

    <!-- Scroll-top -->
    <button class="scroll-top" :class="{open:visible}" @click.stop="handleClick">
      <!-- <span aria-hidden="true" class="carousel-control-prev-icon"></span> -->

      <!-- <b-arrow-bar-up></b-arrow-bar-up> -->
      <b-icon icon="arrow-bar-up"></b-icon>
    </button>
    <!-- Scroll-top-end-->

  </transition>
</template>
 
<script>
// import { _throttle } from "@/utils";

const cubic = (value) => Math.pow(value, 3)
const easeInOutCubic = (value) =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2

export default {
  name: "XlBacktop",

  props: {
    visibilityHeight: {
      type: Number,
      default: 200,
    },
    target: [String],
    right: {
      type: Number,
      default: 40,
    },
    bottom: {
      type: Number,
      default: 80,
    },
  },

  data() {
    return {
      el: null,
      container: null,
      visible: false,
    }
  },

  computed: {
    styleBottom() {
      return `${this.bottom}px`
    },
    styleRight() {
      return `${this.right}px`
    },
  },

  mounted() {
    this.init()
    this.throttledScrollHandler = this._throttle(this.onScroll, 300)
    this.container.addEventListener("scroll", this.throttledScrollHandler)
  },

  methods: {
    _throttle(func, ms = 1000) {
      let canRun = true
      return function (...args) {
        if (!canRun) return
        canRun = false
        setTimeout(() => {
          func.apply(this, args)
          canRun = true
        }, ms)
      }
    },
    init() {
      this.container = document
      this.el = document.documentElement
      if (this.target) {
        this.el = document.querySelector(this.target)
        if (!this.el) {
          throw new Error(`target is not existed: ${this.target}`)
        }
        this.container = this.el
      }
    },
    onScroll() {
      // 这里，如果document.documentElement.scrollTop 的值为0，就获取document.body.scrollTop
      const scrollTop = this.el.scrollTop || document.body.scrollTop
      this.visible = scrollTop >= this.visibilityHeight
    },
    handleClick(e) {
      this.scrollToTop()
      this.$emit("click", e)
    },
    scrollToTop() {
      // 还有这里，如果document.documentElement.scrollTop 的值为0，就获取document.body元素
      const el = this.el.scrollTop ? this.el : document.body
      const beginTime = Date.now()
      const beginValue = el.scrollTop
      const rAF =
        window.requestAnimationFrame || ((func) => setTimeout(func, 16))
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress))
          rAF(frameFunc)
        } else {
          el.scrollTop = 0
        }
      }
      rAF(frameFunc)
    },
  },

  beforeDestroy() {
    this.container.removeEventListener("scroll", this.throttledScrollHandler)
    this.el = null
  },
}
</script>
<style scoped >
/* // 返回顶部 */
/* .xl-backtop {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: fixed;
  width: 12vw;
  height: 12vw;
  padding: 3vw;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.2);
} */

/* scrollUp */
.scroll-top {
  width: 45px;
  height: 45px;
  line-height: 45px;
  position: fixed;
  bottom: 105%;
  right: 30px;
  font-size: 16px;
  border-radius: 0;
  z-index: 99;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background: #0f1724;
  transition: 1s ease;
  border: none;
  opacity: 0;
}
.scroll-top.open {
  bottom: 30px;
  opacity: 1;
}
.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.scroll-top:hover {
  /* background: #57b846; */
}
</style>