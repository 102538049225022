<template>
  <div class="div-height" :style="'height:'+height+';background: '+bg+';'">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {
      height: {
        type: String,
        default: ''
      },
      bg: {
        type: String,
        default: 'transparent'
      },
    },
    data() {
      return {
      };
    },
    created() {
    },

    methods: {
    },
  };

</script>
<style lang='scss' scoped>
  .div-height {
    width: 100%;
  }
</style>
