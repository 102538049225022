module.exports = {
  '首页': 'Home',
  '汽车租赁': 'Car Rental',
  '新车到店': 'New Arrivals',
  '商城中心': 'Online Shop',
  '关于我们': 'About Us',
  '登录': 'Log in',
  '注册': 'Register',
  '新车推荐': 'New Arrivals',
  '豪华自有其道，全新车款推荐': 'New Arrivals Recommendations.',
  '热门车款推荐': 'Our Recommendations',
  '最热门车款推荐，满足您的租赁需求': 'The best rental to meet your needs',
  '服务特色': 'Service Features',
  '一周七天服务': 'Contact us around the clock',
  '取车无忧，代驾到府': 'Deliver to your door',
  '全程安全保障': 'Safety is our priority',
  '全球交通指引': 'GPS/Navigation available',
  '企业概况': 'Enterprise profile',
  // '关于我们': 'About Us',
  '公司简介': 'Our Company',
  '服务项目': 'Our Services',
  '了解更多': 'Learn More',
  '门店地点': 'Our Location',
  // '商城中心': 'Online Shop',
  // '关于我们': 'About Us',
  '如何租赁': 'How to Rent',
  '联系客服': 'Contact Us',
  '充值中心': 'Top Up my online account',
  '联系方式': 'Contact Us',
  '公司地址': 'Location',
  // '版权所有：沛豪国际租车': 'Copyright: Peihao International Car Rental',
  '请输入要搜索的内容': 'Search',
  '汽车租赁2': 'Vehicle Rental',
  '让我们发现最完美的汽车': 'Discover the best car for you',
  '车种': 'Body Type',
  '请选择车种': 'Select Body Type',
  '品牌': 'Brand',
  '请选择品牌': 'Select Brand',
  '型号': 'Model',
  '请选择型号': 'Select Model',
  '年份': 'Years',
  '请选择年份': 'Select Year',
  '立即充值': 'Top up my account now',
  '驾照信息提交': 'Submit your info',
  '车辆选型': 'Select Vehicle',
  '立即预约': 'Book Now',
  '租赁合同签署': 'Sign and make payment',
  '租赁时间': 'Enjoy yout Trip!',
  '取还车方式': 'Return car', //待确认
  '租金明细': 'Check vehicle',
  '支付押金': 'Return the security deposit',
  // '请输入要搜索的内容': '请输入要搜索的内容',
  // '汽车租赁2': '汽车租赁',
  // '让我们发现最完美的汽车': '让我们发现最完美的汽车',
  // '车种': '车种',
  // '请选择车种': '请选择车种',
  // '品牌': '品牌',
  // '请选择品牌': '请选择品牌',
  // '型号': '型号',
  // '请选择型号': '请选择型号',
  // '年份': '年份',
  // '请选择年份': '请选择年份',
  '租赁详情': 'Rental Details',
  '车辆信息': 'Vehicle Details',
  // '车种': 'Body Type',
  // '品牌': 'Brand',
  // '型号': 'Model',
  // '年份': 'Years',
  '租赁介绍': 'Rental introduction',
  // '租赁时间': 'Rental Period',
  '天': 'day',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  '天以上': '+ Days',
  '每日租金': 'Rent/ Per day',
  '每日可跑公里数': 'Km/ Per day',
  '超出公里数费用': 'Overmileage fee',
  '车体险自付額500元方案': 'Car body insurance self payment amount ( $500 deductible)',
  '车体险自付額2500元方案': 'Car body insurance self payment amount ( $2500 deductible)',
  '新车险费用(单日价格)': 'New Vehicle Replacement Policy (Per Day)',
  '租赁介绍价格费用不包含税费，最终租赁价格根据订单明细为准。': "Rental introduction price doesn't include tax.",
  '预约车辆': 'Book A Reservation',
  '详情/服务': 'Details / Service',
  // '如何租赁': '如何租赁',
  // '如何租赁': '下單流程',
  // '新车到店': 'New Arrivals',
  // '豪华自有其道，全新车款推荐': '獨具品味，風格多變。全新車款推薦',
  '详情介绍': 'Details...',
  '新车详情': 'New Arrivals Details',
  // '商城中心': '商城中心',
  '商城详情': 'Details',
  // '详情介绍': 'Details...',
  '购买则可立即使用': 'Buy and Use Now',
  '价格': 'Price',
  '销量': 'Sales',
  '立即购买': 'Buy Now',
  // '价格': 'Price',
  '数量': 'Quantities',
  '总价': 'Total',
  '商城购买': 'Buy Now',
  '支付方式': 'Payment Method',
  '信用卡支付': 'Credit Card',
  '支付金额': 'Payment Amount',
  '余额支付': 'Pay the Balance',
  '支付密码': 'Payment Code',
  '请输入支付密码': 'Please enter the Payment Code',
  '可用余额': 'Check Balance',
  '支付': 'Pay',
  '微信支付': 'WeChat Pay',
  '微信扫码支付': 'Scan The WeChat Pay QR Code',
  '支付宝支付': 'Alipay',
  '支付宝扫码支付': 'Scan The Alipay QR Code',
  // '公司简介': 'Our Company',
  // '服务项目': '服務項目',
  '凭借着可靠的品质和优秀的越野性能深受顾客的喜爱': 'Reliable and excellent off-road performance proofed by our customers',
  '公司简介2': 'Company Profile',
  // '公司简介': 'Our Company',
  "豪华自有其道，全新数位化专享车旅程": "A new luxurious digital exclusive journey in style",
  // 首頁
  "服务项目2": "Service",
  // EMAIL
  '请输入EMAIL': 'Please enter your email',
  '登录密码': 'Password',
  '请输入登录密码': 'Please enter your password',
  // '注册': '註冊',
  '忘记密码？': 'Forget your password?',
  '我已阅读并同意': 'I have read and agree ',
  "《用户协议》与 《隐私政策》": "the privacy policy and terms of use",// '登录': '登錄',
  '注册信息': 'My profile',
  '隐私权政策与服务条款': 'Privacy Policy and Terms of Service',
  // 首頁/用戶登錄/用戶協議與隱私條款
  // EMAIL
  // '请输入EMAIL': '请输入EMAIL',
  '验证码': 'CAPTCHA',
  '请输入验证码': 'Please enter your CAPTCHA',
  // '登录密码': 'Password',
  // '请输入登录密码': 'Please enter your password',
  '重复密码': 'Password',
  '请再次输入登录密码': 'Please enter your password again',
  // 我已阅读并同意《用户协议》与《隐私政策》
  '下一步': 'Next Step',
  '用户协议与隐私条款': 'User Agreement and Privacy Policy',
  // '隐私权政策与服务条款': 'Privacy Policy and Terms of Service',
  '订单中心': 'My Orders',
  '租车订单': 'Rental orders',
  '核销订单': 'Write off order',
  '我的钱包': 'My wallet',
  '充值钱包': 'Recharge My Wallet',
  '租车押金': 'Rental Secutiry Deposit',
  '系统设置': 'Settings',
  '信息通知': 'News',
  '客服咨询': 'Customer Service',
  '绑定银行卡': 'Link to Bank Account',
  // '登录密码': 'Password',
  '支付密码': 'Payment Password',
  '退出系统': 'Exit',
  '个人信息': 'My Profile',
  '点击查看': 'Click',
  '汽车驾照': 'Driver license',
  '未提交': 'unverified',
  '审核中': 'on pending',
  '通过': 'Approved',
  '不通过': 'Unapproved',
  // '我的钱包': 'My wallet',
  // '充值钱包': 'Recharge My Wallet',
  '租车押金2': 'Rental Deposit',
  // '租车订单': 'Rental orders',
  '全部': 'All',
  '待付款': 'Unpaid',
  '待取车': 'Waiting for Pick Up',
  '已取车': 'Already Picked Up',
  '车辆类型': 'Body Type',
  '车辆品牌': 'Vehicle Brand',
  '车辆型号': 'Vehicle Model',
  '车辆年份': 'Vehicle Year',
  "查看详情": "Details",
  // '核销订单': 'Write off order',
  // '全部': 'All',
  '待使用': 'Inactive',
  '已使用': 'Used',
  '已过期': 'Expired',
  '订单号码': 'Order Number',
  '交易单号': 'Transaction Number',
  '支付方式': 'Payment Method',
  '支付时间': 'Payment Time',
  '有效期至': 'Expiration Date',
  // "查看详情": "Details",
  // '系统设置': 'Settings',
  // '信息通知': 'News',
  // '客服咨询': 'Customer Service',
  // '绑定银行卡': 'Link to Bank Account',
  // // '登录密码': 'Password',
  // '支付密码': 'Payment Password',
  // '退出系统': 'Exit',
  // '个人信息': 'My Profile',
  // '个人信息': 'My Profile',
  '头像': 'My photo',
  "Email帐号": 'Email Account',
  "更换Email帐号": 'Change My Email Account',
  '护照号码': 'Passport number',
  '姓名（名+姓）': 'Name ( First name + Last name)',
  '出生日期': 'Date of Birth',
  '性别': 'Gender',
  '男': 'Male',
  '女': 'Female',
  '移动电话': 'Mobile',
  '户籍地址': 'Address',
  '紧急联系人': 'Emergency contact',
  '关系': 'Relationship',
  '紧急联系人电话': 'Emergency contact number',
  '请已中文或英文填写您所在国家的户籍地址（非通讯地址）': 'Please fill in your Registered Residential Address (not mailing address) in Chinese or English',
  '保存': 'Save',
  // 更换Email
  // 更换帐号
  // EMAIL
  // 请输入EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  '获取验证码': 'Please enter your CAPTCHA',
  '完成': 'Finish',
  // '租车订单': '租車訂單',
  // '租车订单': '租車訂單',
  // '全部': 'All',
  // '待付款': 'Unpaid',
  // '待取车': 'Waiting for Pick Up',
  // '已取车': 'Already Picked Up',
  '逾期': 'Overdue',
  // '待付款': 'Unpaid',
  // '待取车': 'Waiting for Pick Up',
  '进行中': 'Already Picked Up',
  // '完成': '完成',
  '已退款': 'Refund',
  '取消订单': 'Cancel Order',
  '订单详情': 'Order Details',
  // '订单详情': 'Order Details',
  '订单信息': 'Order Information',
  // '车辆类型': '車輛類型',
  // '车辆品牌': '車輛品牌',
  // '车辆型号': '車輛型號',
  // '车辆年份': '車輛年份',
  '租赁方式': 'Rental Type',
  '日租': 'Daily Rental',
  '月租': 'Monthly Rental',
  '取车方式': 'How to Pick Up',
  '还车方式': 'How to Drop Off',
  '到店自提': 'Pick Up at Store',
  '代驾到府': 'Deliver to You',
  '取车时间': 'Pick-up Time',
  '还车时间': 'Drop-off Time',
  '还车地点': 'Drop-off Location',
  '汽车保险': 'Vehicle insurance',
  '自付額2500元方案': 'Vehicle Insurance ( $500 deductible)',
  '自付額500元方案': 'Vehicle Insurance ( $2500 deductible)',
  // '新车险': 'Limited Waiver of Depreciation Endorsement',
  '是': 'Yes',
  '否': 'No',
  '租车费用': 'Rent',
  '租赁天数': 'Number of Rental Days',
  '附加费用': 'Additional Fees',
  '取车地点': 'Pick-up Location',
  // '取车时间': 'Pick-up Time',
  // '还车时间': 'Drop-off Time',
  // '还车地点': 'Drop-off Location',
  '租车费用小计': 'Rental Fee Subtotal',
  '税金费用': 'Tax ',
  // '税金费用': 'Tax ',
  '租车总计金额费用': 'Total Fee',
  '押金费用': 'Deposit',
  '驾照押金': 'Driver License Deposit',
  // '租车押金': 'Rental Secutiry Deposit',
  '押金总金额': 'Total Deposit',
  '保险费用': 'Insurance Fee',
  // '汽车保险': '汽车保险',
  '新车险': 'New Vehicle Replacement Policy',
  '超跑险': 'Luxury Car Insurance',
  '其他附加费用': 'Other Fees',
  '未满25岁': 'Under 25 Years Old',
  '非加拿大驾照': 'Non-Canadian Driver License',
  '保险/其他费用小计': 'Insurance/Other Fee',
  '总计金额': 'Total Fee',
  '定金金额': 'Deposit',
  '尾款金额': 'Balance',
  '租车合同': 'Rental Agreement',
  '沛豪国际租赁有限公司租赁合同': 'J&A Car Rental Contract',
  '预览合同': 'View the Contract',
  '订单状态': 'Order Status',
  '订单单号': 'Order Number',
  '创建订单时间': 'Order Creating Process Time',
  // '支付时间': '支付時間',
  //  '支付方式': '支付方式',
  // '取车时间': '取车时间',
  '车牌号码': 'Vehicle Plate',
  '续租': 'Renew The Rent',
  // '核销订单': '核銷訂單',
  // '全部': '全部',
  // '待使用': '待使用',
  // '已使用': '已使用',
  // '已过期': '已過期',
  // '订单号码': '訂單號碼',
  // '交易单号': '交易單號',
  // '支付方式': '支付方式',
  // '支付时间': '支付時間',
  // '有效期至': '有效期至',
  '核销码信息': 'Verification Code Information',
  // '充值钱包': 'Recharge My Wallet',
  '充值总额': 'Total recharge amount',
  '纪录': 'History',
  '充值项目': 'Recharge Item',
  '充值2': 'Top up', '赠送': 'and get',
  '充值': 'Recharge',
  "充值支付": "Payment for Recharge",
  // '支付方式': '支付方式',
  // '支付宝支付': '支付宝支付',
  // '微信支付': '微信支付',
  // '信用卡支付': '信用卡支付',
  // "充值支付": "充值支付",
  //  '支付': 'Pay',
  //  '租车押金': '租車押金',
  '押金总额': 'Total Deposit',
  // '车辆类型': 'Body Type',
  // '车辆品牌': 'Vehicle Brand',
  // '车辆型号': 'Vehicle Model',
  // '车辆年份': 'Vehicle Year',
  // '驾照押金': '駕照押金',
  // '租车押金': 'Rental Secutiry Deposit',
  // '支付方式': '支付方式',
  // '支付时间': '支付時間',
  //'交易单号': '交易單號',
  "收款单号": 'Invoice Number',
  // '待取车': 'Waiting for Pick Up',
  // '进行中': '進行中',
  // '完成': '完成',
  //  '逾期': '逾期',
  //'信息通知': '訊息通知',
  //'信息通知': '訊息通知',
  //'订单信息': '訂單資訊',
  '消息详情': 'Message details',
  // '客服咨询': '客服諮詢',
  // '客服咨询': '客服諮詢',
  '问题反馈': 'Feedback',
  '问题类型': 'Feedback Type',
  '请选择问题类型': 'Choose your problem type',
  '反馈内容': 'Feedback content',
  '请输入您想说的话': 'Please type here',
  '上传图片/影片': 'Upload Photo/Video',
  // '完成': '完成',
  '留言板': 'Message Board',
  // '反馈内容': 'Feedback content',
  // '请输入您想说的话': 'Please type here',
  // '上传图片/影片': 'Upload Photo/Video',
  // '完成': '完成',
  '下单问题': 'Question about my order',
  '微信': 'WeChat',
  '其他问题': 'Other questions',
  // '微信': 'WeChat',
  // 尚未開發
  // // '登录密码': 'Password',
  // // '登录密码': 'Password',
  // EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  //  '获取验证码': '獲取驗證碼',
  // '登录密码': '登錄密碼',
  // '请输入登录密码': '請輸入登錄密碼',
  // '重复密码': '重复密码',
  // '请再次输入登录密码': '请再次输入登录密码',
  // '完成': '完成',
  // '支付密码': '支付密码',
  // '支付密码': '支付密码',
  // EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  //  '获取验证码': '獲取驗證碼',
  // '登录密码': '登錄密碼',
  // '请输入登录密码': '請輸入登錄密碼',
  // '重复密码': '重复密码',
  // '请再次输入登录密码': '请再次输入登录密码',
  // '完成': '完成',










  "请输入Email帐号": 'Please enter Email Account',



  '企业介绍': 'Enterprise introduction',

  '客服介绍': 'customer service',

  '年限': 'Years',
  '请选择年限': 'Please select years',


  '用户菜单': 'User menu',

  '提示': 'Prompt',
  '您确定要退出系统吗': 'Are you sure you want to exit the system?',








  '联名品牌': 'Co-branded brand',






  '拖动滑块完成拼图': 'Drag the slider to complete the puzzle',
  '验证失败，请重试': 'Verification failed, please try again',
  '验证通过！': 'Verification passed!',



  '查看': 'View',


  '待支付': 'be paid',

  '待付特殊费用': 'be paid special fee',
  '待验收': 'to be accepted',
  '待付尾款': 'To be paid',


  '特殊费用支付时间': 'Special fee payment time',
  '特殊费用支付方式': 'Special fee payment method',
  '尾款支付时间': 'Final payment time',
  '尾款支付方式': 'Final payment method',



  'suffix': '_en',













  '暂无数据': 'No data',





  '待审核': 'To be audited',
  '已通过': 'Passed',
  '未通过': 'Not passed',









  '公里': 'km',



  '假支付': 'Fake payment',







  '设置支付密码': 'Set payment password',


  '暂未开放': 'Not open yet',

  '不核销公里数': 'No verification of kilometers',

  '我的': 'My',

  '账号': 'Account',
  '更换账号': 'Change account',








  '如果您在租赁过程中遇到什么困难,请查看如何租赁或者联系客服进行咨询': 'If you encounter any difficulties during the rental process, please check How to Rent or Contact Us for consultation',

  '请填写您要租赁的信息': 'Please fill in the information you want to rent',

  '请选择租赁方式': 'Please select rental method',

  '请选择取车方式': 'select pick-up method',

  '请选择时间': 'select time',
  '送车地点': 'Delivery location',
  '请输入送车地址': 'Please enter the delivery address',

  '请选择还车方式': 'select return method',

  '请先选择租赁方式': 'select rental method',
  '请先选择取车时间': 'select pick-up time',

  '请输入还车地址': 'Please enter the return address',

  '请选择汽车保险': 'Please select car insurance',

  '选配购买': 'Optional purchase',
  '超跑保险': 'Super car insurance',
  '计算规则为7天一周期，不满7则按7天计算': 'The calculation rule is 7 days per cycle, less than 7 days is calculated as 7 days',
  '提交': 'Submit',
  '我要租赁': 'I want to rent',


  'locale': 'en',
  '查看续租': 'View renewal',



  '消费': 'Consumption',
  '交易日期': 'Transaction date',
  '金额': 'Amount',
  '充值方式': 'Recharge method',
  '到账日期': 'Arrival date',
  '操作': 'Operation',


  '使用中': 'In use',

  '余额': 'Balance',

  '尾款单': 'Balance sheet',
  '管理员审核中': 'Administrator is reviewing',









  '请再次输入支付密码': 'Please enter the payment password again',

  '请输入银行卡账号': 'Please enter the bank card number',
  '请输入手机号': 'Please enter the phone number',
  '银行卡账号': 'Bank card number',
  '手机号': 'Phone number',

  '已退还': 'Returned',
  '已还车': 'Returned',

  '已取消': 'Cancelled',
  '请选择汽车驾照类型': 'Please select the type of motor vehicle driver\'s license',
  '请上传驾驶证': 'Please upload driver\'s license',
  '上传驾驶证主页': 'Upload driver\'s license home page',
  '上传驾驶证副页': 'Upload driver\'s license sub-page',
  '上传驾驶证附件': 'Upload driver\'s license attachment',


  '租赁明细': 'Rental details',






  '您确定要退出系统吗？': 'Are you sure you want to exit the system?',
  '取消': 'Cancel',
  '确定': 'Confirm',
  "图片大小不能超过2M": "The image size cannot exceed 2M",
  "图片大小不能超过10M": "The image size cannot exceed 10M",
  "正在上传中...": "Uploading...",
  "请先阅读并同意用户协议与隐私政策": "Please read and agree to the user agreement and privacy policy first",
  '您确定要删除该订单吗？': 'Are you sure you want to delete this order?',


  '请选择': 'select',
  '请下滑到底部查看租车合同': 'Please slide to the bottom',

  '签订合同': 'Sign contract',
  '生成订单': 'Generate order',
  '电子签名': 'Electronic signature',
  '确认签署': 'Confirm signature',
  '签订合同（已签字）': 'Sign contract (signed)',
  '租赁支付': 'Rental payment',

  '您确定要取消该订单吗？': 'Are you sure you want to cancel this order?',
  '支付订单': 'Pay order',
  '取消时间': 'Cancel time',
  '请填写您要续租的信息': 'Fill in the renewal information',





  '个人注册信息': 'Personal registration information',
  '请输入护照号码': 'Please enter passport number',
  '请输入姓名': 'Please enter name',
  '用户登录': 'User login',
  '请输入户移动电话': 'Please enter mobile phone number',
  '请输入户籍地址': 'Please enter address',
  '请输入紧急联系人': 'Please enter emergency contact',
  '请输入关系': 'Please enter relationship',
  '退款中':'refunding',
  '退款':'refund',

  '女士': 'lady',
  '先生': 'Mr.',
  '忘记密码': 'Forgot password',

  '新密码': 'New password',


  '请再次输入紧急联系人电话': 'Please enter emergency contact number again',
  '请以中文或英文填写您所在国家的戶籍地址（非通讯地址）': 'Please fill in your country of residence address (not mailing address) in Chinese or English',

  '特殊费用': 'Special fees',


  '特殊费用支付': 'Special fee payment',



  "取车日期": "Pick-up date",
  "请选择取车日期": "select pickup date",
  "还车日期": "Return date",
  "请先选择取车日期": "select pickup date",
  "请选择还车日期": "select return date",
  "请选择取车时间": "select pickup time",
  "请选择还车时间": "select return time",


  "点击支付后将跳转到第三方服务平台进行支付": "After clicking on payment, you will be redirected to a third-party service platform for payment",

  "银联国际": "UnionPay International",

  "新车险计算规则为租车天数*7天周期计算，不满足7天以7天进行计算": "New vehicle replacement Insurance is purchased by weekly and minimum period is one week.",

  "联邦政府商品及服务税": "GST 5%(Good and Service Tax)",

  "省销售税": "PST 7% (Provincial Sales Tax)",

  "汽车保险500元方案": "Vehicle Insurance ( $2500 deductible)",
  "汽车保险2500元方案": "Vehicle Insurance ( $2500 deductible)",

  '付款方式':'payment method',
  '在线支付':'Online payment',
  '线下支付':'Offline payment',
  '电子合约':'Electronic contract',
  '确认':'confirm'
}
