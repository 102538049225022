<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="shop" />
    <!-- 🥒轮播图 -->
    <section>
      <div class="notice" :style="`background-image: url(${info.goods_cover});`">
        <b-container>
          <b-row>
            <b-col xl="6" lg="6" md="4"></b-col>
            <b-col xl="6" lg="6" md="8">
              <div-card bg="#fff" radius="2px">
                <div-zebra></div-zebra>
                <div-padding padding="30px">
                  <div-text color="#1D2124" size="20px" bold>{{info['goods_name'+$t('suffix')]}} </div-text>
                  <div-left center>
                    <div-text color="#1D2124" size="26px" bold>{{info.kms}}</div-text>
                    <div-text color="#1D2124" size="26px" bold>{{$t('公里')}}</div-text>
                    <div-width size="10px"></div-width>
                    <div-text color="#1D2124" size="20px" bold v-if="info.is_write_off_kms==1">{{$t('不核销公里数')}}</div-text>
                  </div-left>
                  <div-height height="10px" />
                  <div-left center>
                    <div-text color="#1D2124" size="16px" bold>{{$t('价格')}}：{{info.goods_price}}</div-text>
                    <div-width size="10px"></div-width>
                  </div-left>

                  <div-height height="30px" />
                  <div-right center>
                    <div-text color="#1D2124" size="16px" bold>{{$t('数量')}}：1</div-text>
                    <div-width size="10px"></div-width>
                    <!-- <div>
                      <b-form-spinbutton id="demo-sb" v-model="num" min="1" max="100"></b-form-spinbutton>
                    </div> -->
                  </div-right>
                  <div-height height="30px" />
                  <div-right center>
                    <div-text color="#1D2124" size="16px" bold>{{$t('总价')}}：CAD {{price}}</div-text>
                  </div-right>
                </div-padding>
              </div-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
    <!--🍞 面包屑 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <b-row>
            <b-col cols="auto" class="mr-auto">
              <div-left center>
                <div-text color="#1D2124" size="20px" bold> {{$t('支付方式')}} </div-text>
              </div-left>
            </b-col>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('shop')">{{$t('商城中心')}}</b-breadcrumb-item>
                <!-- <b-breadcrumb-item @click="go('shop_details')">商城详情</b-breadcrumb-item> -->
                <b-breadcrumb-item active>{{$t('商城购买')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
        <div-zebra></div-zebra>
      </b-container>
    </section>

    <!-- 商品介绍-->
    <Pay03 ref="pay" url="/index/goodorders/setOrder" :price="price" :good_id="id" />
    <div-height height="40px" />
  </div>
</template>
  <script>
export default {
  data() {
    return {
      info: {},
      num: 1,
      price: "",
      jlFlag: true,
    }
  },
  created() {
    this.urlparam()
    this.get_info()
  },
  methods: {
    //点击加减
    add() {
      console.log(this.num)
      this.sum()
      this.jl()
    },

    /** 节流 **/
    jl() {
      let that = this
      if (!this.jlFlag) {
        return
      }
      this.jlFlag = false
      setTimeout(() => {
        that.$refs.pay.get_order()
        that.jlFlag = true
      }, 2000)
      // 函数被点击一次触发执行后，2秒内再点击函数不会被触发事件，可理解为每2秒触发一次事件。
    },
    // 获取数据
    get_info() {
      this.$get({
        url: "/index/goods/pagedata",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.info = res.data.original
          this.sum()
        },
        tip: () => {},
      })
    },
    sum() {
      this.price = this.num * this.info.goods_price
    },
  },
}
</script>
<style lang="scss" scoped>
.notice {
  background: #122947 url(~@/assets/img/banner/6.png) no-repeat;
  background-size: cover !important;
  background-position: center !important;
  padding: 80px 0 80px;
  .notice_text {
    color: #fff;
    text-align: right;
    line-height: 2;

    h2 {
      font-weight: bold;
    }
  }
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}
.form-control.focus {
  box-shadow: none;
  border: 1px solid #ced4da;
}
</style>