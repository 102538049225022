<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav />
    <!-- 🍞面包屑 -->
    <section>
      <b-container>
        <div-padding padding="10px 0">
          <b-row>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('register')" v-if="type==1">{{$t('注册信息')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('login')" v-if="type==2">{{$t('用户登录')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('用户协议与隐私条款')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>
    <!--🍏 简介-->
    <section>
      <b-container>
        <div-height height="80px" />
        <div class="title_sm">
          <h1 class="wow fadeInUp">{{$t('用户协议与隐私条款')}}</h1>
          <h2 class="wow fadeInUp">User Agreement and Privacy Terms</h2>
          <h3 class="wow fadeInUp"></h3>
          <h4 class="wow fadeInUp"></h4>
          <h5 class="wow fadeInUp">{{create_time}}</h5>
        </div>
        <div-height height="40px" />
        <div-html :html="info['protocol_cont'+$t('suffix')]"></div-html>
      </b-container>
    </section>

    <div-height height="40px"></div-height>
  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      type: "", //1 来自注册 2 来自登录
      id: "", //1用户协议 2隐私条款
      create_time: "",
      info: "",
      protocol_type_str: "",
    }
  },
  created() {
    this.urlparam()
    this.get_info()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/protocols/protocols?protocol_type=1",
        data: { protocol_type: this.id },
        success: (res) => {
          this.info = res.data
          this.protocol_type_str = res.data.protocol_type_str
          this.create_time = res.data.create_time
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang='scss' scoped>
// 轮播图
.banner img {
  width: 100%;
}

// 简介
.blurb_box {
  text-align: center;
  z-index: 1000;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #e6e6e6;
  @include transition(0.4s);
  @media #{$xl} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$lg} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$md} {
    padding: 2.13rem;
    margin-top: -4.38rem;
  }
  @media #{$sm} {
    padding: 1.5rem;
    margin-top: 2rem;
  }
  @media #{$xs} {
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .blurb {
    background: #1d2124;
    width: 100%;
    height: 100%;
    padding: 2rem;
  }
}

.change {
  color: #fff;
  @include transition(0.4s);
  @media #{$xl} {
    font-size: 1.2rem;
  }
  @media #{$lg} {
    font-size: 0.9rem;
  }
  @media #{$md} {
    font-size: 0.9rem;
  }
  @media #{$sm} {
    font-size: 0.9rem;
  }
  @media #{$xs} {
    font-size: 0.9rem;
  }
}

// 小标题
.title_sm {
  position: relative;

  h1 {
    width: 100%;
    position: absolute;
    font-size: 22px;
    color: #1d2124;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 2;
    top: 10px;
    font-weight: bold;
  }

  h2 {
    padding-top: 5px;
    width: 100%;
    font-size: 28px;
    color: #e6e6e6;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
  }

  h3 {
    position: absolute;
    width: 17px;
    height: 3px;
    background: #333333;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 40px;
    z-index: 2;
  }

  h4 {
    height: 50px;
  }

  h5 {
    width: 100%;
    font-size: 16px;
    color: #b7b7b7;
    text-align: center;
  }
}

// 🥒底部轮播图
.swiper-wrapper {
  text-align: center;
  background: #fff;
  img {
    width: 100%;
    transform: scale(1);
    transition: all 0.5s;
  }
}
</style>
