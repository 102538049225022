<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="about" />
    <!-- 🥒轮播图 -->
    <section>
      <div class="banner">
        <Swiper :banners="banners" />
      </div>
    </section>
    <!-- 🍞面包屑 -->
    <section>
      <b-container>
        <div-padding padding="10px 0">
          <b-row>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('about')">{{$t('关于我们')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('服务项目2')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>
    <!--🍏简介-->
    <section>
      <section>
        <div class="hero-bg" :style="`background-image: url(${original.right_pic});`">
          <b-row>
            <b-col xl="7" class="text_box wow slideInLeft">
              <div class="Scope">
                <div class="title_sm">
                  <h1 class="wow fadeInUp">{{$t('服务项目')}}</h1>
                  <h2 class="wow fadeInUp" style="color:transparent">Scope of Services</h2>
                  <h3 class="wow fadeInUp"></h3>
                  <h4 class="wow fadeInUp"></h4>
                </div>
                <div-height height="20px" />
                <div-html :html="original['left_txt'+$t('suffix')]"></div-html>
              </div>
            </b-col>
            <b-col xl="5" class="wow fadeInRight" :data-wow-delay="(0.8) + 's'">
              <div class="at-hero-banner">
                <img :src="original.float_pic">
              </div>
            </b-col>
          </b-row>
        </div>
      </section>

    </section>
  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      banners: [],
      original: {},
    }
  },
  created() {
    this.get_index()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },
  methods: {
    get_index() {
      this.$get({
        url: "/index/enterpriseservicescope/pagedata?id=1",
        success: (res) => {
          this.banners = res.data.banner
          this.original = res.data.original
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang='scss' scoped>
// 轮播图
.banner img {
  width: 100%;
}

// 简介
.blurb_box {
  text-align: center;
  z-index: 1000;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #e6e6e6;
  @include transition(0.4s);
  @media #{$xl} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$lg} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$md} {
    padding: 2.13rem;
    margin-top: -4.38rem;
  }
  @media #{$sm} {
    padding: 1.5rem;
    margin-top: 2rem;
  }
  @media #{$xs} {
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .blurb {
    background: #1d2124;
    width: 100%;
    height: 100%;
    padding: 2rem;
  }
}

//小标题
.title_sm {
  position: relative;

  h1 {
    width: 100%;
    position: absolute;
    font-size: 22px;
    color: #1d2124;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 2;
    top: 10px;
    font-weight: bold;
  }

  h2 {
    padding-top: 5px;
    width: 100%;
    font-size: 28px;
    color: #e6e6e6;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
  }

  h3 {
    position: absolute;
    width: 17px;
    height: 3px;
    background: #333333;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 40px;
    z-index: 2;
  }

  h4 {
    height: 50px;
  }

  h5 {
    width: 100%;
    font-size: 16px;
    color: #b7b7b7;
    text-align: center;
  }
}

//服务项目
.service_box {
  background: #595a5f;
  position: relative;
}

.service_title {
  width: 100%;
  position: relative;
  background: url("~@/assets/img/about/t_bg02.png");
  background-position: center !important;
  background-size: 68rem 14.19rem;
  background-repeat: no-repeat;

  h2 {
    width: 100%;
    font-size: 1.25rem;
    color: #1d2124;
  }
  h3 {
    font-size: 3.94rem;
    color: #fff;
    font-weight: bold;
  }
}

// 背景图
.hero-bg {
  width: 100%;
  position: relative;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.at-hero-banner {
  height: 100%;
  // padding-top: 470px;
  padding-bottom: 470px;
  @include transition(0.4s);
}
.at-hero-banner img {
  position: absolute;
  bottom: 64px;
  width: 150%;
  right: 30px;
  z-index: 1;
}

.text_box {
  display: flex;
  justify-content: flex-end;
  background: #fff;
  @include transition(0.4s);
}
.Scope {
  width: 100%;
}

@media #{$xl} {
  .Scope {
    max-width: 600px;
  }
  .text_box {
    padding: 120px 100px 120px 120px;
  }
  .at-hero-banner img {
    max-width: 850px;
  }
}
@media #{$lg} {
  .Scope {
    max-width: 100%;
  }
  .text_box {
    padding: 60px 60px 60px 60px;
  }
  .at-hero-banner {
    padding-bottom: 0;
  }
  .at-hero-banner img {
    max-width: 100%;
    position: initial;
  }
}
@media #{$md} {
  .text_box {
    padding: 60px 60px 60px 60px;
  }
  .at-hero-banner {
    padding-bottom: 0;
  }
  .at-hero-banner img {
    max-width: 100%;
    position: initial;
  }
}
@media #{$sm} {
  .text_box {
    padding: 60px 60px 60px 60px;
  }
  .at-hero-banner {
    padding-bottom: 0;
  }
  .at-hero-banner img {
    max-width: 100%;
    position: initial;
  }
}
@media #{$xs} {
  .text_box {
    padding: 60px 30px 60px 30px;
  }
  .at-hero-banner {
    padding-bottom: 0;
  }
  .at-hero-banner img {
    max-width: 100%;
    position: initial;
  }
}
</style>
