<template>
  <div class="div-img" :class="{hover}" :style="'height: '+height+';width:'+width+';border-radius: '+radius+';background-image: url('+src+')'">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "div-img",
  props: {
    src: {
      type: String,
    },
    height: {
      type: String,
      default: "10vw",
    },
    width: {
      type: String,
      default: "10vw",
    },
    radius: {
      type: String,
      default: "0 0 0 0",
    },
    hover: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
<style lang='scss' scoped>
.div-img {
  // background: url("@/static/img/logo.png") ;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  flex-shrink: 0;
  overflow: hidden;

  img {
    transition: 0.3s;
    width: 100%;
    height: 100%;
    display: block;
  }
  &.hover:hover img {
    transform: scale(1.1);
  }
}
</style>
