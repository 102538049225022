<template>
  <div>
    <Nav></Nav>

    <b-container>
      <div class="login_box">
        <div class="login">
          <div class="login_text">{{$t('忘记密码')}}</div>
          <form class="logo_input">
            <fieldset class="fieldset">
              <legend>EMAIL*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="email" :placeholder="$t('请输入EMAIL')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>

            <fieldset class="fieldset">
              <legend>{{$t('验证码')}}*</legend>

              <b-input-group class="div-input">
                <template v-slot:append>
                  <b-input-group-text class="div-code">
                    <!-- 获取手机验证码按钮 封装 位置 在 vant-extend ===》 添加了防抖防止连点-->
                    <div-code url="/index/email/sendEmail" scene="2" :email="email"></div-code>
                    <!-- <b-button>获取验证码</b-button> -->
                  </b-input-group-text>
                </template>
                <b-form-input class="input" v-model="code" :placeholder="$t('请输入登录密码')" type="search">
                </b-form-input>
              </b-input-group>
            </fieldset>

            <fieldset class="fieldset">
              <legend>{{$t('新密码')}}*</legend>

              <b-input-group class="div-input">
                <template v-slot:append>
                  <b-input-group-text @click="active = !active" class="cursor">
                    <b-icon-eye-fill :color="active ? '#3B3B3B' : '#D7D7D7'"></b-icon-eye-fill>
                  </b-input-group-text>
                </template>
                <b-form-input :disabled="!email" class="input" v-model="new_password" autocomplete=" " :placeholder="$t('请输入登录密码')" :type="active ? 'search' : 'password'">
                </b-form-input>
              </b-input-group>
            </fieldset>
            <fieldset class="fieldset">
              <legend>{{$t('重复密码')}}*</legend>
              <b-input-group class="div-input">
                <template v-slot:append>
                  <b-input-group-text @click="active = !active" class="cursor">
                    <b-icon-eye-fill :color="active ? '#3B3B3B' : '#D7D7D7'"></b-icon-eye-fill>
                  </b-input-group-text>
                </template>
                <b-form-input :disabled="!email" class="input" v-model="confirm_new_password" autocomplete=" " :placeholder="$t('请再次输入登录密码')" :type="active ? 'search' : 'password'">
                </b-form-input>
              </b-input-group>
            </fieldset>
            <div-height height="30px"></div-height>
            <div-save ref="btn" @click.native="save">{{$t('登录')}}</div-save>
          </form>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      email: "",
      code: "",
      new_password: "",
      confirm_new_password: "",
    }
  },
  created() {},
  methods: {
    //注册 - 注册用户 - 预校验
    save() {
      const loading = this.$loading({
        lock: true,
      })

      this.$post({
        url: "/index/pass/forgotPass",
        data: {
          email: this.email, //邮箱地址
          code: this.code, //邮箱验证码
          new_password: this.new_password, //密码（6-16位英数混合）
          confirm_new_password: this.confirm_new_password, //确认密码
        },
        success: (res) => {
          this.$toast.success(res.msg, {
            timeout: 1000,
            position: "top-center",
            pauseOnHover: false,
          })

          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
            this.$router.push("login")
          }, 1000)
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
//登录盒子

.login_box {
  width: 100%;
  min-height: calc(100vh - 360px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.login {
  width: auto;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 68px 30px 68px;

  @include transition(0.4s);

  @media #{$xl} {
    flex-direction: row;
  }

  @media #{$lg} {
    flex-direction: row;
  }

  @media #{$md} {
    flex-direction: column;
  }

  @media #{$sm} {
    flex-direction: column;
    width: 100%;
    padding: 30px;

    .logo_input {
      width: 100%;
    }
  }

  @media #{$xs} {
    flex-direction: column;
    width: 100%;
    padding: 30px 0;
    .login_text {
      margin-bottom: 30px;
    }
    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }
}

.login_text {
  text-align: center;
  font-size: 23px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 80px;
}

.line {
  width: 1px;
  height: 226px;
  background: linear-gradient(#fff 0%, #d7d7d7 50%, #fff 100%);
}

.logo_input {
  width: 438px;
}

.fieldset {
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 76px;
  @include transition(0.4s);

  &:focus-within {
    border: 1px solid #57b846;

    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }

  legend {
    @include transition(0.4s);
    position: relative;
    z-index: 1;
    color: #2f2e3f;
    font-size: 16px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
  }

  /deep/.div-input {
    position: relative;
    top: -10px;
    width: 100%;
    height: 60px;
    background: #ffffff;

    &:focus-within {
      border-color: #3c58b2;
    }

    .input-group-text {
      padding: 0 24px;
      background: transparent;
      border-color: transparent !important;
    }

    .div-code {
      padding: 0 12px;

      .btn {
        height: 40px;
        background: #fff;
        color: #333;
        font-size: 14px;
        border: 0;
        padding: 0 14px;
        box-shadow: none;

        &:focus {
          box-shadow: none;
        }
      }
    }

    input {
      box-sizing: border-box;
      height: 60px;
      line-height: 60px;
      background: transparent;
      border-color: transparent !important;

      &.form-control {
        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }

    input::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 14px;
    }

    input:-webkit-autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }

    input:autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }
  }

  .cursor {
    cursor: pointer;
  }
}

.link {
  color: #818181;
  font-size: 13px;

  span {
    cursor: pointer;
  }
}
</style>