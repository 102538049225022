<template>
  <div>
    <b-container>
      <section>
        <div class="table_box">
          <div class="opt_box pc">
            <b-row align-h="center">
              <b-col lg="3" md="6" sm="6">
                <div class="opt_tab">
                  <b-row @click="zfb_fun(0)">
                    <b-col cols="2">
                      <div-img width="24px" height="24px">
                        <img :src="
                            require(`@/assets/img/icon/p${
                              pay_state == 0 ? 'p' : ''
                            }04.png`)
                          " />
                      </div-img>
                    </b-col>
                    <b-col cols="10">
                      <div-text size="16px" :color="pay_state == 0 ? '#1D2124' : '#B7B7B7'">
                        {{$t('支付宝支付')}}
                      </div-text>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <!-- <b-col lg="3" md="6" sm="6">
                <div class="opt_tab">
                  <b-row @click="zfb_fun(1)">
                    <b-col cols="2">
                      <div-img width="24px" height="24px">
                        <img :src="
                            require(`@/assets/img/icon/p${
                              pay_state == 1 ? 'p' : ''
                            }01.png`)
                          " />
                      </div-img>
                    </b-col>
                    <b-col cols="10">
                      <div-text size="16px" :color="pay_state == 1 ? '#1D2124' : '#B7B7B7'">
                        {{$t('银联国际')}}
                      </div-text>
                    </b-col>
                  </b-row>
                </div>
              </b-col> -->
              <b-col lg="3" md="6" sm="6">
                <div class="opt_tab">
                  <b-row @click="zfb_fun(2)">
                    <b-col cols="2">
                      <div-img width="24px" height="24px">
                        <img :src="
                            require(`@/assets/img/icon/p${
                              pay_state == 2 ? 'p' : ''
                            }01.png`)
                          " />
                      </div-img>
                    </b-col>
                    <b-col cols="10">
                      <div-text size="16px" :color="pay_state == 2 ? '#1D2124' : '#B7B7B7'">
                        {{$t('信用卡支付')}}
                      </div-text>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <div class="opt_tab">
                  <b-row @click="zfb_fun(4)">
                    <b-col cols="2">
                      <div-img width="24px" height="24px">
                        <img :src="
                            require(`@/assets/img/icon/p${
                              pay_state == 4 ? 'p' : ''
                            }02.png`)
                          " />
                      </div-img>
                    </b-col>
                    <b-col cols="10">
                      <div-text size="16px" :color="pay_state == 4 ? '#1D2124' : '#B7B7B7'">
                        {{$t('余额支付')}}
                      </div-text>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>

          <div class="opt_box wap">
            <b-row align-h="center">
              <b-col lg="3" md="12" sm="12">
                <div class="opt_tab">
                  <b-row @click="zfb_fun(0)">
                    <b-col cols="2">
                      <div-img width="24px" height="24px">
                        <img :src="
                            require(`@/assets/img/icon/p${
                              pay_state == 0 ? 'p' : ''
                            }04.png`)
                          " />
                      </div-img>
                    </b-col>
                    <b-col cols="10">
                      <div-text size="16px" :color="pay_state == 0 ? '#1D2124' : '#B7B7B7'">{{$t('支付宝支付')}}</div-text>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
              <b-col lg="3" md="12" sm="12">
                <div class="opt_tab">
                  <b-row @click="zfb_fun(3)">
                    <b-col cols="2">
                      <div-img width="24px" height="24px">
                        <img :src="
                            require(`@/assets/img/icon/p${
                              pay_state == 3 ? 'p' : ''
                            }03.png`)
                          " />
                      </div-img>
                    </b-col>
                    <b-col cols="10">
                      <div-text size="16px" :color="pay_state == 3 ? '#1D2124' : '#B7B7B7'">{{$t('微信支付')}}</div-text>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </div>

          <div>
            <div-text color="#1D2124" size="24px" bold center>CAD {{price}}</div-text>
            <div-height height="10px" />
            <div-text color="#1D2124" center>{{$t('支付金额')}}</div-text>
            <div-height height="20px" />
          </div>

          <div class="pay_box" v-if="pay_state != 4">
            <div-height height="30px" />
            <div-save ref="btn" @save="get_order">{{$t('支付')}}</div-save>
            <div-height height="30px" />
            <div-text color="#1D2124" center>{{$t('点击支付后将跳转到第三方服务平台进行支付')}}</div-text>
          </div>
          <div v-if="pay_state == 4" class="pay_box">
            <fieldset class="fieldset">
              <legend>{{$t('支付密码')}}*</legend>

              <b-input-group class="div-input">
                <template v-slot:append>
                  <b-input-group-text @click="active = !active">
                    <b-icon-eye-fill :color="active ? '#3B3B3B' : '#D7D7D7'"></b-icon-eye-fill>
                  </b-input-group-text>
                </template>
                <b-form-input class="input" v-model="password" autocomplete=" " :placeholder="$t('请输入支付密码')" :type="active ? 'search' : 'password'">
                </b-form-input>
              </b-input-group>

            </fieldset>
            <div-both br>
              <div-text color="#1D2124" class="text_btn" @click.native="go('set_payword')" right>{{$t('设置支付密码')}}</div-text>
              <div-text color="#1D2124" right>{{$t('可用余额')}}：{{balance}}</div-text>
            </div-both>

            <div-height height="30px" />
            <div-save ref="btn" @save="get_balance_order">{{$t('支付')}}</div-save>
          </div>

        </div>
      </section>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "pay",
  props: ["url", "good_id", "price"],
  data() {
    return {
      active: false,
      balance: 0,
      pay_state: 0,
      pay: {},
      password: "",
      loading: false,
    }
  },
  created() {
    // 1-Alipay;2-Wechat;3-UnionPay
    this.get_info()
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
      if (flag) {
        return true
      } else {
        return false
      }
    },
    zfb_fun(index) {
      this.password = ""
      this.pay_state = index
    },
    get_order() {
      this.loading = this.$loading({
        lock: true,
      })
      this.$post({
        url: this.url,
        data: {
          num: 1,
          good_id: this.good_id, //id
        },
        success: (res) => {
          console.log("res", res)
          let good_order_id = res.data.order.id
          this.pay_fun(this, good_order_id)
        },
        tip: () => {
          setTimeout(() => {
            this.loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
    get_balance_order() {
      this.loading = this.$loading({
        lock: true,
      })

      this.$post({
        url: this.url,
        data: {
          num: 1,
          good_id: this.good_id, //id
        },
        success: (res) => {
          console.log(res)
          let good_order_id = res.data.order.id
          this.pay_fun(this, good_order_id)
          setTimeout(() => {
            this.$refs.btn.disabled = false
            this.loading.close()
          }, 1000)
        },
        tip: () => {
          setTimeout(() => {
            this.loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
    pay_fun(that, good_order_id) {
      console.log("支付方式", [21, 23, 24, 22, 10][that.pay_state])
      let data = {}
      if (that.pay_state == 4) {
        data = {
          good_order_id: good_order_id, //id
          pay_type: [21, 23, 24, 22, 10][that.pay_state], //支付方式
          browser_type: ["pc", "wap"][that._isMobile() * 1],
          pay_password: that.password,
        }
      } else {
        data = {
          good_order_id: good_order_id, //id
          pay_type: [21, 23, 24, 22, 10][that.pay_state], //支付方式
          browser_type: ["pc", "wap"][that._isMobile() * 1],
        }
      }
      this.$post({
        url: "index/goodorders/pay",
        data: data,
        success: (res) => {
          console.log("支付订单", res)
          // this.go("mine")
          if (that.pay_state == 4) {
            that.$toast.success(res.msg, {
              timeout: 1000,
              position: "top-center",
              pauseOnHover: false,
            })
            setTimeout(() => {
              that.back()
            }, 1000)
          } else if (that.pay_state == 3) {
            location.href = res.data.h5pay_url
          } else {
            location.href = res.data.webpay_url
          }

          setTimeout(() => {
            that.$refs.btn.disabled = false
            that.loading.close()
          }, 1000)

          // order_write_off
        },
        tip: () => {
          setTimeout(() => {
            that.loading.close()
            that.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },

    get_info() {
      this.$get({
        url: "/index/users/uinfo",
        success: (res) => {
          let obj = res.data
          Object.keys(obj).map((item) => (this[item] = obj[item]))
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.text_btn {
  cursor: pointer;
}
.pay_box {
  max-width: 500px;
  margin: 0 auto;
}
.fieldset {
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 66px;

  &:focus-within {
    border: 1px solid #57b846;

    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }

  legend {
    @include transition(0.4s);
    position: relative;
    z-index: 1;
    color: #2f2e3f;
    font-size: 16px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
  }

  /deep/.div-input {
    position: relative;
    top: -10px;
    width: 100%;
    height: 50px;
    background: #ffffff;
    // opacity: 1;
    // border: 0.06rem solid #d8d8d8;

    &:focus-within {
      // background: #3c58b2;
      border-color: #3c58b2;
      // box-shadow: 0 0 4px 2px rgba(0, 123, 255, 25%);
      // box-shadow: 0 0 0 0.2rem rgb(28, 41, 83);
    }

    .input-group-text {
      cursor: pointer;
      padding: 0 24px;
      background: transparent;
      border-color: transparent !important;
    }

    input {
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
      background: transparent;
      border-color: transparent !important;

      &.form-control {
        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }

    input::-webkit-input-placeholder {
      color: #d7d7d7;
    }

    input:-webkit-autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }

    input:autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }
  }
}

.table_box {
  padding: 30px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 0;
}

.opt_title {
  height: 70px;
  line-height: 70px;
}
//性别
.opt_box {
  //   border-radius: 8px;
  padding: 30px;
  //   box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  // display: flex;
  //   justify-content: center;
  // align-items: center;
  // width: 100%;

  .opt_tab {
    margin-bottom: 30px;
    cursor: pointer;
    .div-img {
      // margin-right: 20px;
    }
  }
  .bv-no-focus-ring {
    display: flex;
    align-items: center;
  }
  // margin-bottom: 46px;
  .custom-control {
    height: 50px;
    min-height: 50px !important;
    display: flex;
    align-items: center;
  }
  .custom-radio {
    /deep/.custom-control-label {
      cursor: pointer;
      font-size: 14px !important;
    }
  }
}

.upload_box {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  // padding: 10px;

  .upload_file {
    width: 230px;
    height: 151px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .icon {
      position: absolute;
      width: 56px;
      height: 56px;
    }
  }

  .up_text {
    width: 100%;
    text-align: center;
  }

  .the_img {
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 30px;
  }

  @include transition(0.4s);

  @media #{$xl} {
    flex-direction: row;

    .line {
      display: block;
    }
  }

  @media #{$lg} {
    flex-direction: row;

    .line {
      display: block;
    }
  }

  @media #{$md} {
    flex-direction: column;

    .line {
      display: none;
    }
  }

  @media #{$sm} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }

  @media #{$xs} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }
}

@media #{$xl} {
  .pc {
    display: block;
  }
  .wap {
    display: none;
  }
  .opt_title {
    display: none;
  }
  .opt_box {
    box-shadow: none;
  }
  .bv-no-focus-ring {
    display: flex;
    align-items: center;
  }
}

@media #{$lg} {
  .pc {
    display: block;
  }
  .wap {
    display: none;
  }
  .opt_title {
    display: none;
  }
  .opt_box {
    box-shadow: none;
  }
}

@media #{$md} {
  .pc {
    display: none;
  }
  .wap {
    display: block;
  }
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}

@media #{$sm} {
  .pc {
    display: none;
  }
  .wap {
    display: block;
  }
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}

@media #{$xs} {
  .pc {
    display: none;
  }
  .wap {
    display: block;
  }
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}
</style>