import { render, staticRenderFns } from "./relet_01.vue?vue&type=template&id=205052aa&scoped=true&"
import script from "./relet_01.vue?vue&type=script&lang=js&"
export * from "./relet_01.vue?vue&type=script&lang=js&"
import style0 from "./relet_01.vue?vue&type=style&index=0&id=205052aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "205052aa",
  null
  
)

export default component.exports