<template>
  <div class="div-html">
    <div class="htmlcententbox" v-html="html"></div>
  </div>
</template>
<script>
export default {
  name: "div-html",
  props: {
    html: {
      type: String,
      default: "",
    },
  },
}
</script>
<style lang='scss' scoped>
.htmlcententbox {
  width: 100%;

  /deep/p {
    img {
      width: auto !important;
      height: auto !important;
      max-width: 100% !important;
      max-height: 100% !important;
      object-fit: contain;
    }

    video {
      width: auto !important;
      height: auto !important;
      max-width: 100% !important;
      max-height: 100% !important;
      object-fit: contain;
    }
  }

  /deep/ * {
    word-break: break-word;
    max-width: 100% !important;
    width: auto !important;
  }
}
</style>
