<template>
  <div class="div-save">
    <div class="btn_box">
      <button @click="do_fun" class="btn" :class="{ auto }" :disabled="disabled">
        <slot></slot>
        <span class="shape"></span>
      </button>
    </div>
  </div>
</template>
    <script>
export default {
  name: "div-save",
  props: {
    auto: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#fff",
    },
    bg: {
      type: String,
      default: "#333333",
    },
    radius: {
      type: String,
      default: "4px",
    },
    height: {
      type: String,
      default: "50px",
    },
    border: {
      type: String,
      default: "",
    },
    fun: {
      type: Function,
      default: () => {
        return Function
      },
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  created: function () {},
  methods: {
    do_fun() {
      if (this.disabled) {
        return false
      }
      this.disabled = true
      console.log("内部调用了", this.save)
      // this.fun()
      this.$emit("save")
    },
  },
}
</script>

<style lang="scss" scoped>
.div-save {
  width: 100%;

  @media #{$xl} {
    margin-bottom: 20px;
    max-width: 114px;
  }

  @media #{$lg} {
    margin-bottom: 10px;
    max-width: 114px;
  }

  @media #{$md} {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    max-width: 100%;
  }

  @media #{$sm} {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    max-width: 100%;
  }

  @media #{$xs} {
    display: flex;
    margin-bottom: 20px;
    justify-content: center;
    max-width: 100%;
  }
  .btn_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .btn {
      user-select: none;
      border: 1px solid #333;
      border-radius: 4px;
      color: #333;
      cursor: pointer;
      display: inline-block;
      font-size: 14px;
      margin-bottom: 0;
      text-align: center;
      text-transform: capitalize;
      touch-action: manipulation;
      transition: all 0.3s ease 0s;
      vertical-align: middle;
      white-space: nowrap;
      position: relative;
      z-index: 1;
      overflow: hidden;
      font-size: 15px;
      line-height: 1.5;
      color: #fff;
      text-transform: capitalize;
      width: 100%;
      height: 44px;
      border-radius: 0.31rem;
      background: #333;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;
      transition: all 0.4s;
    }
    .btn:hover {
      border: 1px solid #57b846;
      color: #fff;
    }

    .btn > .shape {
      background: #57b846 none repeat scroll 0 0;
      border-radius: 50%;
      display: block;
      height: 0;
      position: absolute;
      transform: translate(-50%, -50%);

      transition: width 0.6s ease 0s, height 0.6s ease 0s;

      width: 0;
      z-index: -1;
      top: 50%;
      left: 50%;
    }

    .btn:hover > .shape {
      height: 500px;
      width: 500px;
    }
  }
}
</style>
