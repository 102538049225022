<template>
  <div class="box">
    <!-- 顶部导航栏 -->
    <Nav name="index"/>

    <!-- 🥒轮播图 -->
    <Swiper :banners="banners"/>
    <div-height height="80px"/>
    <!-- 🥒中级标题 -->
    <!-- <div class="title_sm">
      <h1 class="wow fadeInUp">{{$t('新车推荐')}}</h1>
      <h2 class="wow fadeInUp" style="color:transparent">New car recommendation</h2>
      <h3 class="wow fadeInUp"></h3>
      <h4 class="wow fadeInUp"></h4>
      <h5 class="wow fadeInUp">{{$t('豪华自有其道，全新车款推荐')}}</h5>
    </div> -->

    <section>
      <b-container>
        <div class="title_xs">
          <h1 class="wow fadeInUp">
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
            <div class="text">{{ $t('新车推荐') }}</div>
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
          </h1>
          <!-- <h3 class="wow fadeInUp"></h3> -->
          <h5 class="wow fadeInUp">{{ $t('豪华自有其道，全新车款推荐') }}</h5>
        </div>
      </b-container>
    </section>

    <div-height height="40px"/>
    <!-- 🌰新车推荐商品三份 -->
    <section>
      <b-container>
        <b-row>
          <b-col @click="go('rent_item',item.id)" sm="4" md="4" v-for="(item, ind) of productList" :key="ind"
                 class="wow fadeInUp" :data-wow-delay="(ind * 0.2) + 's'">
            <div class="thumbnail ">
              <div class="image picbox">
                <img :src="item.vehicle_thumbnail">
              </div>
              <div class="caption">
                <div class="text">{{ item['vehicle_name' + $t('suffix')] }}</div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <!-- 更多 -->
    <More to="new_more"/>
    <div-height height="80px"/>

    <!-- 🥒中级标题 -->
    <!-- <div class="title_sm">
      <h1 class="wow fadeInUp">{{$t('热门车款推荐')}}</h1>
      <h2 class="wow fadeInUp" style="color:transparent">Recommended popular models</h2>
      <h3 class="wow fadeInUp"></h3>
      <h4 class="wow fadeInUp"></h4>
      <h5 class="wow fadeInUp">{{$t('最热门车款推荐，满足您的租赁需求')}}</h5>
    </div> -->

    <section>
      <b-container>
        <div class="title_xs">
          <h1 class="wow fadeInUp">
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
            <div class="text">{{ $t('热门车款推荐') }}</div>
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
          </h1>
          <!-- <h3 class="wow fadeInUp"></h3> -->
          <h5 class="wow fadeInUp">{{ $t('最热门车款推荐，满足您的租赁需求') }}</h5>
        </div>
      </b-container>
    </section>

    <div-height height="40px"/>

    <!-- 轮播图 -->
    <b-container>
      <Swiper :banners="hot_cars" @click="go('hot_item')"/>
    </b-container>
    <div-height height="40px"/>

    <!-- 更多 -->
    <More to="hot_more"/>
    <div-height height="40px"/>
    <!-- 🍆高级标题  -->
    <section>
      <b-container>
        <div class="title_xs">
          <h1 class="wow fadeInUp">
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
            <div class="text">{{ $t('服务特色') }}</div>
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
          </h1>
          <h2 class="wow fadeInUp" style="color:#fff">Service standard</h2>
        </div>
      </b-container>
    </section>

    <div-height height="40px"/>

    <!-- 🎈九宫格 -->
    <section>
      <b-container>
        <b-row>
          <b-col @mouseenter="item.hover=true" @mouseleave="item.hover=false" v-for="(item, index) in grid_list"
                 :key="index" md="3" sm="6" cols="6" class=" wow zoomIn">
            <div class="grid_box animated" :class="item.hover?' rubberBand':''">
              <div class="grid">
                <div class="thumb">
                  <img :src="require('@/assets/img/icon/index_icon0' + (index * 1 + 1) + '.png')"/>
                </div>
                <div class="text">
                  {{ $t(item.text) }}
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div-height height="40px"/>
    </section>
    <!-- 公司简介 -->
    <section class="firm_box">
      <div class="firm_bg" style="background-color: #1C2953;"></div>
      <b-container>
        <div class="firm_title">

          <div-text size="22px" color="transparent" class="wow fadeInUp" bold center>Enterprise profile</div-text>
          <div-height height="10px"/>
          <!-- <div-center class="wow fadeInUp" center>
            <div class="line"></div>
            <div-text size="22px" color="#b7b7b7" bold>{{$t('企业概况')}}</div-text>
            <div class="line"></div>
          </div-center> -->

          <section>
            <b-container>
              <div class="title_xs">
                <h1 class="wow fadeInUp">
                  <div class="lines">
                    <div></div>
                    <div class="delay2"></div>
                    <div class="delay4"></div>
                    <div class="delay2"></div>
                    <div></div>
                  </div>
                  <div class="text" style="color:#fff;">{{ $t('企业概况') }}</div>
                  <div class="lines">
                    <div></div>
                    <div class="delay2"></div>
                    <div class="delay4"></div>
                    <div class="delay2"></div>
                    <div></div>
                  </div>
                </h1>
                <h2 class="wow fadeInUp" style="color:transparent;">Our location</h2>
              </div>
            </b-container>
          </section>

        </div>

        <b-row class="firm_list">

          <b-col cols="6" v-for="(item,index) in about_list.slice(0,2)" :key="index"
                 @click="go([,'about','blurb'][item.type])" @mouseenter="toSlide(index)">
            <div class="firm_item">
              <div class="firm_img">
                <img :src="item.switch_pic">
              </div>
              <div class="firm_text">
                <div-text size="20px" color="#fff" center bold>{{ [, $t('关于我们'), $t('公司简介')][item.type] }}
                </div-text>
                <div-text size="12px" color="transparent" center>{{ [, 'About us', 'Company profile',][item.type] }}
                </div-text>
              </div>
            </div>
          </b-col>

        </b-row>
      </b-container>
    </section>
    <div-height height="40px"/>

    <!--关于我们，公司简介，服务项目-->
    <section>
      <div class="about_area">
        <b-container>
          <section>
            <swiper ref="mySwiper">
              <swiper-slide class="swiper-wrapper" v-for="(item, index) in about_list.slice(0, 2)" :key="index">
                <b-row>
                  <b-col xl="4" lg="5" class="wow slideInLeft">
                    <div class="title_sm">
                      <h1 class="wow fadeInUp">{{ [, $t('关于我们'), $t('公司简介')][item.type] }}</h1>
                      <h2 class="wow fadeInUp" style="color:transparent">
                        {{ [, 'About us', 'Company profile'][item.type] }}</h2>
                      <h3 class="wow fadeInUp"></h3>
                      <h4 class="wow fadeInUp"></h4>
                    </div>
                    <div-height height="20px"/>
                    <div class="about__content">
                      <div class="about__text">
                        <p v-html="item['intro'+$t('suffix')]"></p>
                      </div>
                      <div-mdbtn @save="go([,'about','blurb'][item.type])">{{ $t('了解更多') }}</div-mdbtn>
                    </div>
                  </b-col>
                  <b-col xl="8" lg="7" class="wow fadeInRight">
                    <div class="about__thumb">
                      <img class="w-100" :src="item.rht_pic"/>
                    </div>
                  </b-col>
                  <div-height height="40px"/>
                </b-row>
              </swiper-slide>
            </swiper>
          </section>
        </b-container>
      </div>
    </section>

    <!-- 🍆高级标题 -->
    <section>
      <b-container>
        <div class="title_xs">
          <h1 class="wow fadeInUp">
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
            <div class="text">{{ $t('门店地点') }}</div>
            <div class="lines">
              <div></div>
              <div class="delay2"></div>
              <div class="delay4"></div>
              <div class="delay2"></div>
              <div></div>
            </div>
          </h1>
          <h2 class="wow fadeInUp" style="color:transparent;">Our location</h2>
        </div>
      </b-container>
    </section>
    <div-height height="40px"/>

    <!--🚇谷歌地图-->
    <section class="map">
      <!-- <img width="100%" src="~@/assets/img/map.jpg">
     -->
      <iframe v-if="iframe_start"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2607.8684886214623!2d-123.13812238397165!3d49.18408207932067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5486752882508727%3A0x17ced7138763f0d!2zNDAwMCBOdW1iZXIgMyBSZCAjMzEyMCwgUmljaG1vbmQsIEJDIFY2WCAwRTTliqDmi7_lpKc!5e0!3m2!1szh-TW!2stw!4v1675663131381!5m2!1sen!2stw"
              width="800" height="600" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade" class="google-map__two"></iframe>

    </section>

  </div>
</template>
<script>
import {WOW} from "wowjs"

export default {
  data() {
    return {
      iframe_start: false,
      hot_cars: [],
      banners: [],
      about_list: [],
      productList: [
        {
          id: 1,
          icon: require("@/assets/img/banner/4.jpg"),
          title: "专有云解决方案",
          desc: "专有云解决方案依托于阿里云、数梦工场提供的云计算、大数据、中间件和云安全能力，为用户搭建企业级互联网架构。通过提供集计算、存储、网络于一身的IaaS层基础设施服务，云管理平台、数据、安全统一分布式处理平台，满足用户的中、大型专有云场景需求。",
        },
        {
          id: 2,
          icon: require("@/assets/img/banner/4.jpg"),
          title: "混合云解决方案",
          desc: "江苏蚂蚁云数据技术有限公司混合云解决方案深度融合阿里云，结合自身在政企云计算业务技术沉淀，打造出贯通公共云和专有云的解决方案。从业务视角出发，提供基础架构层、数据服务层、应用层、安全能力等多层次的跨云混合。数据服务混合、应用混合和安全能力混合补齐了业界常见的基础架构混合云方案的短板，为用户业务提供全栈混合云能力。",
        },
        {
          id: 3,
          icon: require("@/assets/img/banner/4.jpg"),
          title: "大数据解决方案",
          desc: "蚂蚁云大数据解决方案打造了易使用、高性能、低成本、高可靠的解决方案，帮助客户进行大数据运营，挖掘出蕴含在数据当中的价值。底层计算引擎提供海量数据存储和高速计算能力；中层大数据工作台为数据资产的开发、管理、挖掘、服务化提供工具；上层应用通过各种形式发挥数据的价值",
        },
      ],
      active: 1,
      grid_list: [
        {text: "一周七天服务", hover: false},
        {text: "取车无忧，代驾到府", hover: false},
        {text: "全程安全保障", hover: false},
        {text: "全球交通指引", hover: false},
      ],
    }
  },
  created() {
    this.get_index()
    setTimeout(() => {
      this.iframe_start = true
    }, 500)
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },
  methods: {
    toSlide(index) {
      this.$refs.mySwiper.swiper.slideTo(index, 1500)
    },
    get_index() {
      this.$get({
        url: "/index/index/idx",
        success: (res) => {
          this.banners = res.data.banners
          this.productList = res.data.new_car
          this.hot_cars = res.data.hot_cars
          this.about_list = res.data.enterprise
          console.log("2020", this.banners)
        },
        tip: () => {
        },
      })
    },
  },
}
</script>
<style lang='scss' scoped>
.box {
  width: 100%;
}

//🥒标题
.title_sm {
  position: relative;

  h1 {
    width: 100%;
    position: absolute;
    font-size: 34px;
    color: #1d2124;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 2;
    top: 10px;
    font-weight: bold;
  }
}

//🥒商品图
.thumbnail {
  position: relative;
  display: block;
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  @include transition(0.4s);

  @media #{$xl} {
    height: 271px;
  }

  @media #{$lg} {
    height: 223px;
  }

  @media #{$md} {
    height: 159px;
  }

  @media #{$sm} {
    height: 112px;
  }

  @media #{$xs} {
    height: 60vw;
  }

  &:hover {
    cursor: pointer;

    .new_image {
      transform: scale(1.1);
    }

    .caption {
      height: 30%;

      .text {
        transform: scale(1.1);
      }
    }
  }

  .caption {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 50px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    font-size: 16px;

    @include transition(0.4s);

    .text {
      @include transition(0.4s);
      text-align: center;
      color: #fff;
    }

    @media #{$xl} {
      font-size: 16px;
    }

    @media #{$lg} {
      font-size: 12px;
    }

    @media #{$md} {
      font-size: 12px;
    }

    @media #{$sm} {
      font-size: 12px;
    }

    @media #{$xs} {
      font-size: 12px;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    @include transition(0.4s);

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
}

//🥒高级标题
.title_xs {
  position: relative;
  display: flex;
  align-items: center;

  @media #{$xl} {
    height: 100px;
  }

  @media #{$lg} {
    height: 100px;
  }

  @media #{$md} {
    height: 100px;
  }

  @media #{$sm} {
    height: 100px;
  }

  @media #{$xs} {
    height: 100px;
  }

  h3 {
    position: absolute;
    width: 17px;
    height: 3px;
    background: #333333;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 75px;
    z-index: 2;
  }

  h5 {
    position: absolute;
    margin: 0 auto;
    top: 70px;
    width: 100%;
    font-size: 16px;
    color: #b7b7b7;
    text-align: center;
  }

  h1 {
    top: 0;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    text-align: center;
    z-index: 2;
    font-weight: bold;
    font-size: 34px;
    color: #333333;
    display: flex;
    justify-content: center;

    .text {
      margin: 6px;
      font-size: 34px;
    }

    .lines {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .lines > div {
      background-color: #f67635;
      width: 2.5px;
      height: 25px;
      margin: 1.5px;
      border-radius: 4.6px;
      animation-fill-mode: both;
      display: inline-block;
      animation: lineing 0.9s 0s infinite cubic-bezier(0.85, 0.25, 0.37, 0.85);
    }

    .delay2 {
      animation-delay: 0.2s !important;
    }

    .delay4 {
      animation-delay: 0.4s !important;
    }

    @keyframes lineing {
      0% {
        transform: scaley(1);
      }

      50% {
        transform: scaley(0.4);
      }

      100% {
        transform: scaley(1);
      }
    }
  }
}

//🥒九宫格
.grid_box {
  display: table;
  width: 100%;
  margin-bottom: 40px;
  max-width: 96%;
  margin: 0 auto 40px;
  @include transition(0.4s);

  .grid {
    @include transition(0.2s);
    display: block;
    cursor: pointer;
    text-align: center;

    .thumb {
      img {
        @include transition(0.4s);
      }

      @media #{$xl} {
        margin-bottom: 30px;

        img {
          max-width: 80px;
          margin: 0 auto;
        }
      }

      @media #{$lg} {
        padding: 20px;

        img {
          max-width: 70px;
          margin: 0 auto;
        }
      }

      @media #{$md} {
        padding: 6px;

        img {
          max-width: 60px;
          margin: 0 auto;
        }
      }

      @media #{$sm} {
        padding: 20px;

        img {
          max-width: 80px;
          margin: 0 auto;
        }
      }

      @media #{$xs} {
        padding: 20px;

        img {
          max-width: 60px;
          margin: 0 auto;
        }
      }
    }

    @include transition(0.4s);

    @media #{$xl} {
      padding: 30px;
      font-size: 16px;
    }

    @media #{$lg} {
      padding: 14px;
      font-size: 14px;
    }

    @media #{$md} {
      padding: 10px;
      font-size: 12px;
    }

    @media #{$sm} {
      padding: 20px;
      font-size: 16px;
    }

    @media #{$xs} {
      padding: 6px;
      font-size: 12px;
    }
  }
}

//🥒企业概况

.firm_box {
  position: relative;
  /*背景*/
  .firm_bg {
    position: absolute;
    z-index: -1;
    transition: all 0.4s;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  /*标题*/
  .firm_title {
    padding-top: 34px;
    transition: all 0.4s;

    .line {
      width: 17px;
      height: 1px;
      background: #b7b7b7;
      margin: 0 10px;
    }
  }

  //切换栏
  .firm_list {
    display: flex;
    justify-content: space-around;
    @media #{$xs} {
      display: none !important;
    }

    .firm_item {
      position: relative;
      display: block;
      // margin-bottom: 20px;
      // background-color: #fff;
      border-radius: 4px;
      overflow: hidden;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        transform: scaleY(1.15);

        .firm_img {
          transform: scale(1.2);
        }
      }

      .firm_text {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        padding: 30px;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        font-size: 12px;
      }

      .firm_img {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
        transition: all 0.4s;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }

  // 媒体查询
  @media #{$xl} {
    .firm_bg {
      height: 488px;
    }
    .firm_title {
      padding-bottom: 150px;
    }
    .firm_item {
      height: 350px;
    }
  }

  @media #{$lg} {
    .firm_bg {
      height: 410px;
    }
    .firm_title {
      padding-bottom: 120px;
    }
    .firm_item {
      height: 290px;
    }
  }

  @media #{$md} {
    .firm_bg {
      height: 330px;
    }
    .firm_title {
      padding-bottom: 90px;
    }
    .firm_item {
      height: 210px;
    }
  }

  @media #{$sm} {
    .firm_bg {
      height: 270px;
    }
    .firm_title {
      padding-bottom: 70px;
    }

    .firm_item {
      height: 150px;
    }
  }

  @media #{$xs} {
    .firm_bg {
      height: 100%;
    }
    .firm_title {
      padding-bottom: 50px;
    }
    .firm_item {
      height: 25vw;
    }
  }
}

// 🥒Google Map 谷歌地图
.map {
  position: relative;
  display: block;
  background-color: #ebebeb;

  img {
    position: relative;
    display: block;
    border: none;
    height: 460px;
    width: 100%;
  }

  .google-map__two {
    position: relative;
    display: block;
    border: none;
    height: 460px;
    width: 100%;
    // mix-blend-mode: luminosity;
  }
}
</style>
