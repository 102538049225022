<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="shop" />
    <!-- 🥒轮播图 -->
    <Swiper :banners="banners" />
    <!-- 🍞面包屑导航栏 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <b-row>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('商城中心')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>
    <!-- 🍍商品列表 -->
    <section class="good_list">
      <b-container>
        <b-row>
          <b-col sm="6" md="6" v-for="(item,index) in list" :key="index" class="wow fadeInUp " :data-wow-delay="(index * 0.1) + 's'">
            <div class="item" @click="go('shop_details',item.id)">
              <div class="img picbox">
                <img :src="item.goods_cover" class="img_z">
              </div>
              <div class="text">
                <div class="one">{{item['goods_name'+$t('suffix')]}}</div>
                <!-- <div>CAD {{item.goods_price}}</div> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
      <div-height height="30px" />
      <!-- 🔋底部分页 -->
      <div-center>
        <b-pagination-nav :first-number="true" :last-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :number-of-pages="pages" use-router>
          <template v-slot:prev-text>
            <b-icon icon="chevron-left"></b-icon>
          </template>
          <template v-slot:next-text>
            <b-icon icon="chevron-right"></b-icon>
          </template>
        </b-pagination-nav>
      </div-center>
      <div-height height="30px" />
    </section>

  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      list: [],
      banners: [],
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
    }
  },
  created() {
    this.get_info()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },
  methods: {
    input() {},
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    },
    get_list(page) {
      this.currentPage = page
      // console.log("------", page)
      this.get_info()
    },
    get_info() {
      this.$get({
        url: "/index/goods/idx",
        data: {
          page: this.currentPage, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          //轮播图
          this.banners = res.data.addition.banners
          //列表
          this.list = res.data.list.data
          this.pages = res.data.list.last_page || 1
          this.currentPage = res.data.list.current_page

          // console.log(res.data.list.current_page, "kkk")
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
// 🍉商品列表
.good_list {
  .item {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.4s;
    overflow: hidden;
    cursor: pointer;
    // height: 306px;

    .img {
      transition: all 0.4s;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .text {
      transition: all 0.4s;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 30px;
      height: 60px;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(10px);
      text-align: center;
      color: #fff;
    }
    &:hover {
      .img {
        transform: scale(1.1);
      }
      .text {
        height: 30%;
      }
    }
  }
}
</style>