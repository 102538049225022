<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="rent" />
    <!-- 🥒轮播图 -->
    <Swiper :banners="banners" />
    <!-- 🍞面包屑 -->

    <section>
      <b-container>
        <div-padding padding="20px 0">
          <b-row>
            <b-col cols="auto" class="mr-auto">
              <div-left center>
                <div-text color="#1D2124" size="20px" bold>{{$t('车辆信息')}}</div-text>
              </div-left>
            </b-col>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="back()">
                  {{$t('汽车租赁')}}
                </b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('租赁详情')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>

    <!-- 🍍汽车筛选结果 -->
    <section>
      <b-container>
        <div-zebra></div-zebra>
        <div-card radius="0" bg="#fff" shadow="0px 3px 6px 1px rgba(0, 0, 0, 0.16)">
          <div-padding padding="20px">
            <!-- 🍋五等分 -->
            <b-row>
              <b-col sm="6" md="6" class="quintile_box">
                <div class="quintile">
                  <div-text class="left_text" color="#818181">{{$t('车种')}}</div-text>
                  <div-text color="#1D2124" v-if="original.classifi">{{original.classifi['classi_name'+$t('suffix')]}}</div-text>
                </div>
              </b-col>
              <b-col sm="6" md="6" class="quintile_box">
                <div class="quintile">
                  <div-text class="left_text" color="#818181">{{$t('品牌')}}</div-text>
                  <div-text color="#1D2124" v-if="original.brand">{{original.brand['brand_name'+$t('suffix')]}}</div-text>
                </div>
              </b-col>
              <b-col sm="6" md="6" class="quintile_box">
                <div class="quintile">
                  <div-text class="left_text" color="#818181">{{$t('型号')}}</div-text>
                  <div-text color="#1D2124" v-if="original.model">{{original.model['model_name'+$t('suffix')]}}</div-text>
                </div>
              </b-col>
              <b-col sm="6" md="6" class="quintile_box">
                <div class="quintile">
                  <div-text class="left_text" color="#818181">{{$t('年限')}}</div-text>
                  <div-text color="#1D2124" v-if="original.life">{{original.life['life_name'+$t('suffix')]}}</div-text>
                </div>
              </b-col>
              <b-col sm="12" md="12" class="quintile_box">
                <div class="quintile_card">
                  <div-center>
                    <div-img width="111px" height="73px">
                      <img :src="original.vehicle_thumbnail" alt="" />
                    </div-img>
                  </div-center>
                </div>
              </b-col>
            </b-row>
          </div-padding>
        </div-card>
      </b-container>
    </section>

    <!-- 🔨标题 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <div-text color="#1D2124" size="20px" bold>{{$t('租赁介绍')}}</div-text>
        </div-padding>
      </b-container>
    </section>
    <section>
      <b-container>
        <div-zebra></div-zebra>
        <div-card radius="0" bg="#fff" shadow="0px 3px 6px 1px rgba(0, 0, 0, 0.16)">
          <div-padding padding="30px">
            <!-- 🐰表格 -->
            <table01 class="table01" :original="original" :insurance="insurance" />
            <table02 class="table02" :original="original" :insurance="insurance" />
            <!-- 第八行 -->
            <div-height height="10px" />
            <div-save v-if="login" @save="go('rent_01',original.id)">{{$t('预约车辆')}}</div-save>
            <div-save v-else @save="go('login')">{{$t('预约车辆')}}</div-save>
          </div-padding>
        </div-card>
      </b-container>
    </section>
    <!-- 标题 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <div-text color="#1D2124" size="20px" bold>{{$t('详情/服务')}}</div-text>
        </div-padding>

        <div-zebra></div-zebra>

        <div-card radius="0" bg="#fff" shadow="0px 3px 6px 1px rgba(0, 0, 0, 0.16)">
          <div-padding padding="30px">
            <div-html :html="original['vehicle_desc'+$t('suffix')]"></div-html>
            <div class="htmlcententbox">
            <b>配件服務</b>
            <div v-for="(item,i) in original.fittingsList" :key="i" >
              • {{item['fittings_name'+$t('suffix')] }}
             </div>
            </div>
          </div-padding>
        </div-card>
      </b-container>
    </section>

    <div-height height="30px" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      login: false,
      id: this.query().id,
      banners: [],
      original: {},
      insurance: [],
      type: "",
      html: ` <p> 但最近，相信诸多越野爱好者们又该兴奋了，几天前，丰田中国在微博上发布一则消息，通过一系列配图可以得知，兰德酷路泽即将国产</p>
      <img src="${require("@/assets/img/banner/4.jpg")}"> </p>`,
      items: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Manage",
          href: "#",
        },
        {
          text: "Library",
          active: true,
        },
      ],
      list: [
        {
          t1: "2022-09-02",
          t2: "+500",
          t3: "招行信用卡",
          t4: "2022-09-02",
          t5: "47584839384959057",
        },
      ],
    }
  },
  created() {
    if (localStorage.getItem("zuche_token")) {
      this.login = true
    }
    this.urlparam()
    this.get_index()
  },
  methods: {
    get_index() {
      this.$get({
        url: "/index/vehicles/pagedata",
        data: {
          id: this.id,
        },
        success: (res) => {
          if(res.data.original.vehicle_banners){
          this.banners = res.data.original.vehicle_banners.split(",")
          }
          // console.log(this.banners)
          this.original = res.data.original
          this.insurance = res.data.insurance
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
// 🍋画家的五等分之一
.quintile_box {
  // flex: 20%;
  .quintile {
    width: 100%;
    text-align: center;

    .div-text {
      min-height: 37px;
      line-height: 37px;
    }
  }
  .quintile_card {
    width: 100%;
    text-align: center;
  }
}

//商品信息
.item {
  padding: 0 20px;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
}

//表格样式
.form-control {
  height: 70px;
  border-radius: 0;
  font-size: 15px;
  box-shadow: none;
  padding: 0 30px;
  border: 1px solid #d8d8d8;

  background: #ffffff;
  color: #707e9c;

  text-align: center;

  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;

  overflow: visible;
  font-size: 13px;
  color: #93837b;
}

.form-control:focus {
  position: relative;
  z-index: 1;
  border-color: #57b846;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

//媒体查询
@media #{$xl} {
  .table01 {
    display: block;
  }
  .table02 {
    display: none;
  }
  .table03 {
    display: none;
  }
  // 🍋画家的五等分之一
  .quintile_box {
    flex: 20%;
  }
}

@media #{$lg} {
  .table01 {
    display: block;
  }
  .table02 {
    display: none;
  }
  .table03 {
    display: none;
  }
  // 🍋画家的五等分之一
  .quintile_box {
    flex: 20%;
  }
}

@media #{$md} {
  .table01 {
    display: none;
  }
  .table02 {
    display: block;
  }
  .table03 {
    display: none;
  }
}

@media #{$sm} {
  .table01 {
    display: none;
  }
  .table02 {
    display: block;
  }
  .table03 {
    display: none;
  }

  .quintile_box {
    // flex: 20%;
    .quintile {
      width: 100%;
      text-align: center;
      display: block;
      .div-text {
        min-height: 37px;
        line-height: 37px;
      }
      .div-text.left_text {
        flex-shrink: 0;
        margin-right: 0;
      }
    }
  }
}

@media #{$xs} {
  .table01 {
    display: none;
  }
  .table02 {
    display: block;
  }
  .table03 {
    display: none;
  }

  .quintile_box {
    // flex: 20%;
    .quintile {
      width: 100%;
      text-align: center;

      display: flex;
      .div-text {
        min-height: 37px;
        line-height: 37px;
      }
      .div-text.left_text {
        flex-shrink: 0;
        margin-right: 30px;
      }
    }
  }
}
</style>