<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="new" />
    <!-- 🥒轮播图 -->
    <Swiper :banners="banners" />
    <div-height height="40px" />
    <!-- 商品标题-->
    <section>
      <b-container>
        <div-text color="#1D2124" size="30px" center>
          {{info['title'+$t('suffix')]}}
        </div-text>
        <div-height height="20px" />
        <div-text color="#1D2124" size="14px" center>
          {{info['desc'+$t('suffix')]}}
        </div-text>
        <div-height height="20px" />
      </b-container>
    </section>
    <!-- 🍞面包屑 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <b-row>
            <b-col cols="auto" class="mr-auto">
              <div-left center>
                <div-text color="#1D2124" size="20px" bold> {{$t('详情介绍')}} </div-text>
              </div-left>
            </b-col>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('new')">{{$t('新车到店')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('新车详情')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>
    <!-- 商品介绍-->
    <section>
      <div>
        <b-container>
          <div-zebra></div-zebra>
          <div-html :html="info['detail'+$t('suffix')]"></div-html>
        </b-container>
      </div>
    </section>
    <div-height height="40px" />
  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      id: this.query().id,
      info: {},
      banners: [],
    }
  },
  created() {
    this.get_info()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/newcarsarrive/pagedata",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.info = res.data.original
          this.banners = res.data.original.banners.split(",")
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang='scss' scoped>
</style>
