<template>
  <div class="div-btn">
    <div class="btn_box">
      <button @click="do_fun" class="btn" :class="{ auto }" :disabled="disabled">
        <slot></slot>
      </button>
    </div>
  </div>
</template>
    <script>
export default {
  name: "div-btn",
  props: {
    auto: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#fff",
    },
    bg: {
      type: String,
      default: "#333333",
    },
    radius: {
      type: String,
      default: "4px",
    },
    height: {
      type: String,
      default: "50px",
    },
    border: {
      type: String,
      default: "",
    },
    fun: {
      type: Function,
      default: () => {
        return Function
      },
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  created: function () {},
  methods: {
    do_fun() {
      if (this.disabled) {
        return false
      }
      this.disabled = true
      console.log("内部调用了", this.fun)
      // this.fun()
      this.$emit("fun")
    },
  },
}
</script>
    <style lang = "scss" scoped >
.div-btn {
  width: 100%;

  .auto {
    width: 100%;
    margin: 0 auto;
  }
  .btn_box {
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .btn {
    height: 50px;
    border-radius: 4px;
    background: #333333;
    color: #fff;
    width: 100%;
    max-width: 500px;
    cursor: pointer;

    font-size: 14px;
    transition: all 0.25s;
  }

  .btn:active {
    opacity: 0.9;
  }

  .btn:hover {
    background: #57b846;
  }

  .btn::after {
    border: 0;
  }

  .btn[disabled] {
    opacity: 1;
  }

  .btn[disabled]:active {
    opacity: 0.5;
  }

  .btn[disabled]:not([type]) {
    /* transform: scale(0.9); */
    opacity: 0.5;
  }
}
</style>
