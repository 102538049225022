<template>
  <div class="div-both" :class="{center,br,fill,column}">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    name: "div-both",
    props: {
      center: {
        type: Boolean,
        default: false
      },
      br: {
        type: Boolean,
        default: false
      },
      fill: {
        type: Boolean,
        default: false
      },
      column: {
        type: Boolean,
        default: false
      },
    }
  };

</script>
<style lang='scss' scoped>
  .div-both {
    display: flex;
    justify-content: space-between;
  }
  .div-both.center {
    height: 100%;
    align-items: center;
  }
  .br {
    flex-wrap: wrap;
  }
  .fill {
    width: 100%;
  }
  .column {
    flex-direction: column;
  }
</style>
