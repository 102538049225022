<!--
 * @Author: your name
 * @Date: 2022-01-08 09:30:51
 * @LastEditTime: 2022-01-11 13:09:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \yinheshop\src\vant-extend\vant-code\vant-code.vue
-->
<template>
  <div class="VantCode">
    <b-button size="small" v-if="is_time" @click="submit()">{{$t('获取验证码')}}</b-button>
    <b-button size="small" v-else class="no">{{ scend }}S</b-button>
    <Vcode :sliderText="$t('拖动滑块完成拼图')" :failText="$t('验证失败，请重试')" :successText="$t('验证通过！')" :show="isShow" @success="onSuccess" :imgs="[Img1, Img2, Img3]" @close="onClose" />
  </div>
</template>

<script>
import Vcode from "vue-puzzle-vcode"
import Img1 from "@/assets/img/code/1.jpg"
import Img2 from "@/assets/img/code/2.jpg"
import Img3 from "@/assets/img/code/3.jpg"
export default {
  name: "VantCode",
  props: {
    url: String,
    scene: String,
    email: String,
  },
  components: {
    Vcode,
  },
  data() {
    return {
      isShow: false,
      is_time: true,
      scend: 60,
      Img1,
      Img2,
      Img3,
    }
  },
  created() {
    console.log("加载验证码按钮组件，组件地址为vant-extend")
  },
  methods: {
    hClick() {
      alert("失败")
    },
    submit() {
      this.isShow = true
    },

    onSuccess(msg) {
      this.isShow = false // 通过验证后，需要手动关闭模态框
      //   var time = Date.parse(new Date()) / 1000
      //   var sign = time + 'tfdrnh'
      //   sign = this.$md5(sign)
      //   console.log(sign)
      this.sendsms()
    },

    onClose() {
      this.isShow = false
    },

    sendsms(time, sign) {
      //防抖 监听输入框
      var that = this
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        that.$post({
          url: that.url,
          data: { email: that.email, scene: that.scene },
          success: (res) => {
            // that.$toast({
            //   message: '发送成功',
            // })
            that.$toast(res.msg, {
              timeout: 3000,
              position: "top-center",
              pauseOnHover: false,
            })
            that.is_time = false
            var timer = setInterval(() => {
              that.scend--
              if (that.scend == 0) {
                clearInterval(timer)
                that.is_time = true
                that.scend = 60
              }
            }, 1000)
          },
          tip: (val) => {},
        })
      }, 500)
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
.VantCode {
  .van-button--small {
    // background: $theme;
    color: #ae3fc6;
    background: transparent;
    height: 6vw;
    border: none;
    border-radius: 26vw;
    display: block;
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  // width: 90vw;
  // height: 90vw;
  margin: 0 auto;
  background-color: #fff;
  // /deep/.verify-img-panel {
  //   width: 90vw !important;
  //   height: 90vw !important;
  // }
  // /deep/.verify-bar-area {
  //   width: 90vw !important;
  // }
}
</style>