<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="register" />
    <!--🥒表单 -->
    <b-container>
      <div class="login_box">
        <div class="login">
          <div class="login_text">{{$t('注册信息')}}</div>
          <form class="logo_input">
            <!--🥒输入框 -->
            <fieldset class="fieldset">
              <legend>EMAIL*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="email" :placeholder="$t('请输入EMAIL')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
            <!--🥒输入框 -->
            <fieldset class="fieldset">
              <legend>{{$t('验证码')}}*</legend>
              <b-input-group class="div-input">
                <template v-slot:append>
                  <b-input-group-text class="div-code">
                    <!-- 获取手机验证码按钮 封装 位置 在 vant-extend ===》 添加了防抖防止连点-->
                    <div-code url="/index/email/sendEmail" scene="1" :email="email"></div-code>

                  </b-input-group-text>
                </template>
                <b-form-input class="input" v-model="code" :placeholder="$t('请输入验证码')" type="search">
                </b-form-input>
              </b-input-group>
            </fieldset>
            <!--🥒输入框 -->
            <fieldset class="fieldset">
              <legend>{{$t('登录密码')}}*</legend>
              <b-input-group class="div-input">
                <template v-slot:append>
                  <b-input-group-text @click="active = !active" class="cursor">
                    <b-icon-eye-fill :color="active ? '#3B3B3B' : '#D7D7D7'"></b-icon-eye-fill>
                  </b-input-group-text>
                </template>
                <b-form-input :disabled="!email" class="input" v-model="password" autocomplete=" " :placeholder="$t('请输入登录密码')" :type="active ? 'search' : 'password'">
                </b-form-input>
              </b-input-group>
            </fieldset>
            <!--🥒输入框 -->
            <fieldset class="fieldset">
              <legend>{{$t('重复密码')}}*</legend>
              <b-input-group class="div-input">
                <template v-slot:append>
                  <b-input-group-text @click="active = !active" class="cursor">
                    <b-icon-eye-fill :color="active ? '#3B3B3B' : '#D7D7D7'"></b-icon-eye-fill>
                  </b-input-group-text>
                </template>
                <b-form-input :disabled="!email" class="input" v-model="confirm_password" autocomplete=" " :placeholder="$t('请再次输入登录密码')" :type="active ? 'search' : 'password'">
                </b-form-input>
              </b-input-group>
            </fieldset>

            <div-height height="30px"></div-height>
            <!--🥒复选框 -->
            <b-form-checkbox id="checkbox-1" button-variant="info" v-model="status" name="checkbox-1" value="accepted" unchecked-value="not_accepted">
              {{$t('我已阅读并同意')}}
              <span @click="go('agreement',{id:1,type:1})">{{$t('《用户协议》与 《隐私政策》')}}</span>
              <!-- 与
              <span @click="go('agreement',2)">《隐私政策》</span> -->
            </b-form-checkbox>
            <div-height height="30px"></div-height>
            <div-save ref="btn" @save="save">{{$t('下一步')}}</div-save>
          </form>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      code: "",
      password: "",
      confirm_password: "",
      active: false,
      status: "not_accepted",
    }
  },
  created() {
    let obj = localStorage.getItem("zuche_one")
    if (obj) {
      obj = JSON.parse(obj)
      Object.keys(obj).map((item) => (this[item] = obj[item]))
    }
  },
  methods: {
    //注册 - 注册用户 - 预校验
    save() {
      const loading = this.$loading({
        lock: true,
      })

      let that = this
      if (this.status == "not_accepted") {
        this.$toast(this.$t("请先阅读并同意用户协议与隐私政策"), {
          timeout: 3000,
          position: "top-center",
        })
        setTimeout(() => {
          loading.close()
          this.$refs.btn.disabled = false
        }, 3000)
        return
      }
      let obj = {
        reg_type: 1, //注册类型为邮箱注册
        email: this.email, //邮箱地址
        code: this.code, //邮箱验证码
        password: this.password, //密码（6-16位英数混合）
        confirm_password: this.confirm_password, //确认密码
      }
      //存储第一步的注册信息
      localStorage.setItem("zuche_one", JSON.stringify(obj))
      this.$post({
        url: "/index/reg/regPreCheck",
        data: obj,
        success: (res) => {
          that.$toast.success(res.msg, {
            timeout: 3000,
            position: "top-center",
          })
          setTimeout(() => {
            loading.close()
            this.go("register_over")
            this.$refs.btn.disabled = false
          }, 3000)
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
//注册
.login_box {
  width: 100%;
  min-height: calc(100vh - 360px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.login {
  width: auto;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 30px 68px 30px 68px;
  @include transition(0.4s);
  @media #{$xl} {
    flex-direction: row;
  }
  @media #{$lg} {
    flex-direction: row;
  }
  @media #{$md} {
    flex-direction: column;
  }
  @media #{$sm} {
    flex-direction: column;
    width: 100%;
    padding: 30px;
    .logo_input {
      width: 100%;
    }
  }
  @media #{$xs} {
    flex-direction: column;
    width: 100%;
    padding: 30px 0;
    .login_text {
      margin-bottom: 30px;
    }
    .line {
      display: none;
    }
    /deep/.custom-control-label {
      font-size: 14px !important;
    }
    .logo_input {
      width: 100%;
    }
  }
}
.login_text {
  text-align: center;
  font-size: 23px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 80px;
}
.line {
  width: 1px;
  height: 226px;
  background: linear-gradient(#fff 0%, #d7d7d7 50%, #fff 100%);
}
.logo_input {
  width: 438px;
}
.fieldset {
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 76px;
  @include transition(0.4s);
  &:focus-within {
    border: 1px solid #57b846;
    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }
  legend {
    @include transition(0.4s);
    position: relative;
    z-index: 1;
    color: #2f2e3f;
    font-size: 16px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
  }
  /deep/.div-input {
    position: relative;
    top: -10px;
    width: 100%;
    height: 60px;
    background: #ffffff;
    &:focus-within {
      border-color: #3c58b2;
    }
    .input-group-text {
      padding: 0 24px;
      background: transparent;
      border-color: transparent !important;
    }
    .div-code {
      padding: 0 12px;
      .btn {
        height: 40px;
        background: #fff;
        color: #333;
        font-size: 14px;
        border: 0;
        padding: 0 14px;
        box-shadow: none;
        &:focus {
          box-shadow: none;
        }
      }
    }
    input {
      box-sizing: border-box;
      height: 60px;
      line-height: 60px;
      background: transparent;
      border-color: transparent !important;
      &.form-control {
        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }
    input::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 14px;
    }
    input:-webkit-autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
    }
    input:autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
    }
  }
  .cursor {
    cursor: pointer;
  }
}
//用户协议与隐私政策
/deep/.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #3b3b3b !important;
  background-color: #3b3b3b !important;
  box-shadow: none;
}
/deep/.custom-control-label::before {
  border: 1px solid #3b3b3b;
  box-shadow: none;
}
/deep/.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
/deep/.custom-control-label {
  box-shadow: none;
  font-size: 15px;
  color: #818181;
  cursor: pointer;
  span {
    color: #3b3b3b;
  }
}
</style>