<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="shop" />
    <!-- 🥒轮播图 -->
    <section>
      <div class="notice" :style="`background-image: url(${info.goods_cover});`">
        <b-container>
          <b-row>
            <b-col xl="6" lg="6" md="4"></b-col>
            <b-col xl="6" lg="6" md="8">
              <div-card bg="#fff" radius="2px">
                <div-zebra></div-zebra>
                <div-padding padding="30px">
                  <div-text color="#1D2124" size="20px" bold>{{info['goods_name'+$t('suffix')]}} </div-text>
                  <div-left center>
                    <div-text color="#1D2124" size="26px" bold>{{info.kms}}</div-text>
                    <div-text color="#1D2124" size="26px" bold>{{$t('公里')}}</div-text>
                    <div-width size="10px"></div-width>
                    <div-text color="#1D2124" size="20px" bold v-if="info.is_write_off_kms==1"> {{$t('不核销公里数')}}</div-text>
                  </div-left>
                  <div-height height="10px" />
                  <div-text color="#818181" size="14px">{{$t('购买则可立即使用')}}</div-text>
                  <div-height height="20px" />
                  <div-left center>
                    <div-text color="#1D2124" size="16px" bold>{{$t('价格')}}：CAD {{info.goods_price}}</div-text>
                    <div-width size="10px"></div-width>
                    <div-text color="#818181" size="16px" bold>{{$t('销量')}}：{{info.goods_sales+info.goods_basic_sales}}</div-text>
                  </div-left>
                  <div-height height="50px" />
                  <div-smbtn v-if="login" @save="go('shop_pay',info.id)">{{$t('立即购买')}}</div-smbtn>
                  <div-smbtn v-else @save="go('login')">{{$t('立即购买')}}</div-smbtn>
                </div-padding>
              </div-card>

            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>
    <!-- 面包屑🍞 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <b-row>
            <b-col cols="auto" class="mr-auto">
              <div-left center>
                <div-text color="#1D2124" size="20px" bold>
                  {{$t('详情介绍')}}
                </div-text>
              </div-left>
            </b-col>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('shop')">{{$t('商城中心')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('商城详情')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>

    <!-- 🔋商品介绍-->
    <section>
      <div>
        <b-container>
          <div-zebra></div-zebra>
          <div-height height="40px" />
          <!-- 🥒轮播图 -->
          <section>
            <div class="banner">
              <Swiper :banners="goods_banners" />
            </div>
          </section>
          <div-height height="40px" />
          <div-html :html="info['goods_detail'+$t('suffix')]"></div-html>
        </b-container>
      </div>
    </section>
    <div-height height="40px" />

  </div>
</template>
  <script>
export default {
  data() {
    return {
      login: false,
      info: {},
      uname: "",
      text: "",
      list: [],
      goods_banners: [],
    }
  },
  created() {
    if (localStorage.getItem("zuche_token")) {
      this.login = true
    }
    this.urlparam()
    this.get_info()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/goods/pagedata",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.info = res.data.original
          this.goods_banners = []
          if (this.info.goods_banners) {
            this.goods_banners = this.info.goods_banners.split(",")
          }
        },
        tip: () => {},
      })
    },
  },
}
</script>
  <style lang="scss" scoped>
.notice {
  // background: #122947 url(~@/assets/img/banner/6.png) no-repeat;
  background: no-repeat;
  background-size: cover !important;
  background-position: center !important;
  padding: 80px 0 80px;
  .notice_text {
    color: #fff;
    text-align: right;
    line-height: 2;

    h2 {
      font-weight: bold;
    }
  }
}
</style>