<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒左侧菜单 -->
            <Menu name="order_list"></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🥒 标题 -->
            <div-padding padding="20px 0">
              <div-text color="#1D2124" size="20px" bold>{{ $t('租车订单') }}</div-text>
            </div-padding>

            <div-zebra></div-zebra>

            <div class="zebra_box shadow">
              <b-card-group>
                <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                  <template v-slot:header>
                    <div-both center>
                      <b-button class="btn" variant="none">
                        <div-text>{{ $t('纪录') }}</div-text>
                      </b-button>
                    </div-both>
                  </template>

                  <div-height height="1px" bg="rgba(34, 34, 34, 0.125)" />

                  <b-tabs v-model="status" pills card>
                    <b-tab :title="$t('全部')" @click="opt(0)"></b-tab>
                    <b-tab :title="$t('待付款')" @click="opt(1)"></b-tab>
                    <b-tab :title="$t('待付尾款')" @click="opt(2)"></b-tab>
                    <b-tab :title="$t('待取车')" @click="opt(3)"></b-tab>
                    <b-tab :title="$t('进行中')" @click="opt(4)"></b-tab>
                    <b-tab :title="$t('特殊费用')" @click="opt(5)"></b-tab>
                    <b-tab :title="$t('退款')" @click="opt(6)"></b-tab>
                  </b-tabs>

                  <div-height height="1px" bg="rgba(34, 34, 34, 0.125)" />

                  <el-collapse accordion v-model="leftModuleActiveNames">
                    <el-collapse-item v-for="(items, indexs) in list" :key="indexs" :name="indexs">
                      <template slot="title">
                        <div @click="go('order_item', { id: items.id, the_isp: items.renews.length == 0 && items.status == 35, the_isz: items.renews.length == 0 && items.special_fee && items.special_fee * 1 > 0 })" class="items">
                          <b-row v-if="items.redun">
                            <b-col lg="3">
                              <div-padding padding="20px 0">
                                <div-img class="picbox" hover height="100%" width="100%" radius="6px">
                                  <b-img height="100%" fluid :src="items.redun.vehicle_thumbnail"></b-img>
                                </div-img>
                              </div-padding>
                            </b-col>
                            <b-col lg="9">
                              <div class="item_right">
                                <div>
                                  <b-row>
                                    <b-col lg="6">
                                      <div-text size="15px" color="#1D2124" bold>
                                        {{ items.redun['vehicle_name' + $t('suffix')] }}
                                      </div-text>
                                    </b-col>
                                    <b-col lg="6">
                                      <div-text size="13px" color="#1D2124" right>
                                        {{ {
                                          10: $t('待付款'), 15: $t('待付尾款'), 20: $t('待取车'), 30: $t('进行中'), 35: $t('待付特殊费用'), 38:
                                            $t('待验收'), 40: $t('已还车'), 60: $t('逾期'),70: $t('退款中'), 100: $t('已取消'), 110:
                                            $t('已退款')
                                        }[items.status]}}
                                      </div-text>
                                    </b-col>
                                  </b-row>
                                  <div-text size="13px" color="#666666">

                                    {{ $t('车辆类型') }} ：{{ items.redun['classi_name' + $t('suffix')] }} ｜
                                    {{ $t('车辆品牌') }} ：{{ items.redun['brand_name' + $t('suffix')] }} ｜
                                    {{ $t('车辆型号') }} ：{{ items.redun['model_name' + $t('suffix')] }} ｜
                                    {{ $t('车辆年份') }} ：{{ items.redun['life_name' + $t('suffix')] }}

                                  </div-text>
                                </div>
                                <div-right>
                                  <div v-if="items.status == 10" @click.stop="showPopup2(items.id)" class="cancel">
                                    <div-padding padding="10px">
                                      {{ $t('取消订单') }}
                                    </div-padding>
                                  </div>
                                  <div v-if="items.status == 40 || items.status == 100 || items.status == 110" @click.stop="showPopup(items.id)" class="icon"><i class="el-icon-delete"></i></div>

                                  <div @click.stop="open(indexs)" class="cancel" v-if="items.renews.length > 0" :class="leftModuleActiveNames[0] == indexs ? 'icon_active' : ''">
                                    <div-padding padding="10px">
                                      {{ $t('查看续租') }}
                                    </div-padding>
                                  </div>

                                  <div v-if="items.renews.length == 0 && items.status == 35" class="cancel">
                                    <div-padding padding="10px">
                                      {{ $t('特殊费用支付') }}
                                    </div-padding>
                                  </div>

                                </div-right>
                              </div>
                            </b-col>
                          </b-row>

                        </div>
                      </template>
                      <div @click="go('order_item', { id: item.id, the_isp: item.status == 35 && item.pay_special_show == 1, the_isz: item.special_fee && item.special_fee * 1 > 0 })" v-for="(item, index) in items.renews" :key="index" class="item">
                        <b-row v-if="item.redun">
                          <b-col lg="3">
                            <div-padding padding="20px 0">
                              <div-img class="picbox" hover height="100%" width="100%" radius="6px">
                                <b-img height="100%" fluid :src="item.redun.vehicle_thumbnail"></b-img>
                              </div-img>
                            </div-padding>
                          </b-col>
                          <b-col lg="9">
                            <div class="item_right">
                              <div>
                                <b-row>
                                  <b-col lg="6">
                                    <div-text size="15px" color="#1D2124" bold>
                                      {{ item.redun['vehicle_name' + $t('suffix')] }}
                                    </div-text>
                                  </b-col>
                                  <b-col lg="6">
                                    <div-text size="13px" color="#1D2124" right>
                                      {{ {
                                        10: $t('待付款'), 15: $t('待付尾款'), 20: $t('待取车'), 30: $t('进行中'), 35: $t('待付特殊费用'), 38:
                                          $t('待验收'), 40: $t('已还车'), 60: $t('逾期'), 100: $t('已取消'), 110: $t('已退款')
                                      }[items.status] }}
                                    </div-text>
                                  </b-col>
                                </b-row>
                                <div-text size="13px" color="#666666">

                                  {{ $t('车辆类型') }} ：{{ item.redun['classi_name' + $t('suffix')] }} ｜
                                  {{ $t('车辆品牌') }} ：{{ item.redun['brand_name' + $t('suffix')] }} ｜
                                  {{ $t('车辆型号') }} ：{{ item.redun['model_name' + $t('suffix')] }} ｜
                                  {{ $t('车辆年份') }} ：{{ item.redun['life_name' + $t('suffix')] }}

                                </div-text>
                              </div>
                              <div-right>

                                <div v-if="item.status == 10" @click.stop="showPopup2(item.id)" class="cancel">
                                  <div-padding padding="10px">
                                    {{ $t('取消订单') }}
                                  </div-padding>
                                </div>
                                <div v-if="item.status == 40 || item.status == 100 || item.status == 110" @click.stop="showPopup(item.id)" class="icon"><i class="el-icon-delete"></i>
                                </div>

                                <div v-if="item.status == 35 && item.pay_special_show == 1" class="cancel">
                                  <div-padding padding="10px">
                                    {{ $t('特殊费用支付') }}
                                  </div-padding>
                                </div>

                              </div-right>
                            </div>

                          </b-col>
                        </b-row>

                      </div>
                    </el-collapse-item>
                  </el-collapse>

                  <el-empty v-if="list && list.length == 0" :description="$t('暂无数据')"></el-empty>

                  <template v-slot:footer>

                    <!-- 🔋底部分页 -->
                    <div-center>
                      <b-pagination-nav :first-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :last-number="true" :number-of-pages="pages" use-router>
                        <template v-slot:prev-text>
                          <b-icon icon="chevron-left"></b-icon>
                        </template>
                        <template v-slot:next-text>
                          <b-icon icon="chevron-right"></b-icon>
                        </template>
                      </b-pagination-nav>
                    </div-center>

                  </template>
                </b-card>
              </b-card-group>
            </div>
          </b-col>
        </b-row>
      </section>
      <div-height height="30px" />

    </b-container>

  </div>
</template>
<script>
import toastEel from "@/components/toast-ui/del01.vue"
import toastCancel from "@/components/toast-ui/cancel01.vue"
export default {
  data() {
    return {
      id: 0,
      type: 0,
      shouldShowDialog: false,
      msg: "您确定要取消订单吗？",
      leftModuleActiveNames: [],
      status_arr: [],
      status: 0,
      is_edit: false,
      active: 0,
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
      list: [],
      loading: false,
    }
  },
  created() {
    this.status = this.query().status * 1
    this.get_info(this.status)
  },
  mounted() {},
  methods: {
    showPopup(id) {
      this.id = id
      this.loading = this.$loading({
        lock: true,
      })
      this.$toast(
        {
          component: toastEel,
          props: {
            the_title: this.$t("您确定要删除该订单吗？"),
            the_left: this.$t("取消"),
            the_right: this.$t("确定"),
          },
          // 事件回调，组件中emit的方法放在这里进行回调
          listeners: {
            showDialog: () => {
              console.log("调用删除")
              this.del()
            },
            closeDialog: () => {
              console.log("调用关闭")
              this.close()
            },
          },
        },
        {
          id: 1,
          timeout: false,
          position: "top-center",
          closeOnClick: false, // 单击即关闭
          pauseOnFocusLoss: false,
          pauseOnHover: false, // 鼠标移入暂停
          draggable: false, // 允许通过拖动和滑动事件关闭
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false, // 显示/隐藏进度条
          closeButton: false,
          icon: false,
          rtl: false,
        }
      )
    },
    close() {
      this.loading.close()
      this.$toast.clear()
    },
    del() {
      this.$toast.clear()
      this.$post({
        url: "/index/rentalorders/markDelRentalOrders",
        data: {
          ids: this.id,
        },
        success: (res) => {
          setTimeout(() => {
            this.loading.close()
            this.get_info()
          }, 2000)
        },
        tip: () => {
          console.log("3")
          setTimeout(() => {
            this.loading.close()
          }, 3000)
        },
      })
    },

    showPopup2(id) {
      this.id = id
      this.loading = this.$loading({
        lock: true,
      })
      this.$toast(
        {
          component: toastCancel,
          props: {
            the_title: this.$t("您确定要取消该订单吗？"),
            the_left: this.$t("取消"),
            the_right: this.$t("确定"),
          },
          // 事件回调，组件中emit的方法放在这里进行回调
          listeners: {
            showDialog: () => {
              console.log("调用删除")
              this.cancel()
            },
            closeDialog: () => {
              console.log("调用关闭")
              this.close()
            },
          },
        },
        {
          id: 1,
          timeout: false,
          position: "top-center",
          closeOnClick: false, // 单击即关闭
          pauseOnFocusLoss: false,
          pauseOnHover: false, // 鼠标移入暂停
          draggable: false, // 允许通过拖动和滑动事件关闭
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false, // 显示/隐藏进度条
          closeButton: false,
          icon: false,
          rtl: false,
        }
      )
    },
    cancel() {
      this.$toast.clear()
      this.$post({
        url: "/index/rentalorders/cancelOrder",
        data: {
          rental_order_id: this.id,
        },
        success: (res) => {
          setTimeout(() => {
            this.loading.close()
            this.get_info()
          }, 2000)
        },
        tip: () => {
          console.log("3")
          setTimeout(() => {
            this.loading.close()
          }, 3000)
        },
      })
    },

    open(indexs) {
      if (this.leftModuleActiveNames[0] == indexs) {
        this.leftModuleActiveNames = []
        return
      } else {
        this.leftModuleActiveNames = [indexs]
      }
    },
    opt(status) {
      this.leftModuleActiveNames = []
      this.get_info(status)
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    },
    get_list(page) {
      this.currentPage = page
      this.get_info()
    },
    get_info(status) {
      this.$get({
        url: "/index/rentalorders/idx",
        data: {
          status: { 0: "", 1: 10, 2: 15, 3: 20, 4: 30, 5: 35 ,6: 150 }[status],
          page: this.currentPage, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          //列表
          this.status_arr = res.data.addition.status
          this.list = res.data.list.data
          this.pages = res.data.list.last_page || 1
          this.currentPage = res.data.list.current_page
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.picbox {
  position: relative;
}

.picbox:before {
  content: "";
  display: block;
  padding-top: 70%; //宽高比例修改这个数值
}

.picbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/deep/.button-danger {
  display: none !important;
}

.div-text {
  line-height: 2 !important;
}

.item_right {
  display: flex;
  height: 100%;
  padding: 20px 0;
  flex-direction: column;
  justify-content: space-between;
}

.cancel {
  height: 30px;
  margin-left: 10px;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // background: #e9ebf1;
  background: #333;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.04);
  transition: all 0.3s;
}

.icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // background: #e9ebf1;
  background: #333;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.04);
  transition: all 0.3s;

  i {
    transition: all 0.3s;
  }
}

.icon_active {
  background: #85ce61;

  i {
    transform: rotate(180deg);
  }
}

/deep/.el-collapse-item__arrow {
  position: absolute;
  right: 0 !important;
  display: none;
}

//
.items {
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  background: #fff;
}

.item {
  width: 100%;
  padding: 0 20px;
  cursor: pointer;
  background: #f7f7f7;
  // &:hover {
  //   background: #f5f5f5;
  // }
}

/deep/.el-collapse-item__header {
  padding: 0 !important;
  cursor: pointer;
}

/deep/.el-collapse-item__content {
  padding: 0 !important;
}

/deep/.card-header {
  border-bottom: 0 !important;
  background-color: #fff !important;
}

.tabs {
  /deep/.nav-link {
    font-size: 14px;
    color: #3b3b3b !important;
  }

  /deep/.active {
    color: #fff !important;
    background-color: #3b3b3b !important;
  }

  .tab-content {
    display: none;

    .active {
      display: none;
    }
  }

  .tab {
    padding: 10px 38px 10px 10px;
    cursor: pointer;
    color: #1d2124;
    font-weight: bold;
  }
}
</style>