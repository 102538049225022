module.exports = {
  '首页': '首页',
  '汽车租赁': '汽车租赁',
  '新车到店': '新车到店',
  '商城中心': '商城中心',
  '关于我们': '关于我们',
  '登录': '登录',
  '注册': '注册',
  '新车推荐': '新车推荐',
  '豪华自有其道，全新车款推荐': '豪华自有其道，全新车款推荐',
  '热门车款推荐': '热门车款推荐',
  '最热门车款推荐，满足您的租赁需求': '最热门车款推荐，满足您的租赁需求',
  '服务特色': '服务特色',
  '一周七天服务': '一周七天服务',
  '取车无忧，代驾到府': '取车无忧，代驾到府',
  '全程安全保障': '全程安全保障',
  '全球交通指引': '全球交通指引',
  '企业概况': '企业概况',
  // '关于我们': '关于我们',
  '公司简介': '公司简介',
  '服务项目': '服务项目',
  '了解更多': '了解更多',
  '门店地点': '门店地点',
  // '商城中心': '商城中心',
  // '关于我们': '关于我们',
  '如何租赁': '如何租赁',
  '联系客服': '联系客服',
  '充值中心': '充值中心',
  '联系方式': '联系方式',
  '公司地址': '公司地址',
  // '版权所有：沛豪国际租车': '版权所有：沛豪国际租车',
  '请输入要搜索的内容': '请输入要搜索的内容',
  '汽车租赁2': '汽车租赁',
  '让我们发现最完美的汽车': '让我们发现最完美的汽车',
  '车种': '车种',
  '请选择车种': '请选择车种',
  '品牌': '品牌',
  '请选择品牌': '请选择品牌',
  '型号': '型号',
  '请选择型号': '请选择型号',
  '年份': '年份',
  '请选择年份': '请选择年份',
  '立即充值': '立即充值',
  '驾照信息提交': '驾照信息提交',
  '车辆选型': '车辆选型',
  '立即预约': '立即预约',
  '租赁合同签署': '租赁合同签署',
  '租赁时间': '租赁时间',
  '取还车方式': '取还车方式',
  '租金明细': '租金明细',
  '支付押金': '支付押金',
  // '请输入要搜索的内容': '请输入要搜索的内容',
  // '汽车租赁2': '汽车租赁',
  // '让我们发现最完美的汽车': '让我们发现最完美的汽车',
  // '车种': '车种',
  // '请选择车种': '请选择车种',
  // '品牌': '品牌',
  // '请选择品牌': '请选择品牌',
  // '型号': '型号',
  // '请选择型号': '请选择型号',
  // '年份': '年份',
  // '请选择年份': '请选择年份',
  '租赁详情': '租赁详情',
  '车辆信息': '车辆信息',
  // '车种': 'Body Type',
  // '品牌': 'Brand',
  // '型号': 'Model',
  // '年份': 'Years',
  '租赁介绍': '租赁介绍',
  // '租赁时间': 'Rental Period',
  '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  '天以上': '天以上',
  '每日租金': '每日租金',
  '每日可跑公里数': '每日可跑公里数',
  '超出公里数费用': '超出公里数费用',
  '车体险自付額500元方案': '车体险自付額500元方案',
  '车体险自付額2500元方案': '车体险自付額2500元方案',
  '新车险费用(单日价格)': '新车险费用(单日价格)',
  '租赁介绍价格费用不包含税费，最终租赁价格根据订单明细为准。': '租赁介绍价格费用不包含税费，最终租赁价格根据订单明细为准。',
  '预约车辆': '预约车辆',
  '详情/服务': '详情 / 服务',
  // '如何租赁': '如何租赁',
  // '如何租赁': '下單流程',
  // '新车到店': 'New Arrivals',
  // '豪华自有其道，全新车款推荐': '獨具品味，風格多變。全新車款推薦',
  '详情介绍': '详情介绍',
  '新车详情': '新车详情',
  // '商城中心': '商城中心',
  '商城详情': '商城详情',
  // '详情介绍': 'Details...',
  '购买则可立即使用': '购买则可立即使用',
  '价格': '价格',
  '销量': '销量',
  '立即购买': '立即购买',
  // '价格': '价格',
  '数量': '数量',
  '总价': '总价',
  '商城购买': '商城购买',
  '支付方式': '支付方式',
  '信用卡支付': '信用卡支付',
  '支付金额': '支付金额',
  '余额支付': '余额支付',
  '支付密码': '支付密码',
  '请输入支付密码': '请输入支付密码',
  '可用余额': '可用余额',
  '支付': '支付',
  '微信支付': '微信支付',
  '微信扫码支付': '微信扫码支付',
  '支付宝支付': '支付宝支付',
  '支付宝扫码支付': '支付宝扫码支付',
  '退款中':'退款中',
  '退款':'退款',
  // '公司简介': 'Our Company',
  // '服务项目': '服務項目',
  '凭借着可靠的品质和优秀的越野性能深受顾客的喜爱': '凭借着可靠的品质和优秀的越野性能深受顾客的喜爱',
  '公司简介2': '公司简介',
  // '公司简介': 'Our Company',
  "豪华自有其道，全新数位化专享车旅程": "豪华自有其道，全新数位化专享车旅程",
  // 首頁
  "服务项目2": "服务项目",
  // EMAIL
  '请输入EMAIL': '请输入EMAIL',
  '登录密码': '登录密码',
  '请输入登录密码': '请输入登录密码',
  // '注册': '註冊',
  '忘记密码？': '忘记密码？',
  '我已阅读并同意': '我已阅读并同意',
  "《用户协议》与 《隐私政策》": "《用户协议》与 《隐私政策》", // '登录': '登錄',
  '注册信息': '注册信息',
  '隐私权政策与服务条款': '隐私权政策与服务条款',
  // 首頁/用戶登錄/用戶協議與隱私條款
  // EMAIL
  // '请输入EMAIL': '请输入EMAIL',
  '验证码': '验证码',
  '请输入验证码': '请输入验证码',
  // '登录密码': '登录密码',
  // '请输入登录密码': '请输入登录密码',
  '重复密码': '重复密码',
  '请再次输入登录密码': '请再次输入登录密码',
  // 我已阅读并同意《用户协议》与《隐私政策》
  '下一步': '下一步',
  '用户协议与隐私条款': '用户协议与隐私条款',
  // '隐私权政策与服务条款': 'Privacy Policy and Terms of Service',
  '订单中心': '订单中心',
  '租车订单': '租车订单',
  '核销订单': '核销订单',
  '我的钱包': '我的钱包',
  '充值钱包': '充值钱包',
  '租车押金': '租车押金',
  '系统设置': '系统设置',
  '信息通知': '信息通知',
  '客服咨询': '客服咨询',
  '绑定银行卡': '绑定银行卡',
  // '登录密码': '登录密码',
  '支付密码': '支付密码',
  '退出系统': '退出系统',
  '个人信息': '个人信息',
  '点击查看': '点击查看',
  '汽车驾照': '汽车驾照',
  '未提交': '未提交',
  '审核中': '审核中',
  '通过': '通过',
  '不通过': '不通过',
  // '我的钱包': 'My wallet',
  // '充值钱包': 'Recharge My Wallet',
  // '租车押金': 'Rental Secutiry Deposit',
  // '租车订单': 'Rental orders',
  '全部': '全部',
  '待付款': '待付款',
  '待取车': '待取车',
  '已取车': '已取车',
  '车辆类型': '车辆类型',
  '车辆品牌': '车辆品牌',
  '车辆型号': '车辆型号',
  '车辆年份': '车辆年份',
  "查看详情": "查看详情",
  '核销订单': '核销订单',
  // '全部': '全部',
  '待使用': '待使用',
  '已使用': '已使用',
  '已过期': '已过期',
  '订单号码': '订单号码',
  '交易单号': '交易单号',
  '支付方式': '支付方式',
  '支付时间': '支付时间',
  '有效期至': '有效期至',
  // "查看详情": "查看详情",
  // '系统设置': '系统设置',
  // '信息通知': '信息通知',
  // '客服咨询': '客服咨询',
  // '绑定银行卡': '绑定银行卡',
  // // '登录密码': '登录密码',
  // '支付密码': '支付密码',
  // '退出系统': '退出系统',
  // '个人信息': '个人信息',
  // '个人信息': '个人信息',
  '头像': '头像',
  "Email帐号": 'Email帐号',
  "更换Email帐号": '更换Email帐号',
  '护照号码': '护照号码',
  '姓名（名+姓）': '姓名（名+姓）',
  '出生日期': '出生日期',
  '性别': '性别',
  '男': '男',
  '女': '女',
  '移动电话': '移动电话',
  '户籍地址': '户籍地址',
  '紧急联系人': '紧急联系人',
  '关系': '关系',
  '紧急联系人电话': '紧急联系人电话',
  '请以中文或英文填写您所在国家的户籍地址（非通讯地址）': '请以中文或英文填写您所在国家的户籍地址（非通讯地址）',
  '保存': '保存',
  // 更换Email
  // 更换帐号
  // EMAIL
  // 请输入EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  '获取验证码': '获取验证码',
  '完成': '完成',
  // '租车订单': '租車訂單',
  // '租车订单': '租車訂單',
  // '全部': 'All',
  // '待付款': 'Unpaid',
  // '待取车': 'Waiting for Pick Up',
  // '已取车': 'Already Picked Up',
  '逾期': '逾期',
  // '待付款': 'Unpaid',
  // '待取车': 'Waiting for Pick Up',
  '进行中': '进行中',
  // '完成': '完成',
  '已退款': '已退款',
  '取消订单': '取消订单',
  '订单详情': '订单详情',
  // '订单详情': 'Order Details',
  '订单信息': '订单信息',
  // '车辆类型': '車輛類型',
  // '车辆品牌': '車輛品牌',
  // '车辆型号': '車輛型號',
  // '车辆年份': '車輛年份',
  '租赁方式': '租赁方式',
  '日租': '日租',
  '月租': '月租',
  '取车方式': '取车方式',
  '还车方式': '还车方式',
  '到店自提': '到店自提',
  '代驾到府': '代驾到府',
  '取车时间': '取车时间',
  '还车时间': '还车时间',
  '还车地点': '还车地点',
  '汽车保险': '汽车保险',
  '自付額2500元方案': '自付額2500元方案',
  '自付額500元方案': '自付額500元方案',
  // '新车险': '新车险',
  '是': '是',
  '否': '否',
  '租车费用': '租车费用',
  '租赁天数': '租赁天数',
  '附加费用': '附加费用',
  '取车地点': '取车地点',
  // '取车时间': '取车时间',
  // '还车时间': '还车时间',
  // '还车地点': '还车地点',
  '租车费用小计': '租车费用小计',
  '税金费用': '税金费用',
  // '税金费用': '税金费用',
  '租车总计金额费用': '租车总计金额费用',
  '押金费用': '押金费用',
  '驾照押金': '驾照押金',
  // '租车押金': 'Rental Secutiry Deposit',
  '押金总金额': '押金总金额',
  '保险费用': '保险费用',
  // '汽车保险': '汽车保险',
  '新车险': '新车险',
  '超跑险': '超跑险',
  '其他附加费用': '其他附加费用',
  '未满25岁': '未满25岁',
  '非加拿大驾照': '非加拿大驾照',
  '保险/其他费用小计': '保险/其他费用小计',
  '总计金额': '总计金额',
  '定金金额': '定金金额',
  '尾款金额': '尾款金额',
  '租车合同': '租车合同',
  '沛豪国际租赁有限公司租赁合同': 'J&A Car Rental 租赁合同',
  '预览合同': '预览合同',
  '订单状态': '订单状态',
  '订单单号': '订单单号',
  '创建订单时间': '创建订单时间',
  // '支付时间': '支付時間',
  //  '支付方式': '支付方式',
  // '取车时间': '取车时间',
  '车牌号码': '车牌号码',
  '续租': '续租',
  // '核销订单': '核銷訂單',
  // '全部': '全部',
  // '待使用': '待使用',
  // '已使用': '已使用',
  // '已过期': '已過期',
  // '订单号码': '訂單號碼',
  // '交易单号': '交易單號',
  // '支付方式': '支付方式',
  // '支付时间': '支付時間',
  // '有效期至': '有效期至',
  '核销码信息': '核销码信息',
  // '充值钱包': 'Recharge My Wallet',
  '充值总额': '充值总额',
  '纪录': '纪录',
  '充值项目': '充值项目',
  '充值2': '充值', '赠送': '赠送',
  '充值': '充值',
  "充值支付": "充值支付",
  // '支付方式': '支付方式',
  // '支付宝支付': '支付宝支付',
  // '微信支付': '微信支付',
  // '信用卡支付': '信用卡支付',
  // "充值支付": "充值支付",
  //  '支付': 'Pay',
  //  '租车押金': '租車押金',
  '押金总额': '押金总额',
  // '车辆类型': 'Body Type',
  // '车辆品牌': 'Vehicle Brand',
  // '车辆型号': 'Vehicle Model',
  // '车辆年份': 'Vehicle Year',
  // '驾照押金': '駕照押金',
  // '租车押金': 'Rental Secutiry Deposit',
  // '支付方式': '支付方式',
  // '支付时间': '支付時間',
  //'交易单号': '交易單號',
  "收款单号": '收款单号',
  // '待取车': 'Waiting for Pick Up',
  // '进行中': '進行中',
  // '完成': '完成',
  //  '逾期': '逾期',
  //'信息通知': '訊息通知',
  //'信息通知': '訊息通知',
  //'订单信息': '訂單資訊',
  '消息详情': '消息详情',
  // '客服咨询': '客服諮詢',
  // '客服咨询': '客服諮詢',
  '问题反馈': '问题反馈',
  '问题类型': '问题类型',
  '请选择问题类型': '请选择问题类型',
  '反馈内容': '反馈内容',
  '请输入您想说的话': '请输入您想说的话',
  '上传图片/影片': '上传图片/影片',
  // '完成': '完成',
  '留言板': '留言板',
  // '反馈内容': 'Feedback content',
  // '请输入您想说的话': 'Please type here',
  // '上传图片/影片': 'Upload Photo/Video',
  // '完成': '完成',
  '下单问题': '下单问题',
  '微信': '微信',
  '其他问题': '其他问题',
  // '微信': '微信',
  // 尚未開發
  // // '登录密码': 'Password',
  // // '登录密码': 'Password',
  // EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  //  '获取验证码': '獲取驗證碼',
  // '登录密码': '登錄密碼',
  // '请输入登录密码': '請輸入登錄密碼',
  // '重复密码': '重复密码',
  // '请再次输入登录密码': '请再次输入登录密码',
  // '完成': '完成',
  // '支付密码': '支付密码',
  // '支付密码': '支付密码',
  // EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  //  '获取验证码': '獲取驗證碼',
  // '登录密码': '登錄密碼',
  // '请输入登录密码': '請輸入登錄密碼',
  // '重复密码': '重复密码',
  // '请再次输入登录密码': '请再次输入登录密码',
  // '完成': '完成',








  "请输入Email帐号": '请输入Email帐号',



  '企业介绍': '企业介绍',

  '客服介绍': '客服介绍',

  '年限': '年限',
  '请选择年限': '请选择年限',


  '用户菜单': '用户菜单',

  '提示': '提示',
  '您确定要退出系统吗': '您确定要退出系统吗',










  '联名品牌': '联名品牌',






  '拖动滑块完成拼图': '拖动滑块完成拼图',
  '验证失败，请重试': '验证失败，请重试',
  '验证通过！': '验证通过！',




  '查看': '查看',




  '待支付': '待支付',

  '待付特殊费用': '待付特殊费用',
  '待验收': '待验收',
  '待付尾款': '待付尾款',


  '特殊费用支付时间': '特殊费用支付时间',
  '特殊费用支付方式': '特殊费用支付方式',
  '尾款支付时间': '尾款支付时间',
  '尾款支付方式': '尾款支付方式',



  'suffix': '_zh',















  '暂无数据': '暂无数据',





  '待审核': '待审核',
  '已通过': '已通过',
  '未通过': '未通过',










  '公里': '公里',




  '假支付': '假支付',







  '设置支付密码': '设置支付密码',


  '暂未开放': '暂未开放',

  '不核销公里数': '不核销公里数',

  '我的': '我的',

  '账号': '账号',
  '更换账号': '更换账号',








  '如果您在租赁过程中遇到什么困难,请查看如何租赁或者联系客服进行咨询': '如果您在租赁过程中遇到什么困难,请查看如何租赁或者联系客服进行咨询',

  '请填写您要租赁的信息': '请填写您要租赁的信息',

  '请选择租赁方式': '请选择租赁方式',

  '请选择取车方式': '请选择取车方式',

  '请选择时间': '请选择时间',
  '送车地点': '送车地点',
  '请输入送车地址': '请输入送车地址',

  '请选择还车方式': '请选择还车方式',

  '请先选择租赁方式': '请先选择租赁方式',
  '请先选择取车时间': '请先选择取车时间',

  '请输入还车地址': '请输入还车地址',

  '请选择汽车保险': '请选择汽车保险',

  '选配购买': '选配购买',
  '超跑保险': '超跑保险',
  '计算规则为7天一周期，不满7则按7天计算': '计算规则为7天一周期，不满7则按7天计算',
  '提交': '提交',
  '我要租赁': '我要租赁',


  'locale': 'zh',
  '查看续租': '查看续租',



  '消费': '消费',
  '交易日期': '交易日期',
  '金额': '金额',
  '充值方式': '充值方式',
  '到账日期': '到账日期',
  '操作': '操作',

  '使用中': '使用中',

  '余额': '余额',















  '请再次输入支付密码': '请再次输入支付密码',

  '请输入银行卡账号': '请输入银行卡账号',
  '请输入手机号': '请输入手机号',
  '银行卡账号': '银行卡账号',
  '手机号': '手机号',

  '已退还': '已退还',
  '已还车': '已还车',

  '已取消': '已取消',
  '请选择汽车驾照类型': '请选择汽车驾照类型',
  '请上传驾驶证': '请上传驾驶证',
  '上传驾驶证主页': '上传驾驶证主页',
  '上传驾驶证副页': '上传驾驶证副页',
  '上传驾驶证附件': '上传驾驶证附件',


  '租赁明细': '租赁明细',






  '您确定要退出系统吗？': '您确定要退出系统吗？',
  '取消': '取消',
  '确定': '确定',
  "图片大小不能超过2M": "图片大小不能超过2M",
  "图片大小不能超过10M": "图片大小不能超过10M",
  "正在上传中...": "正在上传中...",
  "请先阅读并同意用户协议与隐私政策": "请先阅读并同意用户协议与隐私政策",
  '您确定要删除该订单吗？': '您确定要删除该订单吗？',

  '车牌号码': '车牌号码',
  '续租': '续租',
  '请选择': '请选择',
  '请下滑到底部查看租车合同': '请下滑到底部查看租车合同',

  '签订合同': '签订合同',
  '生成订单': '生成订单',
  '电子签名': '电子签名',
  '确认签署': '确认签署',
  '签订合同（已签字）': '签订合同（已签字）',
  '租赁支付': '租赁支付',

  '您确定要取消该订单吗？': '您确定要取消该订单吗？',
  '支付订单': '支付订单',
  '取消时间': '取消时间',
  '请填写您要续租的信息': '请填写您要续租的信息',





  '个人注册信息': '个人注册信息',
  '请输入护照号码': '请输入护照号码',
  '请输入姓名': '请输入姓名',
  '用户登录': '用户登录',
  '请输入户移动电话': '请输入户移动电话',
  '请输入户籍地址': '请输入户籍地址',
  '请输入紧急联系人': '请输入紧急联系人',


  '先生': '先生',
  '女士': '女士',
  '忘记密码': '忘记密码',

  '新密码': '新密码',

  '请输入关系': '请输入关系',
  '请再次输入紧急联系人电话': '请再次输入紧急联系人电话',
  '请以中文或英文填写您所在国家的戶籍地址（非通讯地址）': '请以中文或英文填写您所在国家的戶籍地址（非通讯地址）',

  '特殊费用': '特殊费用',


  '特殊费用支付': '特殊费用支付',



  "取车日期": "取车日期",
  "请选择取车日期": "请选择取车日期",
  "还车日期": "还车日期",
  "请先选择取车日期": "请先选择取车日期",
  "请选择还车日期": "请选择还车日期",
  "请选择取车时间": "请选择取车时间",
  "请选择还车时间": "请选择还车时间",




  "点击支付后将跳转到第三方服务平台进行支付": "点击支付后将跳转到第三方服务平台进行支付",
  "银联国际": "银联国际",


  "新车险计算规则为租车天数*7天周期计算，不满足7天以7天进行计算": "新车险计算规则为租车天数*7天周期计算，不满足7天以7天进行计算",

  "联邦政府商品及服务税": "联邦政府商品及服务税 5%",
  "省销售税": "省销售税 7%",

  "汽车保险500元方案": "汽车保险500元方案",
  "汽车保险2500元方案": "汽车保险2500元方案",
  '付款方式':'付款方式',
  '在线支付':'在线支付',
  '线下支付':'线下支付',
  '电子合约':'电子合约',
  '确认':'确认'
}