<template>
  <div class="Menu" :notes="$t('用户菜单')">
    <div class="nav_title">{{$t('订单中心')}}</div>
    <ul>
      <li @click="menu_go('order_list')" :class="{active:name=='order_list'||name=='order'}"><a>{{$t('租车订单')}}</a></li>
      <li @click="menu_go('order_write_off')" :class="name=='order_write_off'?'active':''"><a>{{$t('核销订单')}}</a></li>
    </ul>
    <div class="nav_title">{{$t('我的钱包')}}</div>
    <ul>
      <li @click="menu_go('wallet')" :class="{active:name=='wallet' || name=='wallet_record' }"><a>{{$t('充值钱包')}}</a></li>
      <li @click="menu_go('deposit')" :class="{active:name=='deposit'|| name=='deposit_record'}"><a>{{$t('租车押金')}}</a></li>
    </ul>
    <div class="nav_title">{{$t('系统设置')}}</div>
    <ul>
      <li @click="menu_go('message')" :class="{active:name=='message'}"><a>{{$t('信息通知')}}</a></li>
      <li @click="menu_go('help')" :class="{active:name=='help'}"><a>{{$t('客服咨询')}}</a></li>

      <li @click="menu_go('bank')" :class="{active:name=='bank'}"><a>{{$t('绑定银行卡')}}</a></li>

      <!-- <li @click="menu_go('set_email')" :class="{active:name=='set_email'}"><a>登录账号</a></li> -->

      <li @click="menu_go('set_password')" :class="{active:name=='set_password'}"><a>{{$t('登录密码')}}</a></li>

      <li @click="menu_go('set_payword')" :class="{active:name=='set_payword'}"><a>{{$t('支付密码')}}</a></li>
      <li @click="exit_toast()"><a>{{$t('退出系统')}}</a></li>
    </ul>

  </div>
</template>
<script>
import toastExit from "@/components/toast-ui/exit.vue"
export default {
  name: "Menu",
  props: {
    name: {
      type: String,
      default: "",
    },
    br: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  methods: {
    menu_go(name) {
      if (name != this.name) {
        this.go(name)
      }
    },
    exit_toast() {
      this.$toast(
        {
          component: toastExit,
          props: {
            the_title: this.$t("您确定要退出系统吗？"),
            the_left: this.$t("取消"),
            the_right: this.$t("确定"),
          },
        },
        {
          timeout: false,
          position: "top-center",
          closeOnClick: false, // 单击即关闭
          pauseOnFocusLoss: false,
          pauseOnHover: false, // 鼠标移入暂停
          draggable: false, // 允许通过拖动和滑动事件关闭
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false, // 显示/隐藏进度条
          closeButton: false,
          icon: false,
          rtl: false,
        }
      )
    },
  },
}
</script>
<style lang='scss' scoped>
/*===左侧菜单===*/
.Menu {
  position: relative;
  background: #ffffff;
  width: 100%;
  overflow: hidden;
  padding: 1.25rem 0.75rem;
  border-radius: 0 0 0.5rem 0.5rem;

  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.16);
  .nav_title {
    padding: 1rem 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    color: #444444;
    white-space: pre-wrap; //自动换行
    word-break: normal;
  }

  ul {
    padding: 0;
    margin: 0;

    li {
      a {
        white-space: pre-wrap; //自动换行
        word-break: normal;
        padding: 1rem 0.75rem;
        color: #666666;
        border-radius: 0.4rem;
        background: #ffffff;
        font-size: 13px;
        display: inline-block;
        width: 100%;
        font-weight: 500;
        position: relative;
        transition: all ease 0.4s;

        &:hover,
        &:focus {
          color: #fff;
          background: #1d2124;
          cursor: pointer; //鼠标变小手
        }
      }

      &.active a {
        color: #fff;
        background: #1d2124;
        padding: 1rem 0.75rem;
        border-radius: 0.4rem;
      }
    }
  }
}
</style>
