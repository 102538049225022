<template>
  <div class="div-col" :class="{ center, br, fill }">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "div-col",
  props: {
    center: {
      type: Boolean,
      default: false,
    },
    br: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang='less' scoped>
</style>
