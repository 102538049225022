import Vue from 'vue'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

// import { Locale } from 'vant'

// import enUS from 'vant/lib/locale/lang/en-US';
// import zhCN from 'vant/lib/locale/lang/zh-CN';
// import zhTW from 'vant/lib/locale/lang/zh-TW';

import enLocale from './en'
import cnLocale from './cn'
import twLocale from './tw'

const messages = {
  en: {
    // ...enUS,
    ...enLocale,
  },
  cn: {
    // ...zhCN,
    ...cnLocale
  },
  tw: {
    // ...zhTW,
    ...twLocale
  }
}
const i18n = new VueI18n({
  locale: localStorage.getItem('zuche_lang') || 'en', // 设置地区
  messages: messages // 设置资源文件对象
})

// 更新vant组件库本身的语言变化，支持国际化
// function vantLocales(lang) {
//   if (lang === 'en') {
//     Locale.use(lang, enUS)
//   } else if (lang === 'zh') {
//     Locale.use(lang, zhTW)
//   }
// }

export { i18n }