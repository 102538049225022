<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒 左侧菜单 -->
            <Menu name="message"></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🍞面包屑 -->
            <section>
              <div-padding padding="20px 0">
                <b-row no-gutters>
                  <b-col cols="auto" class="mr-auto">
                    <div-left center>
                      <div-text color="#1D2124" size="20px" bold>{{$t('信息通知')}}</div-text>
                    </div-left>
                  </b-col>
                  <b-col cols="auto">
                    <b-breadcrumb>
                      <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                      <b-breadcrumb-item @click="go('mine')">{{$t('我的')}}</b-breadcrumb-item>
                      <b-breadcrumb-item active>{{$t('信息通知')}}</b-breadcrumb-item>
                    </b-breadcrumb>
                  </b-col>
                </b-row>
              </div-padding>
            </section>

            <div-zebra></div-zebra>

            <!-- <div class="zebra_box shadow "> -->
            <div-card radius="0" bg="#fff" shadow="0px 3px 6px 1px rgba(0, 0, 0, 0.16)">
              <div-padding padding="20px">
                <b-card-group>
                  <b-card no-body header-tag="header" footer-tag="footer">
                    <template v-slot:header>
                      <div-both center>
                        <b-button class="btn" variant="none">
                          <div-text color="#1D2124" size="13px" bold>{{$t('订单信息')}}</div-text>
                        </b-button>
                      </div-both>
                    </template>

                    <b-card-body>
                      <div class="item" @click="go('message_item',{id:item.id,item_id:item.item_id})" v-for="(item,index) in list" :key="index">
                        <div-padding padding="15px">
                          <div-both center>
                            <div-left center>
                              <div-img width="12px" height="12px">
                                <img :src="item.read == 1? require('@/assets/img/xiaoxi.png'):require('@/assets/img/xiaoxin1.png')" alt="">
                              </div-img>
                              <div-width size="15px"></div-width>
                              <div-text size="14px">{{item['push_title'+$t('suffix')]}}</div-text>
                            </div-left>
                            <div-text size="14px">{{item.create_time}}</div-text>

                          </div-both>
                        </div-padding>
                        <div-height v-if="index<list.length-1" height="1px" bg="rgba(34, 34, 34, 0.125)" />
                      </div>

                      <el-empty v-if="list&&list.length==0" :description="$t('暂无数据')"></el-empty>
                    </b-card-body>

                    <template v-slot:footer>
                      <!-- 🔋底部分页 -->
                      <div-center>
                        <b-pagination-nav :first-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :last-number="true" :number-of-pages="pages" use-router>
                          <template v-slot:prev-text>
                            <b-icon icon="chevron-left"></b-icon>
                          </template>
                          <template v-slot:next-text>
                            <b-icon icon="chevron-right"></b-icon>
                          </template>
                        </b-pagination-nav>
                      </div-center>

                    </template>

                  </b-card>
                </b-card-group>
              </div-padding>
            </div-card>
            <!-- </div> -->

          </b-col>

        </b-row>
      </section>

      <div-height height="30px" />
    </b-container>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      is_edit: false,
      list: null,
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    linkGen(pageNum) {
      // console.log("页码参数设置", pageNum)
      return pageNum === 1 ? `/message?page=1` : `/message?page=${pageNum}`
    },

    get_list(page) {
      this.currentPage = page
      this.get_info()
    },
    get_info() {
      this.$get({
        url: "/index/Pushnotisitems/idx",
        data: {
          page: this.currentPage, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          //列表
          this.list = res.data.list.data
          this.pages = res.data.list.last_page || 1
          this.currentPage = res.data.list.current_page
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
//🐤列表样式修改
.card-body {
  padding: 0;
}
.item {
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
}
</style>