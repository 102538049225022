<template>
  <div>
    <b-navbar toggleable="lg" type="dark" variant="primary" fixed="top">

      <b-container>

        <b-navbar-brand to="/">
          <div class="logo">
            <b-img :src="require('@/assets/img/logo.png')" fluid alt="Responsive image"></b-img>
          </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <b-navbar-nav class="ml-auto" right>

            <b-navbar-nav fill class="my_nav">
              <b-nav-item to="/" :class="{active:name=='index'}">
                <div class="nav_text">{{$t('首页')}}</div>
              </b-nav-item>

              <b-nav-item to="/rent" :class="{active:name=='rent'}">
                <div class="nav_text">{{$t('汽车租赁')}}</div>
              </b-nav-item>
              <b-nav-item to="/new?page=1" :class="{active:name=='new'}">
                <div class="nav_text">{{$t('新车到店')}}</div>

              </b-nav-item>
              <b-nav-item to="/shop" :class="{active:name=='shop'}">
                <div class="nav_text">{{$t('商城中心')}}</div>
              </b-nav-item>

              <b-nav-item to="/about" :class="{active:name=='about'}">
                <div class="nav_text">{{$t('关于我们')}}</div>
              </b-nav-item>

              <div class="language ">
                <div-padding padding="0 15px">
                  <b-nav-item-dropdown :text="lang_now" ref="dropdown">
                    <b-dropdown-item>{{lang_now}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="Language('en')">English</b-dropdown-item>
                    <b-dropdown-item @click="Language('cn')">简体中文</b-dropdown-item>
                    <b-dropdown-item @click="Language('tw')">繁體中文</b-dropdown-item>
                  </b-nav-item-dropdown>
                </div-padding>
              </div>

              <b-nav-item to="/mine" v-if="login">
                <div-padding padding="0 15px">
                  <div class="user_box">
                    <div class="user_img">
                      <b-img :src="avatar" rounded="circle" alt="Circle image"></b-img>
                    </div>
                    <div class="user_name">
                      <div class="user_name_text">{{ user_name }}</div>
                    </div>
                  </div>
                </div-padding>
              </b-nav-item>

              <b-navbar-nav v-else>
                <b-button to="/login" pill :variant="name=='login'?'light':'outline-light'">
                  <div class="the_btn">{{$t('登录')}}</div>
                </b-button>
                <div-padding padding="5px"></div-padding>
                <b-button to="/register" pill :variant="name=='register'?'light':'outline-light'">
                  <div class=" the_btn">{{$t('注册')}}</div>
                </b-button>
              </b-navbar-nav>

            </b-navbar-nav>

          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
    <b-navbar toggleable="lg" type="dark" variant="primary">

      <b-container>

        <b-navbar-brand to="/">
          <div class="logo">
            <b-img :src="require('@/assets/img/logo.png')" fluid alt="Responsive image"></b-img>
          </div>
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>

          <b-navbar-nav class="ml-auto" right>

            <b-navbar-nav fill class="my_nav">
              <b-nav-item to="/" :class="{active:name=='index'}">
                <div class="nav_text">{{$t('首页')}}</div>
              </b-nav-item>

              <b-nav-item to="/rent" :class="{active:name=='rent'}">
                <div class="nav_text">{{$t('汽车租赁')}}</div>
              </b-nav-item>
              <b-nav-item to="/new?page=1" :class="{active:name=='new'}">
                <div class="nav_text">{{$t('新车到店')}}</div>

              </b-nav-item>
              <b-nav-item to="/shop" :class="{active:name=='shop'}">
                <div class="nav_text">{{$t('商城中心')}}</div>
              </b-nav-item>

              <b-nav-item to="/about" :class="{active:name=='about'}">
                <div class="nav_text">{{$t('关于我们')}}</div>
              </b-nav-item>

              <div class="language ">
                <div-padding padding="0 15px">
                  <b-nav-item-dropdown :text="lang_now" ref="dropdown">
                    <b-dropdown-item>{{lang_now}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="Language('en')">English</b-dropdown-item>
                    <b-dropdown-item @click="Language('cn')">简体中文</b-dropdown-item>
                    <b-dropdown-item @click="Language('tw')">繁體中文</b-dropdown-item>
                  </b-nav-item-dropdown>
                </div-padding>
              </div>

              <b-nav-item to="/mine" v-if="login">
                <div-padding padding="0 15px">
                  <div class="user_box">
                    <div class="user_img">
                      <b-img :src="avatar" rounded="circle" alt="Circle image"></b-img>
                    </div>
                    <div class="user_name">
                      <div class="user_name_text">{{ user_name }}</div>
                    </div>
                  </div>
                </div-padding>
              </b-nav-item>

              <b-navbar-nav v-else>
                <b-button to="/login" pill :variant="name=='login'?'light':'outline-light'">
                  <div class="the_btn">{{$t('登录')}}</div>
                </b-button>
                <div-padding padding="5px"></div-padding>
                <b-button to="/register" pill :variant="name=='register'?'light':'outline-light'">
                  <div class=" the_btn">{{$t('注册')}}</div>
                </b-button>
              </b-navbar-nav>

            </b-navbar-nav>

          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
const Broadcast = new BroadcastChannel('firebasejs')
export default {
  name: 'Nav',
  props: {
    name: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      lang_arr: {
        en: 'English',
        cn: '简体中文',
        tw: '繁體中文',
      },
      lang_now: '繁體中文',
      mobileToggle: false,
      login: false,
      user_name: '尊敬的用户',
      avatar: '',
    }
  },
  created() {
    this.lang_now = this.lang_arr[this.$i18n.locale]
    Broadcast.postMessage({ lang: this.$i18n.locale })
    if (localStorage.getItem('zuche_token')) {
      this.login = true
      let obj = localStorage.getItem('zuche_user')
      if (obj) {
        obj = JSON.parse(obj)
        this.user_name = obj.name
        this.avatar = obj.avatar
      }
    }
  },
  methods: {
    // 导航栏点击事件
    // handleNavbarClick(item) {
    //   this.lang_now = item
    // },
    Language(item) {
      this.$i18n.locale = item
      localStorage.setItem('zuche_lang', item)
      this.lang_now = this.lang_arr[item]
      Broadcast.postMessage({ lang: item })
    },
  },
}
</script>
<style scoped lang="scss">
.navbar-brand {
  padding: 0;
}
//logo图片
.logo {
  transition: all 0.4s;
  max-width: 121px;
  height: 80px;

  img {
    // width: 100%;
    height: 100%;
  }
}
//切换语言
.language {
  /deep/.dropdown-item.active {
    background-color: #333;
    color: #fff;
  }
  /deep/.dropdown-item:active {
    background-color: #333;
    color: #fff;
  }
  /deep/.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.dropdown-menu {
    padding: 0;
    font-size: 14px;
  }
  /deep/.dropdown-divider {
    margin: 0;
  }
  /deep/.dropdown-item {
    padding: 10px 20px;
  }
}
//登录注册按钮
.the_btn {
  transition: all 0.4s;
  padding: 0 20px;
}
//登录后的用户信息
.user_box {
  display: flex;
  align-items: center;
  justify-content: center;

  .user_img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .user_name {
    display: inline-block;
    .user_name_text {
      font-size: 14px;
      color: #fff;
    }
  }
}

//导航栏下拉菜单
/deep/.nav-link {
  // display: flex;
  // align-items: center;
  // padding: 30px 10px;
}

//全局菜单文字宽度和字体大小
.my_nav {
  font-size: 14px;
  .nav_text {
    padding: 0 15px;
  }
}

//媒体查询
@media #{$xl} {
  .logo {
    height: 80px;
  }
}

@media #{$lg} {
  .logo {
    height: 80px;
  }
  .the_btn {
    padding: 0 10px;
  }
}

@media #{$md} {
  .logo {
    height: 48px;
  }
  .the_btn {
    padding: 0 10px;
  }
}

@media #{$sm} {
  .logo {
    height: 48px;
  }
}

@media #{$xs} {
  .logo {
    height: 48px;
  }
  .the_btn {
    padding: 0 10px;
  }
}
</style>