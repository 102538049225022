<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="new" />
    <!-- 🥒轮播图 -->
    <Swiper :banners="banners" />

    <div-height height="80px" />
    <!-- 🥒中级标题 -->
    <div class="title_sm">
      <h1 class="wow fadeInUp">{{$t('新车到店')}}</h1>
      <h2 class="wow fadeInUp" style="color:transparent">New car To the store</h2>
      <h3 class="wow fadeInUp"></h3>
      <h4 class="wow fadeInUp"></h4>
      <h5 class="wow fadeInUp">{{$t('豪华自有其道，全新车款推荐')}}</h5>
    </div>
    <div-height height="40px" />
    <!-- 🍞面包屑 -->
    <!-- <section>
      <b-container>
        <div-padding padding="10px 0">
          <b-row>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">首页</b-breadcrumb-item>
                <b-breadcrumb-item active>新车到店</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section> -->

    <!-- 🍍商品列表 -->
    <section class="good_list">
      <b-container>
        <b-row>
          <b-col sm="12" md="12" v-for="(item,index) in list" :key="index" class="wow fadeInUp " :data-wow-delay="(index * 0.1) + 's'">
            <div @click="go('new_card',item.id)" class="item">
              <div class="img">
                <img :src="item.cover" class="img_z">
              </div>
              <div class="zhezhao"></div>
              <div class="texts">
                <div-text class="one fsz" size="46px" color="#fff" right>{{item['title'+$t('suffix')]}}</div-text>
                <div-height height="20px"></div-height>
                <div-text class="two fsz_time" size="36px" color="#fff" right>{{item.create_time}}</div-text>
              </div>
              <div class="text">
                <div-text size="14px" color="#fff" left>{{item['title'+$t('suffix')]}}</div-text>
                <div-text size="14px" color="#fff" left>{{item.create_time}}</div-text>
                <!-- <div-text size="14px" color="#fff" left two>近日，教授从相关渠道获得了一组关于宝马XM量产版车型的专利图，该整体轮廓都与此前曝光的谍照图基本相同。此外，根据相关信息获悉，宝马XM量产版本将于2022年第四季度正式发布，有望于明年引入国内销售。</div-text> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <div-height height="20px" />
    <!-- 🔋底部分页 -->
    <div-center>
      <!-- number-of-pages 为总页码数量 -->
      <b-pagination-nav :first-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :last-number="true" :number-of-pages="pages" use-router>
        <template v-slot:prev-text>
          <b-icon icon="chevron-left"></b-icon>
        </template>
        <template v-slot:next-text>
          <b-icon icon="chevron-right"></b-icon>
        </template>
      </b-pagination-nav>
    </div-center>

    <div-height height="30px" />

  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      uname: "",
      text: "",
      list: [],
      banners: [],
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
    }
  },
  created() {
    this.get_info()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {},
      }).init()
    })
  },
  methods: {
    input() {},
    linkGen(pageNum) {
      // console.log("页码参数设置", pageNum)
      return pageNum === 1 ? `/new?page=1` : `/new?page=${pageNum}`
    },
    get_list(page) {
      this.currentPage = page
      // console.log("------", page)
      this.get_info()
    },
    get_info() {
      this.$get({
        url: "/index/newcarsarrive/idx",
        data: {
          page: this.currentPage, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          //轮播图
          this.banners = res.data.addition.banners
          //列表
          this.list = res.data.list.data
          this.pages = res.data.list.last_page || 1
          this.currentPage = res.data.list.current_page

          // console.log(res.data.list.current_page, "kkk")
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
//🥒标题
.title_sm {
  position: relative;
  h1 {
    width: 100%;
    position: absolute;
    font-size: 22px;
    color: #1d2124;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 2;
    top: 10px;
    font-weight: bold;
  }
  h2 {
    padding-top: 5px;
    width: 100%;
    font-size: 28px;
    color: #e6e6e6;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
  }
  h3 {
    position: absolute;
    width: 17px;
    height: 3px;
    background: #333333;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 40px;
    z-index: 2;
  }
  h4 {
    height: 50px;
  }
  h5 {
    width: 100%;
    font-size: 16px;
    color: #b7b7b7;
    text-align: center;
  }
}

// 🍉商品列表
.good_list {
  .item {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.4s;
    overflow: hidden;
    cursor: pointer;
    height: 580px;

    .img {
      transition: all 0.4s;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .zhezhao {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 100%;
      transform: scale(0);
      height: 100%;
      background: rgba(0, 0, 0, 0.5);

      transition: all 0.4s;
      transition-delay: 0.3s;
    }
    .text {
      transition: all 0.4s;

      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px 30px;

      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(10px);
      text-align: center;
      color: #fff;
    }
    .one {
      transition: all 0.4s;
      transition-delay: 0.6s;
      position: absolute;
      bottom: 0;

      width: 100%;
      transform: translateX(100%) skewX(-30deg);
      text-align: right;
      padding: 10px 30px;
      height: 50%;
      color: #fff;
    }
    .two {
      transition: all 0.4s;
      transition-delay: 0.8s;
      transform: translateX(100%) skewX(-30deg);
      position: absolute;
      bottom: -80px;

      width: 100%;
      text-align: right;
      padding: 10px 30px;
      height: 50%;
      color: #fff;
    }
    &:hover {
      .zhezhao {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        transform: scale(1.1);
      }
      .img {
        transform: scale(1.1);
      }
      .text {
        height: 0;
        padding: 0;
      }
      .one {
        animation: move 1.5s forwards ease;
      }
      .two {
        animation: move 1.5s 0.5s forwards ease;
      }
    }
    @keyframes move {
      0% {
        //左倾斜
        font-style: oblique;
        transform: translateX(100%) skewX(-30deg);
      }

      40% {
        //右倾斜
        transform: translateX(0) skewX(20deg);
      }
      50% {
        //右倾斜
        transform: translateX(0) skewX(-10deg);
      }

      60% {
        //右倾斜
        transform: translateX(0) skewX(5deg);
      }
      70% {
        //右倾斜
        transform: translateX(0) skewX(0deg);
      }

      100% {
        transform: translateX(0) skewX(0);
      }
    }
  }
}

// 🍓媒体查询
@media #{$xl} {
  .good_list {
    .item {
      height: 580px;
    }
  }
}

@media #{$lg} {
  .good_list {
    .item {
      height: 580px;
    }
  }
}

@media #{$md} {
  .good_list {
    .item {
      height: 450px;
    }
  }
}

@media #{$sm} {
  .good_list {
    .item {
      height: 350px;
    }
  }
}

@media #{$xs} {
  .good_list {
    .item {
      height: 350px;
      .fsz {
        font-size: 27px !important;
      }
      .fsz_time {
        font-size: 20px !important;
      }
    }
  }
}
</style>