<template class="div-updata">
  <label class="updata_label" @change="updata($event)">
    <input type="file" style="display:none" ref="inputer" accept="image/*, video/*" />
    <slot></slot>
  </label>
</template>
<script>
import { Loading } from "element-ui"
export default {
  name: "div-updata",
  props: ["url", "data", "res", "name"],
  methods: {
    updata() {
      let DOM = this.$refs.inputer
      let formData = new FormData()
      formData.append(this.name || "file", DOM.files[0]) //获取文件详细信息

      console.log(DOM.files[0].type)
      if (DOM.files[0].type.indexOf("image") != -1) {
        formData.append("env", 1)

        //图片大小限制
        if (DOM.files[0].size > 1024 * 1024 * 2) {
          this.$toast(this.$t("图片大小不能超过2M"), {
            timeout: 3000,
            position: "top-center",
          })
          return
        }
      } else if (DOM.files[0].type.indexOf("video") != -1) {
        formData.append("env", 2)
        //视频大小限制
        if (DOM.files[0].size > 1024 * 1024 * 10) {
          this.$toast(this.$t("图片大小不能超过10M"), {
            timeout: 3000,
            position: "top-center",
          })
          return
        }
      }

      let loadingInstance = Loading.service({
        lock: true,
        text: this.$t("正在上传中..."),
        spinner: "el-icon-loading",
        background: "rgba(255, 255, 255, 0.7)",
      })

      this.$post({
        url: "/index/upload/uploadImgs",
        data: formData,
        upload: true,
        success: (res) => {
          let list = res.data
          this.$nextTick(() => {
            // 以服务的方式调用的 Loading 需要异步关闭
            loadingInstance.close()
          })
          this.$emit("list", list)
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang='scss' scoped>
.div-updata {
  margin-bottom: 0;
  border: 0;
}
</style>
