<template>
  <div class="div-input" @tap.stop="inputClick">
    <div class="div-inputcontent">

      <input class="u-input__input" :type="type == 'password' ? 'text' : type" :style="[getStyle]" :value="defaultValue" :password="type == 'password' && !showPassword" :placeholder="placeholder" :placeholderStyle="placeholderStyle" :disabled="disabled || type === 'select'" :maxlength="inputMaxlength" :focus="focus" :confirmType="confirmType" :cursor-spacing="getCursorSpacing" :selection-end="uSelectionEnd" :selection-start="uSelectionStart" :show-confirm-bar="showConfirmbar" :adjust-position="adjustPosition" @focus="onFocus" @blur="handleBlur" @input="handleInput" @confirm="onConfirm" autocomplete="new-password" />
      <div style="width:2.75rem; height:2.75rem">
        <div class="right">
          <div class="icon" @click.stop.prevent="opt_pass" v-if="type == 'password'" :class="!show_clear?'icon_active':''">
            <!-- <u-icon name="eye-fill" color="#78B238" size="28" v-if="showPassword"></u-icon>
            <u-icon name="eye-off" color="#78B238" size="28" v-if="!showPassword"></u-icon> -->
            <b-icon-x-circle-fill v-if="showPassword"></b-icon-x-circle-fill>
            <b-icon-x-circle-fill v-if="!showPassword"></b-icon-x-circle-fill>
          </div>
          <div class="icon" @click.stop.prevent="onClear" :class="defaultValue&&show_clear?'icon_active':''">
            <!-- <div-img width='28rem' height="28rem" >
							<image src="~@/static/img/close_icon.png"></image>
						</div-img> -->
            <!-- <u-icon name="close" color="#78B238" size="28"></u-icon> -->
            <b-icon-x-circle-fill></b-icon-x-circle-fill>

          </div>
        </div>
      </div>

      <slot></slot>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'div-input',
    props: {
      value: {
        type: [String, Number],
        default: ''
      },
      // 输入框的类型，textarea，text，number
      type: {
        type: String,
        default: 'text'
      },
      inputAlign: {
        type: String,
        default: 'left'
      },
      placeholder: {
        type: String,
        default: '请输入内容'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      maxlength: {
        type: [Number, String],
        default: 140
      },
      placeholderStyle: {
        type: String,
        default: 'color: #c0c4cc;'
      },
      confirmType: {
        type: String,
        default: 'done'
      },
      // 输入框的自定义样式
      customStyle: {
        type: Object,
        default() {
          return {};
        }
      },
      // 如果 textarea 是在一个 position:fixed 的区域，需要显示指定属性 fixed 为 true
      fixed: {
        type: Boolean,
        default: false
      },
      // 是否自动获得焦点
      focus: {
        type: Boolean,
        default: false
      },
      // 密码类型时，是否显示右侧的密码图标
      passwordIcon: {
        type: Boolean,
        default: true
      },
      // input|textarea是否显示边框
      border: {
        type: Boolean,
        default: false
      },
      // 输入框的边框颜色
      borderColor: {
        type: String,
        default: '#dcdfe6'
      },
      autoHeight: {
        type: Boolean,
        default: true
      },
      // type=select时，旋转右侧的图标，标识当前处于打开还是关闭select的状态
      // open-打开，close-关闭
      selectOpen: {
        type: Boolean,
        default: false
      },
      // 高度，单位rpx
      height: {
        type: [Number, String],
        default: ''
      },
      // 是否可清空
      clearable: {
        type: Boolean,
        default: true
      },
      // 指定光标与键盘的距离，单位 px
      cursorSpacing: {
        type: [Number, String],
        default: 0
      },
      // 光标起始位置，自动聚焦时有效，需与selection-end搭配使用
      selectionStart: {
        type: [Number, String],
        default: -1
      },
      // 光标结束位置，自动聚焦时有效，需与selection-start搭配使用
      selectionEnd: {
        type: [Number, String],
        default: -1
      },
      // 是否自动去除两端的空格
      trim: {
        type: Boolean,
        default: true
      },
      // 是否显示键盘上方带有”完成“按钮那一栏
      showConfirmbar: {
        type: Boolean,
        default: true
      },
      // 弹出键盘时是否自动调节高度，uni-app默认值是true
      adjustPosition: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        defaultValue: this.value,
        inputHeight: 2.75, // input的高度
        textareaHeight: 100, // textarea的高度
        validateState: false, // 当前input的验证状态，用于错误时，边框是否改为红色
        focused: false, // 当前是否处于获得焦点的状态
        showPassword: false, // 是否预览密码
        lastValue: '', // 用于头条小程序，判断@input中，前后的值是否发生了变化，因为头条中文下，按下键没有输入内容，也会触发@input时间
        show_clear: false,
      };
    },
    watch: {
      value(nVal, oVal) {
        this.defaultValue = nVal;
        // 当值发生变化，且为select类型时(此时input被设置为disabled，不会触发@input事件)，模拟触发@input事件
        if (nVal != oVal && this.type == 'select') this.handleInput({
          detail: {
            value: nVal
          }
        })
      },
    },
    computed: {
      // 因为uniapp的input组件的maxlength组件必须要数值，这里转为数值，给用户可以传入字符串数值
      inputMaxlength() {
        return Number(this.maxlength);
      },
      getStyle() {
        let style = {};
        // 如果没有自定义高度，就根据type为input还是textare来分配一个默认的高度
        style.minHeight = this.height ? this.height + 'rem' : this.type == 'textarea' ?
          this.textareaHeight + 'rem' : this.inputHeight + 'rem';
        style = Object.assign(style, this.customStyle);
        return style;
      },
      //
      getCursorSpacing() {
        return Number(this.cursorSpacing);
      },
      // 光标起始位置
      uSelectionStart() {
        return String(this.selectionStart);
      },
      // 光标结束位置
      uSelectionEnd() {
        return String(this.selectionEnd);
      }
    },
    created() {
      // 监听u-form-item发出的错误事件，将输入框边框变红色
      // this.$on('on-form-item-error', this.onFormItemError);
    },
    methods: {
      opt_pass() {
        console.log('切换密码铭文')
        this.showPassword = !this.showPassword;
        console.log('铭文为', this.showPassword)
      },
      /**
       * change 事件
       * @param event
       */
      handleInput(event) {
        if (event) {
          this.show_clear = true;
        }

        let value = event.detail.value;
        // 判断是否去除空格
        if (this.trim) {
          console.log(value, '去除空格')
          value = value.trim();
        }
        // vue 原生的方法 return 出去
        this.$emit('input', value);
        // 当前model 赋值
        this.defaultValue = value;

      },
      /**
       * blur 事件
       * @param event
       */
      handleBlur(event) {

        // 最开始使用的是监听图标@touchstart事件，自从hx2.8.4后，此方法在微信小程序出错
        // 这里改为监听点击事件，手点击清除图标时，同时也发生了@blur事件，导致图标消失而无法点击，这里做一个延时
        let value = event.detail.value;
        setTimeout(() => {
          this.focused = false;
        }, 100)
        setTimeout(() => {
          this.show_clear = false;
        }, 500)
        // vue 原生的方法 return 出去
        this.$emit('blur', value);

      },
      onFormItemError(status) {
        this.validateState = status;
      },
      onFocus(event) {
        this.show_clear = true;
        this.focused = true;
        this.$emit('focus');
      },
      onConfirm(e) {
        this.$emit('confirm', e.detail.value);
      },
      onClear(event) {
        this.$emit('input', '');
      },
      inputClick() {
        this.$emit('click');
      }
    }
  };
</script>

<style lang="scss" scoped>
  .div-input {
    position: relative;
    flex: 1;
    height: 100%;
    width: 100%;
    text-align: left;
    color: #333;

    .div-inputcontent {
      flex: 1;
      /* #ifndef APP-NVUE */
      width: 100%;
      display: flex;
      box-sizing: border-box;
      height: 100%;
      /* #endif */
      flex-direction: row;
      align-items: center;
    }

    input {
      width: 100%;
      height: 100%;
      display: block;
      font-weight: 800;
      flex: 1;
    }

    .u-input__input {
      width: 100%;
    }

    .right {
      overflow: hidden;
      width: 2.75rem;
      height: 2.75rem;
      display: flex;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.75rem;
        height: 2.75rem;
        transition: all 0.5s;
        transform: translateX(2.75rem) rotate(360deg);
      }

      .icon_active {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.75rem;
        height: 2.75rem;
        transform: translateX(0) rotate(180deg);
        position: absolute;
      }
    }

    .left {
      width: 2.75rem;
      height: 2.75rem;
      margin-right: 2.75rem;
    }
  }
</style>
