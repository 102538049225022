<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav />
    <!--🥒表单 -->
    <b-container>
      <div class="login_box">
        <div class="login_text">{{$t('个人注册信息')}}</div>

        <div class="sex_box">
          <div @click="gender = 1">
            <div class="sex_img" :class="{ active :gender==1}">
              <img src="~@/assets/img/sex01.png" alt="boy" />
            </div>
            <div-text center>{{$t('先生')}}</div-text>
          </div>
          <div @click="gender = 2">
            <div class="sex_img" :class="{ active: gender==2 }">
              <img src="~@/assets/img/sex02.png" alt="girl" />
            </div>
            <div-text center>{{$t('女士')}}</div-text>
          </div>
        </div>

        <div class="login">
          <form class="logo_input">
            <fieldset class="fieldset">
              <legend>{{$t('护照号码')}}*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="passport_no" :placeholder="$t('请输入护照号码')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
            <fieldset class="fieldset">
              <legend>{{$t('姓名（名+姓）')}}*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="name" :placeholder="$t('请输入姓名')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
            <fieldset class="fieldset">
              <legend>{{$t('出生日期')}}*</legend>
              <div class="date_box">

                <b-form-datepicker :locale="$t('locale')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"  :hide-header="true" selected-variant="success" id="datepicker" v-model="birthday" :label-no-date-selected="$t('请选择时间')" label-help=""></b-form-datepicker>
              </div>
            </fieldset>
            <fieldset class="fieldset">
              <legend>{{$t('移动电话')}}*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="mobile_phone" :placeholder="$t('请输入户移动电话')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
          </form>

          <div class="line"></div>

          <form class="logo_input">
            <fieldset class="fieldset">
              <legend>{{$t('户籍地址')}}*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="residence_address" :placeholder="$t('请输入户籍地址')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
            <fieldset class="fieldset">
              <legend>{{$t('紧急联系人')}}*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="emerg_person" :placeholder="$t('请输入紧急联系人')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
            <fieldset class="fieldset">
              <legend>{{$t('关系')}}*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="emerg_relation" :placeholder="$t('请输入关系')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
            <fieldset class="fieldset">
              <legend>{{$t('紧急联系人电话')}}*</legend>
              <b-input-group class="div-input">
                <b-form-input class="input" v-model="emerg_contact" :placeholder="$t('请再次输入紧急联系人电话')" type="search"></b-form-input>
              </b-input-group>
            </fieldset>
          </form>
        </div>

        <div class="foot_btn">
          <div-height height="30px" />
          <div class="link">
            <span>*{{$t('请以中文或英文填写您所在国家的戶籍地址（非通讯地址）')}}</span>
          </div>
          <div-height height="30px" />
          <div-save ref="btn" @save="save()">{{$t('完成')}}</div-save>

          <div-height height="100px" />
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reg_type: 1,
      email: "",
      code: "",
      password: "",
      confirm_password: "",
      gender: 1,
      passport_no: "", //护照
      name: "", //中文姓名
      birthday: "", //出生日期
      mobile_phone: "", //移动电话
      residence_address: "", //户籍地址
      emerg_person: "", //紧急联系人
      emerg_relation: "", //关系
      emerg_contact: "", //紧急联系人电话
    }
  },
  created() {
    //当前页面的数据
    let current_obj = localStorage.getItem("zuche_two")
    if (current_obj) {
      current_obj = JSON.parse(current_obj)
      console.log("当前页面数据", current_obj)
      Object.keys(current_obj).map((item) => (this[item] = current_obj[item]))
    }
    //上个页面的数据
    let obj = localStorage.getItem("zuche_one")
    console.log("当前页面数据", obj)
    if (obj) {
      obj = JSON.parse(obj)
      Object.keys(obj).map((item) => (this[item] = obj[item]))
    }
  },
  methods: {
    //注册 - 注册用户 - 预校验
    save() {
      const loading = this.$loading({
        lock: true,
      })

      let current_obj = {
        pre_check: false,
        gender: this.gender, //性别
        reg_type: 1, //注册类型为邮箱注册
        email: this.email, //邮箱地址
        code: this.code, //邮箱验证码
        password: this.password, //密码（6-16位英数混合）
        confirm_password: this.confirm_password, //确认密码
        passport_no: this.passport_no, //护照
        name: this.name, //中文姓名
        birthday: this.birthday, //出生日期
        mobile_phone: this.mobile_phone, //移动电话
        residence_address: this.residence_address, //户籍地址
        emerg_person: this.emerg_person, //紧急联系人
        emerg_relation: this.emerg_relation, //关系
        emerg_contact: this.emerg_contact, //紧急联系人电话
      }

      localStorage.setItem("zuche_two", JSON.stringify(current_obj))

      this.$post({
        url: "/index/reg/Reg",
        data: current_obj,
        success: (res) => {
          this.$toast.success(res.msg, {
            timeout: 1000,
            position: "top-center",
            pauseOnHover: false,
          })

          console.log("注册后返回", res)
          localStorage.setItem("zuche_token", res.data.token)

          localStorage.setItem("zuche_user", JSON.stringify(res.data.user))

          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
            this.$router.push("/")
          }, 3000)
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.date_box {
  /deep/.dropdown {
    flex-flow: row-reverse;
    border: 0;
    line-height: 2;
  }
  /deep/.form-control {
    text-align: left;
    padding: 0.375rem 0.75rem;
    font-size: 14px;
  }
  /deep/.form-control:focus {
    position: relative;
    z-index: 1;
    border-color: #57b846;
    box-shadow: none;
  }
  /deep/.form-control.focus {
    position: relative;
    z-index: 1;
    border-color: #57b846;
    box-shadow: none;
  }
}
/*//////////////////////////////////////////////////////////////////[ login ]*/

.login_box {
  width: 100%;
  min-height: calc(100vh - 360px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.login {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include transition(0.4s);

  @media #{$xl} {
    flex-direction: row;

    .line {
      display: block;
    }
  }

  @media #{$lg} {
    flex-direction: row;

    .line {
      display: block;
    }
  }

  @media #{$md} {
    flex-direction: column;

    .line {
      display: none;
    }
  }

  @media #{$sm} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }

  @media #{$xs} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }
}

.logo_img {
  width: 218px;
  height: 173px;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.line {
  width: 90px;
  height: 226px;
}

.logo_input {
  width: 438px;
}

.fieldset {
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 76px;
  @include transition(0.4s);

  &:focus-within {
    border: 1px solid #57b846;

    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }

  legend {
    @include transition(0.4s);
    position: relative;
    z-index: 1;
    color: #2f2e3f;
    font-size: 16px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
  }

  /deep/.div-input {
    position: relative;
    top: -10px;
    width: 100%;
    height: 60px;
    background: #ffffff;

    &:focus-within {
      border-color: #3c58b2;
    }

    .input-group-text {
      padding: 0 24px;
      background: transparent;
      border-color: transparent !important;
    }

    input {
      box-sizing: border-box;
      height: 60px;
      line-height: 60px;
      background: transparent;
      border-color: transparent !important;

      &.form-control {
        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }

    input::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 14px;
    }

    input:-webkit-autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }

    input:autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }
  }
}

.login_text {
  text-align: center;
  font-size: 23px;
  color: #333333;
  font-weight: bold;
  padding-top: 70px;
  margin-bottom: 50px;
  width: 100%;
}

.link {
  color: #818181;
  font-size: 13px;
  text-align: center;

  span {
    // cursor: pointer;
  }
}

//底部按钮

.foot_btn {
  max-width: 430px;
  width: 100%;
  padding-top: 20px;
}

//性别
.sex_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin-bottom: 46px;

  .sex_img {
    width: 80px;
    cursor: pointer;
    filter: opacity(20%);
    &.active {
      filter: opacity(100%);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>