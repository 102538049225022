<template class="div-updata">
  <label class="updata_label" @change="updata($event)">
    <input type="file" style="display:none" ref="inputer" accept="image/png, image/jpeg, image/jpg" />
    <slot></slot>
  </label>
</template>
<script>
export default {
  name: "div-updata",
  props: ["url", "data", "res", "name"],
  methods: {
    updata() {
      let DOM = this.$refs.inputer
      let formData = new FormData()
      formData.append(this.name || "file", DOM.files[0]) //获取文件详细信息

      formData.append("env", 1)

      // this.data = { env: 1 }
      //额外的参数
      // if (this.data) {
      //   let keys = Object.keys(this.data)
      //   keys.map((item) => {
      //     formData.append(item, this.data[item])
      //   })
      // }

      this.$post({
        url: this.url || "/index/upload/uploadImgs",
        data: formData,
        upload: true,
        success: (res) => {
          // this.res = this.res || "res.data"
          // let list = eval(this.res)
          let list = res.data
          console.log(list)
          //   this.avatar = res.data
          this.$emit("list", list)
          //   this.$emit("list", this.list)
          // this.edituserinfo(res.data.data.url)
        },
        tip: () => {},
      })
    },
    // updata(e) {
    //   console.log(e);
    //   this.$emit("updata", e);
    // },
  },
}
</script>
<style lang='scss' scoped>
.div-updata {
  margin-bottom: 0;
  border: 0;
}
</style>
