import Vue from 'vue'
import router from 'vue-router'
import routes from './modules/pages'
import children from './modules/navber'
import components from './modules/components'

Vue.use(router)

// 解决跳转到以后路由的时候，控制台报错
const originalPush = router.prototype.push
router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

Object.keys(components).forEach(item => {
    Vue.component(item, components[item]);
})

const Router = new router({
    // mode: 'history',
    routes: [
        ...routes,
        {
            path: '/',
            component: () => import('@/views/index'),
            children
        },
    ]
})
//路由拦截器
// Router.beforeEach((to, from, next) => {
//     console.log(to);
//     // to.name 为无需使用token的页面 可追加
//     if (localStorage.getItem('token') || to.name="") {
//         next();
//     } else {
//         // next({
//         //     name: ""
//         // })
//     }
// })

/** 解决跳转重复路由报错问题 */
// const routerPush = router.push;
// router.push = path => {
//     console.log('走了没', path);
//     // 判断下当前路由是否就是要跳转的路由
//     if (router.currentRoute.fullPath.includes(path)) return;
//     return routerPush.call(router, path);
// }

export default Router;