import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, deleteToken, onMessage } from 'firebase/messaging';
const firebaseConfig = {
  // 测试
  // apiKey: "AIzaSyCdvVb6Z6e9fpSb7xiSJeDvdkY32JBqnDY", authDomain: "phcarrental-d8062.firebaseapp.com", projectId: "phcarrental-d8062", storageBucket: "phcarrental-d8062.appspot.com", messagingSenderId: "74416480003", appId: "1:74416480003:web:4806e84633c55122875336", measurementId: "G-K5XHFQG1BE"
  // 客户提供
  apiKey: "AIzaSyC1QABwpXho1I9sC35xOu8j77Or6Vibh8M", authDomain: "janda-d78eb.firebaseapp.com", projectId: "janda-d78eb", storageBucket: "janda-d78eb.appspot.com", messagingSenderId: "386240438628", appId: "1:386240438628:web:e348bd9bf1ed546528da71", measurementId: "G-1LX5MYF10X"
}
export const firebase = initializeApp(firebaseConfig);
const messaging = getMessaging(firebase);

export const requestDeleteToken = () =>
  new Promise((resolve) => {
    getToken(messaging).then((res) => {
      console.log('token', res);
      deleteToken(messaging).then(() => {
        console.log('Token deleted.');
      }).catch((err) => {
        console.log('Unable to delete token. ', err);
      });

    }).catch((err) => {
      console.log('Error retrieving registration token. ', err);
    });
  })

export const requestForToken = () =>
  new Promise((resolve) => {
    // 测试
    // getToken(messaging, { vapidKey: "BJxOGzFS2W4uKhA0aurd_zoVdseM_-cXaggX7XHq9nDt4WUEicHtMZEP9t2YJExopIqgG_HM1HTusNyK8WD18Vo" })
    // 客户提供
    getToken(messaging, { vapidKey: "BCTPxngnhbCy0TSzczDXaeFpIk9RhgYGG6ToPvtDyAoeuuS_w08afolkk4WtLuzngc5oK2iYdbYtrzEqsT9lftM" })
      .then((res) => {
        if (res) {
          resolve(res);
        } else {
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('getToken err', err);
      });
  })

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });