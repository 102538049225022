<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒左侧菜单 -->
            <Menu name="wallet_record"></Menu>
          </b-col>
          <b-col lg="10" md="9">

            <!-- 🥒 标题 -->
            <div-padding padding="20px 0">
              <div-text color="#1D2124" size="20px" bold>{{$t('充值钱包')}}</div-text>
            </div-padding>

            <div-zebra></div-zebra>

            <div class="zebra_box shadow">
              <b-card-group>
                <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                  <template v-slot:header>
                    <div class="card_title">
                      <b-button class="btn" variant="none">
                        <div-text>{{$t('纪录')}}</div-text>
                      </b-button>
                    </div>
                  </template>
                  <div-height height="1px" bg="rgba(34, 34, 34, 0.125)" />

                  <b-tabs v-model="wallet_env" @input="opt" pills card>
                    <b-tab :title="$t('充值')"></b-tab>
                    <b-tab :title="$t('消费')"></b-tab>
                  </b-tabs>
                  <div-height height="1px" bg="rgba(34, 34, 34, 0.125)" />

                  <b-card-body>
                    <b-row>
                      <div class="list">

                        <div class="item" v-for="(item,index) in list" :key="index">
                          <div class="th">{{$t('交易日期')}}</div>
                          <div class="th">{{$t('金额')}} (CAD)</div>
                          <div class="th">{{$t('充值方式')}}</div>
                          <div class="th">{{$t('到账日期')}}</div>
                          <div class="th">{{$t('交易单号')}}</div>
                          <div class="th">{{$t('操作')}}</div>
                          <div class="tr">{{item.create_time}}</div>
                          <div class="tr">{{item.num}}</div>
                          <div class="tr" v-if="item&&item.rechargeorder">{{item.rechargeorder.pay_type_str}}</div>
                          <div class="tr" v-else>--</div>
                          <div class="tr">{{item.trade_time?item.trade_time:'--'}}</div>
                          <div class="tr">{{item.no}}</div>
                          <div class="tr">
                            <div @click.stop="showPopup(item.id)" class="icon"><i class="el-icon-delete"></i></div>
                          </div>
                        </div>
                      </div>

                    </b-row>
                    <el-empty v-if="list&&list.length==0" :description="$t('暂无数据')"></el-empty>
                  </b-card-body>

                  <template v-slot:footer>
                    <!-- 🔋底部分页 -->
                    <div>
                      <!-- 🔋底部分页 -->
                      <div-center>
                        <!-- number-of-pages 为总页码数量 -->
                        <b-pagination-nav :first-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :last-number="true" :number-of-pages="pages" use-router>
                          <template v-slot:prev-text>
                            <b-icon icon="chevron-left"></b-icon>
                          </template>
                          <template v-slot:next-text>
                            <b-icon icon="chevron-right"></b-icon>
                          </template>
                        </b-pagination-nav>
                      </div-center>
                    </div>
                  </template>

                </b-card>
              </b-card-group>
            </div>

          </b-col>

        </b-row>
      </section>
      <div-height height="30px" />
    </b-container>
  </div>

</template>
<script>
import toastEel from "@/components/toast-ui/del01.vue"
export default {
  data() {
    return {
      id: 0,
      msg: "您确定要取消订单吗？",
      shouldShowDialog: false,
      loading: false,
      wallet_env: 0,
      list: [],
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    showPopup(id) {
      this.id = id
      this.loading = this.$loading({
        lock: true,
      })
      this.$toast(
        {
          component: toastEel,
          // 事件回调，组件中emit的方法放在这里进行回调
          props: {
            the_title: this.$t("您确定要删除该订单吗？"),
            the_left: this.$t("取消"),
            the_right: this.$t("确定"),
          },
          listeners: {
            showDialog: () => {
              console.log("调用删除")
              this.del()
            },
            closeDialog: () => {
              console.log("调用关闭")
              this.close()
            },
          },
        },
        {
          id: 1,
          timeout: false,
          position: "top-center",
          closeOnClick: false, // 单击即关闭
          pauseOnFocusLoss: false,
          pauseOnHover: false, // 鼠标移入暂停
          draggable: false, // 允许通过拖动和滑动事件关闭
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false, // 显示/隐藏进度条
          closeButton: false,
          icon: false,
          rtl: false,
        }
      )
    },
    close() {
      this.loading.close()
      this.$toast.clear()
    },
    del() {
      this.$toast.clear()
      this.$post({
        url: "/index/walletlog/markDel",
        data: {
          ids: this.id,
        },
        success: (res) => {
          setTimeout(() => {
            this.loading.close()
            this.get_info()
          }, 2000)
        },
        tip: () => {
          console.log("3")
          setTimeout(() => {
            this.loading.close()
          }, 3000)
        },
      })
    },
    opt() {
      console.log(this.wallet_env)
      this.get_info()
    },

    linkGen(pageNum) {
      // console.log("页码参数设置", pageNum)
      return pageNum === 1
        ? `/wallet_record?page=1`
        : `/wallet_record?page=${pageNum}`
    },
    get_list(page) {
      this.currentPage = page
      this.get_info()
    },
    get_info() {
      this.$get({
        url: "/index/Walletlog/idx",
        data: {
          wallet_env: this.wallet_env + 1,
          page: this.currentPage, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          this.list = res.data.list.data
          this.pages = res.data.list.last_page || 1
          this.currentPage = res.data.list.current_page
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/.button-danger {
  display: none !important;
}
.list {
  width: 100%;
  .item {
    display: flex;
    flex-wrap: wrap;
    .th {
      display: none;
      width: 16.6%;
      text-align: center;
      color: #6c757d;
      font-size: 14px;
      background: #fbfcfc;
      height: 44px;
      line-height: 44px;
    }
    .tr {
      width: 16.6%;
      text-align: center;
      color: #343a40;
      font-size: 14px;
      // height: 44px;
      line-height: 44px;
      border-bottom: 1px solid #d8d8d8;
    }
    .icon {
      cursor: pointer;
      width: 30px;
      height: 30px;
      margin-left: 10px;
      border-radius: 4px;
      color: #fff;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      // background: #e9ebf1;
      background: #333;
      box-shadow: 0 3px 24px rgba(0, 0, 0, 0.04);
      transition: all 0.3s;
      i {
        transition: all 0.3s;
      }
    }
  }
  .item:nth-child(1) .th {
    display: block;
  }
  .item:last-child .tr {
    border-bottom: 0;
  }
}

@media #{$xl} {
  //默认表格样式
}

@media #{$lg} {
  //默认表格样式
}

@media #{$md} {
  //单元格卡片样式

  .list {
    padding: 15px;
    .item {
      padding: 15px;
      // border: 1px solid #d8d8d8;
      margin-bottom: 30px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      .th {
        display: none;
        width: 50%;
        text-align: center;
        color: #6c757d;
        font-size: 14px;
        background: transparent;
        height: 44px;
        line-height: 44px;
      }
      .tr {
        width: 50%;
        text-align: left;
        color: #343a40;
        font-size: 14px;
        // height: 44px;
        line-height: 44px;
        border-bottom: 0;
      }
      .th {
        display: block;
      }
      .tr {
        border: 0;
      }
    }

    //声明一个变量接受数组
    $order-num: 1, 3, 5, 7, 9, 11, 2, 4, 6, 8, 10, 12;
    //循环数组
    @each $item in $order-num {
      //获取索引
      $index: index($order-num, $item);
      //设置样式
      .item div:nth-child(#{$index}) {
        order: $item;
      }
    }
    //循环设置order结束
  }
}

@media #{$sm} {
  .list {
    padding: 15px;
    .item {
      padding: 15px;
      // border: 1px solid #d8d8d8;
      margin-bottom: 30px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      .th {
        display: none;
        width: 50%;
        text-align: center;
        color: #6c757d;
        font-size: 14px;
        background: transparent;
        height: 44px;
        line-height: 44px;
      }
      .tr {
        width: 50%;
        text-align: left;
        color: #343a40;
        font-size: 14px;
        // height: 44px;
        line-height: 44px;
        border-bottom: 0;
        border: 0;
      }
      .th {
        display: block;
      }
    }

    //声明一个变量接受数组
    $order-num: 1, 3, 5, 7, 9, 11, 2, 4, 6, 8, 10, 12;
    //循环数组
    @each $item in $order-num {
      //获取索引
      $index: index($order-num, $item);
      //设置样式
      .item div:nth-child(#{$index}) {
        order: $item;
      }
    }
    //循环设置order结束
  }
}

@media #{$xs} {
  .list {
    padding: 15px;
    .item {
      padding: 15px;
      // border: 1px solid #d8d8d8;
      margin-bottom: 30px;
      border-radius: 8px;
      box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
      .th {
        display: none;
        width: 50%;
        text-align: center;
        color: #6c757d;
        font-size: 14px;
        background: transparent;
        height: 44px;
        line-height: 44px;
      }
      .tr {
        width: 50%;
        text-align: left;
        color: #343a40;
        font-size: 14px;
        // height: 44px;
        line-height: 44px;
        border-bottom: 0;
      }
      .th {
        display: block;
      }
      .tr {
        border: 0;
      }
    }

    //声明一个变量接受数组
    $order-num: 1, 3, 5, 7, 9, 11, 2, 4, 6, 8, 10, 12;
    //循环数组
    @each $item in $order-num {
      //获取索引
      $index: index($order-num, $item);
      //设置样式
      .item div:nth-child(#{$index}) {
        order: $item;
      }
    }
    //循环设置order结束
  }
}

.card_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tabs {
  /deep/.nav-link {
    font-size: 14px;
    color: #3b3b3b !important;
  }

  /deep/.active {
    color: #fff !important;
    background-color: #3b3b3b !important;
  }

  .tab-content {
    display: none;

    .active {
      display: none;
    }
  }

  .tab {
    padding: 10px 38px 10px 10px;
    cursor: pointer;
    color: #1d2124;
    font-weight: bold;
  }
}
</style>