<template>
  <div-card bg="#303446" style="flex: 1" radius="26vw">
    <div-padding padding="2vw 0" style="flex: 1">
      <div-center center>
        <div-text :color="active ? '#CBAC7B' : '#9195A2'" size="3.5vw">
          <slot></slot>
        </div-text>
      </div-center>
    </div-padding>
  </div-card>
</template>
<script>
export default {
  components: {},
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  created() {},

  methods: {},
}
</script>
<style lang='scss' scoped>
</style>
