module.exports = {
  '首页': '首頁',
  '汽车租赁': '汽車租賃',
  '新车到店': '新車到店',
  '商城中心': '商城中心',
  '关于我们': '關於我們',
  '登录': '登錄',
  '注册': '註冊',
  '新车推荐': '新車推薦',
  '豪华自有其道，全新车款推荐': '獨具品味，風格多變。全新車款推薦',
  '热门车款推荐': '熱門車款推薦',
  '最热门车款推荐，满足您的租赁需求': '最熱門車款推薦，滿足您的租賃需求',
  '服务特色': '服務標準',
  '一周七天服务': '一週七天服務',
  '取车无忧，代驾到府': '取車無憂，代駕到府',
  '全程安全保障': '全程安全保障',
  '全球交通指引': '全球交通指引',
  '企业概况': '企業概況',
  // '关于我们': '關於我們',
  '公司简介': '公司簡介',
  '服务项目': '服務項目',
  '了解更多': '了解更多',
  '门店地点': '門市地點',
  // '商城中心': '商城中心',
  // '关于我们': '關於我們',
  '如何租赁': '如何租賃',
  '联系客服': '聯繫客服',
  '充值中心': '充值中心',
  '联系方式': '聯繫方式',
  '公司地址': '公司地址',
  // '版权所有：沛豪国际租车': '版權所有：沛豪國際租車',
  '请输入要搜索的内容': '請輸入要搜索的內容',
  '汽车租赁2': '汽車租賃',
  '让我们发现最完美的汽车': '讓我們發現最完美的汽車',
  '车种': '車種',
  '请选择车种': '請選擇車種',
  '品牌': '品牌',
  '请选择品牌': '請選擇品牌',
  '型号': '型號',
  '请选择型号': '請選擇型號',
  '年份': '年份',
  '请选择年份': '請選擇年份',
  '立即充值': '立即充值',
  '驾照信息提交': '駕照資訊上傳',
  '车辆选型': '車輛選型',
  '立即预约': '立即預約',
  '租赁合同签署': '租賃合約簽署',
  '租赁时间': '租賃時間',
  '取还车方式': '取還車方式',
  '租金明细': '租金明細',
  '支付押金': '支付押金',
  // '请输入要搜索的内容': '请输入要搜索的内容',
  // '汽车租赁2': '汽车租赁',
  // '让我们发现最完美的汽车': '让我们发现最完美的汽车',
  // '车种': '车种',
  // '请选择车种': '请选择车种',
  // '品牌': '品牌',
  // '请选择品牌': '请选择品牌',
  // '型号': '型号',
  // '请选择型号': '请选择型号',
  // '年份': '年份',
  // '请选择年份': '请选择年份',
  '租赁详情': '租賃詳情',
  '车辆信息': '車輛資訊',
  // '车种': 'Body Type',
  // '品牌': 'Brand',
  // '型号': 'Model',
  // '年份': 'Years',
  '租赁介绍': '租賃介紹',
  // '租赁时间': 'Rental Period',
  '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  // '天': '天',
  '天以上': '天以上',
  '每日租金': '每日租金',
  '每日可跑公里数': '每日可跑公里數',
  '超出公里数费用': '超出公里數費用',
  '车体险自付額500元方案': '車體險自付額500元方案',
  '车体险自付額2500元方案': '車體險自付額2500元方案',
  '新车险费用(单日价格)': '新車險費用（單日價格）',
  '租赁介绍价格费用不包含税费，最终租赁价格根据订单明细为准。': '租賃介紹價格費用不包含稅費，最終租賃價格根據訂單明細為準。',
  '预约车辆': '預約車輛',
  '详情/服务': '詳情 / 服務',
  // '如何租赁': '下單流程',
  // '如何租赁': '下單流程',
  // '新车到店': 'New Arrivals',
  // '豪华自有其道，全新车款推荐': '獨具品味，風格多變。全新車款推薦',
  '详情介绍': '詳情介紹',
  '新车详情': '新車詳情',
  // '商城中心': '商城中心',
  '商城详情': '商城詳情',
  // '详情介绍': 'Details...',
  '购买则可立即使用': '購買則可立即使用',
  '价格': '價格',
  '销量': '銷量',
  '立即购买': '立即購買',
  // '价格': '價格',
  '数量': '數量',
  '总价': '總價',
  '商城购买': '商城購買',
  '支付方式': '支付方式',
  '信用卡支付': '信用卡支付',
  '支付金额': '支付金額',
  '余额支付': '餘額支付',
  '支付密码': '支付密碼',
  '请输入支付密码': '請輸入支付密碼',
  '可用余额': '可用餘額',
  '支付': '支付',
  '微信支付': '微信支付',
  '微信扫码支付': '微信掃碼支付',
  '支付宝支付': '支付寶支付',
  '支付宝扫码支付': '支付寶掃碼支付',
  // '公司简介': 'Our Company',
  // '服务项目': '服務項目',
  '凭借着可靠的品质和优秀的越野性能深受顾客的喜爱': '憑藉著可靠的品質和優秀的越野性能深受顧客的喜愛',
  '公司简介2': '公司簡介',
  // '公司简介': 'Our Company',
  "豪华自有其道，全新数位化专享车旅程": "豪華自有其道，全新數位化專享車旅程",
  // 首頁
  "服务项目2": "服務項目",
  // EMAIL
  '请输入EMAIL': '請輸入EMAIL',
  '登录密码': '登錄密碼',
  '请输入登录密码': '請輸入登錄密碼',
  // '注册': '註冊',
  '忘记密码？': '忘記密碼？',
  '我已阅读并同意': '我已閱讀並同意',
  "《用户协议》与 《隐私政策》": "《用戶協議》與《隱私政策》",  // '登录': '登錄',
  '注册信息': '註冊資訊',
  '隐私权政策与服务条款': '隱私權政策與服務條款',
  // 首頁/用戶登錄/用戶協議與隱私條款
  // EMAIL
  // '请输入EMAIL': '请输入EMAIL',
  '验证码': '驗證碼',
  '请输入验证码': '請輸入驗證碼',
  // '登录密码': '登錄密碼',
  // '请输入登录密码': '請輸入登錄密碼',
  '重复密码': '重複密碼',
  '请再次输入登录密码': '請再次輸入登錄密碼',
  // 我已阅读并同意《用户协议》与《隐私政策》
  '下一步': '下一步',
  '用户协议与隐私条款': '用戶協議與隱私條款',
  // '隐私权政策与服务条款': 'Privacy Policy and Terms of Service',
  '订单中心': '訂單中心',
  '租车订单': '租車訂單',
  '核销订单': '核銷訂單',
  '我的钱包': '我的錢包',
  '充值钱包': '充值錢包',
  '租车押金': '租車押金',
  '系统设置': '系統設置',
  '信息通知': '訊息通知',
  '客服咨询': '客服諮詢',
  '绑定银行卡': '綁定銀行卡',
  // '登录密码': '登錄密碼',
  '支付密码': '支付密碼',
  '退出系统': '退出系統',
  '个人信息': '個人資訊',
  '点击查看': '點擊查看',
  '汽车驾照': '汽車駕照',
  '未提交': '未提交',
  '审核中': '審核中',
  '通过': '通過',
  '不通过': '不通過',
  // '我的钱包': 'My wallet',
  // '充值钱包': 'Recharge My Wallet',
  // '租车押金': 'Rental Secutiry Deposit',
  // '租车订单': 'Rental orders',
  '全部': '全部',
  '待付款': '待付款',
  '待取车': '待取車',
  '已取车': '已取車',
  '车辆类型': '車輛類型',
  '车辆品牌': '車輛品牌',
  '车辆型号': '車輛型號',
  '车辆年份': '車輛年份',
  "查看详情": "查看詳情",
  '核销订单': '核銷訂單',
  // '全部': '全部',
  '待使用': '待使用',
  '已使用': '已使用',
  '已过期': '已過期',
  '订单号码': '訂單號碼',
  '交易单号': '交易單號',
  '支付方式': '支付方式',
  '支付时间': '支付時間',
  '有效期至': '有效期至',
  //"查看详情": "查看詳情",
  // '系统设置': '系統設置',
  // '信息通知': '訊息通知',
  // '客服咨询': '客服諮詢',
  // '绑定银行卡': '綁定銀行卡',
  // // '登录密码': '登錄密碼',
  // '支付密码': '支付密碼',
  // '退出系统': '退出系統',
  // '个人信息': '個人資訊',
  // '个人信息': '個人資訊',
  '头像': '頭像',
  "Email帐号": 'Email帳號',
  "更换Email帐号": '更換Email帳號',
  '护照号码': '護照號碼',
  '姓名（名+姓）': '姓名（名+姓）',
  '出生日期': '出生日期',
  '性别': '性別',
  '男': '男',
  '女': '女',
  '移动电话': '行動電話',
  '户籍地址': '戶籍地址',
  '紧急联系人': '緊急聯絡人',
  '关系': '關係',
  '紧急联系人电话': '緊急聯繫人電話',
  '请已中文或英文填写您所在国家的户籍地址（非通讯地址）': '請以中文或英文填寫您所在國家的戶籍地址（非通訊地址）',
  '保存': '保存',
  // 更换Email
  // 更换帐号
  // EMAIL
  // 请输入EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  '获取验证码': '獲取驗證碼',
  '完成': '完成',
  // '租车订单': '租車訂單',
  // '租车订单': '租車訂單',
  // '全部': 'All',
  // '待付款': 'Unpaid',
  // '待取车': 'Waiting for Pick Up',
  // '已取车': 'Already Picked Up',
  '逾期': '逾期',
  // '待付款': 'Unpaid',
  // '待取车': 'Waiting for Pick Up',
  '进行中': '進行中',
  '退款中': '退款中',
  '退款':'退款',
  // '完成': '完成',
  '已退款': '已退款',
  '取消订单': '取消訂單',
  '订单详情': '訂單詳情',
  // '订单详情': 'Order Details',
  '订单信息': '訂單資訊',
  // '车辆类型': '車輛類型',
  // '车辆品牌': '車輛品牌',
  // '车辆型号': '車輛型號',
  // '车辆年份': '車輛年份',
  '租赁方式': '租賃方式',
  '日租': '日租',
  '月租': '月租',
  '取车方式': '取車方式',
  '还车方式': '還車方式',
  '到店自提': '到店自提',
  '代驾到府': '代駕到府',
  '取车时间': '取車時間',
  '还车时间': '還車時間',
  '还车地点': '還車地點',
  '汽车保险': '汽車保險自付額',
  '自付額2500元方案': '自付額2500元方案',
  '自付額500元方案': '自付額500元方案',
  // '新车险': '新車保險',
  '是': '是',
  '否': '否',
  '租车费用': '租車費用',
  '租赁天数': '租賃天數',
  '附加费用': '附加費用',
  '取车地点': '取車地點',
  // '取车时间': '取車時間',
  // '还车时间': '還車時間',
  // '还车地点': '還車地點',
  '租车费用小计': '租車費用小計',
  '税金费用': '稅金費用',
  // '税金费用': '稅金費用',
  '租车总计金额费用': '租車總計金額費用',
  '押金费用': '押金費用',
  '驾照押金': '駕照押金',
  // '租车押金': 'Rental Secutiry Deposit',
  '押金总金额': '押金總金額',
  '保险费用': '保險費用',
  // '汽车保险': '汽车保险',
  '新车险': '新車險',
  '超跑险': '超跑險',
  '其他附加费用': '其他附加費用',
  '未满25岁': '未滿25歲',
  '非加拿大驾照': '非加拿大駕照',
  '保险/其他费用小计': '保險/其他費用小計',
  '总计金额': '總計金額',
  '定金金额': '訂金金額',
  '尾款金额': '尾款金額',
  '租车合同': '租賃合約',
  '沛豪国际租赁有限公司租赁合同': 'J&A Car Rental 租賃合約',
  '预览合同': '預覽合約',
  '订单状态': '訂單狀態',
  '订单单号': '訂單單號',
  '创建订单时间': '創建訂單時間',
  // '支付时间': '支付時間',
  //  '支付方式': '支付方式',
  // '取车时间': '取车时间',
  '车牌号码': '車牌號碼',
  '续租': '續租',
  // '核销订单': '核銷訂單',
  // '全部': '全部',
  // '待使用': '待使用',
  // '已使用': '已使用',
  // '已过期': '已過期',
  // '订单号码': '訂單號碼',
  // '交易单号': '交易單號',
  // '支付方式': '支付方式',
  // '支付时间': '支付時間',
  // '有效期至': '有效期至',
  '核销码信息': '核銷碼資訊',
  // '充值钱包': 'Recharge My Wallet',
  '充值总额': '充值總額',
  '纪录': '紀錄',
  '充值项目': '充值項目',
  '充值2': '充值', '赠送': '贈送',
  '充值': '充值',
  "充值支付": "充值支付",
  // '支付方式': '支付方式',
  // '支付宝支付': '支付宝支付',
  // '微信支付': '微信支付',
  // '信用卡支付': '信用卡支付',
  // "充值支付": "充值支付",
  //  '支付': 'Pay',
  //  '租车押金': '租車押金',
  '押金总额': '押金總額',
  // '车辆类型': 'Body Type',
  // '车辆品牌': 'Vehicle Brand',
  // '车辆型号': 'Vehicle Model',
  // '车辆年份': 'Vehicle Year',
  // '驾照押金': '駕照押金',
  // '租车押金': 'Rental Secutiry Deposit',
  // '支付方式': '支付方式',
  // '支付时间': '支付時間',
  //'交易单号': '交易單號',
  "收款单号": '收款單號',
  // '待取车': 'Waiting for Pick Up',
  // '进行中': '進行中',
  // '完成': '完成',
  //  '逾期': '逾期',
  //'信息通知': '訊息通知',
  //'信息通知': '訊息通知',
  //'订单信息': '訂單資訊',
  '消息详情': '詳細資訊',
  // '客服咨询': '客服諮詢',
  // '客服咨询': '客服諮詢',
  '问题反馈': '問題反饋',
  '问题类型': '問題類型',
  '请选择问题类型': '請選擇問題類型',
  '反馈内容': '反饋內容',
  '请输入您想说的话': '請輸入您想說的話',
  '上传图片/影片': '上傳圖片/影片',
  // '完成': '完成',
  '留言板': '留言板',
  // '反馈内容': 'Feedback content',
  // '请输入您想说的话': 'Please type here',
  // '上传图片/影片': 'Upload Photo/Video',
  // '完成': '完成',
  '下单问题': '下單問題',
  '微信': '微信',
  '其他问题': '其他問題',
  // '微信': '微信',
  // 尚未開發
  // // '登录密码': '登錄密碼',
  // // '登录密码': '登錄密碼',
  // EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  //  '获取验证码': '獲取驗證碼',
  // '登录密码': '登錄密碼',
  // '请输入登录密码': '請輸入登錄密碼',
  // '重复密码': '重复密码',
  // '请再次输入登录密码': '请再次输入登录密码',
  // '完成': '完成',
  // '支付密码': '支付密码',
  // '支付密码': '支付密码',
  // EMAIL
  // '验证码': '驗證碼',
  // '请输入验证码': '請輸入驗證碼',
  //  '获取验证码': '獲取驗證碼',
  // '登录密码': '登錄密碼',
  // '请输入登录密码': '請輸入登錄密碼',
  // '重复密码': '重复密码',
  // '请再次输入登录密码': '请再次输入登录密码',
  // '完成': '完成',



  "请输入Email帐号": '請輸入Email帳號',

  '企业介绍': '企業介紹',

  '客服介绍': '客服介紹',

  '年限': '年限',
  '请选择年限': '請選擇年限',



  '用户菜单': '用戶菜單',

  '提示': '提示',
  '您确定要退出系统吗': '您確定要退出系統嗎',








  '联名品牌': '聯名品牌',






  '拖动滑块完成拼图': '拖動滑塊完成砌圖',
  '验证失败，请重试': '驗證失敗，請重試',
  '验证通过！': '驗證通過！',



  '查看': '查看',




  '待支付': '待支付',

  '待付特殊费用': '待付特殊費用',
  '待验收': '待驗收',
  '待付尾款': '待付尾款',


  '特殊费用支付时间': '特殊費用支付時間',
  '特殊费用支付方式': '特殊費用支付方式',
  '尾款支付时间': '尾款支付時間',
  '尾款支付方式': '尾款支付方式',




  '忘记密码': '忘記密碼',
  'suffix': '',













  '暂无数据': '暫無資料',




  '待审核': '待審核',
  '已通过': '已通過',
  '未通过': '未通過',










  '公里': '公里',



  '假支付': '假支付',

  '付款方式':'付款方式',
  '在线支付':'在線支付',
  '线下支付':'線下支付',




  '设置支付密码': '設置支付密碼',


  '暂未开放': '暫未開放',

  '不核销公里数': '不核銷公里數',

  '我的': '我的',

  '账号': '帳號',
  '更换账号': '更換帳號',








  '如果您在租赁过程中遇到什么困难,请查看如何租赁或者联系客服进行咨询': '如果您在租賃過程中遇到什麼困難,請查看如何租賃或者聯系客服進行咨詢',

  '请填写您要租赁的信息': '請填寫您要租賃的信息',

  '请选择租赁方式': '請選擇租賃方式',

  '请选择取车方式': '請選擇取車方式',

  '请选择时间': '請選擇時間',
  '送车地点': '送車地點',
  '请输入送车地址': '請輸入送車地點',

  '请选择还车方式': '請選擇還車方式',

  '请先选择租赁方式': '請先選擇租賃方式',
  '请先选择取车时间': '請先選擇取車時間',

  '请输入还车地址': '請輸入還車地點',

  '请选择汽车保险': '請選擇汽車保險自付額',

  '选配购买': '選配購買',
  '超跑保险': '超跑保险',
  '计算规则为7天一周期，不满7则按7天计算': '計算規則為7天一周期，不滿7則按7天計算',
  '提交': '提交',
  '我要租赁': '我要租賃',


  'locale': 'zh',
  '查看续租': '查看續租',



  '消费': '消費',
  '交易日期': '交易日期',
  '金额': '金額',
  '充值方式': '充值方式',
  '到账日期': '到賬日期',
  '操作': '操作',

  '使用中': '使用中',

  '余额': '余額',

  '尾款单': '尾款單',
  '管理员审核中': '管理員審核中',










  '请再次输入支付密码': '請再次輸入支付密碼',



  '请输入银行卡账号': '請輸入銀行卡賬號',
  '请输入手机号': '請輸入手機號',
  '银行卡账号': '銀行卡賬號',
  '手机号': '手機號',

  '已退还': '已退還',
  '已还车': '已還車',

  '已取消': '已取消',
  '请选择汽车驾照类型': '請選擇機動車駕駛證類型',
  '请上传驾驶证': '請上傳駕駛證',
  '上传驾驶证主页': '上傳駕駛證主頁',
  '上传驾驶证副页': '上傳駕駛證副頁',
  '上传驾驶证附件': '上傳駕駛證附件',


  '租赁明细': '租賃明細',






  '您确定要退出系统吗？': '您確定要退出系統嗎？',
  '取消': '取消',
  '确定': '確定',
  "图片大小不能超过2M": "圖片大小不能超過2M",
  "图片大小不能超过10M": "圖片大小不能超過10M",
  "正在上传中...": "正在上傳中...",
  "请先阅读并同意用户协议与隐私政策": "請先閱讀並同意用戶協議與隱私政策",
  '您确定要删除该订单吗？': '您確定要刪除該訂單嗎？',


  '请选择': '請選擇',
  '请下滑到底部查看租车合同': '請下滑到底部查看租車合同',

  '签订合同': '簽訂合同',
  '生成订单': '生成訂單',
  '电子签名': '電子簽名',
  '确认签署': '確認簽署',
  '签订合同（已签字）': '簽訂合同（已簽字）',
  '租赁支付': '租賃支付',

  '您确定要取消该订单吗？': '您確定要取消該訂單嗎？',
  '支付订单': '支付訂單',
  '取消时间': '取消時間',
  '请填写您要续租的信息': '請填寫您要續租的信息',
   '电子合约':'電子合約',
   '确认':'確認',




  '个人注册信息': '個人註冊信息',
  '请输入护照号码': '請輸入護照號碼',
  '请输入姓名': '請輸入姓名',
  '用户登录': '用戶登錄',
  '请输入户移动电话': '請輸入戶移動電話',
  '请输入户籍地址': '請輸入戶籍地址',
  '请输入紧急联系人': '請輸入緊急聯繫人',

  '先生': '先生',
  '女士': '女士',
  '忘记密码': '忘記密碼',

  '新密码': '新密码',

  '请输入关系': '請輸入關係',
  '请再次输入紧急联系人电话': '請再次輸入緊急聯繫人電話',
  '请以中文或英文填写您所在国家的戶籍地址（非通讯地址）': '請以中文或英文填寫您所在國家的戶籍地址（非通訊地址）',

  '特殊费用': '特殊費用',


  '特殊费用支付': '特殊費用支付',


  "取车日期": "取車日期",
  "请选择取车日期": "請選擇取車日期",
  "还车日期": "還車日期",
  "请先选择取车日期": "請先選擇取車日期",
  "请选择还车日期": "請選擇還車日期",
  "请选择取车时间": "請選擇取車時間",
  "请选择还车时间": "請選擇還車時間",

  "点击支付后将跳转到第三方服务平台进行支付": "點擊支付後將跳轉到協力廠商服務平臺進行支付",

  "银联国际": "銀聯國際",

  "新车险计算规则为租车天数*7天周期计算，不满足7天以7天进行计算": "新車險計算規則為租車天數*7天週期計算，不滿足7天以7天進行計算",

  "联邦政府商品及服务税": "聯邦政府商品及服務稅 5%",
  "省销售税": "省銷售稅 7%",

  "汽车保险500元方案": "汽車保險自付額500元方案",
  "汽车保险2500元方案": "汽車保險自付額500元方案",


}