<template>
  <div>
    <el-table :data="tableRowData" :header-cell-style="{background:'#333'}" :span-method="mergeRowMethod" border style="width: 100%; margin-top: 20px">
      <el-table-column prop="sort" label="ID" width="180" header-align="center">
      </el-table-column>
      <el-table-column prop="name" label="姓名" header-align="center">
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableRowData: [],
      originData: [
        {
          sort: "01",
          children: [
            {
              name: "01-1号",
            },
            {
              name: "01-2号",
            },
            {
              name: "01-3号",
            },
          ],
        },
        {
          sort: "02",
          children: [
            {
              name: "02-1号",
            },
          ],
        },
        {
          sort: "03",
          children: [
            {
              name: "03-1号",
            },
            {
              name: "03-2号",
            },
            {
              name: "03-3号",
            },
            {
              name: "03-4号",
            },
          ],
        },
        {
          sort: "04",
          children: [
            {
              name: "04-1号",
            },
            {
              name: "04-2号",
            },
          ],
        },
        {
          sort: "05",
          children: [
            {
              name: "05-1号",
            },
          ],
        },
      ],
      tableData: [],
      list: [
        {
          id: "1623558548373528577",
          nutrients: "維生素Ｄ",
          nutrientsEn: null,
          cereals: null,
          egg: "魚貝類：鮭魚卵、白帶魚、 沙丁魚、秋刀魚、蒲燒鰻、 比目魚\n 皮蛋、 雞蛋",
          dairy: null,
          vegetable: "黑木耳、秀珍菇、 乾香菇",
          fruit: null,
          nuts: null,
        },
        {
          id: "1623558548415471618",
          nutrients: "益生菌",
          nutrientsEn: null,
          cereals: null,
          egg: "納豆",
          dairy: "有添加益生菌的優酪乳或食物、乳糖、果寡糖、乳果糖",
          vegetable: "泡菜、洋蔥、牛蒡、 蒜頭",
          fruit: null,
          nuts: null,
        },
        {
          id: "1623558548214145026",
          nutrients: "鎂",
          nutrientsEn: null,
          cereals: "玄米飯、玉米",
          egg: "蝦米、 大豆、豆皮、 牡蠣、納豆、凍豆腐",
          dairy: null,
          vegetable: "乾燥海帶芽",
          fruit: null,
          nuts: "杏仁、腰果、 花生",
        },
        {
          id: "1623558548407083009",
          nutrients: "Q10",
          nutrientsEn: null,
          cereals: null,
          egg: "動物肝臟、內臟、牛肉、豬肉、 鰹魚、鮪魚",
          dairy: null,
          vegetable: null,
          fruit: null,
          nuts: null,
        },
        {
          id: "1623558548188979202",
          nutrients: "鋅",
          nutrientsEn: null,
          cereals: "蕎麥粉",
          egg: "牡蠣、羊肩肉、牛肩肉、豬肝、魷魚肝、蒲燒鰻、小型章魚、貝類、鱈魚卵、板腱牛",
          dairy: null,
          vegetable: null,
          fruit: null,
          nuts: "蠶豆、腰果",
        },
      ],
      // tableData: [
      //   {
      //     id: "12987122",
      //     name: "王小虎",
      //     amount1: "234",
      //     amount2: "3.2",
      //     amount3: 10,
      //   },
      // ],
    }
  },
  created() {
    this.tableData = this.list.map((item) => {
      let obj = {
        egg: item.egg,
        dairy: item.dairy,
        vegetable: item.vegetable,
        fruit: item.fruit,
        nuts: item.nuts,
      }
      //清楚数组中的null
      let obj2 = this.delObjectKey(obj)
      console.log("obj2", obj2)
      //对象中的值转换成数组
      let arr = Object.values(obj2)

      console.log(arr)

      let new_arr = arr.map((item) => {
        return { name: item }
      })

      console.log("处理后的数组", new_arr)

      return {
        id: item.id,
        sort: item.nutrients,
        children: new_arr,
      }
    })

    console.log(this.tableData)

    this.loadRowData()

    //   return {
    //     ...item,
    //     amount1: item.amount1 + "元",
    //     amount2: item.amount2 + "元",
    //     amount3: item.amount3 + "元",
    //   }
    // })
  },
  methods: {
    loadRowData() {
      this.tableRowData = []
      let originArr = JSON.parse(JSON.stringify(this.tableData)) // 普通的数据转换，将后台格式转换成element表格需要的格式

      // if (Array.isArray(originArr)) {
      this.tableData.map((res) => {
        if (Array.isArray(res.children)) {
          res.children.map((subRes, subIndex) => {
            let content = {}
            content = subRes
            content["sort"] = res.sort // 重点！赋值合并的行数数值，只需要取子循环的第一个数赋值待合并的行数即可
            if (subIndex == 0) {
              content["rowNum"] = res.children.length
            }
            this.tableRowData.push(content)
          })
        }
      })
      // }
      console.log("tableRowData==============", this.tableRowData)
    },
    delObjectKey(obj) {
      var param = {}
      if (obj == null || obj == "" || obj == undefined) return param
      for (var key in obj) {
        if (obj[key] !== null && obj[key] !== "" && obj[key] !== undefined) {
          param[key] = obj[key]
        }
      }
      return param
    },
    mergeRowMethod({ row, column, rowIndex, columnIndex }) {
      // columnIndex 代表列数，从0开始计数,我们要合并的字段属于第一列，取0
      if (columnIndex == 0) {
        return {
          rowspan: row.rowNum, // 待合并行数 -- 合并的行数长度就等于之前赋值的子数据的长度；未赋值的即表示0，不显示
          colspan: row.rowNum > 0 ? 1 : 0, // 待合并列数 -- 合并的列数自身占一列，被合并的要返回0，表示不显示
        }
      }
    },
  },
}
</script>
<style lang="less" scoped>
</style>