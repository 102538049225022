<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="about" />
    <!-- 🥒轮播图 -->
    <section>
      <div class="banner">
        <Swiper :banners="banners" />
      </div>
    </section>
    <!-- 🍞面包屑 -->
    <section>
      <b-container>
        <div-padding padding="10px 0">
          <b-row>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('about')">{{$t('关于我们')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('公司简介2')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>
    <!--🍏 简介-->
    <section>
      <b-container>
        <div-height height="80px" />
        <div class="title_sm">
          <h1 class="wow fadeInUp">{{$t('公司简介')}}</h1>
          <h2 class="wow fadeInUp" style="color:transparent">Company Profile</h2>
          <h3 class="wow fadeInUp"></h3>
          <h4 class="wow fadeInUp"></h4>
          <h5 class="wow fadeInUp">{{$t('豪华自有其道，全新数位化专享车旅程')}}</h5>
        </div>
        <div-height height="40px" />
        <div-html :html="original['intro'+$t('suffix')]"></div-html>
      </b-container>
    </section>
    <div-height height="40px"></div-height>
    <!--🥒无限轮播图-->
    <section>
      <swiper :options="swiperOption" v-if="list.length>1" class="d-none d-lg-block d-xl-block">
        <swiper-slide class="swiper-wrapper" v-for="(item, index) in list" :key="index">
          <img :src="item">
        </swiper-slide>
      </swiper>
      <swiper :options="swiperOption_Sm" v-if="list.length>1" class="d-block d-lg-none d-xl-none">
        <swiper-slide class="swiper-wrapper" v-for="(item, index) in list" :key="index">
          <img :src="item">
        </swiper-slide>
      </swiper>
    </section>
    <div-height height="40px"></div-height>
  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      list: [],
      original: {},
      banners: [],
      swiperOption: {
        slidesPerView: 5,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      },
      swiperOption_Sm: {
        slidesPerView: 3,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
        loop: true,
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
      },
    }
  },
  created() {
    this.get_info()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/enterpriseintro/pagedata?id=1",
        success: (res) => {
          this.banners = res.data.banner
          this.original = res.data.original
          this.list = res.data.original.intro_bot_pics.split(",")
          // console.log("da", this.original)
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang='scss' scoped>
// 轮播图
.banner img {
  width: 100%;
}

// 简介
.blurb_box {
  text-align: center;
  z-index: 1000;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #e6e6e6;
  @include transition(0.4s);
  @media #{$xl} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$lg} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$md} {
    padding: 2.13rem;
    margin-top: -4.38rem;
  }
  @media #{$sm} {
    padding: 1.5rem;
    margin-top: 2rem;
  }
  @media #{$xs} {
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .blurb {
    background: #1d2124;
    width: 100%;
    height: 100%;
    padding: 2rem;
  }
}

.change {
  color: #fff;
  @include transition(0.4s);
  @media #{$xl} {
    font-size: 1.2rem;
  }
  @media #{$lg} {
    font-size: 0.9rem;
  }
  @media #{$md} {
    font-size: 0.9rem;
  }
  @media #{$sm} {
    font-size: 0.9rem;
  }
  @media #{$xs} {
    font-size: 0.9rem;
  }
}

//============================ title text Start ==================================
.title_sm {
  position: relative;

  h1 {
    width: 100%;
    position: absolute;
    font-size: 22px;
    color: #1d2124;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 2;
    top: 10px;
    font-weight: bold;
  }

  h2 {
    padding-top: 5px;
    width: 100%;
    font-size: 28px;
    color: #e6e6e6;
    position: absolute;
    text-align: center;
    margin: 0 auto;
    left: 0;
    right: 0;
    z-index: 1;
  }

  h3 {
    position: absolute;
    width: 17px;
    height: 3px;
    background: #333333;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 40px;
    z-index: 2;
  }

  h4 {
    height: 50px;
  }

  h5 {
    width: 100%;
    font-size: 16px;
    color: #b7b7b7;
    text-align: center;
  }
}

// 🥒底部轮播图
.swiper-wrapper {
  text-align: center;
  background: #fff;
  img {
    width: 100%;
    transform: scale(1);
    transition: all 0.5s;
  }
}
</style>
