import { render, staticRenderFns } from "./relet_02.vue?vue&type=template&id=ad663bb8&scoped=true&"
import script from "./relet_02.vue?vue&type=script&lang=js&"
export * from "./relet_02.vue?vue&type=script&lang=js&"
import style0 from "./relet_02.vue?vue&type=style&index=0&id=ad663bb8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad663bb8",
  null
  
)

export default component.exports