<template>
  <!-- 🚕底部模板 -->
  <footer>
    <section>
      <b-container>
        <div-height height="50px"/>
        <b-row>
          <b-col lg="2" md="12" class="wow slideInLeft">
            <div class="logo_img" @click="go('/')">
              <div class="logo">
                <img src="~@/assets/img/logo.png" alt="img">
              </div>
            </div>
            <div-height height="50px"/>
          </b-col>
          <b-col lg="3" md="12" class="wow zoomIn">
            <div class="vertical_box">
              <div class="vertical"></div>
              <div-height height="50px"/>
            </div>
          </b-col>
          <b-col lg="7" md="12" class="wow fadeInRight">
            <div-both br>
              <div class="foot_nav" @click="go('shop')">
                <div-text class="warep" color="#fff" size="14px">{{ $t('商城中心') }}</div-text>
              </div>
              <div class="foot_nav" @click="go('about')">
                <div-text class="warep" color="#fff" size="14px">{{ $t('关于我们') }}</div-text>
              </div>
              <div class="foot_nav" @click="go('how_to_lease')">
                <div-text class="warep" color="#fff" size="14px">{{ $t('如何租赁') }}</div-text>
              </div>
              <div class="foot_nav" @click="go('help')">
                <div-text class="warep" color="#fff" size="14px">{{ $t('联系客服') }}</div-text>
              </div>
              <!-- <div class="foot_nav" @click="go('login')" v-else>
                <div-text class="warep" color="#fff" size="14px">{{$t('联系客服')}}</div-text>
              </div> -->
              <div class="foot_nav" @click="go('wallet')">
                <div-text class="warep" color="#fff" size="14px">{{ $t('充值中心') }}</div-text>
              </div>
              <!-- <div class="foot_nav" @click="go('login')" v-else>
                <div-text class="warep" color="#fff" size="14px">{{$t('充值中心')}}</div-text>
              </div> -->
            </div-both>
            <div-height height="20px"/>
            <div>
              <div-text class="warep" color="#fff" size="14px">{{ $t('联系方式') }}：{{ info.contact }}</div-text>
              <div-height height="10px"/>
              <div-text class="warep" color="#fff" size="14px">{{ $t('公司地址') }}：{{ info.add }}</div-text>
            </div>
            <div class="social-media-list">
              <a href="https://www.facebook.com/profile.php?id=100082871215601" target="_blank">
                <img src="~@/assets/img/social-media/facebook.png" alt="Facebook">
              </a>
              <a href="https://www.instagram.com/jnacarrental/" target="_blank">
                <img src="~@/assets/img/social-media/instagram.png" alt="Instagram">
              </a>
              <a href="https://www.xiaohongshu.com/user/profile/61b642080000000010007836" target="_blank">
                <img src="~@/assets/img/social-media/xiaohongshu.png" alt="小紅書">
              </a>
              <a href="javascript:void(0)">
                <img src="~@/assets/img/social-media/line.png" alt="Line">
                <p><img src="~@/assets/img/social-media/line-qrcode.png"></p>
              </a>
              <a href="javascript:void(0)">
                <img src="~@/assets/img/social-media/wechat.png" alt="WeChat">
                <p><img src="~@/assets/img/social-media/wechat-qrcode.png"></p>
              </a>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <div class="copyright">
      <a href="#">
        <div-center br>
          <div-text color="#fff">© 2023 J&A Rentals Ltd. All rights reserved.</div-text>
        </div-center>
      </a>
    </div>
  </footer>

</template>

<script>
import {WOW} from "wowjs"

export default {
  name: "Footer",
  data() {
    return {
      login: false,
      scrollBtn: false,
      info: {},
    }
  },
  created() {
    if (localStorage.getItem("zuche_token")) {
      this.login = true
    }
    this.get_info()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },

  methods: {
    get_info() {
      this.$post({
        url: "/index/general/general",
        success: (res) => {
          this.info = res.data
        },
        tip: () => {
        },
      })
    },
    handleScroll() {
      if (window.scrollY > 70) {
        this.scrollBtn = true
      } else if (window.scrollY < 70) {
        this.scrollBtn = false
      }
    },

    scrollTop() {
      window.scrollTo(0, 0)
    },
  },
}
</script>

<style scoped lang="scss">
.social-media-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 0;

  a {
    margin-right: 10px;
    display: flex;
    position: relative;
    justify-content: center;

    img {
      width: 32px;
    }

    p {
      transition: 0.2s;
      position: absolute;
      pointer-events: none;
      box-sizing: border-box;
      top: -110px;
      display: flex;
      transform: translateY(-40px);
      padding-bottom: 10px;
      opacity: 0;

      img {
        height: 100px;
        width: auto;
      }
    }


  }

  a:hover {
    p {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
    }
  }

}

.foot_nav {
  cursor: pointer;

  .warep {
    white-space: pre-wrap; //自动换行
    word-break: normal;
  }

}

//logo
.logo {
  @include transition(0.4s);

  @media #{$xl} {
    width: 137px;
    height: 106px;
  }

  @media #{$lg} {
    width: 137px;
    height: 106px;
  }

  @media #{$md} {
    width: 137px;
    height: 106px;
  }

  @media #{$sm} {
    width: 137px;
    height: 106px;
  }

  @media #{$xs} {
    width: 137px;
    height: 106px;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

footer {
  @include transition(0.4s);
  position: relative;
  background: #1d2124;
  z-index: 10;
}

.logo_img {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.vertical_box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media #{$xl} {
    display: flex;
  }

  @media #{$lg} {
    display: flex;
  }

  @media #{$md} {
    display: none;
  }

  @media #{$sm} {
    display: none;
  }

  @media #{$xs} {
    display: none;
  }

  .vertical {
    width: 1px;
    height: 70px;
    background: #fff;
  }
}

.copyright {
  background: #000;
  color: #979797;
  text-align: center;
  line-height: 40px;
}
</style>
