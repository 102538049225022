<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒小菜单 -->
            <Menu name="help"></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🍞面包屑 -->
            <section>
              <!-- <b-container> -->
              <div-padding padding="20px 0">
                <b-row no-gutters>
                  <b-col cols="auto" class="mr-auto">
                    <div-left center>
                      <div-text color="#1D2124" size="20px" bold>{{$t('客服咨询')}}</div-text>
                    </div-left>
                  </b-col>
                  <b-col cols="auto">
                    <b-breadcrumb>
                      <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                      <b-breadcrumb-item @click="go('mine')">{{$t('我的')}}</b-breadcrumb-item>
                      <b-breadcrumb-item active>{{$t('客服咨询')}}</b-breadcrumb-item>
                    </b-breadcrumb>
                  </b-col>
                </b-row>
              </div-padding>
              <!-- </b-container> -->
            </section>

            <div-zebra></div-zebra>

            <b-card-group>
              <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                <!-- 🍌切换栏 -->
                <template v-slot:header>

                  <b-tabs v-model="active" @input="opt" pills card>
                    <b-tab :title="$t('问题反馈')"></b-tab>
                    <b-tab :title="$t('留言板')"></b-tab>
                  </b-tabs>
                  <div>
                    <b-button variant="none" @click="open_url('https://line.me/ti/p/~@365jfscs')">
                      <div-text>{{$t('下单问题')}}-LINE</div-text>
                    </b-button>
                    <el-tooltip class="item" effect="dark" content="Bottom Left 提示文字" placement="bottom">
                      <div slot="content">
                        <div-img width="320px" height="420px">
                          <img src="~@/assets/img/wx_add.jpg" alt="">
                        </div-img>
                      </div>
                      <b-button variant="none">
                        <div-text>{{$t('下单问题')}}-{{$t('微信')}}</div-text>
                        <!-- wx_add.png -->
                      </b-button>
                    </el-tooltip>

                    <b-button variant="none" @click="open_url('https://line.me/ti/p/~@365jfscs')">
                      <div-text>{{$t('其他问题')}}-LINE</div-text>
                    </b-button>

                    <el-tooltip class="item" effect="dark" content="Bottom Left 提示文字" placement="bottom">
                      <div slot="content">
                        <div-img width="320px" height="420px">
                          <img src="~@/assets/img/wx_add.jpg" alt="">
                        </div-img>
                      </div>
                      <b-button variant="none">
                        <div-text>{{$t('其他问题')}}-{{$t('微信')}}</div-text>
                        <!-- wx_add.png -->
                      </b-button>
                    </el-tooltip>

                  </div>

                </template>

                <!-- 🍉form表单 -->
                <b-card-body>
                  <fieldset class="fieldset" v-if="!active">
                    <legend>{{$t('问题类型')}}</legend>
                    <div class="down_box">
                      <b-dropdown :text="feedback_title['type_name' + $t('suffix')] || $t('请选择问题类型')">
                        <b-dropdown-item v-for="(item,index) in classif_arr" :key="index" @click="opt_class(item)">
                          {{item['type_name'+$t('suffix')]}}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </fieldset>
                  <fieldset class="fieldset">
                    <legend>{{$t('反馈内容')}}</legend>
                    <b-input-group>
                      <template v-slot:append>
                      </template>
                      <b-form-group label-for="input-3">
                        <b-form-textarea v-model="feedback_cont" required rows="5" :placeholder="$t('请输入您想说的话')"></b-form-textarea>
                      </b-form-group>
                    </b-input-group>
                  </fieldset>
                  <fieldset class="fieldset">
                    <legend>{{$t('上传图片/影片')}}</legend>
                    <div-height height="30px"></div-height>
                    <div-width size="100%">
                      <div-center>
                        <div-upvideo @list="(val)=>(feedback_enclosure=val)">
                          <div-img width="60px" height="60px">
                            <b-img v-if="feedback_enclosure&&is_img(feedback_enclosure)" :src="feedback_enclosure"></b-img>
                            <b-img v-else-if="feedback_enclosure&&!is_img(feedback_enclosure)" :src="require('@/assets/img/div/video.jpg')"></b-img>
                            <b-img v-else :src="require('@/assets/img/chuantu.png')"></b-img>
                          </div-img>
                        </div-upvideo>
                      </div-center>
                    </div-width>
                    <div-width size="100%">
                      <div-center>
                        <div-text color="#B7B7B7" center>{{$t('上传图片/影片')}}</div-text>
                      </div-center>
                    </div-width>
                    <div-height height="30px"></div-height>
                  </fieldset>
                  <!--🥒保存按钮 -->
                  <div-height height="30px"></div-height>
                  <div-save ref="btn" @save="save">{{$t('完成')}}</div-save>
                </b-card-body>
              </b-card>
            </b-card-group>

          </b-col>
        </b-row>
      </section>
      <div-height height="30px"></div-height>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      classif_arr: [], //问题类型
      feedback_type: "", //问题id
      feedback_title: "", //问题标题
      feedback_cont: "", //问题回复
      feedback_enclosure: "", //上传文件
      avatar: "",
      value: "",
      active: 0,
      message: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    is_img(url) {
      let reg = /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/
      return reg.test(url)
    },
    opt() {},
    opt_class(item) {
      this.feedback_title = item
      this.feedback_type = item.id
    },
    get_info() {
      this.$get({
        url: "/index/customerservicefeedbacktypes/idx",
        success: (res) => {
          console.log("获取问题类型", res.data.list.data)
          this.classif_arr = res.data.list.data
        },
        tip: () => {},
      })
    },
    save() {
      const loading = this.$loading({
        lock: true,
      })

      let url = ""
      let data = ""
      if (!this.active) {
        url = "index/customerservicefeedbacks/feedback"
        data = {
          feedback_type: this.feedback_type, //类型id
          feedback_cont: this.feedback_cont, //类型回馈
          feedback_enclosure: this.feedback_enclosure, //上传文件
        }
      } else {
        url = "index/customermsgboard/msg"
        data = {
          msg_cont: this.feedback_cont, //类型回馈
          msg_enclosure: this.feedback_enclosure, //上传文件
        }
      }

      this.$post({
        url: url,
        data: data,
        success: (res) => {
          this.$toast.success(res.msg, {
            timeout: 1000,
            position: "top-center",
            pauseOnHover: false,
          })

          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
            this.go("mine")
          }, 1000)
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
// 🌰下拉菜单盒子
.down_box {
  /deep/.btn {
    font-size: 14px;
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  /deep/.btn-secondary {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  /deep/.btn-secondary.dropdown-toggle {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  /deep/.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  /deep/.dropdown {
    width: 100%;
  }
  /deep/.dropdown-item.active {
    background-color: #fff;
    color: #333;
  }
  /deep/.dropdown-item:active {
    background-color: #fff;
    color: #333;
  }
  /deep/.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.dropdown-menu {
    width: 100%;
    padding: 0;
    font-size: 14px;
  }
  /deep/.dropdown-divider {
    margin: 0;
  }
  /deep/.dropdown-item {
    padding: 10px 20px;
  }
}
//🍌切换栏
.tabs {
  /deep/.card-header {
    border-bottom: 0;
    background: transparent;
  }
  /deep/.nav-link {
    font-size: 14px;
    color: #3b3b3b !important;
  }

  /deep/.active {
    color: #fff !important;
    background-color: #3b3b3b !important;
  }
  .tab-content {
    display: none;

    .active {
      display: none;
    }
  }
  .tab {
    padding: 10px 38px 10px 10px;
    cursor: pointer;
    color: #1d2124;
    font-weight: bold;
  }
}
//🍉form表单
.fieldset {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  // height: 76px;
  transition: all 0.5s;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 15px;

  &:focus-within {
    border: 1px solid #57b846;

    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }

  legend {
    transition: all 0.5s;
    position: relative;
    z-index: 1;
    color: #2f2e3f;
    font-size: 14px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
  }

  .cursor {
    cursor: pointer;
  }
  .form-group {
    width: 100%;
    margin-bottom: 0;
  }
  .form-control {
    background: #f8f8f8;
    width: 100%;
    border: 0 !important;
    font-size: 14px;
  }

  :focus {
    border-color: none;
    box-shadow: none;
  }
  textarea {
    resize: none;
  }
  .input-group {
    padding: 12px;
  }
}
</style>