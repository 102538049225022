<template>
  <div>
    <!-- 顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 左侧菜单 -->
            <Menu name="order"></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🍞面包屑 -->
            <section>
              <div-padding padding="20px 0">
                <b-row>
                  <b-col cols="auto" class="mr-auto">
                    <div-left center>
                      <div-text color="#1D2124" size="20px" bold>{{ $t('订单详情') }}</div-text>
                    </div-left>
                  </b-col>
                  <b-col cols="auto">
                    <b-breadcrumb>
                      <b-breadcrumb-item @click="go('/')">{{ $t('首页') }}</b-breadcrumb-item>
                      <b-breadcrumb-item @click="back()">{{ $t('租车订单') }}</b-breadcrumb-item>
                      <b-breadcrumb-item active>{{ $t('订单详情') }}</b-breadcrumb-item>
                    </b-breadcrumb>
                  </b-col>
                </b-row>
              </div-padding>
            </section>
            <div-zebra></div-zebra>
            <div>
              <b-card-group>
                <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                  <template v-slot:header>
                    <div-both center>
                      <b-button class="btn" variant="none">
                        <div-text>{{ $t('订单信息') }}</div-text>
                      </b-button>
                    </div-both>
                  </template>
                  <div class="item">
                    <b-row>
                      <b-col lg="3">
                        <div-padding padding="20px 0">
                          <div-img class="picbox" hover height="100%" width="100%" radius="6px">
                            <b-img height="100%" fluid :src="redun.vehicle_thumbnail"></b-img>
                          </div-img>
                        </div-padding>
                      </b-col>
                      <b-col lg="9">
                        <div-padding padding="20px 0">
                          <b-row>
                            <b-col lg="6">
                              <div-text size="15px" color="#1D2124" bold>
                                {{ redun['vehicle_name' + $t('suffix')] }}
                              </div-text>
                            </b-col>
                            <b-col lg="6">
                              <div-text size="13px" color="#1D2124" right>

                                {{ {
                                  10: $t('待付款'),
                                  15: $t('待付尾款'),
                                  20: $t('待取车'),
                                  30: $t('进行中'),
                                  35: $t('待付特殊费用'),
                                  38: $t('待验收'),
                                  40: $t('已还车'),
                                  60: $t('逾期'),
                                  100: $t('已取消'),
                                  110: $t('已退款')
                                }[original.status] }}

                              </div-text>
                            </b-col>
                          </b-row>
                          <div-height height="6px" />
                          <div-text size="13px" color="#666666">

                            {{ $t('车辆类型') }} ：{{ redun['classi_name' + $t('suffix')] }} ｜
                            {{ $t('车辆品牌') }} ：{{ redun['brand_name' + $t('suffix')] }} ｜
                            {{ $t('车辆型号') }} ：{{ redun['model_name' + $t('suffix')] }} ｜
                            {{ $t('车辆年份') }} ：{{ redun['life_name' + $t('suffix')] }}

                          </div-text>
                          <div-height height="10px" />
                        </div-padding>
                      </b-col>
                    </b-row>
                  </div>
                  <div-padding padding="0 20px">
                    <!-- 表单1 -->
                    <b-row no-gutters class="table">
                      <!-- 第二行 -->
                      <b-col lg="2" md="6">
                        <label class="label">{{ $t('租赁方式') }}</label>
                      </b-col>
                      <b-col lg="10" md="6">
                        <div class="form-control ">{{ { 1: $t('日租'), 2: $t('月租') }[original.lease_mode] }}</div>
                      </b-col>
                      <!-- 第一行 -->
                      <b-col lg="2" md="6">
                        <label class="label">{{ $t('取车方式') }}</label>
                      </b-col>
                      <b-col lg="4" md="6">
                        <div class="form-control no_border">{{ { 1: $t('到店自提'), 2: $t('代驾到府') }[original.pickup_mode] }}
                        </div>
                      </b-col>
                      <b-col lg="2" md="6">
                        <label class="label">{{ $t('取车时间') }}</label>
                      </b-col>
                      <b-col lg="4" md="6">
                        <div class="form-control ">{{ original.pickup_time }}</div>
                      </b-col>
                      <!-- 第三行 -->
                      <b-col lg="2" md="6" v-if="original.pickup_mode == 2">
                        <label class="label">{{ $t('取车地点') }}</label>
                      </b-col>
                      <b-col lg="10" md="6" v-if="original.pickup_mode == 2">
                        <div class="form-control ">{{ original.f_chauffeur_service_detail_place }}</div>
                      </b-col>
                      <!-- 第四行 -->
                      <b-col lg="2" md="6">
                        <label class="label">{{ $t('还车方式') }}</label>
                      </b-col>
                      <b-col lg="4" md="6">
                        <div class="form-control no_border">{{ { 1: $t('到店自提'), 2: $t('代驾到府') }[original.return_mode] }}
                        </div>
                      </b-col>
                      <b-col lg="2" md="6">
                        <label class="label">{{ $t('还车时间') }}</label>
                      </b-col>
                      <b-col lg="4" md="6">
                        <div class="form-control">{{ original.return_time }}</div>
                      </b-col>
                      <!-- 第五行 -->
                      <b-col lg="2" md="6" v-if="original.return_mode == 2">
                        <label class="label">{{ $t('还车地点') }}</label>
                      </b-col>
                      <b-col lg="10" md="6" v-if="original.return_mode == 2">
                        <div class="form-control no_border">{{ original.r_chauffeur_service_detail_place }}</div>
                      </b-col>
                      <!-- 第六行 -->
                      <b-col lg="2" md="6" v-if="original.is_super_car!=1">
                        <label class="label">{{ $t('汽车保险') }}</label>
                      </b-col>
                      <b-col lg="4" md="6" v-if="original.is_super_car!=1">
                        <div class="form-control no_border">
                          {{ { 1: $t("500元方案"), 2: $t('2500元方案') }[original.carbody_ins_type] }}</div>
                      </b-col>
                      <b-col lg="2" md="6" v-if="original.is_super_car!=1">
                        <label class="label">{{ $t('新车险') }}</label>
                      </b-col>
                      <b-col lg="4" md="6" v-if="original.is_super_car!=1">
                        <div class="form-control">{{ { 1: $t('是'), 2: $t('否') }[original.warranty_ins_sel] }}</div>
                      </b-col>

                      <b-col lg="2" md="6" v-if="original.is_super_car==1">
                        <label class="label">{{ $t('超跑保险') }}</label>
                      </b-col>
                      <b-col lg="10" md="6" v-if="original.is_super_car==1">
                        <div class="form-control">{{ $t('是') }}</div>
                      </b-col>

                    </b-row>
                  </div-padding>
                </b-card>
              </b-card-group>
            </div>
            <div-height height="20px" />

            <el-collapse accordion v-model="activeNames">
              <el-collapse-item name="1">
                <template slot="title">
                  <div style="width:100%">
                    <div-text color="#1D2124" size="20px" bold>{{ $t('租赁明细') }}</div-text>
                    <div-zebra></div-zebra>
                  </div>
                </template>
                <div class="table_box">
                  <table style="width: 100%;">
                    <colgroup style="width: 33.33%;"></colgroup>
                    <colgroup style="width: 33.33%;"></colgroup>
                    <colgroup style="width: 33.33%;"></colgroup>
                    <tr>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('租车费用') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('租赁天数') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.pure_rental_fee }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="4" colspan="1" align="center" valign="middle">
                        <div>{{ $t('附加费用') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('取车地点') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.f_chauffeur_service_fee }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('取车时间') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle" style="border-width: 0px 1px 1px 1px;">
                        <div>CAD {{ original.return_holiday_fee }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('还车地点') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.r_chauffeur_service_fee }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('还车时间') }}</div>
                      </td>
                      <td rowspan="1" colspan="3" align="center" valign="middle" style="border-width: 0px 1px 1px 1px;">
                        <div>CAD {{ original.return_holiday_fee }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>{{ $t('租车费用小计') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.rental_fee_b_tax }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>
                          {{ $t('税金费用') }}
                          {{$t('locale')=='en'?'(GST':''}}
                          {{ $np.times(+original.tax_rate_pst, 100)}}%
                          {{$t('locale')=='en'?')':''}}
                        </div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.tax_pst }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>
                          {{ $t('税金费用') }}
                          {{$t('locale')=='en'?'(HST':''}}
                          {{ $np.times(+original.tax_rate_gst, 100)}}%
                          {{$t('locale')=='en'?')':''}}
                        </div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.tax_gst }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>{{ $t('租车总计金额费用') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.rental_fee_a_tax }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="2" colspan="1" align="center" valign="middle">
                        <div>{{ $t('押金费用') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('驾照押金') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.license_deposit }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('租车押金') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.rental_deposit }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>{{ $t('押金总金额') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.deposit_fee }}</div>
                      </td>
                    </tr>
                    <!-- 不是超跑 -->
                    <tr v-if="original.is_super_car == 2">
                      <td rowspan="2" colspan="1" align="center" valign="middle">
                        <div>{{ $t('保险费用') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('汽车保险') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.carbody_ins_fee }}</div>
                      </td>
                    </tr>
                    <tr v-if="original.is_super_car == 2">
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('新车险') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.warranty_ins_fee }}</div>
                      </td>
                    </tr>

                    <!--是超跑-->
                    <tr v-if="original.is_super_car == 1">
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('保险费用') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('超跑险') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.supercar_ins_fee }}</div>
                      </td>
                    </tr>

                    <tr>
                      <td rowspan="2" colspan="1" align="center" valign="middle">
                        <div>{{ $t('其他附加费用') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('未满25岁') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.attach_age_lt25_fee }}</div>
                      </td>
                    </tr>
                    <tr>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>{{ $t('非加拿大驾照') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.attach_not_cad_license_fee }}</div>
                      </td>
                    </tr>

                    <tr class="th">
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>{{ $t('保险/其他费用小计') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.ins_and_attach_fee }}</div>
                      </td>
                    </tr>
                    <tr class="th">
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>{{ $t('总计金额') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.total }}</div>
                      </td>
                    </tr>
                    <tr class="th">
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>{{ $t('定金金额') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.forepay_fee }}</div>
                      </td>
                    </tr>
                    <tr class="th">
                      <td rowspan="1" colspan="2" align="center" valign="middle">
                        <div>{{ $t('尾款金额') }}</div>
                      </td>
                      <td rowspan="1" colspan="1" align="center" valign="middle">
                        <div>CAD {{ original.tailpay_fee }}</div>
                      </td>
                    </tr>
                  </table>
                </div>
              </el-collapse-item>
            </el-collapse>

            <div-height height="20px" />

            <!-- 标题 -->
            <div-padding padding="20px 0">
              <div-text color="#1D2124" size="20px" bold>{{ $t('租车合同') }}</div-text>
            </div-padding>

            <div-zebra></div-zebra>

            <div class="zebra_box shadow">
              <div-both center br>
                <div-left center>
                  <div-text color="#1D2124" size="26px" bold>{{ $t('沛豪国际租赁有限公司租赁合同') }}</div-text>
                </div-left>
                <div-right center>
                  <div-text color="#B7B7B7" @click.native="down_img()" size="18px" class="a_btn">
                    {{ $t('预览合同') }}
                  </div-text>
                </div-right>
              </div-both>
            </div>

            <!-- 10-待付款;20-待取車;30-進行中; 35-待付特殊费用;38-待验收; 40-完成（已还车）;60-逾期;100-已取消;110-已退款 -->

            <div>
              <div v-if="original.status >= 10">
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('订单状态') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">
                    {{ {
                      10: $t('待付款'), 15: $t('待付尾款'), 20: $t('待取车'), 30: $t('进行中'), 35: $t('待付特殊费用'),
                      38: $t('待验收'), 40: $t('已还车'), 60: $t('逾期'), 100: $t('已取消'), 110: $t('已退款')
                    }[original.status] }}
                  </div-text>
                </div-both>
              </div>
              <!-- 待付款 -->
              <div v-if="original.status >= 10">
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('订单单号') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.no }}</div-text>
                </div-both>
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('创建订单时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.create_time }}</div-text>
                </div-both>
              </div>

              <!-- 已取消 -->

              <div v-if="original.status == 100">
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('取消时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.cancel_time }}</div-text>
                </div-both>
              </div>

              <div v-if="[110, 15, 20, 30, 35, 38, 40, 60].includes(original.status)">
                <div-height height=" 20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('支付时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.pay_time }}</div-text>
                </div-both>
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('支付方式') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ $t('余额') }}</div-text>
                </div-both>
              </div>

              <div v-if="original.status == 15 && original.pay_rest_show != 1 && !original.renew_p">
                <div-height height=" 20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('尾款单') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ $t('管理员审核中') }}</div-text>
                </div-both>
              </div>

              <div v-if="[20, 30, 35, 38, 40, 60].includes(original.status) && !original.renew_p">
                <div-height height=" 20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('尾款支付时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.pay_rest_time }}</div-text>
                </div-both>
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('尾款支付方式') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ $t('余额') }}</div-text>
                </div-both>
              </div>

              <!-- 已退款 -->

              <div v-if="original.status == 110">

                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('退款时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ $t('空') }}</div-text>
                </div-both>
              </div>

              <!-- 進行中 -->

              <div v-if="[30, 35, 38, 40, 60].includes(original.status) && !original.renew_p">
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('取车时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.dispatch_time }}</div-text>
                </div-both>
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('车牌号码') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ redun.rela_car_no }}</div-text>
                </div-both>
              </div>

              <div v-if="[35, 38, 40, 60].includes(original.status) && the_isz == 'true'">

                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('特殊费用') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.special_fee }}</div-text>
                </div-both>
              </div>

              <div v-if="[38, 40, 60].includes(original.status) && the_isz == 'true'">

                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('特殊费用支付时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.pay_special_time }}</div-text>
                </div-both>
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('特殊费用支付方式') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ $t('余额') }}</div-text>
                </div-both>

              </div>

              <div v-if="[38].includes(original.status)">
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('待验收') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ $t('等待管理员验收') }}</div-text>
                </div-both>

              </div>

              <!-- 逾期中 -->

              <div v-if="original.status == 60">
                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('逾期状态') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ $t('逾期中') }}</div-text>
                </div-both>
              </div>

              <div v-if="original.status == 40">

                <div-height height="20px" />
                <div-both center>
                  <div-text color="#1D2124" size="15px">{{ $t('还车时间') }}</div-text>
                  <div-text color="#B7B7B7" size="15px">{{ original.checkin_time }}</div-text>
                </div-both>
              </div>

             <div v-if="(original.status == 15 && original.pay_rest_show == 1 && !original.renew_p) || original.status == 35">
              <div-height height="20px" />
            <div-right center style="width:100%;display: block;text-align: center;">
              <b-form-group>
                <b-form-radio-group id="radio-group-1" v-model="selected"
                  name="radio-options">
                  <b-form-radio value='1'>{{  $t('在线支付') }}</b-form-radio>
                  <b-form-radio value='2'>{{  $t('线下支付') }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div-right>
          </div>
            <div-height height="50px" />

              <div-save v-if="original.status == 10 && original.type == 1" @save="go('rent_pay', { id: id, forepay_fee: original.forepay_fee })">{{
                  $t('支付订单')
                }}</div-save>

              <div-save v-if="original.status == 15 && original.pay_rest_show == 1 && !original.renew_p" @save="selected == 2?gopay():go('rent_pay01', { id: id, forepay_fee: original.tailpay_fee })">{{ $t('尾款支付') }}</div-save>

              <!-- 进行中的可以续租 -->
              <div-save v-if="original.status == 30" @save="go('relet_01', { id: original.vehicle_id, renew_id: id,selected:selected })">
                {{ $t('续租') }}
              </div-save>

              <div-save v-show=" original.status == 35" @save="selected == 2?gopayofflineSpecial():go('rent_pay02', { id: id, forepay_fee: original.special_fee })">{{
                  $t('特殊费用支付')
                }}</div-save>

            </div>

          </b-col>

        </b-row>
      </section>
      <div-height height="30px" />
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      the_isp: "false", //是否是特殊费用支付
      the_isz: "false", //是否是特殊费用订单
      original: {},
      redun: {},
      token: localStorage.getItem("zuche_token"),
      activeNames: ["1"], //折叠面板
      selected:1
    }
  },
  created() {
    this.urlparam()
    this.get_info()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/rentalorders/pagedata",
        data: {
          id:this.id
        },
        success: (res) => {
          //获取详情
          console.log(res)
          this.original = res.data.original
          this.redun = res.data.original.redun
        },
        tip: () => {},
      })
    },

    gopay(){
      this.$post({
        url: "/index/rentalorders/offlineRest",
        data: {
          rental_order_id: this.id
        },
        success: (res) => {
          this.go("mine")
        },
        tip: () => {},
      })
    },
    gopayofflineSpecial(){
      this.$post({
        url: "/index/rentalorders/offlineSpecial",
        data: {
          rental_order_id: this.id
        },
        success: (res) => {
          this.go("mine")
        },
        tip: () => {},
      })
    },
  
    down_img() {
      //未签字预览合同
      let url = this.httpPath + "/index/rentalorders/contractPreviewDetail"

      let obj = {
        token: this.token,
        rental_order_id: this.id,
      }

      let new_obj = this.convertObj(obj)

      console.log("新对象", url + "?" + new_obj)

      window.open(url + "?" + new_obj)
    },

    convertObj(params) {
      let paramStr = ""
      Object.keys(params).forEach((item) => {
        if (paramStr === "") {
          paramStr = `${item}=${params[item]}`
        } else {
          paramStr = `${paramStr}&${item}=${params[item]}`
        }
      })
      console.log(paramStr)
      return paramStr
    },
  },
}
</script>
<style lang="scss" scoped>
.picbox {
  position: relative;
}

.picbox:before {
  content: "";
  display: block;
  padding-top: 70%; //宽高比例修改这个数值
}

.picbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-header {
  background: #fff !important;
}

.a_btn {
  cursor: pointer;
}

.image {
  min-height: 120px;
}

.table_box {
  // padding: 20px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

//商品信息
.item {
  padding: 0 20px;
  // cursor: pointer;
  // &:hover {
  //   background: #f5f5f5;
  // }
}

.zebra_box {
  padding: 1.25rem;
}

//表格样式
.form-control {
  height: 70px;
  border-radius: 0;
  font-size: 15px;
  box-shadow: none;
  padding: 0 30px;
  border: 1px solid #d8d8d8;
  line-height: 70px;
  background: #ffffff;
  // color: #707e9c;
  text-align: center;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;
  overflow: visible;
  font-size: 13px;
}

.form-control:focus {
  position: relative;
  z-index: 1;
  // border: 1px solid #d8d8d8;
  border-color: #57b846;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.label {
  height: 70px;
  border-radius: 0;
  font-size: 15px;
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  line-height: 70px;
  text-align: center;
  background: rgba(215, 215, 215, 0.1);

  text-align: center;

  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;
  // background-color: #fff;
  overflow: visible;
  font-size: 13px;
  color: #1d2124;
  font-weight: bold;
  // color: #93837b;
}

@media #{$xl} {
  label {
    border: 1px solid #d8d8d8;
  }

  .avatar {
    border-right-width: 0;
  }

  .no_border {
    border-right-width: 0;
  }
}

@media #{$lg} {
  label {
    border: 1px solid #d8d8d8;
  }
}

@media #{$md} {
  label {
    border: 1px solid #d8d8d8;
  }
}

@media #{$sm} {
  .table {
    border: none;
  }

  .form-control {
    text-align: left;
    border-radius: 4px;
  }

  .label {
    text-align: left;
    border: none;
    background: #fff;
    padding: 0;
    margin-top: 0;
  }

  .text {
    border: none;
  }
}

@media #{$xs} {
  .table {
    border: none;
  }

  .label {
    text-align: left;
    border: none;
    background: #fff;
    padding: 0;
    margin-top: 0;
  }

  .form-control {
    text-align: left;
    border-radius: 4px;
  }

  .text {
    border: none;
    align-items: center;
    padding: 0 30px;
    display: flex;
    height: 100px;
    line-height: 30px;
  }
}

// /*------------------------------------------------------------------
table {
  // 1px solid #d8d8d8
  border: 1px solid #d8d8d8;
}

td {
  height: 70px;
  line-height: 70px;
  color: #818181;
  border: 1px solid #d8d8d8;
  font-size: 14px;
}

.th {
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
}

.btn_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;

  .btn {
    max-width: 500px;
    font-size: 15px;
    line-height: 1.5;
    color: #fff;
    text-transform: capitalize;
    width: 100%;
    height: 70px;
    border-radius: 8px;
    background: #333333;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    transition: all 0.4s;
  }

  .btn:hover {
    background: #57b846;
    color: #fff;
  }
}
/deep/.custom-control{
  width: 50%;
  margin-right: 0;
}
/deep/.custom-control-label{
  cursor: pointer;
}
</style>