<template>
  <div class="div-save">
    <div class="btn_box">
      <button type="button" @click="do_fun" class="btn" :class="{ auto }" :disabled="disabled">
        <slot></slot>
        <span class="shape"></span>
      </button>
    </div>
  </div>
</template>
    <script>
export default {
  name: "div-save",
  props: {
    auto: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "#fff",
    },
    bg: {
      type: String,
      default: "#333333",
    },
    radius: {
      type: String,
      default: "4px",
    },
    height: {
      type: String,
      default: "50px",
    },
    border: {
      type: String,
      default: "",
    },
    save: {
      type: Function,
      default: () => {
        return Function
      },
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  created: function () {},
  methods: {
    do_fun() {
      if (this.disabled) {
        return false
      }
      this.disabled = true
      console.log("内部调用了", this.save)
      // this.fun()
      this.$emit("save")
    },
  },
}
</script>

<style lang="scss" scoped>
.div-save {
  width: 100%;
  .btn_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // padding-top: 40px;

    .btn {
      max-width: 500px;
      font-size: 15px;
      line-height: 1.5;
      color: #fff;
      text-transform: capitalize;

      width: 100%;
      height: 50px;
      border-radius: 0.31rem;
      background: #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 25px;

      -webkit-transition: all 0.4s;
      -o-transition: all 0.4s;
      -moz-transition: all 0.4s;
      transition: all 0.4s;
    }

    .btn:hover {
      // background: #57b846;
      color: #fff;
    }

    /* button style */
    .btn {
      user-select: none;
      -moz-user-select: none;
      // background: #3657cd none repeat scroll 0 0;
      border: none;
      border-radius: 0;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      letter-spacing: 1px;
      // line-height: 1.35;
      margin-bottom: 0;
      // padding: 20px 30px;
      text-align: center;
      text-transform: capitalize;
      touch-action: manipulation;
      transition: all 0.3s ease 0s;
      vertical-align: middle;
      font-family: "Cuprum", sans-serif;
      white-space: nowrap;
      position: relative;
      z-index: 1;
      overflow: hidden;
    }

    .btn > .text {
      display: flex;
      align-items: baseline;
      line-height: 1;
      color: #fff;
      transition: all 0.3s ease 0s;
    }

    .btn > .text::after {
      content: "\f061";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      margin-left: 9px;
      line-height: 1;
      font-size: 13px;
      transition: all 0.3s ease 0s;
      color: #fff;
    }

    .btn:hover > .text::after {
      transform: translateX(5px);
    }

    .btn:hover {
      color: #fff;
    }

    .newsletter-form [type="submit"]:hover,
    .btn:hover > .text,
    .btn:hover > .text::after {
      color: #00172f;
    }

    .btn > .shape {
      background: #57b846 none repeat scroll 0 0;
      border-radius: 50%;
      display: block;
      height: 0;
      position: absolute;
      transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transition: width 0.6s ease 0s, height 0.6s ease 0s;
      -moz-transition: width 0.6s ease 0s, height 0.6s ease 0s;
      -webkit-transition: width 0.6s ease 0s, height 0.6s ease 0s;
      -ms-transition: width 0.6s ease 0s, height 0.6s ease 0s;
      -o-transition: width 0.6s ease 0s, height 0.6s ease 0s;
      width: 0;
      z-index: -1;
      top: 50%;
      left: 50%;
    }

    .btn:hover > .shape {
      height: 562.5px;
      width: 562.5px;
    }
  }
}
</style>
