<template>
    <div class="zebra"></div>
</template>
<script>
export default {
    components: {},
    props: {},
    data() {
        return {
        };
    },
    created() {
    },

    methods: {
    },
};

</script>
<style lang='scss' scoped>
.zebra {
    width: 100%;
    height: 3px;
    background-image: linear-gradient(135deg,
            #1d2124 0%,
            #1d2124 1.25rem,
            transparent 1.25rem,
            transparent 1.5rem,
            #1d2124 1.5rem,
            #1d2124 1.8rem,
            transparent 1.8rem,
            transparent 2.2rem,
            #1d2124 2.2rem,
            #1d2124 2.4rem,
            transparent 2.4rem,
            transparent 2.9rem,
            #1d2124 2.9rem,
            #1d2124 3.15rem,
            transparent 3.15rem,
            transparent 3.6rem,
            #1d2124 3.6rem,
            #1d2124 3.7rem,
            transparent 3.7rem,
            transparent 4.4rem,
            #1d2124 4.4rem,
            #1d2124 100%);
    background-repeat: no-repeat;
}
</style>
  