<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒小菜单 -->
            <Menu></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🍞面包屑 -->
            <section>
              <b-container>
                <div-padding padding="20px 0">
                  <b-row no-gutters>
                    <b-col cols="auto" class="mr-auto">
                      <div-left center>
                        <div-text color="#1D2124" size="20px" bold>{{$t('汽车驾照')}}</div-text>
                      </div-left>
                    </b-col>
                    <b-col cols="auto">
                      <b-breadcrumb>
                        <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                        <b-breadcrumb-item @click="go('mine')">
                          {{$t('我的')}}
                        </b-breadcrumb-item>
                        <b-breadcrumb-item active>{{$t('汽车驾照')}}</b-breadcrumb-item>
                      </b-breadcrumb>
                    </b-col>
                  </b-row>
                </div-padding>
              </b-container>
            </section>

            <div-zebra></div-zebra>

            <div class="table_box">

              <!-- 通过id 判断国家 如果id是1 为加拿大 2为 国际 3.为中国 -->
              <div class="opt_box">

                <div class="item" center v-for="item in opt" :key="item.id">
                  <div @click="opt_id(item.id)">
                    <div-left center class="radio" br>
                      <div-img width="16px" height="16px">
                        <img v-if="item.id==driving_license_type" src="~@/assets/img/icon/radio02.png">
                        <img v-else src="~@/assets/img/icon/radio01.png">
                      </div-img>
                      <div-width size="10px"></div-width>
                      <div>{{item['type_name'+$t('suffix')]}}</div>
                      <div-width size="10px"></div-width>
                      <el-tooltip class="item" effect="dark" :content="item['type_tips'+$t('suffix')]" placement="top-start">
                        <div-img width="16px" height="16px">
                          <img src="~@/assets/img/icon/what.png">
                        </div-img>
                      </el-tooltip>
                      <div-width size="20px"></div-width>
                    </div-left>
                  </div>
                </div>

              </div>

              <div-height height="30px" />

              <div class="upload_box">

                <div class="the_img">
                  <div-updata @list="(val)=>(license_f=val)">
                    <div class="upload_file">
                      <div class="icon">
                        <img src="~@/assets/img/upimg_icon.png" alt="upimg_icon">
                      </div>
                      <div-img width="230px" height="151px">
                        <b-img thumbnail v-if="license_f" :src="license_f"></b-img>
                        <b-img thumbnail v-else :src="require('@/assets/img/jiashizheng.png')"></b-img>
                      </div-img>
                    </div>
                  </div-updata>
                  <div-width size="100%">
                    <div-center>
                      <div-text center>{{$t('上传驾驶证主页')}}</div-text>
                    </div-center>
                  </div-width>
                </div>

                <div class="the_img">
                  <div-updata @list="(val)=>(license_b=val)">
                    <div class="upload_file">
                      <div class="icon">
                        <img src="~@/assets/img/upimg_icon.png" alt="upimg_icon">
                      </div>
                      <div-img width="230px" height="151px">
                        <b-img thumbnail v-if="license_b" :src="license_b"></b-img>
                        <b-img thumbnail v-else :src="require('@/assets/img/jiashizheng.png')"></b-img>
                      </div-img>
                    </div>
                  </div-updata>
                  <div-width size="100%">
                    <div-center>
                      <div-text center>{{$t('上传驾驶证副页')}}</div-text>
                    </div-center>
                  </div-width>
                </div>

                <div class="the_img" v-if="driving_license_type==3">
                  <div-updata @list="(val)=>(license_enclosure=val)">
                    <div class="upload_file">
                      <div class="icon">
                        <img src="~@/assets/img/upimg_icon.png" alt="upimg_icon">
                      </div>
                      <div-img width="230px" height="151px">
                        <b-img thumbnail v-if="license_enclosure" :src="license_enclosure"></b-img>
                        <b-img thumbnail v-else :src="require('@/assets/img/jiashizheng.png')"></b-img>
                      </div-img>
                    </div>
                  </div-updata>
                  <div-width size="100%">
                    <div-center>
                      <div-text center>{{$t('上传驾驶证附件')}}</div-text>
                    </div-center>
                  </div-width>
                </div>

              </div>

              <div-height height="30px" />

              <div-save ref="btn" @save="save">{{$t('完成')}}</div-save>

            </div>
          </b-col>

        </b-row>
      </section>
    </b-container>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      driving_license_type: "",
      opt: [],
      active: "",
      license_f: "",
      license_b: "",
      license_enclosure: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    opt_id(id) {
      if (!this.active) {
        this.driving_license_type = id
      }
    },
    get_info() {
      this.$get({
        url: "/index/users/licenseDetails",
        success: (res) => {
          console.log("驾驶证页面信息获取", res)
          let obj = res.data
          this.opt = obj.licenseTypes

          this.driving_license_type = obj?.uinfo?.driving_license_type
          this.active = this.driving_license_type || 0
          console.log("驾驶舱类型", this.driving_license_type)
          // Object.keys(obj).map((item) => (this[item] = obj[item]))

          this.license_f = obj?.uinfo?.license_f
          this.license_b = obj?.uinfo?.license_b
          this.license_enclosure = obj?.uinfo?.license_enclosure
        },
        tip: () => {},
      })
    },
    save() {
      const loading = this.$loading({
        lock: true,
      })

      let that = this

      let data = { ...this.$data }

      console.log("data", data)

      this.$post({
        url: "/index/users/drivingLicenseUpd",
        data: data,
        success: (res) => {
          this.$toast.success(res.msg, {
            timeout: 1000,
            position: "top-center",
            pauseOnHover: false,
          })

          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
            this.go("mine")
          }, 1000)

          //存储新的用户信息
          // localStorage.setItem("zuche_user", JSON.stringify(data))
          // this.get_info()
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
//单选按钮样式
.radio {
  cursor: pointer;
}
.table_box {
  padding: 30px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 0;
}

.opt_title {
  // height: 70px;
  // line-height: 70px;
}

.opt_box {
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // width: 100%;
  .item {
    line-height: 40px;
  }
}

@media #{$xl} {
  .opt_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .item {
      line-height: 40px;
    }
  }
}

@media #{$lg} {
}

@media #{$md} {
}

@media #{$sm} {
}

@media #{$xs} {
}

.upload_box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  // padding: 10px;

  .upload_file {
    width: 230px;
    height: 151px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .icon {
      position: absolute;
      width: 56px;
      height: 56px;
    }
  }

  .up_text {
    width: 100%;
    text-align: center;
  }

  .the_img {
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 30px;
  }

  @include transition(0.4s);

  @media #{$xl} {
    flex-direction: row;
    .line {
      display: block;
    }
  }

  @media #{$lg} {
    flex-direction: row;

    .line {
      display: block;
    }
  }

  @media #{$md} {
    flex-direction: column;

    .line {
      display: none;
    }
  }

  @media #{$sm} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }

  @media #{$xs} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }
}

@media #{$xl} {
  .opt_title {
    display: none;
  }
  .opt_box {
    box-shadow: none;
  }
  .bv-no-focus-ring {
    display: flex;
    align-items: center;
  }
}

@media #{$lg} {
  .opt_title {
    display: none;
  }
  .opt_box {
    box-shadow: none;
  }
}

@media #{$md} {
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}

@media #{$sm} {
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}

@media #{$xs} {
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}
</style>