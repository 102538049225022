<template>
  <div class="more_box">
    <div class="more" @click="go(to)">
      <div class="text">MORE</div>
      <div class="img">
        <img src="~@/assets/img/gengduo.png" alt="more_img">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "More",
  props: {
    to: {
      type: String,
      default: "",
    },
  },
  data() {
    return {}
  },
  methods: {},
  created() {},
}
</script>
<style lang="scss" scoped>
//============================ more text End ==================================

.more_box {
  display: flex;
  justify-content: center;
  align-items: center;

  .more {
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    .text {
      color: #595a5f;
      font-size: 20px;
      font-weight: bold;
    }

    .img {
      width: 34px;
      height: 16px;

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>