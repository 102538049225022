<template>
  <!-- hs Navigation Start -->
  <div class="hs_navigation_header_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
          <!-- <div class="hs_logo_wrapper d-none d-sm-none d-xs-none d-md-block">
            <img src="~@/assets/img/logo_dark.png" class="img-responsive" alt="logo" title="Logo" />
          </div> -->

          <div class="logo">
            <b-img :src="require('@/assets/img/logo.png')" fluid alt="logo"></b-img>
          </div>

        </div>
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
          <!-- <div class="hs_navi_cart_wrapper  d-none d-sm-none d-xs-none d-md-block d-lg-block d-xl-block">
            <div class="dropdown-wrapper menu-button menu_button_end">
              <a class="menu-button" href="#"><i class="flaticon-shopping-cart"></i><span>3</span></a>
              <div class="drop-menu">
                <div class="cc_cart_wrapper1">
                  <div class="cc_cart_img_wrapper">
                    <img src="images/cart_img.jpg" alt="cart_img" />
                  </div>
                  <div class="cc_cart_cont_wrapper">
                    <h4><a href="#">Car</a></h4>
                    <p>Quantity : 2 × $45</p>
                    <h5>$90</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                  </div>
                </div>
                <div class="cc_cart_wrapper1 cc_cart_wrapper2">
                  <div class="cc_cart_img_wrapper">
                    <img src="images/cart_img.jpg" alt="cart_img" />
                  </div>
                  <div class="cc_cart_cont_wrapper">
                    <h4><a href="#">Car</a></h4>
                    <p>Quantity : 2 × $45</p>
                    <h5>$90</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                  </div>
                </div>
                <div class="cc_cart_wrapper1">
                  <div class="cc_cart_img_wrapper">
                    <img src="images/cart_img.jpg" alt="cart_img" />
                  </div>
                  <div class="cc_cart_cont_wrapper">
                    <h4><a href="#">Car</a></h4>
                    <p>Quantity : 2 × $45</p>
                    <h5>$90</h5>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                  </div>
                  <div class="checkout_btn_resto"> <a href="car_checkout.html">Checkout</a>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <nav class="hs_main_menu d-none d-sm-none d-xs-none d-md-block">
            <ul>
              <li>
                <div class="dropdown-wrapper menu-button">
                  <a class="menu-button" href="#">首页</a>
                  <div class="drop-menu">
                    <a class="menu-button" href="">Home-I</a>
                    <a class="menu-button" href="">Home-II</a>
                  </div>
                </div>
              </li>
              <li>
                <div class="dropdown-wrapper menu-button">
                  <a class="menu-button" href="#">汽车租赁</a>
                </div>
              </li>
              <li> <a class="menu-button single_menu" href="">新车到店</a>
              </li>
              <li> <a class="menu-button single_menu" href="">商城中心</a>
              </li>
              <li> <a class="menu-button single_menu" href="">关于我们 </a>
              </li>
              <li>
                <div class="dropdown-wrapper menu-button"> <a class="menu-button" href="#">Blog</a>
                  <div class="drop-menu">
                    <a class="menu-button" href="blog_category.html">Blog-Categories</a>
                    <a class="menu-button" href="blog_single.html">Blog-Single</a>
                  </div>
                </div>
              </li>
              <li> <a class="menu-button single_menu" href="contact.html">Contact </a>
              </li>
            </ul>
          </nav>
          <header class="mobail_menu d-none d-block d-xs-block d-sm-block d-md-none d-lg-none d-xl-none">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xs-6 col-sm-6 col-6">
                  <div class="cd-dropdown-wrapper">
                    <a class="house_toggle" @click="show=!show">
                      展开
                    </a>
                    <!-- .cd-dropdown -->
                  </div>
                  <nav class="cd-dropdown" :class="{'dropdown-is-active':show}">
                    <h2><a href="index.html">Xpedia</a></h2>
                    <a href="#0" class="cd-close">Close</a>
                    <ul class="cd-dropdown-content">
                      <li>
                        <form class="cd-search">
                          <input type="search" placeholder="Search...">
                        </form>
                      </li>
                      <li class="has-children"> <a href="#">Home</a>
                        <ul class="cd-secondary-dropdown is-hidden">
                          <li class="go-back"><a href="#0">Menu</a>
                          </li>
                          <li> <a href="index.html">Home-I</a>
                          </li>
                          <!-- .has-children -->
                          <li> <a href="index_II.html">Home_II</a>
                          </li>
                          <!-- .has-children -->
                        </ul>
                        <!-- .cd-secondary-dropdown -->
                      </li>
                      <li class="has-children"> <a href="#">Car</a>
                        <ul class="cd-secondary-dropdown is-hidden">
                          <li class="go-back"><a href="#0">Menu</a>
                          </li>
                          <li> <a href="car_accessories.html">Car-Accessories</a>
                          </li>
                          <li> <a href="car_booking.html">Car-Booking</a>
                          </li>
                          <li><a href="car_booking_done.html">Car-Booking-Done</a>
                          </li>
                          <li><a href="car_checkout.html">Car-Checkout</a>
                          </li>
                          <li><a href="car_detail_left.html"> Car-Detail-Left</a>
                          </li>
                          <li><a href="car_detail_right.html"> Car-Detail-Right</a>
                          </li>
                          <!-- .has-children -->
                        </ul>
                        <!-- .cd-secondary-dropdown -->
                      </li>
                      <li> <a href="about.html">About</a>
                      </li>
                      <li> <a href="team.html">Our Team</a>
                      </li>
                      <li> <a href="services.html">Services</a>
                      </li>
                      <li class="has-children"> <a href="#">Blog</a>
                        <ul class="cd-secondary-dropdown is-hidden">
                          <li class="go-back"><a href="#0">Menu</a>
                          </li>
                          <li> <a href="blog_category.html">Blog Categories</a>
                          </li>
                          <!-- .has-children -->
                          <li> <a href="blog_single.html">Blog Single</a>
                          </li>
                          <!-- .has-children -->
                        </ul>
                        <!-- .cd-secondary-dropdown -->
                      </li>
                      <li> <a href="contact.html">Contact</a>
                      </li>
                    </ul>
                    <!-- .cd-dropdown-content -->
                  </nav>
                </div>
              </div>
            </div>
            <!-- .cd-dropdown-wrapper -->
          </header>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      show: false,
      lang_arr: {
        en: "English",
        cn: "简体中文",
        tw: "繁體中文",
      },
      lang_now: "繁體中文",
      mobileToggle: false,
      login: false,
      user_name: "张三",
    }
  },
  created() {
    this.lang_now = this.lang_arr[this.$i18n.locale]
    if (localStorage.getItem("token")) {
      this.login = true
    }
  },
  methods: {
    // 导航栏点击事件
    // handleNavbarClick(item) {
    //   this.lang_now = item
    // },
    Language(item) {
      this.$i18n.locale = item
      localStorage.setItem("lang", item)
      this.lang_now = this.lang_arr[item]
    },
  },
}
</script>
<style scoped lang="scss">
//logo
.logo {
  max-width: 121px;
  height: 80px;
  transition: all 0.4s;

  img {
    height: 100%;
  }
}

@media #{$xl} {
  .logo {
    height: 80px;
  }
}

@media #{$lg} {
  .logo {
    height: 80px;
  }
}

@media #{$md} {
  .logo {
    height: 48px;
  }
}

@media #{$sm} {
  .logo {
    height: 48px;
  }
}

@media #{$xs} {
  .logo {
    height: 48px;
  }
}

/*-- hs Navigation Start --*/
.hs_navigation_header_wrapper {
  float: left;
  width: 100%;
  position: relative;
  // position: fixed;
  background: #525252;
  z-index: 1;
}
.hs_main_menu li {
  float: left;
  position: relative;
}
.hs_main_menu li:last-child:after {
  display: none;
}
.hs_main_menu {
  flex-direction: column;
  justify-content: space-between;
  overflow: visible;
  position: relative;
}
.hs_main_menu a {
  text-align: center;
  padding: 35px 20px 36px;
  text-transform: capitalize;
  display: inline-block;
  border: 0;
  color: #111111;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  position: relative;
}
.dropdown-wrapper:hover > .menu-button {
  color: #4f5dec;
}

.hs_main_menu .drop-menu a {
  padding: 0.75em 1em;
  padding-left: 20px;
  text-align: left;
}
.hs_main_menu a:hover {
  list-style: none;
  //   color: #fff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.dropdown-wrapper > .menu-button {
  position: relative;
}
.dropdown-wrapper:hover > .menu-button {
  color: #4f5dec;
}
.dropdown-wrapper > .menu-button:after {
  content: "";
  border: 1px solid #4f5dec;
  width: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px auto;
  position: absolute;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}

.dropdown-wrapper:hover > .menu-button:after {
  content: "";
  border: 1px solid #4f5dec;
  width: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px auto;
  position: absolute;
  opacity: 1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.hs_main_menu .single_menu:after {
  content: "";
  border: 1px solid #4f5dec;
  width: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px auto;
  position: absolute;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  opacity: 0;
}
.hs_main_menu .single_menu:hover:after {
  content: "";
  border: 1px solid #4f5dec;
  width: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0px auto;
  position: absolute;
  opacity: 1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.menu_button_end a:after {
  display: none !important;
}
/*====================================
  DROPDOWN MENU STYLES
====================================*/

.drop-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: max-height 0.4s, opacity 0.3s;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
}
.drop-menu > .menu-button {
  height: 100%;
  transition: transform 0.4s;
  transform: translateY(-300%);
  background: #ffffff;
  float: left;
  width: 100%;
  text-transform: capitalize;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.drop-menu > .menu-button:hover {
  background: #4f5dec;
  color: #ffffff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.hs_main_menu {
  text-align: center;
}
.hs_main_menu ul {
  display: inline-block;
}
.dropdown-wrapper .drop-menu {
  width: 225px;
}
.dropdown-wrapper:hover .drop-menu {
  max-height: 500px;
  opacity: 1;
  z-index: 1000;
  background: #fff;
}
.cc_cart_wrapper2 {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee !important;
}
.dropdown-wrapper:hover > .drop-menu .menu-button {
  transform: translateY(0%);
}
/*-- hs Navigation right Start --*/
.hs_navi_searchbar_wrapper {
  float: left;
  width: calc(100% - 30px);
  margin-top: 17px;
}
.hs_navi_cart_wrapper {
  float: right;
  width: 30px;
}
.hs_navi_searchbar_wrapper input {
  width: calc(100% - 50px);
  float: left;
  height: 40px;
  background: #ffffff;
  padding-left: 20px;
  color: #666666;
  border: 0;
  -webkit-border-top-left-radius: 50px;
  -moz-border-top-left-radius: 50px;
  border-top-left-radius: 50px;
  -webkit-border-bottom-left-radius: 50px;
  -moz-border-bottom-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.hs_navi_searchbar_wrapper input::placeholder {
  color: #666666;
}

.hs_navi_searchbar_wrapper input:-ms-input-placeholder {
  color: #666666;
}

.hs_navi_searchbar_wrapper input::-ms-input-placeholder {
  color: #666666;
}
.hs_navi_searchbar_wrapper button {
  float: left;
  width: 50px;
  height: 40px;
  background: #ffffff;
  color: #666666;
  border: 0;
  -webkit-border-top-right-radius: 50px;
  -moz-border-top-right-radius: 50px;
  border-top-right-radius: 50px;
  -webkit-border-bottom-right-radius: 50px;
  -moz-border-bottom-right-radius: 50px;
  border-bottom-right-radius: 50px;
}
.cc_cart_wrapper1 {
  padding: 25px !important;
}
.hs_navi_cart_wrapper .menu-button {
  padding: 35px 0 35px 0;
  text-align: right;
}
.hs_navi_cart_wrapper .drop-menu {
  top: 100%;
  right: 16px;
  width: 342px;
}
.hs_navi_cart_wrapper a i:before {
  color: #111111;
  margin-left: 0;
  font-size: 22px;
}
.hs_navi_cart_wrapper .dropdown-wrapper:hover > .menu-button {
  background: transparent;
}
.hs_navi_cart_wrapper:hover > .menu-button {
  background: transparent;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.hs_navi_cart_wrapper a i {
  position: relative;
}
.hs_navi_cart_wrapper a span {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background: #f3532d;
  text-align: center;
  line-height: 20px;
  float: left;
  color: #ffffff;
  font-size: 10px;
  position: absolute;
  margin-top: -6px;
  margin-left: -5px;
}
.menu_fixed {
  position: fixed;
  background: #ffffff;
  -webkit-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  left: 0;
  right: 0;
}
.cc_cart_wrapper1 {
  float: left;
  width: 100%;
  padding-bottom: 25px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.28);
  padding-left: 30px;
  background: #ffffff;
}
.cc_cart_wrapper2 {
  float: left;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 30px;
  border-bottom: 1px solid #37a4dd;
}
.cc_cart_img_wrapper {
  float: left;
  width: 80px;
}
.cc_cart_cont_wrapper {
  width: calc(100% - 80px);
  float: left;
  padding-left: 20px;
  padding-top: 11px;
  position: relative;
}
.cc_cart_cont_wrapper h4 {
  text-align: left;
}
.cc_cart_cont_wrapper h4 a {
  color: #111111;
  font-weight: 600;
  font-size: 18px;
  padding: 0 !important;
  margin-top: 10px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.cc_cart_cont_wrapper h4 a:hover {
  color: #4f5dec;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.cc_cart_cont_wrapper p {
  color: #797979;
  font-size: 14px;
  padding-top: 3px;
  float: left;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.cc_cart_cont_wrapper h5 {
  float: right;
  padding-right: 35px;
  color: #797979;
  font-family: "Lato", sans-serif;
  font-size: 18px;
  margin-top: -9px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.checkout_btn_resto {
  float: left;
  width: 100%;
}
.checkout_btn_resto a {
  float: left;
  width: 150px;
  height: 40px;
  line-height: 35px;
  text-align: center;
  background: #4f5dec;
  color: #ffffff;
  border: 1px solid transparent;
  margin-top: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.checkout_btn_resto a:hover {
  background: transparent;
  border: 1px solid #4f5dec;
  color: #4f5dec;
}
.cc_cart_total_wrapper {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #ffffff;
}
.cc_cart_total_wrapper h4 {
  font-size: 16px;
  font-family: "Lato", sans-serif;
  display: inline-block;
  color: #000000;
  text-transform: capitalize;
}
.cc_cart_total_wrapper h4 b {
  color: #1887e5;
}
.cc_cart_btn_wrapper {
  float: left;
  width: 100%;
  text-align: center;
  padding-top: 25px;
}
.cc_cart_btn {
  display: inline-block;
}
.cc_cart_btn .cart_btn1 {
  width: 145px;
  height: 40px;
  float: left;
  background: #1887e5;
  color: #ffffff;
  text-align: center;
  line-height: 33px;
  border-radius: 2px;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  letter-spacing: 2px;
  border: 2px solid transparent;
}
.cc_cart_btn .cart_btn1:hover {
  background: transparent;
  border: 2px solid #1887e5;
  color: #ffffff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.cc_cart_btn .cart_btn2 {
  width: 145px;
  height: 40px;
  float: left;
  margin-left: 20px;
  background: #ffffff;
  color: #000000;
  text-align: center;
  line-height: 33px;
  border-radius: 2px;
  font-size: 14px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  letter-spacing: 2px;
  border: 2px solid transparent;
}
.cc_cart_btn .cart_btn2:hover {
  background: transparent;
  border: 2px solid #ffffff;
  color: #ffffff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.cc_cart_cont_wrapper li a {
  float: left;
  width: 40px;
  height: 20px;
  background: red;
}
.cc_cart_cont_wrapper button.close {
  color: #797979;
  position: absolute;
  right: 8px;
  opacity: 0.7;
  top: 25px;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.drop-menu > .menu-button:hover .cc_cart_cont_wrapper h5,
.drop-menu > .menu-button:hover .cc_cart_cont_wrapper p,
.drop-menu > .menu-button:hover .cc_cart_cont_wrapper h4 a,
.drop-menu > .menu-button:hover .cc_cart_cont_wrapper button.close {
  color: #ffffff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.house_toggle svg {
  height: 20px;
  width: auto;
}
.hs_logo_wrapper {
  float: left;
  width: auto;
  //   padding-top: 23px;
}
.hs_main_menu {
  float: right;
  width: auto;
  height: 0 !important;
}
/*-- hs Navigation right End --*/

// 菜单栏的样式

/* -------------------------------- 

Main Components 

-------------------------------- */
#preloader {
  display: none;
}
.cd-dropdown-wrapper {
  display: inline-block;
  position: relative;
  padding-top: 35px;
  padding-bottom: 35px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  float: right;
}
.house_toggle i {
  font-size: 17px;
}
.cd-dropdown-trigger {
  display: block;
  position: relative;
  padding: 0 36px 0 20px;
  line-height: 40px;
  background-color: #111433;
  color: #ffffff;
  border-radius: 3px;
}
.no-touch .cd-dropdown-trigger:hover {
  background-color: #171b46;
}
.cd-dropdown-trigger::before,
.cd-dropdown-trigger::after {
  /* this is the arrow inside the dropdown trigger*/
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  height: 2px;
  width: 9px;
  background: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: width 0.3s, -webkit-transform 0.3s;
  -moz-transition: width 0.3s, -moz-transform 0.3s;
  transition: width 0.3s, transform 0.3s;
}
.cd-dropdown-trigger::before {
  right: 22px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.cd-dropdown-trigger::after {
  right: 17px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@media only screen and (min-width: 1024px) {
  .cd-dropdown-trigger {
    font-size: 1.5rem;
  }
  .cd-dropdown-trigger.dropdown-is-active {
    background-color: #3f8654;
  }
  .no-touch .cd-dropdown-trigger.dropdown-is-active:hover {
    background-color: #47975f;
  }
  .cd-dropdown-trigger.dropdown-is-active::before,
  .cd-dropdown-trigger.dropdown-is-active::after {
    /* transform the arrow into a 'X' */
    width: 14px;
  }
  .cd-dropdown-trigger.dropdown-is-active::before {
    /* transform the arrow into a 'X' */
    -webkit-transform: translateX(5px) rotate(-45deg);
    -moz-transform: translateX(5px) rotate(-45deg);
    -ms-transform: translateX(5px) rotate(-45deg);
    -o-transform: translateX(5px) rotate(-45deg);
    transform: translateX(5px) rotate(-45deg);
  }
  .cd-dropdown-trigger.dropdown-is-active::after {
    /* transform the arrow into a 'X' */
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.cd-dropdown h2,
.cd-dropdown-content a,
.cd-dropdown-content ul a {
  height: 50px;
  line-height: 50px;
}
@media only screen and (min-width: 768px) {
  .cd-dropdown h2,
  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    height: 60px;
    line-height: 60px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-dropdown h2,
  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    height: 50px;
    line-height: 50px;
  }
}

.cd-dropdown h2,
.cd-dropdown-content a,
.cd-dropdown-content ul a,
.cd-dropdown-content .cd-divider {
  padding: 0 20px;
}

.cd-dropdown {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #4f5dec;
  color: #000000;
  visibility: hidden;
  /* Force Hardware Acceleration */
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0.5s;
  -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0.5s;
  transition: transform 0.5s 0s, visibility 0s 0.5s;
}
.cd-dropdown h2 {
  /* dropdown title - mobile version only */
  position: relative;
  z-index: 1;
  color: #000000;
  font-size: 26px;
  font-family: "Norican", cursive;
  color: #e5ae49;
  position: relative;
}
.cd-dropdown h2 a {
  color: #ffffff;
}
.cd-dropdown h2:after {
  content: "";
  border: 1px solid rgba(255, 255, 255, 0.52);
  width: 84px;
  position: absolute;
  bottom: 9px;
  left: 19px;
}
.cd-dropdown h2 span {
  color: #00468c;
}
.cd-dropdown .cd-close {
  /* 'X' close icon - mobile version only */
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  /* image replacement */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  color: transparent;
}
.cd-dropdown .cd-close::after,
.cd-dropdown .cd-close::before {
  /* this is the 'X' icon */
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  height: 13px;
  width: 1px;
  background-color: #ffffff;
}
.cd-dropdown .cd-close::after {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.cd-dropdown .cd-close::before {
  -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -moz-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  -o-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
.cd-dropdown.dropdown-is-active {
  visibility: visible;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 0.5s 0s, visibility 0s 0s;
  -moz-transition: -moz-transform 0.5s 0s, visibility 0s 0s;
  transition: transform 0.5s 0s, visibility 0s 0s;
}
@media only screen and (min-width: 768px) {
  .cd-dropdown .cd-close {
    top: 5px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-dropdown {
    position: absolute;
    top: calc(100% - 2px);
    /* reset style*/
    height: auto;
    width: auto;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    background-color: #ffffff;
    color: #111433;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    opacity: 0;
    -webkit-transform: translateY(30px);
    -moz-transform: translateY(30px);
    -ms-transform: translateY(30px);
    -o-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s,
      -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0s 0.3s, transform 0.3s 0s;
  }
  .open-to-left .cd-dropdown {
    /* use the .open-to-left class if you want to open the dropdown on the left */
    right: 0;
    left: auto;
  }
  .cd-dropdown h2,
  .cd-dropdown .cd-close {
    /* on desktop - hide title and 'X' */
    display: none;
  }
  .cd-dropdown.dropdown-is-active {
    opacity: 1;
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity 0.3s 0s, visibility 0.3s 0s,
      -webkit-transform 0.3s 0s;
    -moz-transition: opacity 0.3s 0s, visibility 0.3s 0s, -moz-transform 0.3s 0s;
    transition: opacity 0.3s 0s, visibility 0.3s 0s, transform 0.3s 0s;
  }
}

.cd-dropdown-content,
.cd-dropdown-content ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
  padding-top: 50px;
}
.cd-dropdown-content a,
.cd-dropdown-content ul a {
  display: block;
  color: #ffffff;
  /* truncate text with ellipsis if too long */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid rgba(255, 255, 255, 0.52);
}
.cd-dropdown-content li:first-of-type > a,
.cd-dropdown-content ul li:first-of-type > a {
  border-top-width: 0;
}
.cd-dropdown-content li:last-of-type > a,
.cd-dropdown-content ul li:last-of-type > a {
  border-bottom-width: 1px;
}
.cd-dropdown-content .cd-divider,
.cd-dropdown-content ul .cd-divider {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #0b0e23;
  font-size: 1.1rem;
  font-weight: bold;
  letter-spacing: 1px;
  color: #585a70;
  text-transform: capitalize;
}
.cd-dropdown-content .cd-divider + li > a,
.cd-dropdown-content ul .cd-divider + li > a {
  border-top-width: 0;
}
.cd-dropdown-content a,
.cd-dropdown-content .cd-search,
.cd-dropdown-content .cd-divider,
.cd-dropdown-content ul a,
.cd-dropdown-content ul .cd-search,
.cd-dropdown-content ul .cd-divider {
  /* Force Hardware Acceleration */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
}
.cd-dropdown-content.is-hidden,
.cd-dropdown-content ul.is-hidden {
  /* push the secondary dropdown items to the right */
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}
.cd-dropdown-content.is-hidden > li > a,
.cd-dropdown-content.is-hidden > li > .cd-search,
.cd-dropdown-content.is-hidden > .cd-divider,
.cd-dropdown-content.move-out > li > a,
.cd-dropdown-content.move-out > li > .cd-search,
.cd-dropdown-content.move-out > .cd-divider,
.cd-dropdown-content ul.is-hidden > li > a,
.cd-dropdown-content ul.is-hidden > li > .cd-search,
.cd-dropdown-content ul.is-hidden > .cd-divider,
.cd-dropdown-content ul.move-out > li > a,
.cd-dropdown-content ul.move-out > li > .cd-search,
.cd-dropdown-content ul.move-out > .cd-divider {
  /* lower down the dropdown items opacity - when secondary dropdown slides in or for dropdown items hidden on the right */
  opacity: 0;
}
.cd-dropdown-content.move-out > li > a,
.cd-dropdown-content.move-out > li > .cd-search,
.cd-dropdown-content.move-out > .cd-divider,
.cd-dropdown-content ul.move-out > li > a,
.cd-dropdown-content ul.move-out > li > .cd-search,
.cd-dropdown-content ul.move-out > .cd-divider {
  /* push the dropdown items to the left when secondary dropdown slides in */
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}
.dropdown-is-active .cd-dropdown-content,
.dropdown-is-active .cd-dropdown-content ul {
  -webkit-overflow-scrolling: touch;
}
@media only screen and (min-width: 768px) {
  .cd-dropdown-content,
  .cd-dropdown-content ul {
    padding-top: 60px;
  }
  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
  }
}
@media only screen and (min-width: 1024px) {
  .cd-dropdown-content,
  .cd-dropdown-content ul {
    padding-top: 0;
    overflow: visible;
  }
  .cd-dropdown-content a,
  .cd-dropdown-content ul a {
    color: #111433;
    height: 50px;
    line-height: 50px;
    font-size: 1.5rem;
    border-color: #ebebeb;
  }
  .cd-dropdown-content .cd-divider,
  .cd-dropdown-content ul .cd-divider {
    background-color: transparent;
    color: #b3b3b3;
    border-top: 1px solid #ebebeb;
  }
  .cd-dropdown-content .cd-divider + li > a,
  .cd-dropdown-content ul .cd-divider + li > a {
    border-top-width: 1px;
  }
  .cd-dropdown-content.is-hidden > li > a,
  .cd-dropdown-content.is-hidden > li > .cd-search,
  .cd-dropdown-content.is-hidden > .cd-divider,
  .cd-dropdown-content.move-out > li > a,
  .cd-dropdown-content.move-out > li > .cd-search,
  .cd-dropdown-content.move-out > .cd-divider,
  .cd-dropdown-content ul.is-hidden > li > a,
  .cd-dropdown-content ul.is-hidden > li > .cd-search,
  .cd-dropdown-content ul.is-hidden > .cd-divider,
  .cd-dropdown-content ul.move-out > li > a,
  .cd-dropdown-content ul.move-out > li > .cd-search,
  .cd-dropdown-content ul.move-out > .cd-divider {
    /* reset mobile style */
    opacity: 1;
  }
}

.cd-dropdown-content .see-all a {
  /* different style for the See all links */
  color: #3f8654;
}
.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item,
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
  /* items with picture (or icon) and title */
  height: 80px;
  line-height: 80px;
}
.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item h3,
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item h3 {
  /* truncate text with ellipsis if too long */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
  padding-left: 90px;
}
.cd-dropdown-content .cd-dropdown-gallery img {
  position: absolute;
  display: block;
  height: 40px;
  width: auto;
  left: 20px;
  top: 50%;
  margin-top: -20px;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
  padding-left: 75px;
  position: relative;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
  color: #111433;
  font-size: 1.3rem;
  /* hide description on small devices */
  display: none;
}
.cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
  /* item icon */
  content: "";
  display: block;
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px 40px;
}
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-1::before {
//   background-image: url("../img/nucleo-icon-1.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-2::before {
//   background-image: url("../img/nucleo-icon-2.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-3::before {
//   background-image: url("../img/nucleo-icon-3.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-4::before {
//   background-image: url("../img/nucleo-icon-4.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-5::before {
//   background-image: url("../img/nucleo-icon-5.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-6::before {
//   background-image: url("../img/nucleo-icon-6.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-7::before {
//   background-image: url("../img/nucleo-icon-7.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-8::before {
//   background-image: url("../img/nucleo-icon-8.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-9::before {
//   background-image: url("../img/nucleo-icon-9.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-10::before {
//   background-image: url("../img/nucleo-icon-10.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-11::before {
//   background-image: url("../img/nucleo-icon-11.svg");
// }
// .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item.item-12::before {
//   background-image: url("../img/nucleo-icon-12.svg");
// }
@media only screen and (min-width: 1024px) {
  .cd-dropdown-content {
    /* reset mobile style */
    position: static;
    height: auto;
    width: 280px;
  }
  .cd-dropdown-content > li:last-of-type a {
    border-bottom: none;
  }
  .no-touch .cd-dropdown-content > li:not(.has-children) a:hover {
    color: #000000;
  }
  .cd-dropdown-content.move-out > li > a,
  .cd-dropdown-content.move-out > li > .cd-search,
  .cd-dropdown-content.move-out > .cd-divider {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  .cd-dropdown-content .cd-secondary-dropdown,
  .cd-dropdown-content .cd-dropdown-gallery,
  .cd-dropdown-content .cd-dropdown-icons {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
    left: 100%;
    height: auto;
    background-color: #ffffff;
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
  }
  .cd-dropdown-content .cd-secondary-dropdown::after,
  .cd-dropdown-content .cd-dropdown-gallery::after,
  .cd-dropdown-content .cd-dropdown-icons::after {
    clear: both;
    content: "";
    display: table;
  }
  .open-to-left .cd-dropdown-content .cd-secondary-dropdown,
  .open-to-left .cd-dropdown-content .cd-dropdown-gallery,
  .open-to-left .cd-dropdown-content .cd-dropdown-icons {
    /* use the .open-to-left class if you want to open the dropdown on the left */
    left: auto;
    right: 100%;
  }
  .cd-dropdown-content .cd-secondary-dropdown.is-hidden,
  .cd-dropdown-content .cd-dropdown-gallery.is-hidden,
  .cd-dropdown-content .cd-dropdown-icons.is-hidden {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .cd-dropdown-content .cd-secondary-dropdown.fade-in,
  .cd-dropdown-content .cd-dropdown-gallery.fade-in,
  .cd-dropdown-content .cd-dropdown-icons.fade-in {
    /* animate secondary dropdown items when hovering over of of the .cd-dropdown-content list items */
    -webkit-animation: cd-fade-in 0.2s;
    -moz-animation: cd-fade-in 0.2s;
    animation: cd-fade-in 0.2s;
  }
  .cd-dropdown-content .cd-secondary-dropdown.fade-out,
  .cd-dropdown-content .cd-dropdown-gallery.fade-out,
  .cd-dropdown-content .cd-dropdown-icons.fade-out {
    /* animate secondary dropdown items when hovering over of of the .cd-dropdown-content list items */
    -webkit-animation: cd-fade-out 0.2s;
    -moz-animation: cd-fade-out 0.2s;
    animation: cd-fade-out 0.2s;
  }
  .cd-dropdown-content .cd-secondary-dropdown > .go-back,
  .cd-dropdown-content .cd-dropdown-gallery > .go-back,
  .cd-dropdown-content .cd-dropdown-icons > .go-back {
    display: none;
  }
  .cd-dropdown-content .cd-secondary-dropdown > .see-all,
  .cd-dropdown-content .cd-dropdown-gallery > .see-all,
  .cd-dropdown-content .cd-dropdown-icons > .see-all {
    position: absolute;
    bottom: 20px;
    height: 45px;
    text-align: center;
  }
  .cd-dropdown-content .cd-secondary-dropdown > .see-all a,
  .cd-dropdown-content .cd-dropdown-gallery > .see-all a,
  .cd-dropdown-content .cd-dropdown-icons > .see-all a {
    margin: 0;
    height: 100%;
    line-height: 45px;
    background: #ebebeb;
    pointer-events: auto;
    -webkit-transition: color 0.2s, background-color 0.2s;
    -moz-transition: color 0.2s, background-color 0.2s;
    transition: color 0.2s, background-color 0.2s;
  }
  .no-touch .cd-dropdown-content .cd-secondary-dropdown > .see-all a:hover,
  .no-touch .cd-dropdown-content .cd-dropdown-gallery > .see-all a:hover,
  .no-touch .cd-dropdown-content .cd-dropdown-icons > .see-all a:hover {
    color: #ffffff;
    background-color: #111433;
  }
  .cd-dropdown-content .cd-secondary-dropdown .cd-dropdown-item,
  .cd-dropdown-content .cd-secondary-dropdown a,
  .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item,
  .cd-dropdown-content .cd-dropdown-gallery a,
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item,
  .cd-dropdown-content .cd-dropdown-icons a {
    border: none;
  }
  .cd-dropdown-content .cd-dropdown-gallery,
  .cd-dropdown-content .cd-dropdown-icons {
    padding: 20px 30px 100px;
  }
  .cd-dropdown-content .cd-dropdown-gallery > .see-all,
  .cd-dropdown-content .cd-dropdown-icons > .see-all {
    width: calc(100% - 60px);
  }
  .cd-dropdown-content .cd-dropdown-icons > li,
  .cd-dropdown-content .cd-secondary-dropdown > li {
    width: 50%;
    float: left;
  }
  .cd-dropdown-content .cd-secondary-dropdown {
    overflow: hidden;
    width: 550px;
    padding-bottom: 65px;
  }
  .cd-dropdown-content .cd-secondary-dropdown::before {
    /* this is the separation line in the middle of the .cd-secondary-dropdown element */
    position: absolute;
    content: "";
    top: 290px;
    left: 15px;
    height: 1px;
    width: 520px;
    background-color: #ebebeb;
  }
  .cd-dropdown-content .cd-secondary-dropdown > li > a {
    color: #3f8654;
    font-size: 1.6rem;
    margin-bottom: 10px;
    line-height: 30px;
    height: 30px;
    pointer-events: none;
  }
  .cd-dropdown-content .cd-secondary-dropdown > li > a::after,
  .cd-dropdown-content .cd-secondary-dropdown > li > a::before {
    /* hide the arrow */
    display: none;
  }
  .cd-dropdown-content .cd-secondary-dropdown.move-out > li > a {
    /* reset mobile style */
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .cd-dropdown-content .cd-secondary-dropdown > li {
    margin: 20px 0;
    border-right-width: 1px;
    border-color: #ebebeb;
    border-style: solid;
    padding: 0 30px;
    height: 250px;
  }
  .cd-dropdown-content .cd-secondary-dropdown > li:nth-of-type(2n) {
    border-right-width: 0;
  }
  .cd-dropdown-content .cd-secondary-dropdown > li > ul {
    /* reset mobile style */
    -webkit-transform: translate(0);
    -moz-transform: translate(0);
    -ms-transform: translate(0);
    -o-transform: translate(0);
    transform: translate(0);
    position: relative;
    height: auto;
  }
  .cd-dropdown-content .cd-secondary-dropdown > li > ul > .go-back {
    display: none;
  }
  .cd-dropdown-content .cd-secondary-dropdown a {
    line-height: 25px;
    height: 25px;
    font-size: 1.3rem;
    padding-left: 0;
  }
  .no-touch .cd-dropdown-content .cd-secondary-dropdown a:hover {
    color: #3f8654;
  }
  .cd-dropdown-content .cd-secondary-dropdown ul {
    padding-bottom: 25px;
    overflow: hidden;
    height: auto;
  }
  .cd-dropdown-content .cd-secondary-dropdown .go-back a {
    padding-left: 20px;
    color: transparent;
  }
  .no-touch .cd-dropdown-content .cd-secondary-dropdown .go-back a:hover {
    color: transparent;
  }
  .cd-dropdown-content .cd-secondary-dropdown .go-back a::before,
  .cd-dropdown-content .cd-secondary-dropdown .go-back a::after {
    left: 0;
  }
  .cd-dropdown-content .cd-secondary-dropdown .see-all {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .cd-dropdown-content .cd-dropdown-gallery {
    width: 600px;
    padding-bottom: 100px;
  }
  .cd-dropdown-content .cd-dropdown-gallery > li {
    width: 48%;
    float: left;
    margin-right: 4%;
  }
  .cd-dropdown-content .cd-dropdown-gallery > li:nth-of-type(2n) {
    margin-right: 0;
  }
  .cd-dropdown-content .cd-dropdown-gallery .cd-dropdown-item {
    padding: 0;
    height: auto;
    line-height: normal;
    color: #3f8654;
    margin-bottom: 2em;
  }
  .cd-dropdown-content .cd-dropdown-gallery > li:nth-last-of-type(2) a,
  .cd-dropdown-content .cd-dropdown-gallery > li:last-of-type a {
    margin-bottom: 0;
  }
  .cd-dropdown-content .cd-dropdown-gallery img {
    position: static;
    height: auto;
    width: 100%;
    margin: 0 0 0.6em;
  }
  .cd-dropdown-content .cd-dropdown-icons {
    width: 600px;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item {
    height: 80px;
    line-height: 1.2;
    padding: 24px 0 0 85px;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item:hover {
    background: #ebebeb;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item h3 {
    color: #3f8654;
    font-weight: bold;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item p {
    display: block;
    font-size: 1.2rem;
  }
  .cd-dropdown-content .cd-dropdown-icons .cd-dropdown-item::before {
    left: 25px;
  }
  .cd-dropdown-content > .has-children > ul {
    visibility: hidden;
  }
  .cd-dropdown-content > .has-children > ul.is-active {
    /* when hover over .cd-dropdown-content items - show subnavigation */
    visibility: visible;
  }
  .cd-dropdown-content
    > .has-children
    > .cd-secondary-dropdown.is-active
    > li
    > ul {
    /* if .cd-secondary-dropdown is visible - show also subnavigation */
    visibility: visible;
  }
  .cd-dropdown-content > .has-children > a.is-active {
    /* hover effect for .cd-dropdown-content items with subnavigation */
    box-shadow: inset 2px 0 0 #3f8654;
    color: #3f8654;
  }
  .cd-dropdown-content > .has-children > a.is-active::before,
  .cd-dropdown-content > .has-children > a.is-active::after {
    background: #3f8654;
  }
  .open-to-left .cd-dropdown-content > .has-children > a.is-active {
    box-shadow: inset -2px 0 0 #3f8654;
  }
}

@-webkit-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-moz-keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes cd-fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@-webkit-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
@-moz-keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
@keyframes cd-fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: visible;
  }
}
.cd-search input[type="search"] {
  width: 100%;
  height: 50px;
  padding: 0 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #fff;
  color: #000000;
  border: none;
  border-radius: 0;
  border: 1px solid #d3d3d3;
}
.cd-search input[type="search"]::-webkit-input-placeholder {
  color: #000000;
}
.cd-search input[type="search"]::-moz-placeholder {
  color: #000000;
}
.cd-search input[type="search"]:-moz-placeholder {
  color: #000000;
}
.cd-search input[type="search"]:-ms-input-placeholder {
  color: #000000;
}
.cd-search input[type="search"]:focus {
  background: #ffffff;
  color: #111433;
  outline: none;
}
.cd-search input[type="search"]:focus::-webkit-input-placeholder {
  color: rgba(17, 20, 51, 0.4);
}
.cd-search input[type="search"]:focus::-moz-placeholder {
  color: rgba(17, 20, 51, 0.4);
}
.cd-search input[type="search"]:focus:-moz-placeholder {
  color: rgba(17, 20, 51, 0.4);
}
.cd-search input[type="search"]:focus:-ms-input-placeholder {
  color: rgba(17, 20, 51, 0.4);
}
@media only screen and (min-width: 1024px) {
  .cd-search input[type="search"] {
    background-color: #ebebeb;
  }
  .cd-search input[type="search"]::-webkit-input-placeholder {
    color: #b3b3b3;
  }
  .cd-search input[type="search"]::-moz-placeholder {
    color: #b3b3b3;
  }
  .cd-search input[type="search"]:-moz-placeholder {
    color: #b3b3b3;
  }
  .cd-search input[type="search"]:-ms-input-placeholder {
    color: #b3b3b3;
  }
}

.has-children > a,
.go-back a {
  position: relative;
}
.has-children > a::before,
.has-children > a::after,
.go-back a::before,
.go-back a::after {
  /* arrow icon in CSS - for element with nested unordered lists */
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  display: inline-block;
  height: 1px;
  width: 9px;
  background: #ffffff;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.has-children > a::before,
.go-back a::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
.has-children > a::after,
.go-back a::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@media only screen and (min-width: 1024px) {
  .has-children > a::before,
  .has-children > a::after,
  .go-back a::before,
  .go-back a::after {
    background: #b3b3b3;
  }
}

.has-children > a {
  padding-right: 40px;
}
.has-children > a::before,
.has-children > a::after {
  /* arrow goes on the right side - children navigation */
  right: 20px;
  -webkit-transform-origin: 9px 50%;
  -moz-transform-origin: 9px 50%;
  -ms-transform-origin: 9px 50%;
  -o-transform-origin: 9px 50%;
  transform-origin: 9px 50%;
}

@media only screen and (min-width: 1024px) {
  .open-to-left .cd-dropdown-content > .has-children > a {
    padding-left: 40px;
    padding-right: 20px;
  }
  .open-to-left .cd-dropdown-content > .has-children > a::before,
  .open-to-left .cd-dropdown-content > .has-children > a::after {
    right: auto;
    left: 20px;
    -webkit-transform-origin: 1px 50%;
    -moz-transform-origin: 1px 50%;
    -ms-transform-origin: 1px 50%;
    -o-transform-origin: 1px 50%;
    transform-origin: 1px 50%;
  }
}
.cd-dropdown-content .go-back a {
  padding-left: 40px;
}
.cd-dropdown-content .go-back a::before,
.cd-dropdown-content .go-back a::after {
  /* arrow goes on the left side - go back button */
  left: 20px;
  -webkit-transform-origin: 1px 50%;
  -moz-transform-origin: 1px 50%;
  -ms-transform-origin: 1px 50%;
  -o-transform-origin: 1px 50%;
  transform-origin: 1px 50%;
}

.cd-main-content {
  background-color: #e6e6e6;
  min-height: calc(100vh - 100px);
  padding: 2em 5%;
  line-height: 2;
}

.no-js .cd-dropdown-wrapper:hover .cd-dropdown {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.no-js .cd-dropdown-wrapper .cd-close {
  display: none;
}
.cd-dropdown-content li a {
  border-top: 0;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.cd-dropdown-content li a:hover {
  background: #3b45b1;
  color: #ffffff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.has-children:hover > a::before,
.has-children:hover > a::after,
.go-back:hover a::before,
.go-back:hover a::after {
  background: #ffffff;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
</style>