<template>
  <div class="box">
    <Nav name="rent" />
    <!-- <Nav></Nav> -->

    <!--🍞 面包屑 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <b-row>
            <b-col cols="auto" class="mr-auto">
              <div-left center>
                <div-text color="#1D2124" size="20px" bold>{{ $t('支付方式') }}</div-text>
              </div-left>
            </b-col>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{ $t('首页') }}</b-breadcrumb-item>
                <b-breadcrumb-item @click="back()">{{ $t('汽车租赁') }}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{ $t('租赁支付') }}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
        <div-zebra></div-zebra>
      </b-container>
    </section>

    <Pay02 ref="pay" url="/index/rentalorders/pay" :price="forepay_fee" :good_id="id" />
    <div-height height="40px" />

  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      forepay_fee: 0,
      pay_state: 1,
      state: 0,
      active: 1,
    }
  },
  created() {
    this.urlparam()
  },
  methods: {
    // save() {
    //   this.$post({
    //     url: "/index/rentalorders/pay",
    //     data: { rental_order_id: this.id, pay_type: 1 },
    //     success: (res) => {
    //       console.log(res.data)
    //       this.go("mine")
    //     },
    //     tip: () => {
    //       setTimeout(() => {
    //         this.$refs.btn.disabled = false
    //       }, 3000)
    //     },
    //   })
    // },
  },
}
</script>
<style lang="scss" scoped>
.box {
  min-height: calc(100vh - 246px);
}
.pay_box {
  max-width: 500px;
  margin: 0 auto;
}

.fieldset {
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 66px;

  &:focus-within {
    border: 1px solid #57b846;

    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }

  legend {
    @include transition(0.4s);
    position: relative;
    z-index: 1;
    color: #2f2e3f;
    font-size: 16px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
  }

  /deep/.div-input {
    position: relative;
    top: -10px;
    width: 100%;
    height: 50px;
    background: #ffffff;

    &:focus-within {
      border-color: #3c58b2;
    }

    .input-group-text {
      padding: 0 24px;
      background: transparent;
      border-color: transparent !important;
    }

    input {
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
      background: transparent;
      border-color: transparent !important;

      &.form-control {
        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }

    input::-webkit-input-placeholder {
      color: #d7d7d7;
    }

    input:-webkit-autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }

    input:autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }
  }
}

.table_box {
  padding: 30px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 0;
}

.opt_title {
  height: 70px;
  line-height: 70px;
}

//性别
.opt_box {
  padding: 30px;

  .opt_tab {
    margin-bottom: 30px;
    cursor: pointer;

    .div-img {
      // margin-right: 20px;
    }
  }

  .bv-no-focus-ring {
    display: flex;
    align-items: center;
  }

  // margin-bottom: 46px;
  .custom-control {
    height: 50px;
    min-height: 50px !important;
    display: flex;
    align-items: center;
  }

  .custom-radio {
    /deep/.custom-control-label {
      cursor: pointer;
      font-size: 14px !important;
    }
  }
}

.upload_box {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  // padding: 10px;

  .upload_file {
    width: 230px;
    height: 151px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .icon {
      position: absolute;
      width: 56px;
      height: 56px;
    }
  }

  .up_text {
    width: 100%;
    text-align: center;
  }

  .the_img {
    justify-content: center;
    flex-wrap: wrap;
    display: flex;
    margin-bottom: 30px;
  }

  @include transition(0.4s);

  @media #{$xl} {
    flex-direction: row;

    .line {
      display: block;
    }
  }

  @media #{$lg} {
    flex-direction: row;

    .line {
      display: block;
    }
  }

  @media #{$md} {
    flex-direction: column;

    .line {
      display: none;
    }
  }

  @media #{$sm} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }

  @media #{$xs} {
    flex-direction: column;
    width: 100%;
    // padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }
}

@media #{$xl} {
  .opt_title {
    display: none;
  }

  .opt_box {
    box-shadow: none;
  }

  .bv-no-focus-ring {
    display: flex;
    align-items: center;
  }
}

@media #{$lg} {
  .opt_title {
    display: none;
  }

  .opt_box {
    box-shadow: none;
  }
}

@media #{$md} {
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}

@media #{$sm} {
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}

@media #{$xs} {
  .bv-no-focus-ring {
    flex-wrap: wrap;
  }
}
</style>