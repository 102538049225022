<template>
  <div>

    <b-carousel id="carousel-1" v-model="slide" :interval="4000" controls indicators background="#ababab" img-width="1024" img-height="480" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
      <!-- <b-carousel-slide :img-src="require('@/assets/img/banner/2.jpg')"></b-carousel-slide> -->
      <b-carousel-slide :img-src="item.pic_url||item" v-for="(item,index) in banners" :key="index"></b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  props: {
    // 对‘子组件接收的数据名称’进行声明，需要标明接收数据的类型‘Array’
    banners: Array,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    }
  },
  // created() {
  //   console.log("1212", this.list)
  // },
  mounted() {
    console.log("1212", this.list)
  },
  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    },
  },
}
</script>

<style lang="scss" scoped>
.carousel-item {
  /deep/img {
    // min-height: 180px;
    max-height: 648px;
  }
}
</style>