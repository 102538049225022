<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒左侧菜单 -->
            <Menu name="deposit"></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🥒 标题 -->
            <div-padding padding="20px 0">
              <div-text color="#1D2124" size="20px" bold>{{$t('租车押金')}}</div-text>
            </div-padding>

            <div-zebra></div-zebra>

            <div class="zebra_box shadow">
              <b-card-group>
                <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                  <template v-slot:header>

                    <div class="card_title">
                      <div-left center>
                        <div-text>{{$t('押金总额')}} (CAD)</div-text>
                        <div-width size="10px"> </div-width>
                        <div-text size="30px" bold>{{deposit}}</div-text>
                      </div-left>
                    </div>

                  </template>

                  <div-height height="1px" bg="rgba(34, 34, 34, 0.125)" />

                  <div v-for="(item, index) in list" :key="index" class="item">
                    <b-row>
                      <b-col lg="3">
                        <div-padding padding="20px 0">
                          <div-img class="picbox" hover height="100%" width="100%" radius="6px">
                            <b-img v-if="item.redun" height="100%" fluid :src="item.redun.vehicle_thumbnail"></b-img>
                          </div-img>
                        </div-padding>
                      </b-col>
                      <b-col lg="9">
                        <div class="item_right">
                          <div>
                            <b-row>
                              <b-col lg="6">
                                <div-text size="15px" color="#1D2124" bold>
                                  {{item.redun['vehicle_name'+$t('suffix')]}}
                                </div-text>
                              </b-col>
                              <b-col lg="6">
                                <div-text size="13px" color="#1D2124" right>
                                  {{{1:$t('已退还'),2:$t('使用中')}[item.deposit_return_status]}}
                                </div-text>
                              </b-col>
                            </b-row>
                            <div-text size="13px" color="#666666">
                              {{$t('车辆类型')}} ：{{item.redun['classi_name'+$t('suffix')]}} ｜
                              {{$t('车辆品牌')}} ：{{item.redun['brand_name'+$t('suffix')]}} ｜
                              {{$t('车辆型号')}} ：{{item.redun['model_name'+$t('suffix')]}} ｜
                              {{$t('车辆年份')}} ：{{item.redun['life_name'+$t('suffix')]}}
                            </div-text>

                            <div-text size="13px" color="#666666">
                              {{$t('驾照押金')}}(CAD) ： {{item.license_deposit}} ｜
                              {{$t('租车押金')}}(CAD) ：{{item.rental_deposit}} ｜
                              {{$t('支付方式')}} ： {{{10:$t('余额')}[item.pay_type]}} ｜
                              {{$t('支付时间')}} ： {{item.pay_time}} ｜
                              {{$t('交易单号')}} ： {{item.no}}
                              <!-- {{$t('收款单号')}} ： {{item.pay_time}} 对接支付后对接 -->
                            </div-text>
                          </div>
                          <div-right center>
                            <div @click.stop="showPopup(item.id)" class="icon"><i class="el-icon-delete"></i></div>
                          </div-right>
                        </div>
                      </b-col>
                    </b-row>

                  </div>

                  <el-empty v-if="list&&list.length==0" :description="$t('暂无数据')"></el-empty>

                  <template v-slot:footer>

                    <!-- 🔋底部分页 -->
                    <div-center>
                      <b-pagination-nav :first-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :last-number="true" :number-of-pages="pages" use-router>
                        <template v-slot:prev-text>
                          <b-icon icon="chevron-left"></b-icon>
                        </template>
                        <template v-slot:next-text>
                          <b-icon icon="chevron-right"></b-icon>
                        </template>
                      </b-pagination-nav>
                    </div-center>

                  </template>
                </b-card>
              </b-card-group>
            </div>
          </b-col>
        </b-row>
      </section>
      <div-height height="30px" />
    </b-container>

  </div>
</template>
<script>
import toastEel from "@/components/toast-ui/del01.vue"
export default {
  data() {
    return {
      id: 0,
      msg: "您确定要取消订单吗？",
      shouldShowDialog: false,
      loading: false,
      deposit: 0,
      leftModuleActiveNames: [],
      status_arr: [],
      status: 0,
      is_edit: false,
      active: 0,
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
      list: [],
    }
  },
  created() {
    this.status = this.query().status * 1
    this.get_deposit()
    this.get_info()
  },
  mounted() {},
  methods: {
    showPopup(id) {
      this.id = id
      this.loading = this.$loading({
        lock: true,
      })
      this.$toast(
        {
          component: toastEel,
          props: {
            the_title: this.$t("您确定要删除该订单吗？"),
            the_left: this.$t("取消"),
            the_right: this.$t("确定"),
          },
          // 事件回调，组件中emit的方法放在这里进行回调
          listeners: {
            showDialog: () => {
              console.log("调用删除")
              this.del()
            },
            closeDialog: () => {
              console.log("调用关闭")
              this.close()
            },
          },
        },
        {
          id: 1,
          timeout: false,
          position: "top-center",
          closeOnClick: false, // 单击即关闭
          pauseOnFocusLoss: false,
          pauseOnHover: false, // 鼠标移入暂停
          draggable: false, // 允许通过拖动和滑动事件关闭
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false, // 显示/隐藏进度条
          closeButton: false,
          icon: false,
          rtl: false,
        }
      )
    },
    close() {
      this.loading.close()
      this.$toast.clear()
    },
    del() {
      this.$toast.clear()
      this.$post({
        url: "/index/rentalorders/markDelDeposit",
        data: {
          ids: this.id,
        },
        success: (res) => {
          setTimeout(() => {
            this.loading.close()
            this.get_info()
          }, 2000)
        },
        tip: () => {
          console.log("3")
          setTimeout(() => {
            this.loading.close()
          }, 3000)
        },
      })
    },
    get_deposit() {
      this.$get({
        url: "/index/users/uinfo",
        success: (res) => {
          let obj = res.data
          Object.keys(obj).map((item) => (this[item] = obj[item]))
        },
        tip: () => {},
      })
    },

    open(indexs) {
      if (this.leftModuleActiveNames[0] == indexs) {
        this.leftModuleActiveNames = []
        return
      } else {
        this.leftModuleActiveNames = [indexs]
      }
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    },
    get_list(page) {
      this.currentPage = page
      this.get_info()
    },
    get_info() {
      this.$get({
        url: "/index/rentalorders/depositOrders",
        data: {
          page: this.currentPage, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          //列表
          this.list = res.data.list.data
          console.log(this.list)
          this.pages = res.data.list.last_page || 1
          this.currentPage = res.data.list.current_page
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.picbox {
  position: relative;
}
.picbox:before {
  content: "";
  display: block;
  padding-top: 70%; //宽高比例修改这个数值
}

.picbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/deep/.button-danger {
  display: none !important;
}
.div-text {
  line-height: 2 !important;
}

.item_right {
  line-height: 48px;
  display: flex;
  height: 100%;
  padding: 20px 0;
  flex-direction: column;
  justify-content: space-between;
}
.icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  // background: #e9ebf1;
  background: #333;
  box-shadow: 0 3px 24px rgba(0, 0, 0, 0.04);
  transition: all 0.3s;
  i {
    transition: all 0.3s;
  }
}
.icon_active {
  background: #85ce61;
  i {
    transform: rotate(180deg);
  }
}
/deep/.el-collapse-item__arrow {
  position: absolute;
  right: 0 !important;
  display: none;
}
//
.item {
  padding: 0 20px;
  cursor: pointer;
  // &:hover {
  //   background: #f5f5f5;
  // }
}

/deep/.card-header {
  border-bottom: 0 !important;
  background-color: #fff !important;
}

.tabs {
  /deep/.nav-link {
    font-size: 14px;
    color: #3b3b3b !important;
  }
  /deep/.active {
    color: #fff !important;
    background-color: #3b3b3b !important;
  }
  .tab-content {
    display: none;

    .active {
      display: none;
    }
  }
  .tab {
    padding: 10px 38px 10px 10px;
    cursor: pointer;
    color: #1d2124;
    font-weight: bold;
  }
}
</style>