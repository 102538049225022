<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="index" />
    <!-- 🥒轮播图 -->
    <!-- <Swiper /> -->
    <!-- 🍞面包屑 -->

    <div class="fill_screen">
      <section>
        <b-container>
          <div-padding padding="20px 0">

            <b-row>
              <b-col sm="12" cols="12" lg="auto" xl="auto" md="auto" order-md="1" order="2" class="mr-auto">
                <div class="wow fadeInLeft">
                  <b-input-group class="div-search">
                    <template #prepend>
                      <b-input-group-text>
                        <b-icon-search color="#D7D7D7"></b-icon-search>
                      </b-input-group-text>
                    </template>
                    <b-form-input class="input" :placeholder="$t('请输入要搜索的内容')" type="search" v-model="search" @input="onKeyDown(search)"></b-form-input>
                  </b-input-group>
                </div>
              </b-col>
              <b-col sm="12" cols="12" lg="auto" xl="auto" md="auto" order-md="2" order="1">
                <b-breadcrumb>
                  <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                  <b-breadcrumb-item active>{{$t('热门车款推荐')}}</b-breadcrumb-item>
                </b-breadcrumb>
              </b-col>
            </b-row>

          </div-padding>
        </b-container>
      </section>

      <!-- 🍍商品列表 -->
      <section class="good_list">
        <b-container>
          <b-row>
            <b-col sm="12" md="12" v-for="(item,index) in list" :key="index" class="wow fadeInUp " :data-wow-delay="(index * 0.1) + 's'">
              <div class="item" @click="go('rent_item',{type:2,id:item.id})">
                <div class="img picbox">
                  <img :src="item.vehicle_thumbnail">
                </div>
                <div class="text">
                  <div class="one">{{item['vehicle_name'+$t('suffix')]}}</div>
                  <!-- <div>CAD {{item.base_rent}}</div> -->
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <el-empty v-if="list&&list.length==0" :description="$t('暂无数据')"></el-empty>

      <div-height height="20px" />
      <!-- 🔋底部分页 -->
      <div-center>
        <b-pagination-nav :first-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :last-number="true" :number-of-pages="pages" use-router>
          <template v-slot:prev-text>
            <b-icon icon="chevron-left"></b-icon>
          </template>
          <template v-slot:next-text>
            <b-icon icon="chevron-right"></b-icon>
          </template>
        </b-pagination-nav>
      </div-center>
      <div-height height="30px" />
    </div>

  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      uname: "",
      text: "",
      search: "",
      info: {},
      list: [],
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
    }
  },
  created() {
    this.get_info()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })
  },
  methods: {
    onKeyDown(even) {
      //防抖
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        // console.log("onKeyDown", even)
        this.get_info()
      }, 500)
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    },

    get_list(page) {
      this.currentPage = page
      this.get_info()
    },
    get_info() {
      this.$get({
        url: "/index/vehicles/hotCarMore",
        data: {
          kw: this.search, //搜索
          page: this.currentPage, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          //列表
          this.list = res.data.data
          this.pages = res.data.last_page || 1
          this.currentPage = res.data.current_page
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.picbox {
  position: relative;
}
.picbox:before {
  content: "";
  display: block;
  padding-top: 50%;
}

.picbox img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// 🍉商品列表
.good_list {
  .item {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.4s;
    overflow: hidden;
    cursor: pointer;

    .img {
      transition: all 0.4s;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .text {
      transition: all 0.4s;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 10px 30px;
      height: 120px;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(10px);
      text-align: center;
      color: #fff;
    }
    &:hover {
      .img {
        transform: scale(1.1);
      }
      .text {
        height: 30%;
      }
    }
  }
}

// 🍓媒体查询
@media #{$xl} {
}

@media #{$lg} {
}

@media #{$md} {
}

@media #{$sm} {
}

@media #{$xs} {
}
</style>