<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒小菜单 -->
            <Menu name="set_password"></Menu>
          </b-col>
          <b-col lg="10" md="9">

            <!-- 🍞面包屑 -->
            <section>

              <div-padding padding="20px 0">
                <b-row no-gutters>
                  <b-col cols="auto" class="mr-auto">
                    <div-left center>
                      <div-text color="#1D2124" size="20px" bold>{{$t('登录密码')}}</div-text>
                    </div-left>
                  </b-col>
                  <b-col cols="auto">
                    <b-breadcrumb>
                      <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                      <b-breadcrumb-item @click="go('mine')">{{$t('我的')}}</b-breadcrumb-item>
                      <b-breadcrumb-item active>{{$t('登录密码')}}</b-breadcrumb-item>
                    </b-breadcrumb>
                  </b-col>
                </b-row>
              </div-padding>

            </section>

            <div-zebra></div-zebra>

            <b-card-group>
              <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                <b-card-body>
                  <div-padding padding="30px 0">

                    <form>

                      <!--🥒输入框 -->
                      <fieldset class="fieldset">
                        <legend>EMAIL*</legend>
                        <b-input-group class="div-input">
                          <b-form-input class="input" readonly v-model="email" placeholder="请输入EMAIL" type="search"></b-form-input>
                        </b-input-group>
                      </fieldset>
                      <!--🥒输入框 -->
                      <fieldset class="fieldset">
                        <legend>{{$t('验证码')}}*</legend>
                        <b-input-group class="div-input">
                          <template v-slot:append>
                            <b-input-group-text class="div-code">
                              <!-- 获取手机验证码按钮 封装 位置 在 vant-extend ===》 添加了防抖防止连点-->
                              <div-code url="/index/email/sendEmail" scene="3" :email="email"></div-code>
                            </b-input-group-text>
                          </template>
                          <b-form-input class="input" v-model="code" :placeholder="$t('请输入验证码')" type="search">
                          </b-form-input>
                        </b-input-group>
                      </fieldset>
                      <!--🥒输入框 -->
                      <fieldset class="fieldset">
                        <legend>{{$t('登录密码')}}*</legend>
                        <b-input-group class="div-input">
                          <template v-slot:append>
                            <b-input-group-text @click="active = !active" class="cursor">
                              <b-icon-eye-fill :color="active ? '#3B3B3B' : '#D7D7D7'"></b-icon-eye-fill>
                            </b-input-group-text>
                          </template>
                          <b-form-input :disabled="!email" class="input" v-model="new_pass" autocomplete=" " :placeholder="$t('请输入登录密码')" :type="active ? 'search' : 'password'">
                          </b-form-input>
                        </b-input-group>
                      </fieldset>
                      <!--🥒输入框 -->
                      <fieldset class="fieldset">
                        <legend>{{$t('重复密码')}}*</legend>
                        <b-input-group class="div-input">
                          <template v-slot:append>
                            <b-input-group-text @click="active = !active" class="cursor">
                              <b-icon-eye-fill :color="active ? '#3B3B3B' : '#D7D7D7'"></b-icon-eye-fill>
                            </b-input-group-text>
                          </template>
                          <b-form-input :disabled="!email" class="input" v-model="confirm_pass" autocomplete=" " :placeholder="$t('请再次输入登录密码')" :type="active ? 'search' : 'password'">
                          </b-form-input>
                        </b-input-group>
                      </fieldset>
                      <div-height height="30px"></div-height>
                      <div-save ref="btn" @save="save">{{$t('完成')}}</div-save>

                    </form>
                  </div-padding>

                </b-card-body>

              </b-card>
            </b-card-group>

          </b-col>

        </b-row>
      </section>
      <div style="height:30px"></div>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: false,
      email: "",
      code: "",
      new_pass: "",
      confirm_pass: "",
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/users/uinfo",
        success: (res) => {
          let obj = res.data
          Object.keys(obj).map((item) => (this[item] = obj[item]))
        },
        tip: () => {},
      })
    },
    save() {
      const loading = this.$loading({
        lock: true,
      })
      this.$post({
        url: "/index/users/resetPass",
        data: {
          code: this.code, //邮箱验证码
          new_pass: this.new_pass, //密码（6-16位英数混合）
          confirm_pass: this.confirm_pass, //确认密码
        },
        success: (res) => {
          this.$toast.success(res.msg, {
            timeout: 1000,
            position: "top-center",
            pauseOnHover: false,
          })
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
            this.go("login")
          }, 1000)
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
form {
  display: block;
  margin: 0 auto;
  max-width: 400px;

  .fieldset {
    margin-bottom: 15px;
    border: 1px solid #e6e6e6;
    border-radius: 4px;
    height: 76px;
    margin-bottom: 15px;

    &:focus-within {
      border: 1px solid #57b846;

      legend {
        color: #57b846;
        margin-left: 20px;
      }
    }

    legend {
      transition: all 0.5s;
      position: relative;
      z-index: 1;
      color: #2f2e3f;
      font-size: 14px;
      margin-left: 10px;
      padding: 0 5px;
      width: auto;
      margin-bottom: 0;
    }

    /deep/.div-input {
      position: relative;
      top: -10px;
      width: 100%;
      height: 60px;
      background: #ffffff;
      // opacity: 1;
      // border: 0.06rem solid #d8d8d8;

      &:focus-within {
        // background: #3c58b2;
        border-color: #3c58b2;
        // box-shadow: 0 0 4px 2px rgba(0, 123, 255, 25%);
        // box-shadow: 0 0 0 0.2rem rgb(28, 41, 83);
      }

      .input-group-text {
        padding: 0 24px;
        background: transparent;
        border-color: transparent !important;
      }

      .div-code {
        padding: 0 12px;

        .btn {
          height: 40px;
          background: #fff;
          color: #333;
          font-size: 14px;
          border: 0;
          padding: 0 14px;
          box-shadow: none;

          &:focus {
            box-shadow: none;
          }
        }
      }

      input {
        box-sizing: border-box;
        height: 60px;
        line-height: 60px;
        background: transparent;
        border-color: transparent !important;

        &.form-control {
          &:focus {
            border-color: none;
            box-shadow: none;
          }
        }
      }

      input::-webkit-input-placeholder {
        color: #d7d7d7;
        font-size: 14px;
      }

      input:-webkit-autofill {
        border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
        box-shadow: 0 0 4px 1000px white inset !important;
        border-color: none;
      }

      input:autofill {
        border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
        box-shadow: 0 0 4px 1000px white inset !important;
        border-color: none;
      }
    }

    .cursor {
      cursor: pointer;
    }
  }
}
</style>