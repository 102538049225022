<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="rent" />
    <!-- 🥒轮播图 -->
    <div class="banner" :style="`background-image: url(${info.vehicle_thumbnail});`">
      <b-container>
        <b-row>
          <b-col xl="6" lg="6" md="12">
            <div class="content">
              <div-text size="40px" color="#fff" bold>{{ info['vehicle_name' + $t('suffix')] }}</div-text>
              <div-height height="20px" />
              <div-text size="20px" color="#cecece">{{ $t('如果您在租赁过程中遇到什么困难,请查看如何租赁或者联系客服进行咨询') }}</div-text>
              <div-height height="80px" />
              <div class="btn_arr">
                <el-button @click="go('how_to_lease')">{{ $t('如何租赁') }}</el-button>
                <el-button class="right_btn" type="success" @click="go('help')">{{ $t('联系客服') }}</el-button>
              </div>
            </div>
          </b-col>
          <!-- 轮播图上的表单 -->
          <b-col xl="6" lg="6" md="12">
            <div class="content_tabs">
              <b-row>
                <b-col cols="12">
                  <!-- ⊥表单盒子 -->
                  <div class="form_box">
                    <div class="form_title">
                      <h3>{{ $t('请下滑到底部查看租车合同') }}</h3>
                    </div>
                    <div-height height="10px"></div-height>
                    <b-row>
                      <b-col cols="12">
                        <div-height height="20px" />
                        <fieldset class="fieldset">
                          <legend>{{ $t('租赁方式') }}*</legend>
                          <div class="down_box">
                            <b-dropdown block :text="{ 1: $t('日租'), 2: $t('月租') }[lease_mode] || $t('请选择租赁方式')">
                              <b-dropdown-item @click="lease_mode = item.id" v-for="(item, index) in addition.lease_mode"
                                :key="index">
                                {{ { 1: $t('日租'), 2: $t('月租') }[item.id] }}
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </fieldset>
                      </b-col>
                      <b-col sm="12" md="12">
                        <fieldset class="fieldset">
                          <legend>{{ $t('取车方式') }}*</legend>
                          <div class="down_box">
                            <b-dropdown :text="{ 1: $t('到店自提'), 2: $t('代驾到府') }[pickup_mode] || $t('请选择取车方式')">
                              <b-dropdown-item @click="pickup_mode = item.id"
                                v-for="(item, index) in addition.pickup_mode" :key="index">{{ {
                                  1: $t('到店自提'), 2: $t('代驾到府')
                                }[item.id] }}</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </fieldset>
                      </b-col>

                      <b-col sm="12" md="6">
                        <fieldset class="fieldset">
                          <legend>{{ $t('取车日期') }}*</legend>
                          <div class="date_box">
                            <b-form-datepicker :locale="$t('locale')"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                               :hide-header="true" selected-variant="success" id="datepicker"
                              v-model="pickup_time" :label-no-date-selected="$t('请选择取车日期')"
                              label-help="" :min="minDate" :max="maxDate"></b-form-datepicker>
                          </div>
                        </fieldset>
                      </b-col>
                      <b-col sm="12" md="6">
                        <fieldset class="fieldset">
                          <legend>{{ $t('取车时间') }}*</legend>
                          <div class="date_box">
                            <!-- <b-form-timepicker :label-no-time-selected="$t('请选择取车时间')" v-model="pickup_time_hour" :locale="$t('locale')" 
                              :label-close-button="$t('确定')" 
                               :hour12="false"
                              ></b-form-timepicker> -->

                            <el-time-select v-model="pickup_time_hour" :picker-options="{
                              start: '09:00',
                              step: '01:00',
                              end: '21:00'
                            }" :placeholder="$t('请选择取车时间')">
                            </el-time-select>


                          </div>
                        </fieldset>
                      </b-col>


                      <b-col cols="12" v-if="pickup_mode != 1">
                        <fieldset class="fieldset">
                          <legend>{{ $t('送车地点') }}*</legend>
                          <div style="display: flex;">
                            <el-cascader :placeholder="$t('请选择')" v-model="f_chauffeur_service_arr" :options="options"
                              @change="(value) => { this.f_chauffeur_service_arr = value }" :show-all-levels="false">
                            </el-cascader>
                            <el-input v-model="f_chauffeur_service_detail_place" :placeholder="$t('请输入送车地址')"></el-input>
                          </div>

                        </fieldset>
                      </b-col>
                      <b-col sm="12" md="12">
                        <fieldset class="fieldset">
                          <legend>{{ $t('还车方式') }}*</legend>
                          <div class="down_box">
                            <b-dropdown :text="{ 1: $t('到店自提'), 2: $t('代驾到府') }[return_mode] || $t('请选择还车方式')">
                              <b-dropdown-item @click="return_mode = item.id"
                                v-for="(item, index) in addition.return_mode" :key="index">{{ {
                                  1: $t('到店自提'), 2: $t('代驾到府')
                                }[item.id] }}</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </fieldset>
                      </b-col>
                      <!-- <b-col sm="12" md="6">
                        <fieldset class="fieldset">
                          <legend>{{ $t('还车时间') }}*</legend>
                          <div class="date_box" v-if="!lease_mode">
                            <el-input disabled v-model="r_chauffeur_service_detail_place"
                              :placeholder="$t('请先选择租赁方式')"></el-input>
                          </div>
                          <div class="date_box" v-if="lease_mode && !pickup_time">
                            <el-input disabled v-model="r_chauffeur_service_detail_place"
                              :placeholder="$t('请先选择取车时间')"></el-input>
                          </div>
                          <div class="date_box" v-if="lease_mode && pickup_time">

                            <b-form-datepicker ref="time_ref" :locale="$t('locale')" :date-disabled-fn="dateDisabled"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                               :hide-header="true" selected-variant="success"
                              id="example-datepicker" v-model="return_time" label-no-date-selected="请选择时间"
                              label-help=""></b-form-datepicker>

                          </div>
                        </fieldset>
                      </b-col> -->

                      <b-col sm="12" md="6">
                        <fieldset class="fieldset">
                          <legend>{{ $t('还车日期') }}*</legend>
                          <div class="date_box" v-if="!lease_mode">
                            <el-input disabled v-model="r_chauffeur_service_detail_place"
                              :placeholder="$t('请先选择租赁方式')"></el-input>
                          </div>
                          <div class="date_box" v-if="lease_mode && !pickup_time">
                            <el-input disabled v-model="r_chauffeur_service_detail_place"
                              :placeholder="$t('请先选择取车日期')"></el-input>
                          </div>
                          <div class="date_box" v-if="lease_mode && pickup_time">
                            <b-form-datepicker ref="time_ref" :locale="$t('locale')" :date-disabled-fn="dateDisabled"
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                               :hide-header="true" selected-variant="success"
                              id="example-datepicker" v-model="return_time" :label-no-date-selected="$t('请选择还车日期')"
                              label-help="" :min="returnMinDate" :max="returnMaxDate"></b-form-datepicker>
                          </div>
                        </fieldset>
                      </b-col>

                      <b-col sm="12" md="6">
                        <fieldset class="fieldset">
                          <legend>{{ $t('还车时间') }}*</legend>
                          <div class="date_box">
                            <!-- <b-form-timepicker v-model="return_time_hour" :locale="$t('locale')"  :label-no-time-selected="$t('请选择还车时间')" 
                              :label-close-button="$t('确定')" 
                               :hour12="false"
                              ></b-form-timepicker> -->

                            <el-time-select v-model="return_time_hour" :picker-options="{
                              start: '09:00',
                              step: '01:00',
                              end: '21:00'
                            }" :placeholder="$t('请选择还车时间')">
                            </el-time-select>


                          </div>
                        </fieldset>
                      </b-col>

                      <b-col cols="12" v-if="return_mode != 1">
                        <fieldset class="fieldset">
                          <legend>{{ $t('还车地点') }}*</legend>
                          <div style="display: flex;">
                            <el-cascader :placeholder="$t('请选择')" v-model="r_chauffeur_service_arr" :options="options"
                              @change="(value) => { this.r_chauffeur_service_arr = value }" :show-all-levels="false">
                            </el-cascader>
                            <el-input v-model="r_chauffeur_service_detail_place" :placeholder="$t('请输入还车地址')"></el-input>
                          </div>
                        </fieldset>
                      </b-col>

                      <b-col sm="12" md="12" v-if="!info.is_super_car">
                        <fieldset class="fieldset">
                          <legend>{{ $t('汽车保险') }}*</legend>
                          <div class="down_box">
                            <b-dropdown :text="{ 1: $t('自付額500元方案'), 2: $t('自付額2500元方案') }[carbody_ins_type] || $t('请选择汽车保险')">
                              <b-dropdown-item @click="carbody_ins_type = item.id"
                                v-for="(item, index) in addition.insurance_strategies" :key="index">{{ {
                                  1: $t('自付額500元方案'),
                                  2: $t('自付額2500元方案')
                                }[item.id] }}</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </fieldset>
                      </b-col>
                      <b-col sm="12" md="12" v-if="!info.is_super_car && info.warranty_ins_is_disabled == 2">
                        <fieldset class="fieldset">
                          <legend>{{ $t('新车险') }}*</legend>
                          <div class="down_box">
                            <b-dropdown :text="{ 1: $t('是'), 2: $t('否') }[warranty_ins_sel] || $t('选配购买')">
                              <b-dropdown-item @click="warranty_ins_sel = item.id"
                                v-for="(item, index) in addition.warranty_ins" :key="index">{{ { 1: $t('是'), 2: $t('否') }
                                [item.id] }}</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </fieldset>
                      </b-col>
                      <!-- 如果是超跑，就不显示汽车保险和新车险 -->
                      <b-col sm="12" md="12" v-if="info.is_super_car">
                        <fieldset class="fieldset">
                          <legend>{{ $t('超跑保险') }}*</legend>
                          <div class="down_box">
                            <b-dropdown :text="{ 1: $t('是'), 2: $t('否') }[super_car_sel] || $t('选配购买')">
                              <b-dropdown-item @click="super_car_sel = item.id" v-for="(item, index) in [{ id: 1 }]"
                                :key="index">{{ { 1: $t('是'), 2: $t('否') }
                                [item.id] }}</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </fieldset>
                      </b-col>
                      <b-col cols="12">
                        <!-- <div-text right>{{ $t('计算规则为7天一周期，不满7则按7天计算') }}</div-text> -->
                      </b-col>
                      <div-height height="15px" />

                      <b-col cols="12">
                        <div-save ref="btn" @save="set_form()">{{ $t('提交') }}</div-save>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container>
      <section>
        <!-- 🍞面包屑 -->

        <div-padding padding="20px 0">
          <b-row>
            <b-col cols="auto" class="mr-auto">
              <div-left center>
                <div-text color="#1D2124" size="20px" bold>{{ $t('车辆信息') }}</div-text>
              </div-left>
            </b-col>
            <b-col cols="auto">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{ $t('首页') }}</b-breadcrumb-item>
                <b-breadcrumb-item @click="go('rent_list')">{{ $t('汽车租赁') }}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{ $t('我要租赁') }}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>




        <div-zebra></div-zebra>

        <div class="zebra_box shadow">
          <b-row>
            <!-- quintile 五等分 -->
            <b-col sm="6" md="6" class="quintile_box">
              <div class="quintile">
                <div-text class="left_text" color="#818181">{{ $t('车种') }}</div-text>
                <div-text color="#1D2124">{{ info['classi_name' + $t('suffix')] }}</div-text>
              </div>
            </b-col>
            <b-col sm="6" md="6" class="quintile_box">
              <div class="quintile">
                <div-text class="left_text" color="#818181">{{ $t('品牌') }}</div-text>
                <div-text color="#1D2124">{{ info['brand_name' + $t('suffix')] }}</div-text>
              </div>
            </b-col>
            <b-col sm="6" md="6" class="quintile_box">
              <div class="quintile">
                <div-text class="left_text" color="#818181">{{ $t('型号') }}</div-text>
                <div-text color="#1D2124">{{ info['model_name' + $t('suffix')] }}</div-text>
              </div>
            </b-col>
            <b-col sm="6" md="6" class="quintile_box">
              <div class="quintile">
                <div-text class="left_text" color="#818181">{{ $t('年限') }}</div-text>
                <div-text color="#1D2124">{{ info['life_name' + $t('suffix')] }}</div-text>
              </div>
            </b-col>
            <b-col sm="12" md="12" class="quintile_box">
              <div class="quintile_card">
                <div-center>
                  <div-img width="111px" height="73px">
                    <img :src="info.vehicle_thumbnail" alt="" />
                  </div-img>
                </div-center>
              </div>
            </b-col>
          </b-row>
        </div>
        <div-height height="20px" />
        <el-collapse accordion v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <div style="width:100%">
                <div-padding padding=" 0">
                  <div-text color="#1D2124" size="20px" bold>{{ $t('租赁明细') }}</div-text>
                </div-padding>
                <div-zebra></div-zebra>

              </div>
            </template>
            <div class="table_box">
              <table style="width: 100%">
                <colgroup style="width: 33.33%"></colgroup>
                <colgroup style="width: 33.33%"></colgroup>
                <colgroup style="width: 33.33%"></colgroup>
                <tr>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('租车费用') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('租赁天数') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ form.rental_days }} {{ $t('天') }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="4" colspan="1" align="center" valign="middle">
                    <div>{{ $t('附加费用') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('取车地点') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.pickup_fee }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('取车时间') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle" style="border-width: 0px 1px 1px 1px">
                    <div>cad {{ form.pickup_holiday_fee }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('还车地点') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.return_fee }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('还车时间') }}</div>
                  </td>
                  <td rowspan="1" colspan="3" align="center" valign="middle" style="border-width: 0px 1px 1px 1px">
                    <div>cad {{ form.return_holiday_fee }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>{{ $t('租车费用小计') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.rental_fee_b_tax }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>
                      {{ $t('联邦政府商品及服务税') }}
                      <!-- {{$t('locale')=='en'?'(GST':''}}   
                      {{ $np.times(+form.tax_rate_pst, 100)}}%
                      {{$t('locale')=='en'?')':''}} -->
                    </div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.tax_pst }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>
                      {{ $t('省销售税') }}
                      <!-- {{$t('locale')=='en'?'(HST':''}}
                      {{ $np.times(+form.tax_rate_gst, 100) }}%
                      {{$t('locale')=='en'?')':''}} -->
                    </div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.tax_gst }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>{{ $t('租车总计金额费用') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.rental_fee_a_tax }}</div>
                  </td>
                </tr>

                <tr>
                  <td rowspan="2" colspan="1" align="center" valign="middle">
                    <div>{{ $t('押金费用') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('驾照押金') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.license_deposit }}</div>
                  </td>
                </tr>
                <tr>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('租车押金') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.vehicle_deposit }}</div>
                  </td>
                </tr>

                <tr>
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>{{ $t('押金总额') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.deposit_fee }}</div>
                  </td>
                </tr>


                <!-- 不是超跑 -->
                <template v-if="!info.is_super_car">
                <tr>
                  <td :rowspan=" info.warranty_ins_is_disabled == 2 ? 2: 1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('保险费用') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ { 1: $t('汽车保险500元方案'), 2: $t('汽车保险500元方案') }[carbody_ins_type] }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>CAD {{ form.carbody_ins_fee }}</div>
                  </td>
                </tr>
                <tr v-if="info.warranty_ins_is_disabled == 2">
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('新车险') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>CAD {{ form.warranty_ins_fee }}</div>
                  </td>
                </tr>
                </template>

                <!--是超跑-->
                <tr v-if="info.is_super_car">
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('保险费用') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('超跑险') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>CAD {{ form.supercar_ins_fee }}</div>
                  </td>
                </tr>

                <tr v-if="form.attach_not_cad_license_fee > 0||form.attach_age_lt25_fee > 0">
                  <td rowspan="2" colspan="1" align="center" valign="middle">
                    <div>{{ $t('其他附加费用') }}</div>
                  </td>
                  <td v-if="form.attach_age_lt25_fee > 0" rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('未满25岁') }}</div>
                  </td>
                  <td v-if="form.attach_age_lt25_fee > 0" rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.attach_age_lt25_fee }}</div>
                  </td>
                </tr>
                <tr v-if="form.attach_not_cad_license_fee > 0">
                  <td  rowspan="1" colspan="1" align="center" valign="middle">
                    <div>{{ $t('非加拿大驾照') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.attach_not_cad_license_fee }}</div>
                  </td>
                </tr>

                <tr class="th">
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>{{ $t('保险/其他费用小计') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.ins_and_attach_fee }}</div>
                  </td>
                </tr>
                <tr class="th">
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>{{ $t('总计金额') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.total }}</div>
                  </td>
                </tr>
                <tr class="th">
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>{{ $t('定金金额') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.forepay_fee }}</div>
                  </td>
                </tr>
                <tr class="th">
                  <td rowspan="1" colspan="2" align="center" valign="middle">
                    <div>{{ $t('尾款金额') }}</div>
                  </td>
                  <td rowspan="1" colspan="1" align="center" valign="middle">
                    <div>cad {{ form.tailpay_fee }}</div>
                  </td>
                </tr>
              </table>
            </div>
          </el-collapse-item>
        </el-collapse>

        <!-- 标题 -->
        <div-height height="20px" />

        <!-- 标题 -->
        <div-padding padding="20px 0">
          <div-text color="#1D2124" size="20px" bold>{{ $t('租车合同') }}</div-text>
        </div-padding>

        <div-zebra></div-zebra>

        <div class="zebra_box shadow">
          <div-both center br>
            <div-left center>
              <div-text color="#1D2124" size="26px" bold>{{ $t('沛豪国际租赁有限公司租赁合同') }}</div-text>
            </div-left>
            <div-right center>
              <div-text color="#B7B7B7" @click.native="down_img()" size="18px" class="a_btn">
                {{ $t('预览合同') }}
              </div-text>
              <div-text color="#B7B7B7" size="18px" @click.native="show_modaltwo()" class="a_btn ml-4" v-if="signature">
                {{ $t('签订合同（已签字）') }}
              </div-text>
              <div-text color="#B7B7B7" size="18px" @click.native="show_modal()" class="a_btn ml-4" v-else>
                {{ $t('签订合同') }}
              </div-text>
            </div-right>
          </div-both>
        </div>
        <div-padding padding="20px 0">
          <div-text color="#1D2124" size="20px" bold>{{ $t('付款方式') }}</div-text>
        </div-padding>
        <div-zebra></div-zebra>
        <div class="zebra_box shadow">
        <div-both center br>
            <div-right center style="width:100%;display: block;text-align: center;">
              <b-form-group>
                <b-form-radio-group id="radio-group-1" v-model="selected"
                  name="radio-options">
                  <b-form-radio value='1'>{{  $t('在线支付') }}</b-form-radio>
                  <b-form-radio value='2'>{{  $t('线下支付') }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </div-right>
          </div-both>
          </div>
      </section>
      <div-height height="50px" />
      <div-save ref="btn_01" @save="save">{{ $t('生成订单') }}</div-save>
      <div-height height="50px" />
    </b-container>

    <div style="position: absolute; z-index: 1040" v-if="Sign">
      <div class="modal fade" :class="{ show: Sign2 }">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <header class="modal-header">
              <h5 class="modal-title">{{ $t('电子签名') }}</h5>
              <button @click.stop="hide_modal()" type="button" class="close">
                ×
              </button>
            </header>
            <div class="modal-body">
              <VPerfectSignature :stroke-options="strokeOptions" ref="signaturePad" />
            </div>
            <footer class="modal-footer">
              <div-save ref="divsave" @save="updata(1)">{{ $t('确认签署') }}</div-save>
            </footer>
          </div>
        </div>
      </div>
      <div class="modal-backdrop" @click.stop="hide_modal()"></div>

    </div>
    <div class="heyuemodel" style="position: absolute; z-index: 1040;" v-if="signMode">
      <div class="modal fade" :class="{ show: Sign2 }">
        <div class="modal-dialog modal-md">
          <div class="modal-content">
            <header class="modal-header">
              <h5 class="modal-title">{{ $t('电子合约') }}</h5>
              <button @click.stop="hide_modaltwo()" type="button" class="close">
                ×
              </button>
            </header>
            <div class="modal-body">
              <embed :src='httpPath +
        "/index/rentalorders/contractPreview?token=" +
        token+ "&id="+id +"&lease_mode="+lease_mode+"&pickup_mode="+pickup_mode+"&pickup_time="+pickup_time + " " + pickup_time_hour+(pickup_mode == 2?
          ("&f_chauffeur_service_id="+f_chauffeur_service_id+
          "&f_chauffeur_service_detail_id="+f_chauffeur_service_detail_id+
          "&f_chauffeur_service_detail_place="+f_chauffeur_service_detail_place):"&qq=1")+
          "&return_mode="+return_mode+
          "&return_time="+return_time + " " + return_time_hour+(return_mode == 2?
          ("&r_chauffeur_service_id="+r_chauffeur_service_id+
          "&r_chauffeur_service_detail_id="+r_chauffeur_service_detail_id+
          "&r_chauffeur_service_detail_place="+r_chauffeur_service_detail_place):"&qq=1")+
          "&carbody_ins_type="+carbody_ins_type+
          "&warranty_ins_sel="+warranty_ins_sel+
          "&super_car_sel="+super_car_sel+
          "&signature="+signature' type="application/pdf" width="100%" height="100%" />
            </div>
            <footer class="modal-footer">
              <div-save @save="updata(2)">{{ $t('确认') }}</div-save>
            </footer>
          </div>
        </div>
      </div>
      <div class="modal-backdrop" @click.stop="hide_modaltwo()"></div>

    </div>

  </div>
</template>
<script>
const cubic = (value) => Math.pow(value, 3)
const easeInOutCubic = (value) =>
  value < 0.5 ? cubic(value * 2) / 2 : 1 - cubic((1 - value) * 2) / 2

import VPerfectSignature from "v-perfect-signature"

export default {
  components: {
    VPerfectSignature,
  },
  data() {
    return {
      info: {}, //车辆信息
      addition: {}, //表单数据
      lease_mode: "", //租赁方式id
      pickup_mode: "", //取车方式id
      pickup_time: "", //取车时间
      return_time: "", //还车时间
      return_mode: "", //还车方式id
      f_chauffeur_service_arr: [], //取车地点数组
      r_chauffeur_service_arr: [], //还车地点数组
      f_chauffeur_service_detail_place: "", //取车地点
      r_chauffeur_service_detail_place: "", //还车地点
      carbody_ins_type: "", //保险策略
      warranty_ins_sel: "", //保险id
      super_car_sel: "1", //超值车型id
      options: [], //地址数据
      form: {}, //表单数据

      signature: "", //签名图片
      Sign: false,
      Sign2: false,
      strokeOptions: {
        size: 4,
        thinning: 0.5,
        smoothing: 0.5,
        streamline: 0.5,
      }, //签名
      token: localStorage.getItem("zuche_token"),

      activeNames: ["1"], //折叠面板

      pickup_time_hour: "", //新增取车小时
      return_time_hour: "", //新增还车小时
      selected: 1,
      signMode:false,
      minDate: null,
      maxDate: null,
      returnMinDate:null,
      returnMaxDate:null
    }
  },
  created() {
    this.urlparam()

    let arr01 = this.pickup_time.split(" ")

    this.pickup_time = arr01[0]
    this.pickup_time_hour = arr01[1]

    let arr02 = this.return_time.split(" ")

    this.return_time = arr02[0]
    this.return_time_hour = arr02[1]
    this.get_info()

    setTimeout(() => {
      this.scrollToBottom()
    }, 500)
  },
  mounted() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const disabledStartYear = currentYear - 2;
    const disabledEndYear = currentYear + 2;
    const disabledEndRetrunYear = currentYear + 3;

    this.minDate = new Date(disabledStartYear, 0, 1);
    this.maxDate = new Date(disabledEndYear, 11, 31);
    this.returnMinDate = new Date(disabledStartYear, 0, 1);
    this.returnMaxDate = new Date(disabledEndRetrunYear,0,30)
   },
  methods: {
    scrollToBottom() {
      // 还有这里，如果document.documentElement.scrollTop 的值为0，就获取document.body元素
      let height = document.documentElement.scrollHeight
      const beginTime = Date.now()

      console.log(height)

      const rAF =
        window.requestAnimationFrame || ((func) => setTimeout(func, 16))
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500
        if (progress < 1) {
          window.scrollTo(0, height * (0 + easeInOutCubic(progress)))

          rAF(frameFunc)
        } else {
          window.scrollTo(0, height)
        }
      }
      rAF(frameFunc)
    },
    dateDisabled(ymd, date) {
      if (this.lease_mode == 1) {
        return false
      } else {
        // 已知 x=年月日（如2023/1/30）已知 y=年月 （如2023/3） 求 z 为 除了 当前 y年月中 是 x年月日的30倍的那天 的数组

        if (this.$refs.time_ref) {
          var tempDate = new Date(this.pickup_time.replace(/-/g, "/"))
          // console.log("查看tempDate", this.js_biao_year(tempDate))
          let now_arr = []
          var now = tempDate

          for (var i = 1; i < 36; i++) {
            if (i == 1) {
              now_arr.push(
                this.js_biao_year(now.setDate(tempDate.getDate() + 29))
              )
            } else {
              now_arr.push(
                this.js_biao_year(now.setDate(tempDate.getDate() + 30))
              )
            }
          }

          // console.log("查看now_arr", now_arr)
          return !now_arr.includes(ymd)
        }
      }
    },
    js_biao_year(time) {
      var date = new Date(time)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? "0" + m : m
      var d = date.getDate()
      d = d < 10 ? "0" + d : d
      return y + "-" + m + "-" + d
    },
    get_info() {
      this.$get({
        url: "/index/rentalorders/orderFormPageData",
        data: {
          id: this.id,
        },
        success: (res) => {
          this.info = res.data.vehicle
          this.addition = res.data.addition

          //取车还车方式
          this.f_chauffeur_service_arr = [
            +this.f_chauffeur_service_id,
            +this.f_chauffeur_service_detail_id,
          ]
          this.r_chauffeur_service_arr = [
            +this.r_chauffeur_service_id,
            +this.r_chauffeur_service_detail_id,
          ]

          console.log(this.f_chauffeur_service_arr)
          //修改地址数据
          this.options = this.addition.chauffeur_datas.map((v) => ({
            value: v.id,
            label: v,
            children: v.subs.map((v2) => ({
              value: v2.id,
              label: v2["area_name" + this.$t("suffix")],
            })),
          }))
          this.get_form()
        },
        tip: () => { },
      })
    },
    //通过id获取车辆信息
    find_name(arr, id) {
      if (arr) {
        // console.log("查看数组", arr)
        let name = ""
        arr.forEach((item) => {
          if (item.id == id) {
            name = item.name
          }
        })
        return name
      }
    },
    set_form() {
      let data = {
        id: this.id, //订单id
        lease_mode: this.lease_mode, //租赁方式
        pickup_mode: this.pickup_mode, //取车方式
        pickup_time: this.pickup_time + " " + this.pickup_time_hour, //取车时间
        return_mode: this.return_mode, //还车方式
        return_time: this.return_time + " " + this.return_time_hour, //还车时间
        // carbody_ins_type: this.carbody_ins_type, //车身保险
        // warranty_ins_sel: this.warranty_ins_sel, //车辆保修
        // super_car_sel: this.super_car_sel, //超级车
      }

      if (this.info.is_super_car) {
        data.super_car_sel = this.super_car_sel //超级车
      } else {
        data.carbody_ins_type = this.carbody_ins_type //车身保险
        data.warranty_ins_sel = this.warranty_ins_sel //车辆保修
      }

      if (this.pickup_mode == 2) {
        data.f_chauffeur_service_id = this.f_chauffeur_service_arr[0] //取车地点（区域一级）
        data.f_chauffeur_service_detail_id = this.f_chauffeur_service_arr[1] //取车地点（区域二级）
        data.f_chauffeur_service_detail_place =
          this.f_chauffeur_service_detail_place //取车地点（详细地址）
      }

      if (this.return_mode == 2) {
        data.r_chauffeur_service_id = this.r_chauffeur_service_arr[0] //还车地点（区域一级）
        data.r_chauffeur_service_detail_id = this.r_chauffeur_service_arr[1] //还车地点（区域二级）
        data.r_chauffeur_service_detail_place =
          this.r_chauffeur_service_detail_place //还车地点（详细地址）
      }

      const loading = this.$loading({
        lock: true,
      })

      this.$post({
        url: "/index/rentalorders/bill",
        data: data,
        success: (res) => {
          setTimeout(() => {
            this.$refs.btn.disabled = false
          }, 1000)
          //跳转到当前页面
          setTimeout(() => {
            loading.close()
            this.$router.push({
              query: data,
            })
            this.scrollToBottom()
          }, 2000)
        },
        tip: () => {
          console.log("3")
          setTimeout(() => {
            this.$refs.btn.disabled = false
            loading.close()
          }, 3000)
        },
      })
    },
    save() {
      //创建订单
      this.$post({
        url: "/index/rentalorders/uniOrder",
        data: {
          id: this.id, //订单id
          lease_mode: this.lease_mode, //租赁方式
          pickup_mode: this.pickup_mode, //取车方式
          pickup_time: this.pickup_time + " " + this.pickup_time_hour, //取车时间
          f_chauffeur_service_id: this.f_chauffeur_service_id, //取车地点（区域一级）
          f_chauffeur_service_detail_id: this.f_chauffeur_service_detail_id, //取车地点（区域二级）
          f_chauffeur_service_detail_place:
            this.f_chauffeur_service_detail_place, //取车地点（详细地址）
          return_mode: this.return_mode, //还车方式
          return_time: this.return_time + " " + this.return_time_hour, //还车时间
          r_chauffeur_service_id: this.r_chauffeur_service_id, //还车地点（区域一级）
          r_chauffeur_service_detail_id: this.r_chauffeur_service_detail_id, //还车地点（区域二级）
          r_chauffeur_service_detail_place:
            this.r_chauffeur_service_detail_place, //还车地点（详细地址）
          carbody_ins_type: this.carbody_ins_type, //车身保险
          warranty_ins_sel: this.warranty_ins_sel, //车辆保修
          super_car_sel: this.super_car_sel, //超级车
          signature: this.signature, //签名,
          type:this.selected, //付款方式
        },
        success: (res) => {
          if(this.selected == 2){
            this.go("order_list")
          }else{
            this.go("rent_pay", res.data)
          }


          // setTimeout(() => {
          //   this.$refs.btn_01.disabled = false
          // }, 3000)
        },
        tip: () => {
          setTimeout(() => {
            this.$refs.btn_01.disabled = false
          }, 3000)
        },
      })
    },
    down_img() {
      //预览合同
      //未签字预览合同
      if(this.signature){
      window.open(
        this.httpPath +
        "/index/rentalorders/contractPreview?token=" +
        this.token+ "&id="+this.id +"&lease_mode="+this.lease_mode+"&pickup_mode="+this.pickup_mode+"&pickup_time="+this.pickup_time + " " + this.pickup_time_hour+
        (this.pickup_mode == 2?
          ("&f_chauffeur_service_id="+this.f_chauffeur_service_id+
          "&f_chauffeur_service_detail_id="+this.f_chauffeur_service_detail_id+
          "&f_chauffeur_service_detail_place="+this.f_chauffeur_service_detail_place):'&qq=1')+
          "&return_mode="+this.return_mode+
          "&return_time="+this.return_time + " " + this.return_time_hour+
          (this.return_mode == 2?
          ("&r_chauffeur_service_id="+this.r_chauffeur_service_id+
          "&r_chauffeur_service_detail_id="+this.r_chauffeur_service_detail_id+
          "&r_chauffeur_service_detail_place="+this.r_chauffeur_service_detail_place):'&qq=1')+
          "&carbody_ins_type="+this.carbody_ins_type+
          "&warranty_ins_sel="+this.warranty_ins_sel+
          "&super_car_sel="+this.super_car_sel+
          "&signature="+this.signature
      )}else{
        window.open(
        this.httpPath +
        "/index/rentalorders/contractPreview?token=" +
        this.token+ "&id="+this.id +"&lease_mode="+this.lease_mode+"&pickup_mode="+this.pickup_mode+"&pickup_time="+this.pickup_time + " " + this.pickup_time_hour+
        (this.pickup_mode == 2?
          ("&f_chauffeur_service_id="+this.f_chauffeur_service_id+
          "&f_chauffeur_service_detail_id="+this.f_chauffeur_service_detail_id+
          "&f_chauffeur_service_detail_place="+this.f_chauffeur_service_detail_place):'&qq=1')+
          "&return_mode="+this.return_mode+
          "&return_time="+this.return_time + " " + this.return_time_hour+
          (this.return_mode == 2?
          ("&r_chauffeur_service_id="+this.r_chauffeur_service_id+
          "&r_chauffeur_service_detail_id="+this.r_chauffeur_service_detail_id+
          "&r_chauffeur_service_detail_place="+this.r_chauffeur_service_detail_place):'&qq=1')+
          "&carbody_ins_type="+this.carbody_ins_type+
          "&warranty_ins_sel="+this.warranty_ins_sel+
          "&super_car_sel="+this.super_car_sel
        )
      }
    },

    get_form() {
      this.$post({
        url: "/index/rentalorders/bill",
        data: {
          id: this.id, //订单id
          lease_mode: this.lease_mode, //租赁方式
          pickup_mode: this.pickup_mode, //取车方式
          pickup_time: this.pickup_time + " " + this.pickup_time_hour, //取车时间
          f_chauffeur_service_id: this.f_chauffeur_service_id, //取车地点（区域一级）
          f_chauffeur_service_detail_id: this.f_chauffeur_service_detail_id, //取车地点（区域二级）
          f_chauffeur_service_detail_place:
            this.f_chauffeur_service_detail_place, //取车地点（详细地址）
          return_mode: this.return_mode, //还车方式
          return_time: this.return_time + " " + this.return_time_hour, //还车时间
          r_chauffeur_service_id: this.r_chauffeur_service_id, //还车地点（区域一级）
          r_chauffeur_service_detail_id: this.r_chauffeur_service_detail_id, //还车地点（区域二级）
          r_chauffeur_service_detail_place:
            this.r_chauffeur_service_detail_place, //还车地点（详细地址）
          carbody_ins_type: this.carbody_ins_type, //车身保险
          warranty_ins_sel: this.warranty_ins_sel, //车辆保修
          super_car_sel: this.super_car_sel, //超级车
        },
        success: (res) => {
          this.form = res.data
        },
        tip: () => { },
      })
    },

    show_modal() {
      this.Sign = true
      setTimeout(() => {
        this.Sign2 = true
      }, 0)
    },
    show_modaltwo(){
      this.signMode = true
      setTimeout(() => {
        this.Sign2 = true
      }, 0)
    },
    hide_modal() {
      setTimeout(() => {
        this.Sign2 = false
      }, 0)
      setTimeout(() => {
        this.Sign = false
      }, 250)
    },
    hide_modaltwo() {
      setTimeout(() => {
        this.Sign2 = false
      }, 0)
      setTimeout(() => {
        this.signMode = false
      }, 250)
    },
    updata(i) {

      if(i == 1){
        if(this.$refs.signaturePad.toDataURL()){
      this.Sign = false
      let formData = new FormData()
      formData.append(this.name || "file", this.$refs.signaturePad.toDataURL()) //获取文件详细信息
      formData.append("env", 1)
      this.$post({
        url: this.url || "/index/upload/uploadImgs",
        data: formData,
        upload: true,
        success: (res) => {
          this.signature = res.data
          // localStorage.setItem("signature", this.signature)
          console.log(this.signature)
          this.signMode = true
        this.$refs.divsave.disabled = false
        },
        tip: () => { },
      })
    }else{
      this.$refs.divsave.disabled = false
    }
      }else{
        this.signMode = false
        }
    }
  },
}
</script>
<style lang="scss" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;

  /deep/.el-input__prefix {
    display: none !important;
  }

  /deep/.el-input__inner {
    padding-left: 0.75rem !important;
  }

  /deep/ .el-icon-time {
    background: #57b846 !important;
    display: none !important;
  }
}

/deep/.button-danger {
  display: none !important;
}

/deep/.b-form-btn-label-control.form-control>.form-control {
  min-height: auto !important;
  height: 35px;
}

/deep/.btn-outline-success {
  color: #67c23a;
  border-color: #67c23a;
}

/deep/.btn-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}

/deep/.btn-success:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}

/deep/.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}

/deep/.b-form-btn-label-control.form-control>.dropdown-menu {
  transform: translate3d(0, 41px, 0px) !important;
}

/deep/.btn .b-icon.bi,
/deep/.nav-link .b-icon.bi,
/deep/.dropdown-toggle .b-icon.bi,
/deep/.dropdown-item .b-icon.bi,
/deep/.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
  // color: #67c23a;
}

//顶部栏广告
.banner {
  min-height: 800px;
  width: 100%;
  position: relative;
  background-repeat: repeat;
  background-size: 100%;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
  }

  .content {
    display: block;
    position: relative;
    z-index: 999;
    overflow: hidden;
    padding-top: 250px;
  }

  .content_tabs {
    display: block;
    position: relative;
    z-index: 999;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

/deep/.el-cascader {
  .el-input__inner {
    width: 100px !important;
    border: 0px solid #e6e6e6 !important;
  }
}

/deep/.el-input__inner {
  // width: 100px !important;
  border: 0px solid #e6e6e6 !important;
}

// 🌰下拉菜单盒子
.down_box {
  /deep/.btn {
    font-size: 14px;
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /deep/.btn-secondary {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }

  /deep/.btn-secondary.dropdown-toggle {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }

  /deep/.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }

  /deep/.dropdown {
    width: 100%;
  }

  /deep/.dropdown-item.active {
    background-color: #fff;
    color: #333;
  }

  /deep/.dropdown-item:active {
    background-color: #fff;
    color: #333;
  }

  /deep/.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /deep/.dropdown-menu {
    width: 100%;
    padding: 0;
    font-size: 14px;
  }

  /deep/.dropdown-divider {
    margin: 0;
  }

  /deep/.dropdown-item {
    padding: 10px 20px;
  }
}

.date_box {
  button {
    display: initial !important;
  }



  /deep/.dropdown {
    flex-flow: row-reverse;
    border: 0;
    line-height: 2;
  }

  /deep/.form-control {
    background: transparent;
    text-align: left;
    padding: 0.375rem 0.75rem;
  }

  /deep/.form-control:focus {
    position: relative;
    z-index: 1;
    border-color: #57b846;
    box-shadow: none;
  }

  /deep/.form-control.focus {
    position: relative;
    z-index: 1;
    border-color: #57b846;
    box-shadow: none;
  }
}

.modal.fade .modal-dialog {
  margin-top: 12rem;
}

.modal {
  display: block;
}

.a_btn {
  cursor: pointer;
}

.text {
  padding: 0 30px;
  height: 70px;
  line-height: 70px;
  font-size: 15px;
  border: 1px solid #d8d8d8;
  text-align: center;
  border-top: 0;
  color: #b7b7b7;
}

.quintile_box {

  // flex: 20%;
  .quintile {
    width: 100%;
    text-align: center;

    .div-text {
      min-height: 37px;
      line-height: 37px;
    }
  }

  .quintile_card {
    width: 100%;
    text-align: center;
  }
}

.breadcrumb {
  background-color: #fff;
  padding: 0;
  margin-bottom: 0;

  .breadcrumb-item {
    a {
      color: #000;
    }
  }
}

.image {
  min-height: 120px;
}

.table_box {
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.table_item {
  flex: 12.5%;
  max-width: 12.5%;
}

.table_item2 {
  flex: 87.5%;
  max-width: 87.5%;
}

//商品信息
.item {
  padding: 0 20px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }
}

.zebra_box {
  padding: 20px;
}

//表格样式
.form-control {
  height: 70px;
  border-radius: 0;
  font-size: 15px;
  box-shadow: none;
  padding: 0 30px;
  border: 1px solid #d8d8d8;

  background: #ffffff;
  color: #707e9c;

  text-align: center;

  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;

  overflow: visible;
  font-size: 13px;
  color: #93837b;
}

.form-control:focus {
  position: relative;
  z-index: 1;
  // border: 1px solid #d8d8d8;
  border-color: #57b846;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.label {
  height: 70px;
  border-radius: 0;
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  display: block;
  width: 100%;
  line-height: 70px;
  text-align: center;
  background: rgba(215, 215, 215, 0.1);
  margin: -1px -1px 0 0;
  overflow: visible;
  font-size: 13px;
  color: #1d2124;
  font-weight: bold;
}

@media #{$xl} {
  .quintile_box {
    flex: 20%;
  }

  label {
    border: 1px solid #d8d8d8;
  }

  .avatar {
    border-right-width: 0;
  }

  .no_border {
    border-right-width: 0;
  }
}

@media #{$lg} {
  .quintile_box {
    flex: 20%;
  }

  label {
    border: 1px solid #d8d8d8;
  }
}

@media #{$md} {
  label {
    border: 1px solid #d8d8d8;
  }
}

@media #{$sm} {
  .quintile_box {

    // flex: 20%;
    .quintile {
      width: 100%;
      text-align: center;
      display: block;

      .div-text {
        min-height: 37px;
        line-height: 37px;
      }

      .div-text.left_text {
        flex-shrink: 0;
        margin-right: 0;
      }
    }
  }

  .table {
    border: none;
  }

  .form-control {
    text-align: left;
    border-radius: 4px;
  }

  .label {
    text-align: left;
    border: none;
    background: #fff;
    padding: 0;
    margin-top: 0;
  }

  .text {
    border: none;
  }
}

.btn_arr {
  .right_btn {
    margin-left: 10px;
  }
}

@media #{$xs} {
  .banner .content {
    padding-top: 70px;
  }

  .btn_arr {
    .el-button {
      width: 100%;
      margin-bottom: 10px;
    }

    .right_btn {
      margin-left: 0;
    }
  }

  .table_box {
    padding: 30px 0;
  }

  .quintile_box {

    // flex: 20%;
    .quintile {
      width: 100%;
      text-align: center;

      display: flex;

      .div-text {
        min-height: 37px;
        line-height: 37px;
      }

      .div-text.left_text {
        flex-shrink: 0;
        margin-right: 30px;
      }
    }
  }

  .table {
    border: none;
  }

  .label {
    text-align: left;
    border: none;
    background: #fff;
    padding: 0;
    margin-top: 0;
  }

  .form-control {
    text-align: left;
    border-radius: 4px;
  }

  .text {
    border: none;
    align-items: center;
    padding: 0 30px;
    display: flex;
    height: 100px;
    line-height: 30px;
  }
}

td {
  height: 70px;
  line-height: 70px;
  color: #818181;
  border: 1px solid #d8d8d8;
  font-size: 14px;
}

.th {
  background: #fbfbfb;
  border: 1px solid #d8d8d8;
}

/*-- hs Slider Start --*/

.form_box {
  padding: 30px;
  background: #ffffff;
  position: relative;
  padding-top: 65px;
  min-height: 300px;
  border-radius: 10px;

  .form_title {
    background: #333;
    width: 320px;
    position: absolute;
    top: 20px;
    height: 50px;
    left: -20px;
    text-align: center;

    &:after {
      content: "";
      position: absolute;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 21px solid #333;
      right: -21px;
      top: 0px;
    }

    &:before {
      content: "";
      position: absolute;
      border-bottom: 17px solid transparent;
      border-right: 19px solid #333;
      left: 0;
      bottom: -17px;
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
      color: #ffffff;
      text-transform: capitalize;
      line-height: 50px;
    }
  }
}

.dropdown-menu {
  padding: 5px 0 !important;
}

// 表单样式
.fieldset {
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 67px;
  @include transition(0.4s);

  &:focus-within {
    border: 1px solid #57b846;

    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }

  legend {
    @include transition(0.4s);
    position: relative;
    color: #2f2e3f;
    font-size: 16px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: bold;
  }

  /deep/.div-input {
    position: relative;
    top: -10px;
    width: 100%;
    height: 50px;
    background: #ffffff;

    &:focus-within {
      border-color: #3c58b2;
    }

    .input-group-text {
      padding: 0 24px;
      background: transparent;
      border-color: transparent !important;
    }

    input {
      box-sizing: border-box;
      height: 50px;
      line-height: 50px;
      background: transparent;
      border-color: transparent !important;

      &.form-control {
        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }

    input::-webkit-input-placeholder {
      color: #d7d7d7;
    }

    input:-webkit-autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
    }

    input:autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
    }
  }
}
/deep/.custom-control{
  width: 50%;
  margin-right: 0;
}
/deep/.custom-control-label{
  cursor: pointer;
}

/deep/ .heyuemodel .modal-dialog {
  margin:5vw;
  margin-top: 5vh !important;
.modal-content{
  width: 90vw !important;
  height: 90vh !important;
}
}
</style>