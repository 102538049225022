<template>
  <b-row no-gutters class="table">
    <!-- 第一行 -->

    <div v-for="(item,index) in list" :key="index">
      <b-row no-gutters>
        <b-col>
          <div class="label active">{{ $t('租赁时间') }}</div>
        </b-col>
        <b-col>
          <div class="label active">
            <template v-if="item === '_90'">
              {{ days[item] }}{{ $t('天以上') }}
            </template>
            <template v-else>
              {{ days[item] }}{{ $t('天') }}
            </template>
          </div>
        </b-col>
      </b-row>
      <!-- 第二行 -->
      <b-row no-gutters>
        <b-col>
          <div class="label ">{{ $t('每日租金') }}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{ original[`rent${list[index]}`] }}</div>
        </b-col>
      </b-row>
      <!-- 第三行 -->
      <b-row no-gutters>
        <b-col>
          <div class="label ">{{ $t('每日可跑公里数') }}</div>
        </b-col>
        <b-col>
          <div class="label">{{ original[`kms${list[index]}`] }} km</div>
        </b-col>
      </b-row>
      <!-- 第四行 -->
      <b-row no-gutters>
        <b-col>
          <div class="label ">{{ $t('超出公里数费用') }}</div>
        </b-col>
        <b-col>
          <div class="label">{{ original[`rent_bey${list[index]}`] }} CAD/km</div>
        </b-col>
      </b-row>
      <!-- 第五行 -->
      <b-row no-gutters v-if="!original.is_super_car">
        <b-col>
          <div class="label ">{{ $t('车体险自付額500元方案') }}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{ insurance[`carbody_ins_500${list[index]}`] }}</div>
        </b-col>
      </b-row>
      <!-- 第六行 -->
      <b-row no-gutters v-if="!original.is_super_car">
        <b-col>
          <div class="label ">{{ $t('车体险自付額2500元方案') }}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{ insurance[`carbody_ins_2500${list[index]}`] }}</div>
        </b-col>
      </b-row>
      <!-- 第七行 -->
      <b-row no-gutters v-if="original.is_super_car">
        <b-col>
          <div class="label ">{{ $t('超跑险') }}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{ insurance[`supercar_ins${list[index]}`] }}</div>
        </b-col>
      </b-row>
      <!-- 第八行 -->
      <b-row no-gutters v-if="!original.is_super_car">
        <b-col>
          <div class="label ">{{ $t('新车险费用(单日价格)') }}</div>
        </b-col>
        <b-col>
          <div class="label left_none">CAD {{ insurance.warranty_ins }}</div>
        </b-col>
      </b-row>

    </div>
    <!-- 最后一行 -->
    <b-col lg="12" md="12">
      <div class="text_box">
        <div class="text">
          *{{ $t('租赁介绍价格费用不包含税费，最终租赁价格根据订单明细为准。') }}
          <br>
          *{{ $t('新车险计算规则为租车天数*7天周期计算，不满足7天以7天进行计算') }}
        </div>
      </div>
    </b-col>
  </b-row>

</template>
<script>
export default {
  props: ["original", "insurance"],
  data() {
    return {
      list: ["_3_7", "_8_12", "_13_17", "_18_22", "_23_27", "_28_90", "_90"],
      days: {
        "_3_7": "3-7",
        "_8_12": '8-12',
        "_13_17": '13-17',
        "_18_22": '28-22',
        "_23_27": '23-27',
        "_28_90": '28-90',
        "_90": '90'
      },
      // original insurance
    }
  },
  methods: {},
  created() {
  },
}
</script>
<style lang="scss" scoped>
.text_box {
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  font-size: 15px;
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  width: 100%;
  margin: -1px -1px 0 0;

  .text {
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;
    font-size: 13px;
    color: #b7b7b7;
  }
}

.table {
  border-right: 1px solid #d8d8d8;
}

.table_box {
  padding: 30px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.table_item {
  flex: 12.5%;
  max-width: 12.5%;
}

.table_item2 {
  flex: 87.5%;
  max-width: 87.5%;
}

.label {
  height: 70px;
  border-radius: 0;
  font-size: 15px;
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  display: flex;
  width: 100%;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;
  overflow: visible;
  font-size: 13px;
  color: #1d2124;
  font-weight: bold;

  &.active {
    background: #fbfbfb;
  }

  @media #{$xl} {
    &.none {
      border: 0;
    }
    &.left_none {
      border-left: 0;
    }
    &.right_none {
      border-right: 0;
    }
  }
  @media #{$lg} {
    &.none {
      border: 0;
    }
    &.left_none {
      border-left: 0;
    }
    &.right_none {
      border-right: 0;
    }
  }
}
</style>