<template>
  <b-row no-gutters class="table">
    <!-- 第一行 -->

    <b-row no-gutters>
      <b-col>
        <div class="label active">{{$t('租赁时间')}}</div>
      </b-col>
      <b-col>
        <div class="label active">3-7{{$t('天')}}</div>
      </b-col>
      <b-col>
        <div class="label active">8-12{{$t('天')}}</div>
      </b-col>
      <b-col>
        <div class="label active">13-17{{$t('天')}}</div>
      </b-col>
      <b-col>
        <div class="label active">18-22{{$t('天')}}</div>
      </b-col>
      <b-col>
        <div class="label active">23-27{{$t('天')}}</div>
      </b-col>
      <b-col>
        <div class="label active">28-90{{$t('天')}}</div>
      </b-col>
      <b-col>
        <div class="label active">90{{$t('天以上')}}</div>
      </b-col>
    </b-row>

    <!-- 第二行 -->
    <b-col>
      <b-row no-gutters>
        <b-col>
          <div class="label active">{{$t('每日租金')}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{original.rent_3_7}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{original.rent_8_12}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{original.rent_13_17}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{original.rent_18_22}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{original.rent_23_27}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{original.rent_28_90}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{original.rent_90}}</div>
        </b-col>
      </b-row>
    </b-col>
    <!-- 第三行 -->
    <b-col>
      <b-row no-gutters>
        <b-col>
          <div class="label active">{{$t('每日可跑公里数')}}</div>
        </b-col>
        <b-col>
          <div class="label"> {{original.kms_3_7}} km</div>
        </b-col>
        <b-col>
          <div class="label"> {{original.kms_8_12}} km</div>
        </b-col>
        <b-col>
          <div class="label"> {{original.kms_13_17}} km</div>
        </b-col>
        <b-col>
          <div class="label"> {{original.kms_18_22}} km</div>
        </b-col>
        <b-col>
          <div class="label"> {{original.kms_23_27}} km</div>
        </b-col>
        <b-col>
          <div class="label"> {{original.kms_28_90}} km</div>
        </b-col>
        <b-col>
          <div class="label"> {{original.kms_90}} km</div>
        </b-col>
      </b-row>
    </b-col>
    <!-- 第四行 -->
    <b-col>
      <b-row no-gutters>
        <b-col>
          <div class="label active">{{$t('超出公里数费用')}}</div>
        </b-col>
        <b-col>
          <div class="label">{{original.rent_bey_3_7}} CAD/km</div>
        </b-col>
        <b-col>
          <div class="label">{{original.rent_bey_8_12}} CAD/km</div>
        </b-col>
        <b-col>
          <div class="label">{{original.rent_bey_13_17}} CAD/km</div>
        </b-col>
        <b-col>
          <div class="label">{{original.rent_bey_18_22}} CAD/km</div>
        </b-col>
        <b-col>
          <div class="label">{{original.rent_bey_23_27}} CAD/km </div>
        </b-col>
        <b-col>
          <div class="label">{{original.rent_bey_28_90}} CAD/km</div>
        </b-col>
        <b-col>
          <div class="label">{{original.rent_bey_90}} CAD/km</div>
        </b-col>
      </b-row>
    </b-col>
    <!-- 第五行 -->
    <b-col v-if="!original.is_super_car">
      <b-row no-gutters>
        <b-col>
          <div class="label active">{{$t('车体险自付額500元方案')}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_500_3_7}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_500_8_12}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_500_13_17}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_500_18_22}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_500_23_27}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_500_28_90}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_500_90}}</div>
        </b-col>
      </b-row>
    </b-col>
    <!-- 第6行 -->
    <b-col v-if="!original.is_super_car">
      <b-row no-gutters>
        <b-col>
          <div class="label active">{{$t('车体险自付額2500元方案')}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_2500_3_7}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_2500_8_12}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_2500_13_17}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_2500_18_22}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_2500_23_27}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_2500_28_90}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.carbody_ins_2500_90}}</div>
        </b-col>
      </b-row>
    </b-col>
    <!-- 第7行 -->
    <b-col v-if="original.is_super_car">
      <b-row no-gutters>
        <b-col>
          <div class="label active">{{$t('超跑险')}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.supercar_ins_3_7}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.supercar_ins_8_12}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.supercar_ins_13_17}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.supercar_ins_18_22}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.supercar_ins_23_27}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.supercar_ins_28_90}}</div>
        </b-col>
        <b-col>
          <div class="label">CAD {{insurance.supercar_ins_90}}</div>
        </b-col>
      </b-row>
    </b-col>
    <!-- 第8行 -->
    <b-col v-if="!original.is_super_car">
      <b-row no-gutters>

        <b-col>
          <div class="label active">{{$t('新车险费用(单日价格)')}}</div>
        </b-col>
        <b-col class="d-none d-lg-block">
          <div class="label right_none"></div>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
        <b-col>
          <div class="label left_none">CAD {{insurance.warranty_ins}}</div>
        </b-col>
        <b-col class="d-none d-lg-block">
          <div class="label left_none"></div>
        </b-col>
        <b-col></b-col>
        <b-col></b-col>
      </b-row>
    </b-col>

    <!-- 第9行 -->
    <b-col>
      <div class="text_box">
        <div class="text">
          *{{$t('租赁介绍价格费用不包含税费，最终租赁价格根据订单明细为准。')}}
          <br>
          *{{$t('新车险计算规则为租车天数*7天周期计算，不满足7天以7天进行计算')}}
        </div>
      </div>

    </b-col>
  </b-row>

</template>
<script>
export default {
  props: ["original", "insurance"],
  data() {
    return {
      // original insurance
    }
  },
  methods: {},
  created() {},
}
</script>
<style lang="scss" scoped>
.text_box {
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  font-size: 15px;
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  width: 100%;
  margin: -1px -1px 0 0;

  .text {
    overflow-wrap: break-word;
    word-break: break-word;
    text-align: center;
    font-size: 13px;
    color: #b7b7b7;
  }
}
.table {
  border-right: 1px solid #d8d8d8;
}
.table_box {
  padding: 30px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
}

.table_item {
  flex: 12.5%;
  max-width: 12.5%;
}
.table_item2 {
  flex: 87.5%;
  max-width: 87.5%;
}

.label {
  height: 70px;
  border-radius: 0;
  font-size: 15px;
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  display: flex;
  width: 100%;
  margin-bottom: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;
  overflow: visible;
  font-size: 13px;
  color: #1d2124;
  font-weight: bold;
  &.active {
    background: #fbfbfb;
  }

  @media #{$xl} {
    &.none {
      border: 0;
    }
    &.left_none {
      border-left: 0;
    }
    &.right_none {
      border-right: 0;
    }
  }
  @media #{$lg} {
    &.none {
      border: 0;
    }
    &.left_none {
      border-left: 0;
    }
    &.right_none {
      border-right: 0;
    }
  }
}
</style>