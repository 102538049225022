<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" :key="navkey" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒小菜单 -->
            <Menu></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🍞面包屑 -->
            <section>
              <b-container>
                <div-padding padding="20px 0">
                  <b-row no-gutters>
                    <b-col cols="auto" class="mr-auto">
                      <div-left center>
                        <div-text color="#1D2124" size="20px" bold>{{$t('个人信息')}}</div-text>
                      </div-left>
                    </b-col>
                    <b-col cols="auto">
                      <b-breadcrumb>
                        <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                        <b-breadcrumb-item @click="go('mine')">
                          {{$t('我的')}}
                        </b-breadcrumb-item>
                        <b-breadcrumb-item active>{{$t('个人信息')}}</b-breadcrumb-item>
                      </b-breadcrumb>
                    </b-col>
                  </b-row>
                </div-padding>
              </b-container>
            </section>

            <div-zebra></div-zebra>
            <div-card radius="0" bg="#fff" shadow="0px 3px 6px 1px rgba(0, 0, 0, 0.16)">
              <div-padding padding="30px">

                <b-row no-gutters>
                  <!-- 第一行 -->
                  <b-col lg="2" md="6">
                    <label class="label">{{$t('头像')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">
                    <div class="avatar">
                      <div-updata url="/index/upload/uploadImgs" res="res.data" :data="{env: 1}" name="file" @list="(val)=>set_avatar(val)">
                        <div-img width="44px" height="44px">
                          <b-img thumbnail v-if="avatar" :src="avatar" rounded="circle" alt="Circle image"></b-img>
                          <b-img thumbnail v-else src="https://www.runoob.com/try/demo_source/pineapple.jpg" rounded="circle" alt="Circle image"></b-img>
                        </div-img>
                      </div-updata>
                    </div>
                  </b-col>

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('Email帐号')}}</label>
                  </b-col>
                  <b-col lg="6" md="6">
                    <b-input-group>
                      <template v-slot:append>
                        <b-input-group-text class="div-code">
                          <b-button @click="go('set_email')">{{$t('更换Email帐号')}}</b-button>
                        </b-input-group-text>
                      </template>
                      <b-form-input v-model="email" readonly class="input" :placeholder="$t('请输入Email帐号')" type="search">
                      </b-form-input>
                    </b-input-group>
                  </b-col>

                  <!-- 第二行 -->

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('护照号码')}}</label>
                  </b-col>
                  <b-col lg="10" md="6">
                    <input v-model="passport_no" type="text" class="form-control ">
                  </b-col>

                  <!-- 第三行 -->

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('姓名（名+姓）')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">
                    <input v-model="name" type="text" class="form-control no_border">
                  </b-col>

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('出生日期')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">

                    <div class="date_box form-control no_border">
                      <b-form-datepicker :locale="$t('locale')" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :hide-header="true" selected-variant="success" id="datepicker" v-model="birthday" :label-no-date-selected="$t('请选择时间')" label-help=""></b-form-datepicker>
                    </div>

                  </b-col>

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('性别')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">

                    <div class="down_box form-control ">
                      <b-dropdown :text="['',$t('男'),$t('女')][gender || 1]">
                        <b-dropdown-item @click="gender = '1'">{{$t('男')}}</b-dropdown-item>
                        <b-dropdown-item @click="gender = '2'">{{$t('女')}}</b-dropdown-item>
                      </b-dropdown>
                    </div>

                  </b-col>

                  <!-- 第四行 -->

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('移动电话')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">
                    <input v-model="mobile_phone" type="text" class="form-control no_border">
                  </b-col>

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('户籍地址')}}*</label>
                  </b-col>
                  <b-col lg="6" md="6">
                    <input type="text" class="form-control " v-model="residence_address">
                  </b-col>

                  <!-- 第五行 -->

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('紧急联系人')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">
                    <input v-model="emerg_person" type="text" class="form-control no_border">
                  </b-col>

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('关系')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">
                    <input v-model="emerg_relation" type="text" class="form-control no_border">
                  </b-col>

                  <b-col lg="2" md="6">
                    <label class="label">{{$t('紧急联系人电话')}}</label>
                  </b-col>
                  <b-col lg="2" md="6">
                    <input v-model="emerg_contact" type="text" class="form-control ">
                  </b-col>

                  <!-- 第六行 -->
                  <b-col lg="12" md="12">
                    <div class="text_box">
                      *{{$t('请已中文或英文填写您所在国家的户籍地址（非通讯地址）')}}
                    </div>
                  </b-col>
                  <div-height height="30px"></div-height>
                  <!-- 第七行 -->
                  <div-save ref="btn" @save="save">{{$t('保存')}}</div-save>

                </b-row>

              </div-padding>
            </div-card>
          </b-col>

        </b-row>
      </section>
      <div-height height="30px" />
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navkey: 0,
      avatar: "", //头像
      email: "", //邮箱
      passport_no: "", //护照号码
      name: "", //姓名
      birthday: "", //出生日期
      gender: "", //性别
      mobile_phone: "", //移动电话
      residence_address: "", //户籍地址
      emerg_person: "", //紧急联系人
      emerg_relation: "", //关系
      emerg_contact: "", //紧急联系人电话
    }
  },
  created() {
    this.get_info()
  },
  mounted() {},
  methods: {
    set_avatar(val) {
      const loading = this.$loading({
        lock: true,
      })
      this.avatar = val
      this.$post({
        url: "/index/users/setAvatar",
        data: {
          avatar: this.avatar,
        },
        success: (res) => {
          this.$toast.success(res.msg, {
            timeout: 1000,
            position: "top-center",
            pauseOnHover: false,
          })

          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 1000)
          let data = { ...this.$data }
          //存储新的用户信息
          localStorage.setItem("zuche_user", JSON.stringify(data))
          this.navkey++
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
    get_info() {
      this.$get({
        url: "/index/users/uinfo",
        success: (res) => {
          let obj = res.data
          Object.keys(obj).map((item) => (this[item] = obj[item]))
          localStorage.setItem("zuche_user", JSON.stringify(res.data))
        },
        tip: () => {},
      })
    },

    save() {
      const loading = this.$loading({
        lock: true,
      })
      let that = this

      // let data = {
      //   avatar: this.avatar,
      //   gender: this.gender,
      //   email: this.email,
      //   passport_no: this.passport_no,
      //   name: this.name,
      //   birthday: this.birthday,
      //   mobile_phone: this.mobile_phone,
      //   emerg_person: this.emerg_person,
      //   emerg_relation: this.emerg_relation,
      //   emerg_contact: this.emerg_contact,
      //   residence_address: this.residence_address,
      // }

      let data = { ...this.$data }

      console.log("data", data)

      this.$post({
        url: "/index/users/uinfo",
        data: data,
        success: (res) => {
          this.$toast.success(res.msg, {
            timeout: 1000,
            position: "top-center",
            pauseOnHover: false,
          })

          //存储新的用户信息
          localStorage.setItem("zuche_user", JSON.stringify(data))
          // this.get_info()

          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
            this.go("mine")
          }, 1000)
        },
        tip: () => {
          setTimeout(() => {
            loading.close()
            this.$refs.btn.disabled = false
          }, 3000)
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
  /deep/.el-input__prefix {
    display: none !important;
  }

  /deep/.el-input__inner {
    padding-left: 0.75rem !important;
  }

  /deep/ .el-icon-time {
    background: #57b846 !important;
    display: none !important;
  }
}

/deep/.button-danger {
  display: none !important;
}

/deep/.b-form-btn-label-control.form-control > .form-control {
  min-height: auto !important;
  height: 35px;
}

/deep/.btn-outline-success {
  color: #67c23a;
  border-color: #67c23a;
}

/deep/.btn-success:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}

/deep/.btn-success:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}

/deep/.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #67c23a;
  border-color: #67c23a;
}

/deep/.b-form-btn-label-control.form-control > .dropdown-menu {
  transform: translate3d(0, 50px, 0px) !important;
}

// 🌰下拉菜单盒子
.down_box {
  padding: 0;
  /deep/.btn {
    font-size: 14px;
    line-height: 2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.btn-secondary {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  /deep/.btn-secondary.dropdown-toggle {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  /deep/.btn-secondary:not(:disabled):not(.disabled):active {
    background-color: #fff;
    border-color: #fff;
    color: #333;
  }
  /deep/.dropdown {
    height: 100%;
    width: 100%;
  }
  /deep/.dropdown-item.active {
    background-color: #fff;
    color: #333;
  }
  /deep/.dropdown-item:active {
    background-color: #fff;
    color: #333;
  }
  /deep/.nav-link {
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
  /deep/.dropdown-menu {
    width: 100%;
    padding: 0;
    font-size: 14px;
  }
  /deep/.dropdown-divider {
    margin: 0;
  }
  /deep/.dropdown-item {
    padding: 10px 20px;
  }
}
//时间选择器
.date_box {
  padding: 0;
  display: flex;
  align-items: center;
  /deep/.dropdown {
    flex-flow: row-reverse;
    border: 0;
    line-height: 2;
  }
  /deep/.form-control {
    background: transparent !important;
    // text-align: left;
    // padding: 0.375rem 0.75rem;
  }
  /deep/.form-control:focus {
    position: relative;
    z-index: 1;
    border-color: #57b846;
    box-shadow: none;
  }
  /deep/.form-control.focus {
    position: relative;
    z-index: 1;
    border-color: #57b846;
    box-shadow: none;
  }
}
//头像
.avatar {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8d8d8;

  line-height: 70px;

  margin-top: -1px;
  margin-right: -1px;
  background-color: #fff;

  label {
    margin-bottom: 0;
    border: 0;
  }
}

//修改邮箱右侧按钮
.input-group {
  .input-group-text {
    background: #fff;
  }
  .btn {
    max-width: 495px;
    font-size: 13px;
    line-height: 1.5;
    color: #818181;
    text-transform: capitalize;
    border: 0;
    width: 100%;
    height: 50px;
    border-radius: 0.31rem;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    transition: all 0.4s;
  }
}

//表格样式
.form-control {
  height: 70px;
  border-radius: 0;
  font-size: 15px;
  box-shadow: none;
  border: 1px solid #d8d8d8;
  color: #333;

  text-align: center;

  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;

  overflow: visible;
  font-size: 13px;
  color: #333;

  &[readonly] {
    background: #fff;
  }
  &:focus {
    position: relative;
    z-index: 1;
    border-color: #57b846;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  }
}

.label {
  height: 70px;
  border-radius: 0;
  border: 1px solid #d8d8d8;
  border-right-width: 0;
  display: block;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  // line-height: 70px;
  text-align: center;
  background: rgba(215, 215, 215, 0.1);
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;
  overflow: visible;
  font-size: 13px;
  color: #1d2124;
  font-weight: bold;
}

.text_box {
  padding: 0 30px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  // line-height: 70px;
  font-size: 13px;
  border: 1px solid #d8d8d8;
  text-align: center;
  border-top: 0;
  color: #b7b7b7;
}

//超大屏

@media #{$xl} {
  label {
    border: 1px solid #d8d8d8;
  }
  .avatar {
    border-right-width: 0;
  }
  .no_border {
    border-right-width: 0;
  }
}
//电脑端
@media #{$lg} {
  label {
    border: 1px solid #d8d8d8;
  }
}
//平板
@media #{$md} {
  label {
    border: 1px solid #d8d8d8;
  }
}
//手机
@media #{$sm} {
  .table {
    border: none;
  }
  .form-control {
    text-align: left;
    border-radius: 4px;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border: none;
    background: #fff;
    padding: 0;
    margin-top: 0;
  }
  .text {
    border: none;
  }
}

//智能手表
@media #{$xs} {
  .table {
    border: none;
  }
  .label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px;
    border: none;
    background: #fff;
    padding: 0;
    margin-top: 0;
  }
  .form-control {
    text-align: left;
    border-radius: 4px;
  }
  .text {
    border: none;
    align-items: center;
    padding: 0 30px;
    display: flex;
    height: 100px;
    line-height: 30px;
  }
}
</style>