<template>
  <div id="app" v-cloak>
    <transition :name="transitionName">
      <router-view class="router" v-if="show" />
    </transition>
    <!-- 全局底部栏 -->
    <Footer />
    <!-- 滚动到顶部 -->
    <goTop />
  </div>
</template>
<script>
// import {
//   requestForToken,
//   onMessageListener,
//   requestDeleteToken,
// } from "./plugins/firebase"

if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  // 是移动设备，不加载JavaScript文件
} else {
  // 不是移动设备，加载JavaScript文件
  const firebase = require("./plugins/firebase")

  const requestForToken = firebase.requestForToken
  const onMessageListener = firebase.onMessageListener
  const requestDeleteToken = firebase.requestDeleteToken
}

export default {
  name: "app",
  data() {
    return {
      show: true,
      transitionName: "slide-right",
    }
  },
  watch: {
    // $route(to, from) {
    //   let isBack = this.$router.isBack;
    //   console.log("监听页面路由变化", isBack);
    //   if (isBack) {
    //     this.transitionName = "slide-right";
    //   } else {
    //     this.transitionName = "slide-left";
    //   }
    //   this.$router.isBack = false;
    // },
  },
  mounted() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      // 是移动设备，不加载JavaScript文件
    } else {
      // 不是移动设备，加载JavaScript文件
      Notification.permission != "granted" && this.requestPermission()
      this.setFCMToken()
      // 页面在前台（有焦点）
      onMessageListener().then((data) => {
        console.log("onMessageListener", data)
        this.showNotification(data)
      })
    }
  },
  methods: {
    // 获取显示通知
    requestPermission() {
      let that = this
      Notification.requestPermission().then((res) => {
        if (res === "granted") {
          console.log("Notification ok")
        } else {
          console.log("Notification", res)
          that.$toast("请允许网站显示通知")
        }
      })
    },
    // 获取设备token
    async setFCMToken() {
      // requestDeleteToken(); //获取FCMToken前 先删除FCMToken 未实现 报错！
      // return
      let gcm_token = await requestForToken()
      console.log("gcm_token", gcm_token)

      // 接口需要翻墙才能走通 故测试走不动 日本那个服务器能走通
      this.$post({
        url: "/index/users/refreshGcmToken",
        data: { gcm_token },
        success: (pres) => {
          console.log("refreshGcmToken", pres)
        },
        tip: () => {},
      })
    },
    // 显示通知
    showNotification(res) {
      let that = this
      let body, title
      // en cn tw
      if (that.$i18n.locale == "tw") {
        body = res.data.body
        title = res.data.title
      } else {
        body = res.data[`body_${that.$i18n.locale}`]
        title = res.data[`title_${that.$i18n.locale}`]
      }
      let m = new Notification(title, {
        body: body,
        data: res.data.id,
        tag: res.data.domain,
      })
      m.onclick = function (res) {
        console.log("onclick", res)
        window.open(`${res.target.tag}/#/message_item?id=${res.target.data}`)
      }
      m.onclose = function (res) {
        console.log("onclose ", res)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>