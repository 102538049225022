<template>
  <div class="box">
    <!-- 📷顶部导航栏 -->
    <Nav name="about" />
    <!-- 🥒轮播图 -->
    <section>
      <div class="banner">
        <Swiper :banners="banners" />
      </div>
    </section>
    <!-- 🍊简介-->
    <section>
      <b-container>
        <div class="blurb_box">
          <div class="blurb" @click="go('blurb')">
            <b-row>
              <b-col xl="6" lg="6">
                <b-row no-gutters>
                  <b-col cols="12" class="wow bounceInDown " :data-wow-delay="(1 * 0.2) + 's'">
                    <img class="w-100" :src="original.inro_left_pic">
                  </b-col>
                </b-row>
              </b-col>
              <b-col xl="6" lg="6" class="wow fadeInRight">
                <b-row>
                  <div-height height="30px" />
                  <div class="title_sm">
                    <h1 class="fadeInUp">{{$t('公司简介')}}</h1>
                    <h3 class="fadeInUp"></h3>
                  </div>
                  <div-height height="30px" />
                  <div class="change" color="#fff" v-html="original['intro'+$t('suffix')]">

                  </div>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-container>
    </section>
    <div-height height="60px" />
    <!-- 🔋服务项目-->
    <section>
      <div class="service_box">
        <b-container>

          <div class="service" @click="go('service')">
            <div class="service_title">
              <!-- <div-height height="120px" /> -->
              <div class="shangup">
                <!-- <h2 class="wow bounceInRight" :data-wow-delay="(1 * 0.2) + 's'">{{$t('凭借着可靠的品质和优秀的越野性能深受顾客的喜爱')}}</h2> -->
                <div-height height="10px" />
                <h3 class="wow bounceInRight" :data-wow-delay="(1 * 0.2) + 's'">{{$t('服务项目')}}</h3>
                <!-- <div-height height="30px"></div-height> -->
              </div>
              <!-- <div-height height="10px" /> -->
              <h2 class="wow fadeInUp new" style="color:transparent">Service scope</h2>
            </div>
            <b-row>
              <b-col xl="4" lg="4" class="wow bounceInRight" :data-wow-delay="(1 * 0.2) + 's'">
                <div class="change" color="#fff" v-html="original['scope_left_txt'+$t('suffix')]">
                </div>
              </b-col>
              <b-col xl="4" lg="4" class="wow bounceInRight" :data-wow-delay="(2 * 0.2) + 's'">
                <div class="change" color="#fff" v-html="original['scope_right_txt'+$t('suffix')]">
                </div>
              </b-col>
            </b-row>
            <div-height height="30px" />
            <b-row v-if="list">
              <b-col cols="4" class="wow bounceInRight" :data-wow-delay="((index+1) * 0.4) + 's'" v-for="(item,index) in list" :key="index">
                <img class="w-100" :src="item">
              </b-col>
            </b-row>
            <div-height height="30px" />
          </div>
        </b-container>
      </div>
    </section>
  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      list: ["", "", ""],
      original: {},
      banners: [],
    }
  },
  created() {
    this.get_info()
  },
  mounted() {},
  methods: {
    init_wow() {
      this.$nextTick(() => {
        // 在dom渲染完后,再执行动画
        new WOW({
          boxClass: "wow", // 需要执行动画的元素的 class
          animateClass: "animated", //animation.css 动画的 class
          offset: 0, // 距离可视区域多少开始执行动画
          mobile: true, // 是否在移动设备上执行动画
          live: false, // 异步加载的内容是否有效
          callback: function (box) {
            // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
          },
        }).init()
      })
    },
    get_info() {
      this.$get({
        url: "/index/enterpriseaboutus/pagedata?id=1",
        success: (res) => {
          this.banners = res.data.banner
          this.original = res.data.original
          this.list = res.data.original.scope_bot_pics.split(",")
          // console.log("da", this.original)
          this.init_wow()
        },
        tip: () => {},
      })
    },
  },
}
</script>
<style lang='scss' scoped>
.box {
  overflow-x: hidden;
}
// 轮播图
.banner img {
  width: 100%;
}

// 简介
.blurb_box {
  cursor: pointer;
  text-align: center;
  z-index: 1000;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #e6e6e6;
  @include transition(0.4s);
  @media #{$xl} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$lg} {
    padding: 2.13rem;
    margin-top: -8rem;
  }
  @media #{$md} {
    padding: 2.13rem;
    margin-top: -4.38rem;
  }
  @media #{$sm} {
    padding: 1.5rem;
    margin-top: 2rem;
  }
  @media #{$xs} {
    padding: 1.5rem;
    margin-top: 2rem;
  }

  .blurb {
    background: #1d2124;
    width: 100%;
    height: 100%;
    padding: 2rem;
  }
}

.change {
  color: #fff;
  font-size: 15px;
  line-height: 1.8;
}

//标题
.title_sm {
  width: 100%;
  position: relative;
  // background: url("~@/assets/img/about/t_bg01.png");
  background-position: center !important;
  background-size: 14.63rem 2.31rem;
  background-repeat: no-repeat;
  h1 {
    width: 100%;
    font-size: 22px;
    color: #fff;
    text-align: center;
    font-weight: bold;
  }
  h2 {
    width: 100%;
    font-size: 28px;
    color: #505355;
    position: absolute;
    text-align: center;
  }
  h3 {
    width: 17px;
    height: 3px;
    background: #fff;
    margin: 0 auto;
  }
}

//服务项目
.service_box {
  background: #595a5f;
  position: relative;
  .service {
    cursor: pointer;
    .service_title {
      padding-top: 40px;
      padding-bottom: 50px;
      width: 100%;
      position: relative;
      h2 {
        width: 100%;
        // font-size: 1.25rem;
        color: #1d2124;
        @media #{$xl} {
          font-size: 22px;
        }

        @media #{$lg} {
          font-size: 20px;
        }

        @media #{$md} {
          font-size: 18px;
        }

        @media #{$sm} {
          font-size: 16px;
        }

        @media #{$xs} {
          font-size: 14px;
        }
      }
      .shangup {
        padding-top: 80px;
        width: 100%;
        position: absolute;
        // text-align: center;
        z-index: 2;
        font-weight: bold;
        font-size: 22px;
        color: #333333;
        @media #{$xl} {
          padding-top: 80px;
        }

        @media #{$lg} {
          padding-top: 30px;
        }

        @media #{$md} {
          padding-top: 30px;
        }

        @media #{$sm} {
          padding-top: 30px;
        }

        @media #{$xs} {
          padding-top: 25px;
        }
      }
      // background: url("~@/assets/img/about/t_bg02.png");
      // background-position: center !important;
      // background-size: 68rem 14.19rem;
      // background-repeat: no-repeat;

      .new {
        vertical-align: text-top;
        width: 100%;
        font-size: 165px;
        color: #75767a;
        font-weight: bold;
        // text-align: center;

        @media #{$xl} {
          font-size: 165px;
        }

        @media #{$lg} {
          font-size: 110px;
        }

        @media #{$md} {
          font-size: 80px;
        }

        @media #{$sm} {
          font-size: 70px;
        }

        @media #{$xs} {
          font-size: 50px;
        }
      }
      h3 {
        font-size: 3.94rem;
        color: #fff;
        font-weight: bold;
        @media #{$xl} {
          font-size: 3.94rem;
        }

        @media #{$lg} {
          font-size: 3.94rem;
        }

        @media #{$md} {
          font-size: 1.94rem;
        }

        @media #{$sm} {
          font-size: 1.94rem;
        }

        @media #{$xs} {
          font-size: 1.94rem;
        }
      }
    }
  }
}
</style>
