<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="rent" />
    <!-- 🥒轮播图 -->
    <Swiper />
    <!-- 🍞面包屑导航栏 -->
    <section>
      <b-container>
        <div-padding padding="20px 0">
          <b-row>
            <b-col sm="12" cols="12" lg="auto" xl="auto" md="auto" order-md="1" order="2" class="mr-auto">
              <div class="wow fadeInLeft">
                <b-input-group class="div-search">
                  <template #prepend>
                    <b-input-group-text>
                      <b-icon-search color="#D7D7D7"></b-icon-search>
                    </b-input-group-text>
                  </template>
                  <b-form-input class="input" :placeholder="$t('请输入要搜索的内容')" type="search" v-model="search" @input="onKeyDown(search)"></b-form-input>
                </b-input-group>
              </div>
            </b-col>
            <b-col sm="12" cols="12" lg="auto" xl="auto" md="auto" order-md="2" order="1">
              <b-breadcrumb>
                <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                <b-breadcrumb-item active>{{$t('汽车租赁2')}}</b-breadcrumb-item>
              </b-breadcrumb>
            </b-col>
          </b-row>
        </div-padding>
      </b-container>
    </section>
    <!-- 🍊筛选 -->
    <div class="screen_box">
      <b-container>
        <div class="card">
          <div class="card_title">
            <div-text color="#fff" size="16px" bold>{{$t('让我们发现最完美的汽车')}}</div-text>
          </div>
          <b-row>
            <b-col xl="3" lg="3" md="6" sm="12">
              <div-height height="20px" />
              <div-text color="#333" size="14px" bold>{{$t('车种')}}</div-text>
              <div-height height="15px" />
              <div class="down_box">
                <b-dropdown :text="arr_text[0][arr_index[0]+$t('suffix')] || $t('请选择车种')" variant="outline-dark">
                  <b-dropdown-item @click="opt(item,0)" v-for="(item,index) in arr_opt[0]" :key="index">
                    {{item['classi_name'+$t('suffix')]}}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="12">
              <div-height height="20px" />
              <div-text color="#333" size="14px" bold>{{$t('品牌')}}</div-text>
              <div-height height="15px" />
              <div class="down_box">
                <b-dropdown :text="arr_text[1][arr_index[1]+$t('suffix')] || $t('请选择品牌') " variant="outline-dark">
                  <b-dropdown-item @click="opt(item,1)" v-for="(item,index) in arr_opt[1]" :key="index">
                    {{item['brand_name'+$t('suffix')]}}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="12">
              <div-height height="20px" />
              <div-text color="#333" size="14px" bold>{{$t('型号')}}</div-text>
              <div-height height="15px" />
              <div class="down_box">
                <b-dropdown :text="arr_text[2][arr_index[2]+$t('suffix')] ||  $t('请选择型号') " variant="outline-dark">
                  <b-dropdown-item @click="opt(item,2)" v-for="(item,index) in arr_opt[2]" :key="index">
                    {{item['model_name'+$t('suffix')]}}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
            <b-col xl="3" lg="3" md="6" sm="12">
              <div-height height="20px" />
              <div-text color="#333" size="14px" bold>{{$t('年限')}} </div-text>
              <div-height height="15px" />
              <div class="down_box">
                <b-dropdown :text="arr_text[3][arr_index[3]+$t('suffix')] || $t('请选择年限') " variant="outline-dark">
                  <b-dropdown-item @click="opt(item,3)" v-for="(item,index) in arr_opt[3]" :key="index">
                    {{item['life_name'+$t('suffix')]}}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <div-height height="30px" />

    <!-- 🍍商品列表 -->
    <section class="good_list">
      <b-container>
        <b-row>
          <b-col sm="6" md="6" v-for="(item,index) in list" :key="index" class="wow fadeInRight" :data-wow-delay="index * 0.1 + 's'">
            <div class="item" @click="go('rent_item', item.id)">
              <div class="img picbox">
                <img :src="item.vehicle_thumbnail" />
              </div>
              <div class="text">
                <div class="one">{{item['vehicle_name'+$t('suffix')]}}</div>
                <!-- <div>CAD {{item.base_rent}}</div> -->
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>

    <el-empty v-if="list&&list.length==0" :description="$t('暂无数据')"></el-empty>

    <!-- 🔋底部分页 -->
    <div-center>
      <b-pagination-nav :first-number="true" :page="currentPage" :link-gen="linkGen" @change="get_list" :last-number="true" :number-of-pages="pages" use-router>
        <template v-slot:prev-text>
          <b-icon icon="chevron-left"></b-icon>
        </template>
        <template v-slot:next-text>
          <b-icon icon="chevron-right"></b-icon>
        </template>
      </b-pagination-nav>
    </div-center>

    <div-height height="30px" />

  </div>
</template>
<script>
import { WOW } from "wowjs"
export default {
  data() {
    return {
      list: [],
      banners: [],
      search: "",
      arr_opt: [], //当前选择数组
      arr_id: [], //当前选择id数组
      arr_text: [], //当前选择文字数组
      arr_index: ["classi_name", "brand_name", "model_name", "life_name"], //当前选择索引数组
      rechargeEntry: {},
      pages: 1, //总页数
      currentPage: this.query().page ? this.query().page : 1, //当前页数
      kw: "", //搜索关键字

      input1: "",
      input1state: null,
      input2: "",
      input2state: null,
      options: [{ text: "- Choose 1 -", value: "" }, "Red", "Green", "Blue"],
      input1Return: "",
      input2Return: "",
      popoverShow: false,
      grid_list: [
        { text: "驾照信息提交", hover: false },
        { text: "车辆选型", hover: false },
        { text: "立即预约", hover: false },
        { text: "租赁合同签署", hover: false },
        { text: "租赁时间", hover: false },
        { text: "租金明细", hover: false },
        { text: "租金明细", hover: false },
        { text: "支付押金", hover: false },
      ],
    }
  },
  created() {
    this.get_info()
    this.opt()
  },
  mounted() {
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      new WOW({
        boxClass: "wow", // 需要执行动画的元素的 class
        animateClass: "animated", //animation.css 动画的 class
        offset: 0, // 距离可视区域多少开始执行动画
        mobile: true, // 是否在移动设备上执行动画
        live: false, // 异步加载的内容是否有效
        callback: function (box) {
          // console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
        },
      }).init()
    })

    var foot = this.$route.query.id
    if (foot == "foot") {
      this.goAnchor("#foot")
    }
  },
  methods: {
    onKeyDown(even) {
      //防抖
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        // console.log("onKeyDown", even)
        this.get_info()
      }, 500)
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`
    },

    //获取所有分类
    opt(item = -1, index = -1) {
      console.log("获取所有分类", item, index)
      let arr = ["classi_name", "brand_name", "model_name", "year_name"]
      this.arr_text[index] = item
      this.arr_id[index] = item.id
      for (let i = index + 1; i < 4; i++) {
        this.arr_opt[i] = []
        this.arr_text[i] = ""
        this.arr_id[i] = ""
      }
      this.$get({
        url: "/index/general/cbml",
        data: {
          type: { "-1": "c", 0: "b", 1: "m", 2: "l" }[index], //c-分类;b-品牌;m-型号;l-年限
          c_id: this.arr_id[0],
          b_id: this.arr_id[1],
          m_id: this.arr_id[2],
          l_id: this.arr_id[3],
        },
        success: (res) => {
          this.arr_opt[index + 1] = res.data
        },
      })
      this.get_info()
    },
    get_list(page) {
      this.currentPage = page
      this.get_info()
    },
    get_info() {
      this.$get({
        url: "/index/vehicles/idx",
        data: {
          kw: this.search, //搜索关键字
          classifi_id: this.arr_id[0], //分类（车种）
          brand_id: this.arr_id[1], //品牌
          model_id: this.arr_id[2], //型号
          life_id: this.arr_id[3], //年限
          page: 1, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          this.list = res.data.list.data
          this.banners = res.data.addition.banners
          this.rechargeEntry = res.data.addition.rechargeEntry[0]

          this.pages = res.data.list.last_page || 1
          this.currentPage = res.data.list.current_page
        },
        tip: () => {},
      })
    },
    input() {},
  },
}
</script>
<style lang="scss" scoped>
/*🍊筛选*/
.screen_box {
  position: relative;
  .card {
    padding: 35px;
    background: #ffffff;
    position: relative;
    padding-top: 80px;
    animation-delay: 2.6s;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    box-shadow: 0px 0 8px rgba(0, 0, 0, 0.1);
    .card_title {
      background: #333;
      width: 320px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 20px;
      height: 50px;
      left: -20px;
    }
    .card_title:after {
      content: "";
      position: absolute;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent;
      border-left: 21px solid #333;
      right: -21px;
      top: 0px;
    }
    .card_title:before {
      content: "";
      position: absolute;
      border-bottom: 17px solid transparent;
      border-right: 19px solid #333;
      left: 0;
      bottom: -17px;
    }
  }

  // 🌰下拉菜单盒子
  .down_box {
    /deep/.btn {
      font-size: 14px;
      line-height: 2;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    /deep/.dropdown {
      width: 100%;
    }
    /deep/.dropdown-item.active {
      background-color: #333;
      color: #fff;
    }
    /deep/.dropdown-item:active {
      background-color: #333;
      color: #fff;
    }
    /deep/.nav-link {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    /deep/.dropdown-menu {
      width: 100%;
      padding: 0;
      font-size: 14px;
    }
    /deep/.dropdown-divider {
      margin: 0;
    }
    /deep/.dropdown-item {
      padding: 10px 20px;
    }
  }
}

// 🍉商品列表
.good_list {
  .item {
    position: relative;
    display: block;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 4px;
    transition: all 0.4s;
    overflow: hidden;
    cursor: pointer;
    // height: 306px;

    .img {
      transition: all 0.4s;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .text {
      transition: all 0.4s;
      position: absolute;
      bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 30px;
      height: 60px;
      background: rgba(255, 255, 255, 0.25);
      backdrop-filter: blur(10px);
      text-align: center;
      color: #fff;
    }
    &:hover {
      .img {
        transform: scale(1.1);
      }
      .text {
        height: 30%;
      }
    }
  }
}
</style>