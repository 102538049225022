<template>
  <div class="div-width" :style="'width:'+size" :class="{over}">
    <slot></slot>
  </div>
</template>
<script>
  export default {
    components: {},
    props: {
      size: {
        type: String,
        default: ''
      },

      over: {
        type: Boolean,
        default: false
      },

    },
    data() {
      return {
      };
    },
    created() {
    },

    methods: {
    },
  };

</script>
<style lang='scss' scoped>
  .div-width {
    flex-shrink: 0;
  }
  .over {
    overflow: hidden;
  }
</style>
