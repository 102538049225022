

import axios from 'axios';
import router from './../router'
import qs from 'qs';
import JSONBIG from 'json-bigint';
// import plugins from '../plugins'

export default {
  install: Vue => {

    // Vue.use(plugins)

    // Vue.prototype.$axios = axios
    // axios.defaults.withCredentials = true
    // js返回进度丢失
    axios.defaults.transformResponse = [
      function (data) {
        const json = JSONBIG({
          storeAsString: true
        })
        const res = json.parse(data)
        return res
      }
    ]

    axios.defaults.timeout = 100000; //请求超时
    const no_login_code = -1; //未登录返回的状态码
    const success_code = 1; //成功返回的状态码
    const err_code = 0; //失败返回的状态码
    const no_license_code = -10; //没有许可证

    // 设置开发环境域名 https://www.phcar.qwangluo.net/ 正式打包域名不能为空 
    // 正式 http://phcarrental.p2.sdqttx.net:92
    if (process.env.NODE_ENV == 'development') {
      //开发
      setPath('https://api.jnacarrentals.casaloma.cc/')
    }
    else if (process.env.NODE_ENV == 'production') {
      //生产
      //https://api.jnacarrentals.ca/
      setPath('https://api.jnacarrentals.ca/')
    }

    function setPath(path) {
      axios.defaults.baseURL = path;
      Vue.prototype.httpPath = path;
      Vue.prototype.wsPath = '';//WebSocket
    }

    Vue.prototype.$get = params => {

      const token = localStorage.getItem("zuche_token") || "";
      //多语言传参
      //   en: 'English',
      //   cn: '简体中文',
      //   tw: '繁體中文',
      const locale = localStorage.getItem('zuche_lang') || 'en'
      const lang = { en: 'en-us', cn: 'zh-cn', tw: 'zh-tw' }[locale]


      axios
        .get(params.url, {
          params: Object.assign({}, params.data), headers: {
            //令牌
            'Authorization': token ? `Bearer ${token}` : '',
            //多语言
            'P-Lang': lang
          }
        })
        .then(res => {

          if (res.data.code == no_login_code) {
            //🥒没有登录的状态
            if (token) localStorage.removeItem('zuche_token')
            // console.log("没有登录");
            // Vue.$toast.error(res.data.msg, {
            //   timeout: 3000,
            //   position: 'top-center',
            //   pauseOnHover: false,
            // });

            router.push("login")

          }
          else if (res.data.code == no_license_code) {
            //🥒驾照信息未通过审核的状态 
            // console.log("驾照信息未通过审核");
            params.tip(res)
            // Vue.$toast.error(res.data.msg, {
            //   timeout: 3000,
            //   position: 'top-center',
            // });

          }

          else if (res.data.code == success_code) {
            //🥒数据获取成功
            if (params.success) { params.success(res.data); }

          }
          else if (res.data.code == err_code) {
            //🥒接口异常或返回成功后编写错误


            if (params.tip) {
              params.tip('val')
              Vue.$toast(res.data.msg, {
                timeout: 3000,
                position: 'top-center',
                pauseOnHover: false,
              });
            } else if (params.tips) {
              // 无轻提示
              params.tips('vals')
            }
          }
        })
        .catch(err => {
          console.log('服务器请求失败', err)
          if (err.response.status == '404') {
            // Toast('ERROR');
            Vue.$toast('ERROR', {
              timeout: 3000,
              position: 'top-center',
              pauseOnHover: false,
            });
          } else if (err.response.status == '500') {
            // Toast('ERROR');
            Vue.$toast('ERROR', {
              timeout: 3000,
              position: 'top-center',
              pauseOnHover: false,
            });
          } else if (params.fail)
            params.fail(err);
        })
    }
    Vue.prototype.$post = params => {
      const token = localStorage.getItem("zuche_token") || "";

      const locale = localStorage.getItem('zuche_lang') || 'en'
      const lang = { en: 'en-us', cn: 'zh-cn', tw: 'zh-tw' }[locale]


      let config
      let $axios;
      if (params.upload) {
        config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token ? `Bearer ${token}` : '',
            //多语言
            'P-Lang': lang
          }
        };
        $axios = axios.post(params.url, params.data, config)
      } else if (params.noToken) {
        config = {
          headers: {
            //多语言
            'P-Lang': lang
          }
        };
        let data = qs.stringify(Object.assign({}, params.data, config))
        $axios = axios.post(params.url, data)
      } else {
        config = {
          headers: {
            'Authorization': 'Bearer ' + token,
            //多语言
            'P-Lang': lang
          }
        };
        let data = qs.stringify(params.data)
        $axios = axios.post(params.url, data, config)
      }
      $axios
        .then(res => {

          if (res.data.code == no_login_code) {
            //🥒没有登录的状态
            if (token) localStorage.removeItem('zuche_token')
            // console.log("没有登录");
            // Vue.$toast.error(res.data.msg, {
            //   timeout: 3000,
            //   position: 'top-center',
            //   pauseOnHover: false,
            // });

            router.push("login")

          }
          else if (res.data.code == no_license_code) {
            //🥒驾照信息未通过审核的状态 
            // console.log("驾照信息未通过审核");
            params.tip(res)
            // Vue.$toast.error(res.data.msg, {
            //   timeout: 3000,
            //   position: 'top-center',
            // });

          }


          //   if (res.data.code == no_login_code || res.data.code == -10) {
          //     if (token) localStorage.removeItem('zuche_token')
          //   } 
          else if (res.data.code == success_code) {
            //🥒数据获取成功
            if (params.success) { params.success(res.data); }

          }

          else if (res.data.code == err_code) {
            //🥒接口异常或返回成功后编写错误




            if (params.tip) {
              params.tip('val')
              Vue.$toast(res.data.msg, {
                timeout: 3000,
                position: 'top-center',
                pauseOnHover: false,
              });
            }
            else if (params.tips) {
              params.tips('vals')
            }
            // 无轻提示
          }
        })
        .catch(err => {
          console.log('服务器请求失败', err)
          if (err.response.status == '404') {
            Vue.$toast('ERROR', {
              timeout: 3000,
              position: 'top-center',
              pauseOnHover: false,
            });
          } else if (err.response.status == '500') {
            Vue.$toast('ERROR', {
              timeout: 3000,
              position: 'top-center',
              pauseOnHover: false,
            });
          } else if (params.fail)
            params.fail(err);
          // if (params.fail)
          //     params.fail(err);
        })
    }
  }
}
