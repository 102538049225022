export default {
    data() {
        return {

        }
    },
    created() { },
    mounted() { },
    methods: {
        cut(num, index) {
            return Number(num).toFixed(index + 1).slice(0, -1);
        },
        go(name, val) {
            window.scrollTo(0, 0);
            if (typeof val == 'object') {
                this.$router.push({
                    path: name,
                    query: val
                });
            } else if (typeof val == 'number' || typeof val == 'string') {
                this.$router.push({
                    path: name,
                    query: { id: val }
                });
            } else {
                this.$router.push({
                    path: name
                });
            }
        },
        back() {
            window.scrollTo(0, 0);
            this.$router.go(-1);
        },
        call(ph) {
            window.location.href = "tel://" + ph;
        },
        open_url(url) {
            window.open(url);
        },

        query() {
            return this.$route.query;
        },
        copy(name) {
            let that = this;
            this.$copyText(name).then(function (e) {
                that.say(this.$t('复制成功'));
            }, function (e) {
                that.say(this.$t('复制失败'));
            })
        },
        urlparam() {
            let obj = this.$route.query
            Object.keys(obj).map((item) => (this[item] = obj[item]))
        },
    },
}