<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" :key="navkey" />
    <!-- 🥒个人中心 -->
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒小菜单 -->
            <Menu name=""></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <div style="height: 30px"></div>

            <b-row no-gutters align-v="center" class="mb-0">
              <!-- 🥒用户信息 -->
              <b-col lg="3" md="12" class="user_col">
                <b-card no-body bg-variant="secondary" text-variant="white" foot="Dark" class="user_box">
                  <b-card-body class="user" @click="go('set_user')">
                    <div class="avatar_box">
                      <div class="avatar">
                        <b-img thumbnail :src="avatar" rounded="circle"></b-img>
                        <!-- <b-img thumbnail src="https://www.runoob.com/try/demo_source/pineapple.jpg" rounded="circle" alt="Circle"></b-img> -->
                      </div>
                    </div>
                    <b-card-text> {{email}} </b-card-text>
                    <div class="mask">
                      <div>
                        <div-text color="#fff" size="16px" center>{{$t('个人信息')}}</div-text>
                        <div-height height="10px"></div-height>
                        <div-text color="#fff" center>{{$t('点击查看')}}</div-text>
                      </div>
                    </div>
                  </b-card-body>
                  <template v-slot:footer>
                    <div class="user_foot_box">
                      <div class="user_foot" @click="go('set_license')">
                        <div class="left">{{$t('汽车驾照')}}</div>
                        <div class="right">{{{1:$t('审核中'),2:$t('通过'),3:$t('不通过'),4:$t('未提交')}[license_audit_status]}}</div>
                      </div>
                    </div>
                  </template>
                </b-card>
              </b-col>
              <!-- 🐮钱包信息 -->
              <b-col lg="9" md="12" class="user_col_right">
                <b-card-group>
                  <b-card class="wallet_box" border-variant="light" header-tag="header" footer-tag="footer">
                    <template v-slot:header>
                      <h6 class="mb-0">{{$t('我的钱包')}}</h6>
                    </template>
                    <b-row align-v="center">
                      <b-col lg="6" md="6" sm="12" class="wallet_btn" @click="go('wallet')">
                        <div class="text">
                          <h4>{{balance || '0.00'}}</h4>
                          <span>{{$t('充值钱包')}}(CAD)</span>
                        </div>
                      </b-col>
                      <b-col lg="6" md="6" sm="12" class="wallet_btn" @click="go('deposit')">
                        <div class="text">
                          <h4>{{deposit || '0.00'}}</h4>
                          <span>{{$t('租车押金')}}(CAD)</span>
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-card-group>
              </b-col>
            </b-row>

            <b-row>
              <!-- 🍊订单 -->
              <b-col lg="8" md="12" sm="12">
                <b-card-group class="order_box">
                  <b-card no-body border-variant="light" header-tag="header" footer-tag="footer">
                    <template v-slot:header>
                      <b-tabs v-model="active" @input="opt" pills card>
                        <b-tab :title="$t('租车订单')"> </b-tab>
                        <b-tab :title="$t('核销订单')"> </b-tab>
                      </b-tabs>
                    </template>
                    <b-card-body>
                      <section>
                        <b-container>
                          <b-row v-if="active == 0">
                            <b-col lg="3" md="6" sm="6" cols="6" v-for="(item, index) in order_tab1" :key="index">
                              <div class="grid_box" @click="go('order_list',{status:item})">
                                <div class="grid">
                                  <div class="thumb">
                                   <!-- <b-badge pill variant="dark">1</b-badge> -->
                                    <img :src="
                                        require(`@/assets/img/icon/mine_icon0${
                                          1 + index
                                        }.png`)
                                      " />
                                  </div>
                                  <div-text color="#444444" size="13px">{{
                                    [$t("全部"), $t("待付款"), $t("待付尾款"), $t("待取车"), $t("进行中"), $t("特殊费用")][index]
                                  }}</div-text>
                                </div>
                              </div>
                            </b-col>
                            <!-- <b-col class="d-xl-none">
                              <div class="grid_box" >
                                <div class="grid">
                                  <div class="thumb">
                                  </div>
                                  <div-text color="#444444" size="13px"></div-text>
                                </div>
                              </div>
                            </b-col> -->
                          </b-row>
                          <b-row v-if="active == 1">
                            <b-col lg="3" md="6" sm="6" cols="6" v-for="(item, index) in order_tab2" :key="index">
                              <div class="grid_box" @click="go('order_write_off',{status:item})">
                                <div class="grid">
                                  <div class="thumb">
                                    <!-- <b-badge pill variant="dark">1</b-badge> -->
                                    <img :src="require(`@/assets/img/icon/mine_icon0${7 + index}.png`)" />
                                  </div>
                                  <div-text color="#444444" size="13px">{{
                                    [$t("全部"), $t("待使用"), $t("已使用"), $t("已过期")][index]
                                  }}</div-text>
                                </div>
                              </div>
                            </b-col>
                          </b-row>
                        </b-container>
                      </section>
                    </b-card-body>

                    <template v-slot:footer>
                      <div class="list" v-if="active==0">
                        <b-media v-for="(item,index) in list" :key="index" @click="go('order_item',item.id)">
                          <template v-slot:aside>
                            <div class="status" v-if="item.redun">
                              <div-text size="12px" color="#fff" center one>
                                        {{{10: $t('待付款'),15: $t('待付尾款'),20: $t('待取车'),30: $t('进行中'),35: $t('特殊费用'),38: $t('待验收'),40: $t('已还车'),60: $t('逾期'),100: $t('已取消'),110: $t('已退款')}[item.status]}}
                                      </div-text>
                              </div>
                            <b-img rounded v-if="item.redun" :src="item.redun.vehicle_thumbnail" width="54"></b-img>
                          </template>
                          <b-media-body class="foot_text" v-if="item.redun">
                            <b-row align-v="center">
                              <b-col xl="12">
                                   <div-both>
                                       <div>{{item.redun['vehicle_name'+$t('suffix')]}}</div>
                                   </div-both>
                                <div>
                                  {{$t('车辆类型')}} ：{{item.redun['classi_name'+$t('suffix')]}} ｜ 
                                  {{$t('车辆品牌')}} ：{{item.redun['brand_name'+$t('suffix')]}} ｜
                                  {{$t('车辆型号')}} ：{{item.redun['model_name'+$t('suffix')]}} ｜ 
                                  {{$t('车辆年份')}} ：{{item.redun['life_name'+$t('suffix')]}}

                                </div>
                              </b-col>
                            </b-row>
                          </b-media-body>
                        </b-media>
                      </div>
                      <div class="list" v-if="active==1">
                     
                        <b-media v-for="(item,index) in list" :key="index">
                          <template v-slot:aside>

                            <div class="status" v-if="item.goods_cover">
                              <div-text size="12px" color="#fff" left >
                                       {{{10:$t('待使用'),20:$t('已使用'),30:$t('已过期')}[item.use_status]}}
                                      </div-text>
                              </div>
                           
                            <b-img rounded v-if="item.goods_cover" :src="item.goods_cover" width="54"></b-img>
                          </template>
                          <b-media-body class="foot_text" v-if="item.goods_cover">
                            <b-row align-v="center">
                              <b-col xl="12">
                                   <div>{{item['goods_name'+$t('suffix')]}}</div>
                                <div>
                                  {{$t('订单号码')}} ： {{item.no}} ｜
                                  <!-- {{$t('交易单号')}} ：{{item.pay_no || ''}} ｜ -->
                                  {{$t('支付方式')}} ： {{item.channel_str}} ｜
                                  {{$t('支付时间')}} ： {{item.pay_time}} ｜
                                  {{$t('有效期至')}} ： {{item.pay_time}} - {{item.code_time}}
                                </div>
                              </b-col>
                            </b-row>
                          </b-media-body>
                        </b-media>
                      </div>
                      <el-empty v-if="list&&list.length==0" :description="$t('暂无数据')"></el-empty>
                    </template>
                  </b-card>
                </b-card-group>
              </b-col>
              <!-- 🍫系统菜单 -->
              <b-col lg="4" md="12" sm="12">
                <b-card no-body class="set_box" border-variant="light" header-tag="header" footer-tag="footer">
                  <template v-slot:header>
                    <h6 class="mb-0">{{$t('系统设置')}}</h6>
                  </template>
                  <b-row no-gutters>
                    <b-col md="6" sm="6" cols="6" v-for="(item, index) in jump_list" :key="index">
                      <div class="grid_border_box" @click="jump(index)">
                        <div class="thumb">
                          <img :src="
                              require(`@/assets/img/icon/me_set_0${
                                1 + index
                              }.png`)
                            " />
                            <span v-if="index == 0 && countList" :style="{'--size':countList > 99?'25px':'20px'}" class="setbadge">{{countList > 99?'99+':countList}}</span>
                        </div>
                        <div class="text">
                          {{ [$t("信息通知"),
                              $t("客服咨询"),
                              $t("绑定银行卡"),
                              $t("登录密码"),
                              $t("支付密码"),
                              $t("退出系统")][index] }}
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </section>
    </b-container>
  </div>
</template>
<script>
import toastExit from "@/components/toast-ui/exit.vue"
export default {
  data() {
    return {
      navkey: 0,
      avatar: "", //头像
      email: "", //邮箱
      license_audit_status: "", //驾驶证状态
      order_tab1: [0, 1, 2, 3, 4, 5],
      order_tab2: [0, 1, 2, 3],
      list: [],
      jump_list: [0, 1, 2, 3, 4, 5],
      active: 0,
      balance: 0,
      deposit: 0,
      countList:null
    }
  },
  created() {
    this.get_info()
    this.get_order()
    this.get_unread_num()
  },
  methods: {
    fatherMethod() {
      console.log("父组件方法")
    },
    get_order() {
      this.$get({
        url: "/index/rentalorders/idx",
        data: {
          status: "",
          page: 1, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          console.log("获取订单列表", res)
          this.list = res.data.list.data
        },
        tip: () => {},
      })
    },
    get_order_02() {
      this.$get({
        url: "/index/Goodorders/idx",
        data: {
          status: "",
          page: 1, //頁碼
          page_limit: 10, //分頁條數
        },
        success: (res) => {
          console.log("获取订单列表2222", res)
          this.list = res.data.list.data
        },
        tip: () => {},
      })
    },
    get_info() {
      this.$get({
        url: "/index/users/uinfo",
        success: (res) => {
          let obj = res.data
          Object.keys(obj).map((item) => (this[item] = obj[item]))
          //修改nav的头像
          localStorage.setItem("zuche_user", JSON.stringify(obj))
          this.navkey++
        },
        tip: () => {},
      })
    },
    get_unread_num() {
      this.$post({
        url: "/index/Pushnotisitems/unread_num",
        data: {
        },
        success: (res) => {
          this.countList = res.data.num
        },
        tip: () => {},
      })
    },
    opt(index) {
      console.log(index)
      if (index == 0) {
        this.get_order()
      } else {
        this.get_order_02()
      }
    },
    exit_toast() {
      this.$toast(
        {
          component: toastExit,
          props: {
            the_title: this.$t("您确定要退出系统吗？"),
            the_left: this.$t("取消"),
            the_right: this.$t("确定"),
          },
        },
        {
          timeout: false,
          position: "top-center",
          closeOnClick: false, // 单击即关闭
          pauseOnFocusLoss: false,
          pauseOnHover: false, // 鼠标移入暂停
          draggable: false, // 允许通过拖动和滑动事件关闭
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false, // 显示/隐藏进度条
          closeButton: false,
          icon: false,
          rtl: false,
        }
      )
    },

    jump(index) {
      console.log(index)
      if (index == 0) {
        this.go("message")
      } else if (index == 1) {
        this.go("help")
      } else if (index == 2) {
        this.go("/bank")
        // this.back()
      } else if (index == 3) {
        this.go("set_password")
      } else if (index == 4) {
        this.go("set_payword")
      } else if (index == 5) {
        this.exit_toast()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.media-aside {
  position: relative;
  .status {
    position: absolute;
    left: 0;
    top: 0;
    height: 20px;
    border-radius: 0 4px 4px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.4);
    // width: 100%;
    font-size: 12px;
  }
}
//🥒用户信息
.user_col {
  margin-bottom: 30px;
  cursor: pointer;
  .card {
    margin-bottom: 0;
  }
  .user_box {
    .user {
      padding: 30px;
      background: #595a5f;
      position: relative;
      overflow: hidden;

      &:hover {
        // background: #1d2124;
        .mask {
          transform: translateY(0);
        }
      }
      .mask {
        transition: all 0.4s;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        transform: translateY(100%);
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .avatar_box {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
          width: 64px;
          height: 64px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
    .card-footer {
      background: #3b3b3b;
      .user_foot_box {
        // overflow: hidden;
        // &:hover {
        //   .double_width {
        //     transform: translateX(-50%);
        //   }
        // }
        .user_foot {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          font-size: 14px;
          cursor: pointer;
          align-items: center;

          .left {
            border: 0;
            color: #fff;
            white-space: pre-wrap;
            word-break: normal;
            flex: 1;
          }

          .right {
            height: 24px;
            line-height: 24px;
            border-radius: 24px;
            padding: 0 10px;
            color: #fff;
            background: #1d2124;
            flex-shrink: 0;

            text-align: center;
          }
        }
      }
    }
  }
}

.user_col_right {
  margin-bottom: 30px;
}

.wallet_box {
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.16);

  .wallet_btn {
    cursor: pointer;
  }

  .card-header {
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    padding: 0.75rem 1.25rem;
    font-size: 13px;
    font-weight: bold;
    color: #444444;
  }

  .text {
    padding: 25px 0;
    padding-left: 60px;

    h4 {
      font-size: 25px;
      color: #1d2124;
      font-weight: bold;
    }
  }
}

/*订单*/

.order_box {
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;

  .card-header {
    border: 0;
    padding: 0;
    background-color: #fff !important;
  }

  .card-footer {
    padding: 0;
    background: #fff;

    .foot_text {
      line-height: 2;
      font-size: 13px;
      color: #666666;
    }

    .list .media {
      // margin-bottom: 18px;
      padding: 20px;
      cursor: pointer;
      position: relative;

      &:hover {
        background: #f5f5f5;
        // .more {
        //   transform: scale(1);
        // }
      }
    }
    // .more {
    //   transition: all 0.5s ease;
    //   transform: scale(0);
    //   position: absolute;
    //   right: 30px;
    // }
    .media-aside {
      width: 90px;
      height: 70px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .media:last-child {
      margin-bottom: 0;
    }

    .align-self-start {
      align-self: center !important;
    }
  }
}

//左侧九宫格
.setbadge{
         position: absolute;
         top: 25%;
        //  padding: 1px 3px;
        width: var(--size);
        height: var(--size);
        text-align: center;
        line-height:var(--size);
         border-radius:50%;
         background: red;
         color: #ffffff;
         font-size: 12px !important;
         margin-left: 2px;
      }
.grid_box {
  display: table;
  width: 100%;

  margin: 0 auto;

  @include transition(0.4s);

  .grid {
    @include transition(0.4s);
    display: block;
    background: #fff;
    border-radius: 18px;
    text-align: center;
    border: 1px dashed #fff;
    box-shadow: 0 0 0px 10px #fff;
   
    .thumb {
      position: relative;
      
      .badge-dark {
        z-index: 1;
        position: absolute;

        margin: 0 auto;
      }

      img {
        @include transition(0.4s);
      }

      @media #{$xl} {
        margin-bottom: 10px;
        .badge-dark {
          transform: translate(28px, -6px);
        }
        img {
          max-width: 40px;
          margin: 0 auto;
        }
      }

      @media #{$lg} {
        padding: 20px;
        .badge-dark {
          transform: translate(28px, -6px);
        }
        img {
          max-width: 40px;
          margin: 0 auto;
        }
      }

      @media #{$md} {
        padding: 6px;
        .badge-dark {
          transform: translate(46px, -3px);
        }
        img {
          max-width: 60px;
          margin: 0 auto;
        }
      }

      @media #{$sm} {
        padding: 20px;
        .badge-dark {
          transform: translate(46px, -3px);
        }
        img {
          max-width: 80px;
          margin: 0 auto;
        }
      }

      @media #{$xs} {
        padding: 20px;
        .badge-dark {
          transform: translate(46px, -3px);
        }

        img {
          max-width: 60px;
          margin: 0 auto;
        }
      }
    }

    @include transition(0.4s);

    @media #{$xl} {
      padding: 10px;
      font-size: 16px;
    }

    @media #{$lg} {
      padding: 10px;
      font-size: 14px;
    }

    @media #{$md} {
      padding: 10px;
      font-size: 12px;
      margin-bottom: 20px;
    }

    @media #{$sm} {
      padding: 20px;
      font-size: 16px;
      margin-bottom: 20px;
    }

    @media #{$xs} {
      padding: 6px;
      font-size: 12px;
      margin-bottom: 20px;
    }
  }

  &:hover {
    cursor: pointer;

    .grid {
      // background: #3b3b3b;
      // border: 1px dashed #3b3b3b;
      // box-shadow: 0 0 0px 10px hsla(0, 0%, 48%, 0.171);
      // color: #ffffff;
    }
    .div-text {
      // color: #fff !important;
    }

    img {
      // filter: brightness(100);
    }
  }
}

//右侧九宫格

.set_box {
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.16);
  margin-bottom: 30px;

  .card-header {
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
    padding: 0.75rem 1.25rem;
    font-size: 13px;
    font-weight: bold;
    color: #444444;
  }
}

.grid_border_box {
  text-align: center;
  padding: 39px 0 32px;
  border: 1px solid #f5f5f5;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 0;
  margin-left: 0;
  background-color: #fff;
  overflow: visible;
  font-size: 13px;
  color: #93837b;
  @include transition(0.4s);
  cursor: pointer;

  .thumb {
    img {
      @include transition(0.4s);
    }

    @media #{$xl} {
      margin-bottom: 10px;

      img {
        max-width: 30px;
        margin: 0 auto;
      }
    }

    @media #{$lg} {
      padding: 20px;

      img {
        max-width: 30px;
        margin: 0 auto;
      }
    }

    @media #{$md} {
      padding: 6px;

      img {
        max-width: 60px;
        margin: 0 auto;
      }
    }

    @media #{$sm} {
      padding: 20px;

      img {
        max-width: 80px;
        margin: 0 auto;
      }
    }

    @media #{$xs} {
      padding: 20px;

      img {
        max-width: 60px;
        margin: 0 auto;
      }
    }
  }
}

.tabs {
  /deep/.card-header {
    background-color: #fff !important;
  }

  /deep/.nav-link {
    font-size: 14px;
    color: #3b3b3b !important;
  }

  /deep/.active {
    color: #fff !important;
    background-color: #3b3b3b !important;
  }

  .tab-content {
    display: none;

    .active {
      display: none;
    }
  }

  .tab {
    padding: 10px 38px 10px 10px;
    cursor: pointer;
    color: #1d2124;
    font-weight: bold;
  }
}
</style>