<template>
  <div>
    <!-- 顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <!-- ============================ 个人中心 ================================== -->
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 左侧菜单 -->
            <Menu name="wallet"></Menu>
          </b-col>
          <b-col lg="10" md="9">

            <!-- 标题 -->
            <div-padding padding="20px 0">
              <div-text color="#1D2124" size="20px" bold>{{$t('充值钱包')}}</div-text>
            </div-padding>

            <div-zebra></div-zebra>

            <div class="zebra_box shadow">
              <b-card-group>
                <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                  <template v-slot:header>
                    <div class="card_title">
                      <div-left center>
                        <div-text>{{$t('充值总额')}} (CAD)</div-text>
                        <div-width size="10px"> </div-width>
                        <div-text size="30px" bold>{{balance}}</div-text>
                      </div-left>
                      <div class="card_right">
                        <div-text @click.native="go('wallet_record')">
                          {{$t('纪录')}}
                        </div-text>
                      </div>
                    </div>
                  </template>

                  <b-card-body>
                    <div>
                      <div-left center>
                        <div-img width="14px" height="14px">
                          <b-img :src="require('@/assets/img/chongzhi.png')" alt=""></b-img>
                        </div-img>
                        <div-width size="9px"></div-width>
                        <div-text size="14px">{{$t('充值项目')}}：</div-text>
                      </div-left>
                    </div>

                    <div-height height="16px" />

                    <b-row>
                      <b-col lg="3" md="6" cols="6" v-for="(item,index) in list" :key="index">
                        <div @click="active=index,id=item.id">
                          <div class="item" :class="{active:active==index}">
                            <div-text center :color="active==index?'#fff':'#333'">
                              {{$t('充值2')}} {{item.recharge_num}}
                            </div-text>
                            <div-text center v-if="item.gift_amount>0" :color="active==index?'#fff':'#333'">
                              {{$t('赠送')}} {{item.gift_amount}}
                            </div-text>
                          </div>
                          <div-height height="16px" />
                        </div>
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <template v-slot:footer>
                    <div-height height="16px" />
                    <div-save ref="btn_01" @save="save()">{{$t('充值')}}</div-save>
                    <div-height height="16px" />
                  </template>

                </b-card>
              </b-card-group>
            </div>

          </b-col>

        </b-row>
      </section>

      <div-height height="30px" />

    </b-container>

  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      balance: 0,
      active: 0,
      list: [],
    }
  },
  created() {
    this.get_info()
  },

  methods: {
    get_info() {
      this.$get({
        url: "/index/Rechargeconf/idx",
        success: (res) => {
          this.balance = res.data.addition.balance
          this.list = res.data.list.data
          this.id = this.list[0].id
          console.log("充值项目列表", res)
        },
        tip: () => {},
      })
    },

    save() {
      const loading = this.$loading({
        lock: true,
      })
      this.$post({
        url: "/index/rechargeorders/recharge",
        data: { recharge_id: this.id }, //订单id,
        success: (res) => {
          console.log("充值", res.data.id)
          setTimeout(() => {
            loading.close()
            this.go("wallet_pay", {
              id: res.data.id,
              price: this.list[this.active].recharge_num,
              gift_amount: this.list[this.active].gift_amount,
            })
          }, 2000)
        },
        tip: () => {
          console.log("3")
          setTimeout(() => {
            loading.close()
            this.$refs.btn_01.disabled = false
          }, 3000)
        },
      })
    },

    //  /index/rechargeorders/recharge
  },
}
</script>
<style lang="scss" scoped>
.div-text {
  line-height: 2 !important;
}
.card-header:first-child {
  background: #fff !important;
}
.item {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 74px;
  width: 100%;
  background: #ffffff;
  border-radius: 6px 6px 6px 6px;
  opacity: 1;
  border: 1px solid #dfdfdf;
  &.active {
    background: #1d2124;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px solid #333333;
  }
}
.zebra_box {
  padding: 1.25rem;
}

.card_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card_right {
  cursor: pointer;
}

.container-login100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login100-form-btn {
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: capitalize;
  width: 100%;
  height: 50px;
  border-radius: 0.31rem;
  background: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #1d2124;
}
</style>