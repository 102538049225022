<template>
  <div class="toast_box">

    <div-height height="10px" />

    <div class="toast_title">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-info-circle fa-w-16 Vue-Toastification__icon">
        <path fill="currentColor" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
      </svg>
      <div role="alert" class="Vue-Toastification__toast-body">{{the_title}}</div>
    </div>

    <div-height height="30px" />

    <div class="btn_arr">
      <el-button @click="close()">{{the_left}}</el-button>
      <el-button type="success" @click.native="exit()">{{the_right}}</el-button>
    </div>

    <div-height height="10px" />

  </div>
</template>
<script>
export default {
  props: {
    the_title: {
      type: String,
      default: "提示",
    },
    the_left: {
      type: String,
      default: "取消",
    },
    the_right: {
      type: String,
      default: "确定",
    },
  },
  data() {
    return {
      loading: false,
      disabled: false,
    }
  },
  created() {
    this.loading = this.$loading({
      lock: true,
    })
  },
  methods: {
    close() {
      this.loading.close()
      this.$toast.clear()

      // emit("showDialog")
    },
    clicked() {
      alert("Clicked!")
    },
    // 退出登录
    exit() {
      if (this.disabled) {
        return false
      }
      this.disabled = true

      this.$post({
        url: "/index/users/logOut",
        data: {},
        success: (res) => {
          localStorage.removeItem("zuche_token")

          this.loading.close()
          this.$toast.clear()
          window.location.reload()
        },
        tip: () => {
          localStorage.removeItem("zuche_token")
          this.loading.close()
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.toast_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.btn_arr {
  display: flex;
  justify-content: center;
  align-items: center;
  .el-button {
    flex: 1;
  }
}
</style>