<template>
  <div>
    <!-- 📷顶部导航栏 -->
    <Nav name="mine" />
    <b-container>
      <section>
        <b-row>
          <b-col lg="2" md="3">
            <!-- 🥒小菜单 -->
            <Menu name="bank"></Menu>
          </b-col>
          <b-col lg="10" md="9">
            <!-- 🍞面包屑 -->
            <section>
              <div-padding padding="20px 0">
                <b-row no-gutters>
                  <b-col cols="auto" class="mr-auto">
                    <div-left center>
                      <div-text color="#1D2124" size="20px" bold>{{$t('绑定银行卡')}}</div-text>
                    </div-left>
                  </b-col>
                  <b-col cols="auto">
                    <b-breadcrumb>
                      <b-breadcrumb-item @click="go('/')">{{$t('首页')}}</b-breadcrumb-item>
                      <b-breadcrumb-item @click="go('mine')">{{$t('我的')}}</b-breadcrumb-item>
                      <b-breadcrumb-item active>{{$t('绑定银行卡')}}</b-breadcrumb-item>
                    </b-breadcrumb>
                  </b-col>
                </b-row>
              </div-padding>
            </section>

            <div-zebra></div-zebra>

            <b-card-group>
              <b-card no-body class="shadow" header-tag="header" footer-tag="footer">
                <b-card-body>
                  <div class="login_box">
                    <div class="login">
                      <form class="logo_input">
                        <!--🥒输入框 -->
                        <fieldset class="fieldset">
                          <legend>{{$t('银行卡账号')}}*</legend>
                          <b-input-group class="div-input">
                            <b-form-input class="input" v-model="bank" :placeholder="$t('请输入银行卡账号')" type="search"></b-form-input>
                          </b-input-group>
                        </fieldset>
                        <!--🥒输入框 -->
                        <fieldset class="fieldset">
                          <legend>{{$t('手机号')}}*</legend>
                          <b-input-group class="div-input">
                            <b-form-input class="input" v-model="tel" :placeholder="$t('请输入手机号')" type="search"></b-form-input>
                          </b-input-group>
                        </fieldset>

                        <!--🥒保存按钮 -->
                        <div-height height="30px"></div-height>
                        <div-save ref="btn" @save="save">{{$t('暂未开放')}}</div-save>
                      </form>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-card-group>

          </b-col>
        </b-row>
      </section>
      <div-height height="30px"></div-height>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      bank: "", //邮箱
      tel: "", //邮箱
      code: "", //验证码
    }
  },
  created() {
    this.get_info()
  },
  methods: {
    get_info() {
      this.$get({
        url: "/index/users/uinfo",
        success: (res) => {
          let obj = res.data
          Object.keys(obj).map((item) => (this[item] = obj[item]))
        },
        tip: () => {},
      })
    },
    save() {},
  },
}
</script>
<style lang="scss" scoped>
.login_box {
  width: 100%;
  //   min-height: calc(100vh - 360px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  // background: #9053c7;
}

.login {
  width: auto;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: space-between;
  padding: 30px 68px 30px 68px;

  @include transition(0.4s);

  @media #{$xl} {
    flex-direction: row;
  }

  @media #{$lg} {
    flex-direction: row;
  }

  @media #{$md} {
    flex-direction: column;
  }

  @media #{$sm} {
    flex-direction: column;
    width: 100%;
    padding: 30px;

    .logo_input {
      width: 100%;
    }
  }

  @media #{$xs} {
    flex-direction: column;
    width: 100%;
    padding: 30px;

    .line {
      display: none;
    }

    .logo_input {
      width: 100%;
    }
  }
}

.line {
  width: 1px;
  height: 226px;
  background: linear-gradient(#fff 0%, #d7d7d7 50%, #fff 100%);
}

.logo_input {
  width: 438px;
}

.fieldset {
  margin-bottom: 15px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  height: 76px;
  @include transition(0.4s);

  &:focus-within {
    border: 1px solid #57b846;

    legend {
      color: #57b846;
      margin-left: 20px;
    }
  }

  legend {
    @include transition(0.4s);
    position: relative;
    z-index: 1;
    color: #2f2e3f;
    font-size: 16px;
    margin-left: 10px;
    padding: 0 5px;
    width: auto;
    margin-bottom: 0;
  }

  /deep/.div-input {
    position: relative;
    top: -10px;
    width: 100%;
    height: 60px;
    background: #ffffff;
    // opacity: 1;
    // border: 0.06rem solid #d8d8d8;

    &:focus-within {
      // background: #3c58b2;
      border-color: #3c58b2;
      // box-shadow: 0 0 4px 2px rgba(0, 123, 255, 25%);
      // box-shadow: 0 0 0 0.2rem rgb(28, 41, 83);
    }

    .input-group-text {
      padding: 0 24px;
      background: transparent;
      border-color: transparent !important;
    }

    .div-code {
      padding: 0 12px;

      .btn {
        height: 40px;
        background: #fff;
        color: #333;
        font-size: 14px;
        border: 0;
        padding: 0 14px;
        box-shadow: none;

        &:focus {
          box-shadow: none;
        }
      }
    }

    input {
      box-sizing: border-box;
      height: 60px;
      line-height: 60px;
      background: transparent;
      border-color: transparent !important;

      &.form-control {
        &:focus {
          border-color: none;
          box-shadow: none;
        }
      }
    }

    input::-webkit-input-placeholder {
      color: #d7d7d7;
      font-size: 14px;
    }

    input:-webkit-autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }

    input:autofill {
      border-radius: 1.38rem 1.38rem 1.38rem 1.38rem;
      box-shadow: 0 0 4px 1000px white inset !important;
      border-color: none;
      // -webkit-text-fill-color: #333;
    }
  }

  .cursor {
    cursor: pointer;
  }
}

.link {
  color: #818181;
  font-size: 13px;

  span {
    cursor: pointer;
  }
}
</style>