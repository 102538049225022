
const components_files = require.context("@/components", true, /\.vue$/);

const components = {};
// 循环所有组件
components_files.keys().forEach((key) => {
    // 获取组件路径
    let route = key.replace(/(\.\/|\.vue)/g, "");
    // 获取最后一个/的位置
    let index = route.lastIndexOf("/");
    // 获取组件名称
    let name = route.substring(index + 1);
    // 获取组件
    let component = components_files(key).default;
    // 添加到路由
    components[name] = component;
});

export default components;
