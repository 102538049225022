<template>
  <b-navbar toggleable="lg" type="dark" variant="primary" sticky>

    <b-container>

      <b-navbar-brand to="/" exact exact-active-class="active">
        <div class="logo">
          <b-img :src="require('@/assets/img/logo_dark.png')" fluid alt="Responsive image"></b-img>
        </div>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="ml-auto" right>

          <b-navbar-nav fill class="my_nav">
            <b-nav-item to="/">
              <div class="nav_text">{{$t('首页')}}</div>
            </b-nav-item>
            <b-nav-item to="/rent">
              <div class="nav_text">{{$t('汽车租赁')}}</div>
            </b-nav-item>
            <b-nav-item to="/new">
              <div class="nav_text">{{$t('新车到店')}}</div>
            </b-nav-item>
            <b-nav-item to="/shop">
              <div class="nav_text">{{$t('商城中心')}}</div>
            </b-nav-item>
            <b-nav-item to="/about">
              <div class="nav_text">{{$t('关于我们')}}</div>
            </b-nav-item>

            <div class="language">
              <div-padding padding="0 15px">
                <b-nav-item-dropdown :text="lang_now" ref="dropdown">
                  <b-dropdown-item>{{lang_now}}</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item @click="Language('en')">English</b-dropdown-item>
                  <b-dropdown-item @click="Language('cn')">简体中文</b-dropdown-item>
                  <b-dropdown-item @click="Language('tw')">繁體中文</b-dropdown-item>
                </b-nav-item-dropdown>
              </div-padding>
            </div>

            <b-nav-item to="/mine" v-if="login">
              <div-padding padding="0 15px">
                <div class="user_box">
                  <div class="user_img">
                    <b-img :src="require('@/assets/img/div/avatar.png')" rounded="circle" alt="Circle image"></b-img>
                  </div>
                  <div class="user_name">
                    <div class="user_name_text">{{ user_name }}</div>
                  </div>
                </div>
              </div-padding>
            </b-nav-item>

            <b-navbar-nav v-else>
              <b-button to="/login" pill variant="light">
                <div class="the_btn">登录</div>
              </b-button>
              <div-padding padding="5px"></div-padding>
              <b-button to="/register" pill variant="outline-light">
                <div class="the_btn">注册</div>
              </b-button>
            </b-navbar-nav>

          </b-navbar-nav>

        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: "Nav",
  data() {
    return {
      lang_arr: {
        en: "English",
        cn: "简体中文",
        tw: "繁體中文",
      },
      lang_now: "繁體中文",
      mobileToggle: false,
      login: false,
      user_name: "张三",
    }
  },
  created() {
    this.lang_now = this.lang_arr[this.$i18n.locale]
    if (localStorage.getItem("token")) {
      this.login = true
    }
  },
  methods: {
    // 导航栏点击事件
    // handleNavbarClick(item) {
    //   this.lang_now = item
    // },
    Language(item) {
      this.$i18n.locale = item
      localStorage.setItem("lang", item)
      this.lang_now = this.lang_arr[item]
    },
  },
}
</script>
<style scoped lang="scss">
.navbar-brand {
  padding: 0;
}
//logo
.logo {
  transition: all 0.4s;
  max-width: 121px;
  height: 80px;
  @media #{$xl} {
    height: 80px;
  }
  @media #{$lg} {
    height: 80px;
  }
  @media #{$md} {
    height: 48px;
  }
  @media #{$sm} {
    height: 48px;
  }
  @media #{$xs} {
    height: 48px;
  }
  img {
    // width: 100%;
    height: 100%;
  }
}

//导航与登录按钮之间的间距
.space {
  transition: all 0.4s;
  width: 50px;
  height: 5px;

  @media #{$lg} {
    width: 0;
    height: 5px;
  }

  @media #{$md} {
    width: 0;
    height: 5px;
  }

  @media #{$xs} {
    width: 0;
    height: 5px;
  }
}

.the_btn {
  @include transition(0.4s);
  padding: 0 20px;

  @media #{$lg} {
    padding: 0 10px;
  }

  @media #{$md} {
    padding: 0 10px;
  }

  @media #{$xs} {
    padding: 0 10px;
  }
}

.language {
  /deep/.nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  /deep/.dropdown-menu {
    padding: 0;
    font-size: 14px;
  }
  /deep/.dropdown-divider {
    margin: 0;
  }
  /deep/.dropdown-item {
    padding: 10px 20px;
  }
}

.user_box {
  display: flex;
  align-items: center;
  justify-content: center;

  .user_img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 10px;

    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .user_name {
    display: inline-block;

    .user_name_text {
      font-size: 14px;
      color: #fff;
    }
  }
}

.my_nav {
  font-size: 14px;
  .nav_text {
    padding: 0 15px;
  }
}
</style>